import { useNavigate } from "react-router-dom";
 
const ChatVerifyEmail = () => {
 const navigate = useNavigate()

  const handleButtonClick = () => {
    window.open("https://mail.google.com/mail/u/0/#inbox", "_blank");
    setTimeout(() => {
        navigate('/');
        window.location.reload()
      }, 5000); 
  };
  const handleHomeClick = () => {
    navigate('/');
    window.location.reload()
  };

  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
        <div className="ir-ws-signup-content-container">
        
          <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
            Verification Link sent to your mail 
            </h3>
          </div>
          <div className="ir-ws-deleteModal-button-container">
            <button
              className="ir-ws-deleteModal-button-yes"
              onClick={handleButtonClick}
            >
              Verify
            </button>
            <button
              className="ir-ws-deleteModal-button-no"
              onClick={handleHomeClick}
            >
              Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default ChatVerifyEmail;