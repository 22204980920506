import React from "react";

const SuccessPayment = () => {
    return (
        <div>This is success status</div>
    )
};

export default SuccessPayment;



//pull context

// read url params 
//get the product details
//chatgroup_id from localStore
//get the user_id from profile
//URL : /payment/status
// method: POST
// payload:
// {
// paymentLink_id,
// product_name:
// price:
// product_id:
// price_id:
// user_id:
// group_id:
// }
