import React from "react";
import "./WebinarPageBanner.scss";
import WebinarListTab from "../WebinarListTab/WebinarListTab";
import { useNavigate } from "react-router-dom";

const WebinarPageBanner = () => {
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const navigate = useNavigate();
  return (
    <div className="ir-ws-webinar-page-banner-main-container ir-ws-banner-padding-tb">
      <div className="ir-ws-webinar-page-banner-inner-container">
        <div className="ir-ws-webinar-page-banner-content-container">
          <div className="ir-ws-padding-lr-132 ir-ws-webinar-page-banner-content-inner-container">
            <h2 className="ir-ws-app-color ir-ws-text-capitalize ir-ws-font-700 ir-ws-text-center ir-ws-webinar-page-title-pad-top ir-ws-webinar-page-title">
              Empower you practice
            </h2>
            <h4 className="ir-ws-heading-default-color ir-ws-text-capitalize ir-ws-text-center ir-ws-font-700 ir-ws-webinar-page-scnd-title">
              Dive deep into interventional radiology learning{" "}
              <span className="ir-ws-app-color">webinars</span>
            </h4>
            <p className="ir-default-color ir-ws-text-center ir-ws-webinar-page-banner-content-para">
              Dive into mastery with our Interventional Radiology Learning
              Webinars, tailored for professionals, students, and experts.
              Elevate your practice and explore the latest advancements in just
              a few clicks.
            </p>
            <div className="ir-ws-default-btn-container ir-ws-text-center ir-ws-webinar-page-join-now-btn">
              {/* <button
                className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws-webinar-page-banner-btn"
                onClick={() => {
                  if (loggedIn) {
                    window.scrollTo({ top: 1500, behavior: "smooth" });
                  }
                }}
              >
                {loggedIn ? <span>Explore Us</span> : <span>Join Us</span>}
              </button> */}
              {loggedIn ? (
                <button
                  className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws-webinar-page-banner-btn"
                  onClick={() => {
                    if (loggedIn) {
                      window.scrollTo({ top: 1500, behavior: "smooth" });
                    }
                  }}
                >
                  <span>Explore Us</span>
                </button>
              ) : (
                <button
                  className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws-webinar-page-banner-btn"
                  onClick={() => {
                    navigate("/signup");
                  }}
                >
                  <span>Join Us</span>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="ir-ws-webinar-banner-video-main-container">
          <div className="ir-ws-webinar-banner-video-inner-container">
            <div className="ir-ws-padding-lr-132 ir-ws-text-center ir-ws-position-relative ir-ws-webinar-banner-video-tile-one-container">
              <img
                src={require("../../assets/images/webinar-banner-video.webp")}
                className="ir-ws-webinar-video-baner"
                alt="webinar-video-tile"
              />
            </div>
          </div>
        </div>
        <div className="ir-ws-banner-padding-tb ir-ws-webinar-banner-last-conent-container">
          <div className="ir-ws-webinar-banner-last-conent-inner-container">
            <h2 className="ir-ws-app-color ir-ws-text-capitalize ir-ws-font-700 ir-ws-text-center ir-ws-webinar-page-title">
              Elevate Your Interventional Radiology Expertise
            </h2>
            <p className="ir-default-color ir-ws-text-center ir-ws-webinar-page-banner-content-para">
              Join Our Webinars for In-Depth Discussions, Expert Panels, and the
              Latest Innovations in Interventional Radiology!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebinarPageBanner;
