import React, { useState } from "react";
import "./WebinarAdminUserListTab.scss";
import DeleteUserModal from "./DeleteUserModal";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";
const WebinarAdminUserListTab = ({
  firstname,
  lastname,
  userId,
  email,
  username,
}) => {
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const handleOpenModal = () => {
    setDeleteUserModal(true);
  };
  const toggleContentVisibility = () => {
    setIsContentVisible(!isContentVisible);
  };
  const accordianListButton = ["Email", "Username", "Firstname", "Lastname"];
  const userData = [
    {
      email: email,
      username: username,
      firstname: firstname,
      lastname: lastname,
      userId: userId,
    },
  ];
  return (
    <div className={`ir-ws-webinar-list-tab `}>
      <div className="ir-ws-admin-webinar-content">
        <div className="ir-ws-admin-webinar-title-container">
          <p className="ir-ws-webinar-title-text">{username}</p>
        </div>
        <div className="ir-ws-admin-list-button-container">
          <button className="ir-ws-user-delet-btn" onClick={handleOpenModal}>
            Delete User
          </button>
          <button
            className={`ir-ws-webinar-transaction-button ir-ws-webinar-actions-button ${
              isContentVisible ? "ir-ws-webinar-transaction-button-active" : ""
            }`}
            onClick={toggleContentVisibility}
          >
            User Details
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="ir-ws-admin-drop-down-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
      {isContentVisible && (
        <div>
          <ul className="ir-ws-webinar-list-accordian-tab ir-ws-webinar-admin-user-list">
            {accordianListButton.map((item, index) => (
              <li className="ir-ws-list-item-box" key={index}>
                {item}
              </li>
            ))}
          </ul>
          <div className="ir-ws-webinar-admin-userlist-detail">
            {userData.map((item, index) => (
              <ul className="ir-ws-webinar-list-data " key={index}>
                <li>{item.email}</li>
                <li>{item.username}</li>
                <li>{item.firstname}</li>
                <li>{item.lastname}</li>
              </ul>
            ))}
          </div>
        </div>
      )}
      {deleteUserModal && (
        <DeleteUserModal
          setDeleteUserModal={setDeleteUserModal}
          userId={userId}
          username={username}
        />
      )}
    </div>
  );
};

export default WebinarAdminUserListTab;
