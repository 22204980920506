import React, { useRef } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Comingsoon from "../../components/Comingsoon/Comingsoon";
import CasesList from "../../components/CasesList/CasesList";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";
import CaseBanner from "../../components/CaseBanner/CaseBanner";
import Casecategory from "../../components/CaseCategory/Casecategory";
import CaseFeatures from "../../components/CaseFeatures/CaseFeatures";
import CaseWhyChooseUs from "../../components/CaseWhyChooseUs/CaseWhyChooseUs";
import HomeJoinContainer from "../../components/HomeJoinContainer/HomeJoinContainer";

const Cases = () => {
    const casesListRef  = useRef(null);
    const { cases } = useModalContext();
    const scrollToCasesList = () => {
        casesListRef.current.scrollIntoView({ behavior: "smooth" });
      };
    return (
        <div className="ir-ws-home-main-container">
            <Header />
            {/* <Comingsoon /> */}
            <CaseBanner scrollToCasesList={scrollToCasesList}/>
            <Casecategory />
            <CaseFeatures />
            <CaseWhyChooseUs/>
            <CasesList cases={cases} casesListRef ={casesListRef }/>
            <HomeJoinContainer />
            <Footer />
        </div>
    )
};
export default Cases;