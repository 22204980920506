import { useEffect, useState } from "react";
import "./UserJoinMeetingForm.scss";
import UserScreen from "./UserScreen";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";
import { useNavigate, useParams } from "react-router-dom";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";

const UserJoinMeetingForm = () => {
  const [userScreen, setUserScreen] = useState(false);
  const {
    username,
    setUsername,
    passCode,
    setPassCode,
    handleJoinUserUrl,
    videoTiles,
    modalOpen,
    webinarData,
    setVideoTiles,
    runUseEffect,
    setRunUseEffect,
    role,
    setRole,
    isJoin,
  } = useModalContext();
  const meetingManager = useMeetingManager();
  // console.log(localContentShare);
  const [countdownComplete, setCountdownComplete] = useState(false);
  //Decoding Base 64 and extracting webinar data from webinar Id
  const { joinUrl } = useParams();
  function decodeBase64(encodedString) {
    const decodedString = atob(encodedString);
    return decodedString;
  }

  const decodedString = decodeBase64(joinUrl);
  const webinarId = JSON.parse(decodedString)["webianrId"];
  const selectedWebinar = webinarId
    ? webinarData.find((webinar) => webinar.id === webinarId)
    : null;

  //Function for converting UTC time into local time and showing it
  function convertUTCToLocalAndFormat(utcTimeString) {
    const utcDateTime = new Date(utcTimeString);
    const localTimeZoneOffset = new Date().getTimezoneOffset();
    const localDateTime = new Date(
      utcDateTime.getTime() - localTimeZoneOffset * 60000
    );
    const localTimeFormat = localDateTime.toISOString().slice(0, 16);
    const inputDate = new Date(localTimeFormat);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const outputString = inputDate.toLocaleString("en-US", options);
    return outputString;
  }

  //Function for Showing Countdown timer of meeting
  const targetDate = new Date(selectedWebinar?.dateAndTime);
  const calculateTimeRemaining = () => {
    const now = new Date();
    const difference = targetDate - now;
    if (difference <= 0) {
      setCountdownComplete(true);
      return { hours: 0, minutes: 0, seconds: 0 };
    }
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return { hours, minutes, seconds };
  };
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining);
  useEffect(() => {
    const interval = setInterval(() => {
      const remainingTime = calculateTimeRemaining();
      setTimeRemaining(remainingTime);

      if (
        remainingTime.hours <= 0 &&
        remainingTime.minutes <= 0 &&
        remainingTime.seconds <= 0
      ) {
        setCountdownComplete(true);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  //Function for joining the meeting
  const handleJoin = async () => {
    if (!username || !passCode) return;
    setRunUseEffect(true);
    await handleJoinUserUrl(joinUrl);
    setUserScreen(true);
  };

  useEffect(() => {
    let intervalId;

    const runEffect = () => {
      if (runUseEffect && userScreen) {
        const handleJoinUser = async (joinUrl) => {
          try {
            const response = await axios.get(
              `https://webinar-staging.backend.ir4u.info/api/v1/webinar/attendee/join/${joinUrl}?username=${username}&passcode=${passCode}&role=${role}`,
              {},
              {
                headers: {
                  Authorization:
                    "Bearer " + localStorage.getItem("jwtTokenApi1"),
                },
              }
            );

            if (response.status === 200) {
              setRunUseEffect(false);
              setUserScreen(false);
            }

            const hostAttendeeDetails = response.data.attendeeDetails;
            const meetingDetails = response.data.meetingDetails;
            const meetingSessionConfiguration = new MeetingSessionConfiguration(
              meetingDetails,
              hostAttendeeDetails
            );

            await meetingManager.join(meetingSessionConfiguration);
            await meetingManager.start();
            setVideoTiles(true);
            setUserScreen(true);
          } catch (error) {
            console.log(error);
          }
        };

        handleJoinUser(joinUrl);
      }
    };

    // Run the effect initially
    runEffect();

    // Set up an interval to run the effect every 15 seconds
    intervalId = setInterval(() => {
      runEffect();
    }, 15000);

    // Clean up the interval when the component unmounts or if you want to stop the repeated execution for some reason
    return () => clearInterval(intervalId);
  }, [userScreen, runUseEffect]);
  const formattedHours = String(timeRemaining.hours).padStart(2, "0");
  const formattedMinutes = String(timeRemaining.minutes).padStart(2, "0");
  const formattedSeconds = String(timeRemaining.seconds).padStart(2, "0");
  return (
    <>
      {userScreen && videoTiles ? (
        <UserScreen
          webinarTitle={selectedWebinar?.title}
          username={username}
          webinarId={selectedWebinar?.id}
        />
      ) : (
        <div className="ir-bg-white ir-ws-webinar-meeting-login-container">
          <div className="ir-ws-webinar-meeting-login-inner-container">
            <div className="ir-ws-webinar-sub-inner-container">
              <div className="ir-ws-webinar-meeting-logo-container">
                <a href="/">
                  <img
                    src={require("../../../assets/images/ir4u2.png")}
                    alt="logo"
                    className="ir-ws-webinar-meeting-logo"
                  />
                </a>
              </div>
              <div className="ir-ws-gray-bg ir-ws-webinar-meeting-preview-container">
                <div className="ir-ws-webinar-meeting-preview-inner-container">
                  <div className="ir-ws-webinar-meeting-preview-sub-container">
                    <p className="ir-ws-webnar-meeting-webinar-name">
                      {selectedWebinar?.title}
                    </p>
                    <p className="ir-ws-webnar-meeting-webinar-time-date">
                      {selectedWebinar?.dateAndTime &&
                        convertUTCToLocalAndFormat(selectedWebinar.dateAndTime)}
                    </p>
                    {/* {selectedWebinar?.dateAndTime && !countdownComplete && (
                      <p className="ir-ws-webnar-meeting-webinar-time-date">
                        -----Timer-----
                      </p>
                    )} */}
                    {selectedWebinar?.dateAndTime ? (
                      countdownComplete ? (
                        <p className="ir-ws-webnar-meeting-webinar-join-meeting-text">
                          You can now join the meeting!
                        </p>
                      ) : (
                        <p className="ir-ws-webnar-meeting-webinar-timer">
                          <p className="ir-ws-webnar-meeting-webinar-timer">
                            {formattedHours} : {formattedMinutes} :{" "}
                            {formattedSeconds}
                          </p>
                        </p>
                      )
                    ) : null}
                    <div className="ir-ws-webinar-meeting-video-tile-preview">
                      <div className="ir-ws-webinar-video-title-preview-inner">
                        <div className="ir-ws-webinar-video-title-preview-inner-sub">
                          <div className="ir-ws-webinar-meeting-blank-video">
                            <div className="ir-ws-position-absolute ir-ws-admin-video-off-container">
                              <img
                                className="ir-ws-admin-video-off-img"
                                src={require("../../../assets/images/video-off.png")}
                                alt=""
                              />
                              <p className="ir-color-white ir-ws-admin-presenter-screen-off-text">
                                Camera is off
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ir-ws-webinar-meeting-input-button-container">
                      <p className="ir-ws-webinar-meeting-presenter-name">
                        Name
                      </p>
                      <input
                        className="ir-ws-webinar-meeting-presenter-name-input"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />

                      <p className="ir-ws-webinar-meeting-presenter-name ir-ws-webinar-meeting-presenter-passcode">
                        PassCode
                      </p>
                      <input
                        className="ir-ws-webinar-meeting-presenter-name-input"
                        value={passCode}
                        onChange={(e) => setPassCode(e.target.value)}
                      />
                      <p className="ir-ws-webinar-meeting-presenter-name ir-ws-webinar-meeting-presenter-passcode">
                        Occupation
                      </p>
                      <input
                        className="ir-ws-webinar-meeting-presenter-name-input"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                      />
                      <button
                        className="ir-ws-webinar-meeting-join-button"
                        onClick={handleJoin}
                        disabled={!countdownComplete}
                      >
                        <span>Join</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isJoin && <Loader />}
    </>
  );
};

export default UserJoinMeetingForm;
