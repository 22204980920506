import React, { useEffect, useState } from "react";
import "./PurchaseHistory.scss";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";

const PurchaseHistory = () => {
  const { courseTransactionDetails, userId, transacationDetails, isLoading } =
    useModalContext();
  const [copied, setCopied] = useState(false);
  const [accordianOpen, setAccordianOpen] = useState({});
  // const reversedTransactionDetails = [...transacationDetails]?.reverse();
  const reversedTransactionDetails = Array.isArray(transacationDetails)
    ? [...transacationDetails]?.reverse()
    : [];
  const [latestCourseTransaction, setlatestCourseTransaction] = useState([]);
  const [allLatestTransaction, setAllLatestTransaction] = useState([]);
  const [latestCaseTransaction, setLatestCaseTransaction] = useState([]);


  const handleAccordianToggle = (itemId) => {
    setAccordianOpen((prevOpen) => ({
      ...prevOpen,
      [itemId]: !prevOpen[itemId] || false,
    }));
  };

  const handleCopyPasscode = (passcode) => {
    const link = passcode;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        console.log("Text successfully copied to clipboard");
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      })
      .catch((err) => {
        console.error("Unable to copy text to clipboard", err);
      });
  };
  function convertUTCToLocalAndFormat(utcTimeString) {
    if (utcTimeString) {
      const utcDateTime = new Date(utcTimeString);

      const localTimeZoneOffset = new Date().getTimezoneOffset();

      const localDateTime = new Date(
        utcDateTime.getTime() - localTimeZoneOffset * 60000
      );

      const localTimeFormat = localDateTime.toISOString().slice(0, 16);
      const inputDate = new Date(localTimeFormat);
      const options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const outputString = inputDate.toLocaleString("en-US", options);

      return outputString;
    } else {
      return;
    }
  }

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      if (userId) {
        try {
          const courseTransactionData = await courseTransactionDetails(userId);

          setlatestCourseTransaction(courseTransactionData);
        } catch (error) {
          console.error("Error fetching transaction details:", error);
        }
      }
    };

    const fetchCaseTransactionDetails = async () => {
      if (userId) {
        try {
          const token = localStorage.getItem("jwtTokenApi1");
          const response = await fetch(`https://case.ir4u.info/api/v1/user/case/transaction/${userId}`, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          const caseTransactionData = await response.json();
          setLatestCaseTransaction(caseTransactionData);
        } catch (error) {
          console.error("Error fetching case transaction details:", error);
        }
      }
    };

    fetchTransactionDetails();
    fetchCaseTransactionDetails()
  }, [userId]);

  console.log("latestCourseTransaction", latestCourseTransaction);
  console.log("latestCaseTransaction", latestCaseTransaction);

  useEffect(() => {
    if (latestCourseTransaction.length > 0) {
      const allTransactions = [
        ...latestCourseTransaction,
        ...latestCaseTransaction,
        ...reversedTransactionDetails,
      ];
      const allTransactionData = allTransactions.sort(
        (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
      );
      // const latestTenTransactions = allTransactions.slice(0, 10);
      setAllLatestTransaction(allTransactionData);
    } else {
      setAllLatestTransaction(reversedTransactionDetails);
    }
    // console.log("those are the coursetransaction", tenCourseTransactions);
    // console.log("those are the userTransaction", userTransactionData);
  }, [latestCourseTransaction, latestCaseTransaction]);

  console.log("allLatestTransaction from purchase history:", allLatestTransaction);

  return (
    <div className="ir-ws-purchase-history-conatiner">
      <p className="ir-ws-purchase-history-title-text">Purchasing History</p>
      {!isLoading && (
        <div className="ir-ws-purchase-history-box">
          <ul className="ir-ws-purchase-history-title-container ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center">
            <li>Date</li>
            <li>product name</li>
            <li>more details</li>
          </ul>
          <div className="ir-ws-purchase-history-details-container">
            {allLatestTransaction
              ?.filter((p)=>
                p?.status === "SUCCESS" || p?.status === "success" ||
                p?.userJoinDetails?.passCodeStatus === "active"  
              ).map((item) => (
              <div
                className="ir-ws-purchase-history-details-box"
                key={item?.transactionId || item?.transactionID }
              >
                <ul className="ir-ws-purchase-history-title-details-container ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center">
                  <li>{convertUTCToLocalAndFormat(item?.createdOn)}</li>
                  <li>{item.webinarTitle || item.productName}</li>
                  <li
                    onClick={() => handleAccordianToggle(item?.transactionId || item?.transactionID)}
                  >
                    <span
                      className={`${
                        !accordianOpen[item?.transactionId || item?.transactionID]
                          ? "ir-ws-purchase-accordian-dropdown-inactive"
                          : "ir-ws-purchase-accordian-dropdown-active"
                      }`}
                    >
                      {!accordianOpen[item?.transactionId || item?.transactionID] ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="ir-wws-purchase-history-dropdown"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m19.5 8.25-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="ir-wws-purchase-history-dropdown"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m4.5 15.75 7.5-7.5 7.5 7.5"
                          />
                        </svg>
                      )}
                    </span>
                  </li>
                </ul>
                {accordianOpen[item?.transactionId || item?.transactionID] && (
                  <>
                    <ul className="ir-ws-purchase-history-accordian-title ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center">
                      <li>Product Type</li>
                      <li>Status</li>
                      <li>Product Link</li>
                      {/* <li>Passcode</li> */}
                      {item?.productType === "course" || item?.productType === "case" ? null : <li>Passcode</li> }
                    </ul>
                    <ul className="ir-ws-purchase-history-accordian-details ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center">
                      <li>
                      {
                        item?.productType === "course"
                        ? "Course"
                        // : item?.productType === "webinar"
                        // ? "Webinar"
                        : item?.productType === "case"
                        ? "Case"
                        : "Webinar"
                      }
                      </li>
                      <li
                        className={`${
                          item?.userJoinDetails?.passCodeStatus === "active" ||
                          item?.status === "success" || item?.status === "SUCCESS"
                            ? "ir-ws-purchase-status-active"
                            : item?.userJoinDetails?.passCodeStatus ===
                              "cancelled"
                            ? "ir-ws-purchase-status-cancelled"
                            : item?.userJoinDetails?.passCodeStatus ===
                              "completed"
                            ? "ir-ws-purchase-status-completed"
                            : ""
                        }`}
                      >
                        {item?.userJoinDetails?.passCodeStatus || item?.status}
                      </li> 
                      <li>
                        <a
                          href={
                            item?.productType === "course"
                            ? `/course/${item?.productID}`
                            :item?.productType === "case"
                            ? `/case/${item?.productId}`
                            :item?.userJoinDetails?.joinUrl
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link
                        </a>
                      </li>
                      
                      {item?.productType === "course" || item?.productType === "case" ? null : (
                        <li className="ir-ws-purchase-history-copy-passcode-conatiner ir-ws-flex ir-ws-justify-center ir-ws-align-center">
                        {/* {item?.userJoinDetails?.passCode || "NULL"} */}
                        {item?.userJoinDetails?.passCode &&
                          (!copied ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="ir-ws-purchase-history-copy-passcode-button"
                              onClick={() =>
                                handleCopyPasscode(
                                  item?.userJoinDetails?.passCode
                                )
                              }
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="ir-ws-purchase-history-copy-passcode-button"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </svg>
                          ))}
                      </li>
                      ) }

                      
                    </ul>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PurchaseHistory;
