import { useState } from "react";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";
import Swal from "sweetalert2";

const BookAgainModal = ({ toggleBookAgain, id, confirmationFunc }) => {
  // console.log(sendWebinarId);
  const { handlePaymentCheckout } = useModalContext();
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
        <div className="ir-ws-signup-content-container">
          {/* <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            // onClick={handleTermsAndCondition}
          >
            <span>X</span>
          </div> */}
          <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
              Terms And Condition
            </h3>
          </div>
          <div className="ir-ws-flex ir-ws-align-center ir-ws-justify-center ir-ws-terms-and-condition-container">
            <input
              type="checkbox"
              className="ir-ws-terms-and-condition-input"
              onChange={handleCheckboxChange}
            />
            <p>
              I agree with the
              <span>
                <a
                  href="/terms-conditions"
                  target="_blank"
                  className="ir-ws-terms-and-condition-link"
                >
                  {" "}
                  terms and condition
                </a>
              </span>
            </p>
          </div>
          <div className="ir-ws-book-again-box">
            {/* <p className="ir-ws-book-again-text"> */}
            <a href="/myprofile" className="ir-ws-book-again-link">
              Are you sure you want to book again ?
            </a>
            {/* </p> */}
          </div>

          <div className="ir-ws-deleteModal-button-container">
            <button
              className="ir-ws-deleteModal-button-yes"
              //   onClick={confirmationFunc}
              onClick={() => {
                if (!isChecked) {
                  Swal.fire("Accept the terms and condition to book webinar");
                } else if (id) {
                  // // await handleRegisterUserMeeting(sendWebinarId);
                  // await handlePaymentCheckout(sendWebinarId);
                  // handleTermsAndCondition();
                  confirmationFunc();
                }
              }}
            >
              Yes
            </button>
            <button
              className="ir-ws-deleteModal-button-no"
              onClick={toggleBookAgain}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookAgainModal;
