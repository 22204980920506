import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";


const StarRating = ({ iconWidth, iconHeight, iconGap, courseId, userId ,paid}) => {
  const [rating, setRating] = useState(null);
  const [tempRating, setTempRating] = useState(null);
  const [avgRating, setAvgRating] = useState(null); // To hold average rating

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  };

  const starContainerStyle = {
    display: "flex",
    gap: `${iconGap}px`,
  };

  const handleRating = (rating) => {
    setRating(rating);
  };

  const addOrUpdateRating = async (rating) => {

    if(!paid) return;
    
    const url = `https://courses.ir4u.info/api/${courseId}/rate`;
    const requestBody = {
      userId: userId,
      rating: rating,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log(data);
      setAvgRating(data?.averageRating); 
      Swal.fire("Rating Submitted", `Your rating: ${rating}`, "success");

      // Refresh average rating after updating
      fetchAvgRating(); 
    } catch (error) {
      console.error("Failed to add or update rating", error);
    }
  };

  const fetchAvgRating = async () => {

    if(courseId){
    
      const url = `https://courses.ir4u.info/api/${courseId}/ratings`;

      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
  
        const data = await response.json();
  
        if(data.length < 1) {
          console.log("No ratings found");
          return null;
        }
        // Calculate the average rating
        const average = data?.reduce((acc, rating) => acc + rating.rating, 0) / data.length;
        setAvgRating(average);
        console.log("avg rating: " + average);
      } catch (error) {
        console.error("Failed to fetch average rating", error);
      }

    }
  };

  useEffect(() => {

    // Fetch average rating when component mounts
    fetchAvgRating(); 
  }, []);

  return (
    <div style={containerStyle}>
      <div style={starContainerStyle}>
        {Array.from({ length: 5 }, (_, i) => (
          <Star
            key={i}
            onRate={() => {
              handleRating(i + 1);
              addOrUpdateRating(i + 1); // Update rating on server
            }}
            full={tempRating ? tempRating >= i + 1 : avgRating >= i + 1}
            onHoverIn={() => setTempRating(i + 1)}
            onHoverOut={() => setTempRating(0)}
            iconWidth={iconWidth} // Pass iconWidth prop to Star
            iconHeight={iconHeight} // Pass iconHeight prop to Star
          />
        ))}
      </div>
    </div>
  );
};

const Star = ({
  onRate,
  full,
  onHoverIn,
  onHoverOut,
  iconWidth,
  iconHeight,
}) => {
  const starStyle = {
    width: `${iconWidth}px`,
    height: `${iconHeight}px`,
    display: "block",
    cursor: "pointer",
  };

  return (
    <>
      {full ? (
        <svg
          height="21px"
          version="1.1"
          viewBox="0 0 20 21"
          width="20px"
          xmlns="http://www.w3.org/2000/svg"
          style={starStyle}
          onClick={onRate}
          onMouseEnter={onHoverIn}
          onMouseLeave={onHoverOut}
        >
          <title />
          <desc />
          <defs />
          <g
            fill="none"
            fillRule="evenodd"
            id="Page-1"
            stroke="none"
            strokeWidth="1"
          >
            <g
              fill="#FF9900"
              id="Core"
              transform="translate(-296.000000, -422.000000)"
            >
              <g id="star" transform="translate(296.000000, 422.500000)">
                <path
                  d="M10,15.273 L16.18,19 L14.545,11.971 L20,7.244 L12.809,6.627 L10,0 L7.191,6.627 L0,7.244 L5.455,11.971 L3.82,19 L10,15.273 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </svg>
      ) : (
        <svg
          enable-background="new 0 0 20 21"
          height="21px"
          version="1.1"
          viewBox="0 0 50 50"
          width="20px"
          xmlns="http://www.w3.org/2000/svg"
          style={starStyle}
          onClick={onRate}
          onMouseEnter={onHoverIn}
          onMouseLeave={onHoverOut}
        >
          <rect fill="none" height="50" width="50" />
          <polygon
            fill="none"
            points="25,3.553 30.695,18.321 46.5,19.173 34.214,29.152 38.287,44.447 25,35.848 11.712,44.447 15.786,29.152 3.5,19.173 19.305,18.321 "
            stroke="#FF9900"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      )}
    </>
  );
};

export default StarRating;
