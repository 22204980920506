import React, {useState} from "react";

const ArchivedAdminTab = ({ data, addToArchieve, removeFromArchive,  }) => {

    return (
        <>
            <div className="ir-customtabs-main-container">
                <span className="ir-customa-tabs-link activeTab">Archived Rooms</span>
            </div>
            <div className="ir-customtabs-child-container">
                <div className="ir-customtab-main-container">
                    <div className="ir-chat-rooms-tab-container ir-active-rooms-tab-main-container">
                        <div className="ir-chat-admin-inside-tab-container">
                            {data?.groups?.length > 0 && data?.groups?.filter(i => i.archive === true)?.length === 0 && (
                                    <p className="ir-found-no-results-text">There are No Archived Rooms</p>
                                )
                            }
                            {/* {JSON.stringify(data.groups)} */}
                            <ul className="ir-chat-admin-tab-in-list-container">
                                {data?.groups?.filter(i => i.archive === true).map((chatInfo, index) => {
                                    return (
                                        <li className="ir-chat-admin-tab-in-list-item-container" key={index}>
                                            <div className="ir-chat-admin-tab-in-list-item">
                                                <div className="ir-chat-admin-tab-in-list-item-content-container ir-chat-admin-tab-in-list-item-check-container">
                                                    <div className="ir-checkbox">
                                                        <input id={index} type="checkbox" />
                                                        <label htmlFor={index}></label>
                                                    </div>
                                                </div>
                                                <div className="ir-chat-admin-tab-in-list-item-content-container">
                                                    <img 
                                                        src={chatInfo?.image[0]} 
                                                        className="rounded-circle" 
                                                        alt="case chat icon" 
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null;
                                                            currentTarget.src = require("../../../../assets/images/chat-images/no-image.png")
                                                        }}
                                                    />
                                                </div>
                                                <div className="ir-chat-admin-tab-in-list-item-content-container ir-chat-admin-tab-in-list-item-more-width-container">
                                                    <span>{chatInfo.name}</span>
                                                </div>
                                                <div className="ir-chat-admin-tab-in-list-actions-container">
                                                    <div className="ir-chat-admin-tab-in-list-item-content-container">
                                                        {chatInfo.archive === true ? (
                                                            <button onClick={()=>{removeFromArchive(chatInfo.id)}}>Unarchive</button>
                                                            )
                                                        : (
                                                            
                                                            <button onClick={()=>{addToArchieve(chatInfo.id)}}>Archive</button> 
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )})}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ArchivedAdminTab;