import React from 'react';

const AssetRender = ({ 
    data, 
    imageClicked, 
    options={width: 80, height: 80, videoControl: true}
 }) => {
    // console.log("Data ", data);
    const imageFormats = ['jpg', "jpeg", "gif", "png", "bmp", "tiff", "tif", "webp"];
    const videoFormats = ["mp4", "mov", "wmv", "avi", "webm", "mkv"];
    const documentsFormats = ["pdf", "doc", "docx", "xlsx", "xls"]
    
    let renderMessage = "";
    if (data.message !== "") renderMessage = data.message;
    if (data.images !== "") {
        let ext = data.images.split(".")
        // console.log("Extension", data.images, ext);
        // image render 
        if (imageFormats.includes(ext[ext.length - 1])) renderMessage = (<img className="chat-image" src={data.images} onClick={()=>{
            imageClicked(data.images)
        }} height={options.height} alt="t" />)
        // video render
        if (videoFormats.includes(ext[ext.length - 1])) renderMessage = (<div className='ir-video-icon-container' onClick={()=>{imageClicked(data.images)}}><video height={options.height} loop={false} controls={options.videoControl}>
            <source src={data.images} type={"video/" + ext[ext.length - 1]} />
        </video><i className="bi bi-play-circle"></i></div>
        )
    };
    if (data.videos !== "") {
        let ext = data.videos.split(".")
        // video render
        if (videoFormats.includes(ext[ext.length - 1])) renderMessage = (<video height={options.height} loop={false} controls={options.videoControl}>
            <source src={data.videos} type={"video/" + ext[ext.length - 1]} />
        </video>)
    };
    if (data.documents !== "") {
        let ext = data.documents.split(".");
        const [,,,fileNameDetils, fileExtension] = ext;
        let fileName = fileNameDetils.split('/')[3];

        console.log("Extension is", ext);
        if (documentsFormats.includes(ext[ext.length - 1])) renderMessage = (
            <div className='ir-chat-list-container-documents'>
                <div className='doc-file-type-container'>
                    { <i className={`${fileExtension === 'pdf' ? 'bi-filetype-pdf' : fileExtension === 'doc' ? 'bi-filetype-doc' : fileExtension === 'docx' ? 'bi-filetype-docx' : fileExtension === 'xlsx' ? 'bi-filetype-xlsx' : 'bi-filetype-xls'} doc-file-type bi`}></i> }
                </div>
                <div className='ir-chat-doc'>{fileName}</div>
                <div className='ir-chat-doc'>.{fileExtension}</div>
                <a href={data.documents} download><i className="bi bi-download"></i></a>
            </div>
        )
    };
    
    return (
        <>
            {renderMessage}
        </>
    )
}
export default AssetRender;