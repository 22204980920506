import React from "react";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";

import CasePreviewPage from "./CasePreviewPage/CasePreviewPage";
import CreateCasePage from "./CaseFirstPage/CreateCasePage";
import "./CreateCase.scss";

const CreateCase = () => {
  const { caseCurrentPage, setCaseCurrentPage, handlePreviewCasePage } =
    useModalContext();
  return (
    <div className="ir-ws-create-case">
      <h2 className="ir-ws-create-course-heading">Create Cases</h2>
      {/* {caseCurrentPage === 1 && (
   <CaseFirstPage handlePreviewCasePage={handlePreviewCasePage} /> 
      <CreateCasePage />
      )}  */}
      {caseCurrentPage === 1 && <CreateCasePage />}
      {/* : (
        <CasePreviewPage
          caseCurrentPage={caseCurrentPage}
          setCaseCurrentPage={setCaseCurrentPage}
        />
      ) */}
    </div>
  );
};

export default CreateCase;
