import {
  AudioInputControl,
  ChatBubble,
  ContentShare,
  LocalVideo,
  RemoteVideos,
  VideoInputControl,
  useLocalAudioOutput,
  VideoTileGrid,
  useContentShareControls,
  useAttendeeStatus,
  useRosterState,
  useLocalVideo,
  useToggleLocalMute,
} from "amazon-chime-sdk-component-library-react";
import axios from "axios";
import "./PresenterScreen.scss";
import { useEffect, useRef } from "react";
import io from "socket.io-client";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";
import LeaveMeetingModal from "../userContainers/LeaveMeetingModal";

// const socket = io.connect("https://free.hexanova.tech");
// const socket = io.connect("https://webinar-staging.backend.ir4u.info/");
const socket = io.connect(
  // "https://socket-server-production-5b95.up.railway.app/"
  "https://courses.ir4u.info/chat"
);

const PresenterScreen = ({
  meetingManager,
  urlMeetingId,
  webinarTitle,
  isLocalUserSharing,
  username,
  videoState,
  isMicOn,
  webinarId,
}) => {
  const { leaveMeeting, meetingId } = useModalContext();
  const [attendeeDetails, setAttendeeDetails] = useState([]);
  const [leaveModal, setLeaveModal] = useState(false);
  const [inputText, setInputText] = useState("");
  const videoRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [stream, setStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [videoBlob, setVideoBlob] = useState(null);
  const [presenterId, setPresenterId] = useState("");
  const [presenterName, setPresenterName] = useState("");
  const [messageSenderId, setMessageSenderId] = useState("");
  const BASE_URL = "https://webinar-staging.backend.ir4u.info";
  const token = localStorage.getItem("jwtTokenApi1");
  const [toggleActive, setToggleActive] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const { toggleContentShare } = useContentShareControls();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  // const { isAudioOn, toggleAudio } = useLocalAudioOutput();
  const { muted, toggleMute } = useToggleLocalMute();
  const [activeParticipantBox, setActiveParticipantBox] = useState(true);
  const [activeChatBox, setActiveChatBox] = useState(true);
  const handleOpenLeaveModal = () => {
    setLeaveModal(true);
  };
  const sendMessage = async () => {
    if (inputText !== "") {
      const messageData = {
        id: messageSenderId,
        variant: "incoming",
        author: username,
        message: inputText,
        time: `${new Date(Date.now()).getHours()}:${new Date(
          Date.now()
        ).getMinutes()}`,
      };
      await socket.emit("send_message", messageData);
      setMessageList((list) => [...list, messageData]);
      setInputText("");
    }
  };
  useEffect(() => {
    if (videoState) {
      toggleVideo();
    }
    if (!isMicOn) {
      // toggleAudio();
      toggleMute();
    }
  }, []);

  useEffect(() => {
    socket.off("receive_message");
    socket.on("receive_message", (data) => {
      setMessageList((list) => [...list, data]);
    });

    return () => {
      socket.off("receive_message");
    };
  }, [socket]);

  function getInitials(name) {
    const words = name.split(" ");
    const initials = words.map((word) => word.charAt(0).toUpperCase());
    const initialsString = initials.join("");
    return initialsString;
  }

  const handleShareBtn = () => {
    toggleContentShare(); // Call your toggleContentShare function
    // setIsContentShareEnabled(!isContentShareEnabled);
  };
  const handleVideoBtn = () => {
    toggleVideo();
  };
  const handleAudioBtn = () => {
    // toggleAudio();
    toggleMute();
  };

  const { roster } = useRosterState();
  const attendees = Object.values(roster);

  useEffect(() => {
    if (attendees?.length > 0) {
      setMessageSenderId(attendees[0].chimeAttendeeId);
    }
    if (attendees?.length > 0) {
      setPresenterId(attendees[0].chimeAttendeeId);
    }
    if (attendeeDetails?.length > 0) {
      setPresenterName(attendeeDetails[0].username);
    }
  }, [attendees, attendeeDetails]);
  // const { sharingContent, videoEnabled } = useAttendeeStatus(presenterId);
  const extractedAttendeeIds = attendees.map(
    (attendee) => attendee.chimeAttendeeId
  );

  const lastFetchTimeRef = useRef(0);
  useEffect(() => {
    const fetchAttendeeDetails = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/api/v1/attendee/details`,
          {
            attendeeIds: extractedAttendeeIds,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setAttendeeDetails(response.data);
      } catch (error) {
        console.error("Error fetching attendee details:", error);
      }
    };

    const currentTime = Date.now();

    // Check if 15 seconds have passed since the last fetch
    if (currentTime - lastFetchTimeRef.current >= 3000) {
      // Perform the fetch
      fetchAttendeeDetails();

      // Update the last fetch time
      lastFetchTimeRef.current = currentTime;
    }
  }, [extractedAttendeeIds]);

  const navigate = useNavigate();

  const messageBoxRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messageList]);

  useEffect(() => {
    // Check if videoBlob is available and trigger download
    if (videoBlob) {
      const url = URL.createObjectURL(videoBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "recorded-video.mp4";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }, [videoBlob]);

  const scrollToBottom = () => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
  };

  // const startRecording = async () => {
  //   try {
  //     const newStream = await navigator.mediaDevices.getDisplayMedia({
  //       video: { mediaSource: "screen" },
  //     });

  //     const recorder = new MediaRecorder(newStream);
  //     const chunks = [];

  //     recorder.ondataavailable = (event) => {
  //       if (event.data.size > 0) {
  //         chunks.push(event.data);
  //       }
  //     };

  //     recorder.onstop = () => {
  //       setRecording(false);
  //       const blob = new Blob(chunks, { type: "video/webm" });
  //       setVideoBlob(blob);
  //       const url = URL.createObjectURL(blob);
  //       videoRef.current.src = url;
  //     };

  //     setStream(newStream);
  //     setMediaRecorder(recorder);
  //     recorder.start();
  //     setRecording(true);
  //   } catch (error) {
  //     console.error("Error starting recording:", error);
  //   }
  // };
  const startRecording = async () => {
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: { mediaSource: "screen" },
      });

      const combinedStream = new MediaStream([
        ...audioStream.getTracks(),
        ...screenStream.getTracks(),
      ]);

      const recorder = new MediaRecorder(combinedStream);
      const chunks = [];

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      recorder.onstop = () => {
        setRecording(false);
        const blob = new Blob(chunks, { type: "video/webm" });
        setVideoBlob(blob);
        const url = URL.createObjectURL(blob);
        videoRef.current.src = url;
      };

      setStream(combinedStream);
      setMediaRecorder(recorder);
      recorder.start();
      setRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      console.log("stopped Recording");
      mediaRecorder.stop();
      stream.getTracks().forEach((track) => track.stop());
      setRecording(false);
      if (videoBlob) {
        // Do nothing, let the useEffect handle the download
      }
    }
  };

  const activeThreeDots = () => {
    setToggleActive(!toggleActive);
  };

  const toggleParticipantBox = () => {
    setActiveParticipantBox(!activeParticipantBox);
    setToggleActive(false);
  };
  const toggleActiveChatBox = () => {
    setActiveChatBox(!activeChatBox);
    setToggleActive(false);
  };

  return (
    <>
      <video ref={videoRef} controls style={{ display: "none" }} />

      <div className="ir-ws-webinar-presenter-main-container">
        <div className="ir-ws-webinar-presenter-inner-main-container">
          <div className="ir-ws-webinar-presenter-inner-sub-container">
            <div className="ir-ws-flex ir-ws-justify-space-btw ir-ws-gray-bg ir-ws-align-center ir-ws-webinar-presenter-header">
              <div className="ir-ws-webinar-presenter-logo-container">
                <a href="/">
                  <img
                    src={require("../../../assets/images/ir4u2.png")}
                    alt="logo"
                    className="ir-ws-webinar-presenter-logo"
                  />
                </a>
              </div>
              <p
                className="ir-margin-0 ir-ws-webinar-presenter-webinar-name-text"
                style={{ textAlign: "center" }}
              >
                {webinarTitle}
              </p>
              <div className="ir-ws-webinar-presenter-logo-pack">
                <div
                  className="ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center ir-ws-webinar-presenter-logo-pack-record-container"
                  onClick={() => {
                    if (recording) {
                      stopRecording();
                    } else {
                      startRecording();
                    }
                  }}
                >
                  {recording ? (
                    <p className="ir-ws-webinar-presenter-recording-text">
                      Recording
                    </p>
                  ) : (
                    <p className="ir-ws-webinar-presenter-recording-text">
                      Record
                    </p>
                  )}

                  <div className="ir-ws-webinar-presenter-recorder-logo">
                    <div
                      className={`${
                        recording
                          ? "ir-ws-webinar-presenter-recoredr-logo-red-btn"
                          : "ir-ws-webinar-presenter-recoredr-logo-purple-btn"
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ir-ws-webinar-presenter-main-screen-container">
              <div className="ir-ws-height-100-percent ir-ws-webinar-presenter-inner-screen-container">
                <div className="ir-ws-webinar-presenter-inner-sub-screen-container">
                  <div
                    className={`ir-ws-webinar-presenter-participants-box ${
                      activeParticipantBox
                        ? "ir-ws-webinar-presenter-none"
                        : "ir-ws-webinar-presenter-block"
                    }`}
                  >
                    <div className="ir-ws-webinar-presenter-participants-number-container">
                      <p className="ir-ws-webinar-presenter-text">
                        Participants
                      </p>
                      <p className="ir-ws-webinar-presenter-number-text">
                        {attendeeDetails?.length}
                      </p>
                      <span
                        class="material-symbols-outlined ir-ws-webinar-participant-close-btn"
                        onClick={toggleParticipantBox}
                      >
                        close
                      </span>
                    </div>
                    <div className="ir-ws-webinar-presenter-participants-container">
                      {attendeeDetails?.map((attendee, index) => (
                        <div
                          className="ir-ws-webinar-participants-occupations-container"
                          key={index}
                        >
                          <div className="ir-ws-webinar-participants-user-logo">
                            <p></p>
                          </div>
                          <div>
                            <p className="ir-ws-webinar-participants-text">
                              {attendee.username}
                            </p>
                            <p className="ir-ws-webinar-occupations-text">
                              {attendee.role}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="ir-ws-webinar-presenter-meeting-container">
                    <VideoTileGrid
                      className="ir-ws-presenter-local-video"
                      style={{ background: "black" }}
                    >
                      <RemoteVideos className="ir-ws-presenter-local-video" />
                      <ContentShare
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "15px",
                        }}
                      />
                    </VideoTileGrid>

                    <div className="ir-ws-webinar-presenter-meeting-control-container">
                      <div className="ir-ws-webinar-presenter-meeting-control">
                        {/* <AudioInputControl /> */}
                        {!muted ? (
                          <span
                            className="material-symbols-outlined control-icon control-icon-active"
                            onClick={handleAudioBtn}
                          >
                            mic
                          </span>
                        ) : (
                          <span
                            className="material-symbols-outlined control-icon control-icon-inactive"
                            onClick={handleAudioBtn}
                          >
                            mic_off
                          </span>
                        )}
                        {isVideoEnabled ? (
                          <span
                            className="material-symbols-outlined control-icon control-icon-active"
                            onClick={handleVideoBtn}
                          >
                            videocam
                          </span>
                        ) : (
                          <span
                            className="material-symbols-outlined control-icon control-icon-inactive"
                            onClick={handleVideoBtn}
                          >
                            videocam_off
                          </span>
                        )}
                        {isLocalUserSharing ? (
                          <span
                            className="material-symbols-outlined control-icon control-icon-active"
                            onClick={handleShareBtn}
                          >
                            screen_share
                          </span>
                        ) : (
                          <span
                            className="material-symbols-outlined control-icon control-icon-inactive"
                            onClick={handleShareBtn}
                          >
                            stop_screen_share
                          </span>
                        )}
                        <span
                          className="material-symbols-outlined control-icon control-icon-inactive ir-ws-webinar-toggle-dots "
                          onClick={activeThreeDots}
                        >
                          more_vert
                        </span>
                        {toggleActive && (
                          <div className="ir-ws-webinar-three-dots-cont">
                            <div className="ir-ws-webinar-group-chat-cont">
                              <span
                                class="material-symbols-outlined control-icon control-icon-inactive "
                                onClick={toggleParticipantBox}
                              >
                                group
                              </span>
                              <span
                                class="material-symbols-outlined control-icon control-icon-inactive"
                                onClick={toggleActiveChatBox}
                              >
                                chat
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <button
                        className="ir-ws-webinar-presenter-meeting-leave-btn"
                        onClick={handleOpenLeaveModal}
                      >
                        Leave
                      </button>
                    </div>
                  </div>
                  <div
                    // className="ir-ws-webinar-presenter-message-notes-container"
                    className={`ir-ws-webinar-presenter-message-notes-container ${
                      activeChatBox
                        ? "ir-ws-webinar-chat-none"
                        : "ir-ws-webinar-chat-block"
                    }`}
                  >
                    <div className="ir-ws-webinar-presenter-notes-container">
                      <div className="ir-ws-webinar-presenter-notes-text-box">
                        <p className="ir-ws-webinar-presenter-notes-text">
                          Notes
                        </p>
                        <span
                          class="material-symbols-outlined ir-ws-webinar-participant-close-btn"
                          onClick={toggleActiveChatBox}
                        >
                          close
                        </span>
                      </div>
                      <textarea
                        type="text"
                        placeholder="Type here"
                        className="ir-ws-webinar-presenter-note-input-box"
                      />
                    </div>

                    <div className="ir-ws-webinar-presenter-message-conatiner">
                      <div className="ir-ws-webinar-presenter-chat-text-box">
                        <p className="ir-ws-webinar-presenter-chat-text">
                          Chat
                        </p>
                        <span
                          class="material-symbols-outlined ir-ws-webinar-participant-close-btn"
                          onClick={toggleActiveChatBox}
                        >
                          close
                        </span>
                      </div>
                      <div
                        className="ir-ws-webinar-presenter-message-box"
                        ref={messageBoxRef}
                      >
                        {messageList?.map((message, index) =>
                          message.author === username ? (
                            <div
                              className="ir-ws-webinar-presenter-message-sender-reciever-box"
                              key={`${message.id}-${index}`}
                            >
                              <div className="ir-ws-webinar-sender-message">
                                <ChatBubble
                                  variant={message.variant}
                                  senderName={message.author}
                                  time={message.time}
                                  style={{
                                    borderRadius: "15px",
                                    fontSize: "12px",
                                    lineHeight: "1.2",
                                    padding: "8px",
                                    background: "#e1dafd",
                                    color: "#000",
                                  }}
                                >
                                  {message.message}
                                </ChatBubble>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="ir-ws-webinar-presenter-message-receiver-box"
                              key={`${message.id}-${index}`}
                            >
                              <div className="ir-ws-webinar-presenter-message-sender">
                                <p className="ir-ws-webinar-presenter-message-name">
                                  {getInitials(message.author)}
                                </p>
                              </div>
                              <ChatBubble
                                variant={message.variant}
                                senderName={message.author}
                                time={message.time}
                                style={{
                                  color: "#000",
                                  borderRadius: "15px",
                                  background: "#e1dafd",
                                  fontSize: "12px",
                                  lineHeight: "1.2",
                                  padding: "8px",
                                }}
                              >
                                {message.message}
                              </ChatBubble>
                            </div>
                          )
                        )}
                      </div>
                      <div className="ir-ws-webinar-presenter-message-input-container">
                        <div className="ir-ws-webinar-presenter-message-input-send-btn-container">
                          <textarea
                            placeholder="Type message here"
                            className="ir-ws-webinar-presenter-message-input"
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault();
                                sendMessage();
                              }
                            }}
                          />
                          <svg
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                            className="ir-ws-webinar-message-send-btn"
                            onClick={sendMessage}
                          >
                            <title />
                            <g data-name="1" id="_1">
                              <path d="M291.48,449.94A15,15,0,0,1,278,441.5L207.5,296.57,62.57,226.08a15,15,0,0,1,1-27.41L435.48,49.08A15,15,0,0,1,455,68.6L305.4,440.54A15,15,0,0,1,292,449.93Zm-185.38-236,119.18,58a15,15,0,0,1,6.93,6.93l58,119.18L414,90Z" />
                              <path d="M218.72,300.35a15,15,0,0,1-10.6-25.61L430.47,52.39a15,15,0,1,1,21.21,21.22L229.33,296A15,15,0,0,1,218.72,300.35Z" />
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {leaveModal && (
          <LeaveMeetingModal
            leaveModal={leaveModal}
            setLeaveModal={setLeaveModal}
            leaveMeeting={leaveMeeting}
            webinarId={webinarId}
            stopRecording={stopRecording}
            videoRef={videoRef}
            recording={recording}
          />
        )}
      </div>
    </>
  );
};

export default PresenterScreen;
