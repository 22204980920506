import React from "react";
import WebinarAdminContent from "../../../components/WebinarAdminContentWebinar/WebinarAdminContent";
import WebinarAdminSideNavigation from "../../../components/WebinarAdminSideNavigation/WebinarAdminSideNavigation";

const WebinarAdmin = () => {
  return (
    <div className="ir-ws-flex ir-bg-white ir-ws-height-100-vh">
      <WebinarAdminSideNavigation />
      <WebinarAdminContent />
    </div>
  );
};

export default WebinarAdmin;
