import { useEffect, useState } from "react";
import "./WebinarPaymentSuccess.scss";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';



const WebinarPaymentSuccess = () => {
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState("");
  const [searchParams] = useSearchParams();
  const productId = searchParams.get('productId');
  const productType = searchParams.get('productType')
  useEffect(() => {
    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const formattedDate = `${day < 10 ? "0" : ""}${day} - ${
        month < 10 ? "0" : ""
      }${month} - ${year}`;
      setCurrentDate(formattedDate);
    };

    getCurrentDate();
  }, []);

  const handleNavigation = () => {
    if (productId && productType === 'case') {
      navigate(`/case/${productId}`);
    } else if (productId) {
      navigate(`/course/${productId}`);
    } else {
      navigate('/');
    }
  }
  

  return (
    <>
      <div className="ir-ws-webinar-payment-success-container ir-ws-flex ir-ws-justify-center ir-ws-align-center">
        <div className="ir-ws-webinar-payment-success-box">
          <div className="ir-ws-flex ir-ws-justify-center ir-ws-align-center">
            <a href="/">
              <img
                src={require("../../assets/images/ir4u2.png")}
                alt="logo"
                className="ir-ws-webinar-payment-ir4u-logo"
              />
            </a>
          </div>
          <div className="ir-ws-flex ir-ws-justify-center ir-ws-align-center ir-ws-webinar-payment-success-logo-container">
            <img
              src={require("../../assets/images/active.png")}
              alt="Success"
              className="ir-ws-webinar-payment-success-logo"
            />
            <p className="ir-ws-webinar-payment-success-text">
              Transaction Successful!
            </p>
            <p className="ir-ws-webinar-payment-receipt-date-text">
              {currentDate}
            </p>
            <p className="ir-ws-webinar-payment-receipt-text">
              Your payment was successful. <br />A receipt for this purchase has
              been
              <br /> sent to your email
            </p>
            <div
              className="ir-ws-text-center ir-ws-default-btn-container ir-ws-webinar-payment-status-button-container"
              onClick={handleNavigation}
            >
              <button className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ">
                <span>OK</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebinarPaymentSuccess;
