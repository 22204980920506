import React, {useState} from "react";
import './FinanceAdminTab.scss';

const FinanceAdminTab = ({ data }) => {

    const [expandedRows, setExpandedRows] = useState(null);
    const expandUser = (id) => {
        console.log("expandUser", id);
        let currentExpandedRows = null;
        const isRowExpanded = currentExpandedRows === id ? id : null;
        const newExpandedRows = isRowExpanded
            ? null
            : (currentExpandedRows = id);
        if (expandedRows !== id) {
            setExpandedRows(newExpandedRows);
        } else {
            setExpandedRows(null);
        }
    }

    return (
        <>
            <div className="ir-customtabs-main-container">
                <span className="ir-customa-tabs-link activeTab"> Rooms</span>
            </div>
            <div className="ir-customtabs-child-container">
                <div className="ir-customtab-main-container">
                    <div className="ir-chat-finance-tab-container ir-active-rooms-tab-main-container">
                        <div className="ir-chat-admin-inside-tab-container">
                            {data?.groups?.length > 0 && data?.groups?.filter(i => i.archive === true)?.length === 0 && (
                                    <p className="ir-found-no-results-text">There are No Archived Rooms</p>
                                )
                            }
                            {/* {JSON.stringify(data.groups)} */}
                            <ul className="ir-chat-admin-tab-in-list-container">
                                {/* <li className="ir-chat-admin-tab-in-list-item-container">
                                    <div className="ir-chat-admin-tab-in-list-item">
                                    <div className="ir-chat-admin-tab-in-list-item-content-container ir-chat-admin-tab-in-list-item-check-container">
                                            <div className="ir-checkbox">
                                                <input type="checkbox" />
                                                <label></label>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}
                                {data?.groups?.filter(i => i.archive === true).map((chatInfo, index) => {
                                    return (
                                        <li className="ir-chat-admin-tab-in-list-item-container" key={index}>
                                            <div className="ir-chat-admin-tab-in-list-item">
                                                <div className="ir-chat-admin-tab-in-list-item-content-container ir-chat-admin-tab-in-list-item-check-container">
                                                    <div className="ir-checkbox">
                                                        <input id={index} type="checkbox" />
                                                        <label htmlFor={index}></label>
                                                    </div>
                                                </div>
                                                <div className="ir-chat-admin-tab-in-list-item-content-container">
                                                    <img 
                                                        src={chatInfo?.image[0]} 
                                                        className="rounded-circle" 
                                                        alt="case chat icon" 
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null;
                                                            currentTarget.src = require("../../../../assets/images/chat-images/no-image.png")
                                                        }}
                                                    />
                                                </div>
                                                <div className="ir-chat-admin-tab-in-list-item-content-container ir-chat-admin-tab-in-list-item-more-width-container">
                                                    <span>{chatInfo.name}</span>
                                                </div>
                                                <div className="ir-chat-admin-tab-in-list-actions-container">
                                                    <div className="ir-chat-admin-tab-in-list-item-content-container">
                                                        Price
                                                    </div>
                                                </div>
                                                <div className="ir-chat-admin-tab-in-list-actions-container">
                                                    <div className="ir-chat-admin-tab-in-list-item-content-container">
                                                        No.of Users Purchased
                                                    </div>
                                                </div>
                                                <div className="ir-chat-admin-tab-in-list-item-content-container">
                                                    <button onClick={() => expandUser(index)}>View More</button>
                                                </div>
                                            </div>
                                            {expandedRows === index ? (
                                                <div className="expandable">
                                                    <div className="table-responsive ir-finance-table-container">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Username</th>
                                                                    <th>Amount</th>
                                                                    <th>Transaction Id</th>
                                                                    <th>Status</th>
                                                                    <th>Invoice</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>User 1</td>
                                                                    <td>100USD</td>
                                                                    <td>txnId12379874</td>
                                                                    <td>Success</td>
                                                                    <td>
                                                                        <button>Invoice</button>
                                                                    </td>
                                                                    <td>Send Mail</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>User 1</td>
                                                                    <td>100USD</td>
                                                                    <td>txnId12379874</td>
                                                                    <td>Success</td>
                                                                    <td>
                                                                        <button>Invoice</button>
                                                                    </td>
                                                                    <td>Send Mail</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>User 1</td>
                                                                    <td>100USD</td>
                                                                    <td>txnId12379874</td>
                                                                    <td>Success</td>
                                                                    <td>
                                                                        <button>Invoice</button>
                                                                    </td>
                                                                    <td>Send Mail</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>User 1</td>
                                                                    <td>100USD</td>
                                                                    <td>txnId12379874</td>
                                                                    <td>Success</td>
                                                                    <td>
                                                                        <button>Invoice</button>
                                                                    </td>
                                                                    <td>Send Mail</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </li>
                                    )}
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FinanceAdminTab;