import React, { useState } from "react";
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";

const DiscussionImageCrop = ({
  cropImage,
  setCropImageModal,
  handleCroppedImageSave,
  handleCloseImageCrop,
}) => {
  const [image, setImage] = useState(cropImage);
  const [cropper, setCropper] = useState(null);

  const handleSave = (e) => {
    e.preventDefault();
    if (cropper) {
      const canvas = cropper.getCroppedCanvas();
      canvas.toBlob((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        const newImage = {
          name: "cropped_image", // Or use some unique name or identifier
          url: blobUrl,
        };
        handleCroppedImageSave(newImage); // Save the cropped image
        setCropImageModal(false); // Close the modal
      }, "image/jpeg");
    }
  };

  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={handleCloseImageCrop}
          >
            <span>X</span>
          </div>
          <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
              Discussion Image
            </h3>
          </div>
          <form className="ir-ws-signup-form-container">
            <div>
              <div className="ir-ws-webinar-image-crop-container">
                <Cropper
                  src={image}
                  style={{ height: 400, width: "100%" }}
                  initialAspectRatio={1}
                  guides={false}
                  cropBoxResizable={true}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  background={false}
                />
              </div>
              <div className="ir-ws-text-center ir-ws-default-btn-container">
                <button
                  className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                  style={{ width: "200px", height: "50px" }}
                  onClick={handleSave}
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DiscussionImageCrop;
