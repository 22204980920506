import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useRef } from "react";
import UploadCasePage from "./UploadCasePage";
import ConfirmationModal from "../../Courses/AdminCreateCourseTab/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";
import "./CasePreviewPage.scss";
import Swal from "sweetalert2";
import { addThumbnail, updateSlide } from "../CaseSlice/Caseslice";
import axios from "axios";
import Header from "../../../components/Header/Header";
import ImageSequence from "../../Cases/SingleCase/ImageSequence";

import SingleCaseComponent from "../../Cases/SingleCase/SingleCaseComponent";

const CasePreviewPage = () => {
  const [uploadingCaseProgress, setUploadingCaseProgress] = useState(0);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const slides = useSelector((state) => state.case.slides);
  const caseDetails = useSelector((state) => state.case);
  // console.log(cases);
  // console.log(cases.slides[0].componentName, "This is component name");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleCreateCase } = useModalContext();
  const [answerType, setAnswerType] = useState("single");
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
  const [isFavorite, setIsFavorite] = useState(false);
  const [options, setOptions] = useState([]);
  const [activeSlide, setActiveSlide] = useState(1);
  const [answerTypeModal, setanswerTypeModal] = useState(false);
  // const [addedComponents, setAddedComponents] = useState([]);
  const fullcontainerRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [activeOption, setActiveOption] = useState(1);
  const [startedAnswering, setStartedAnswering] = useState(false);
  // const [rapidUserAnswers, setRapidUserAnswers] = useState({});
  // const jumblingRef = useRef(null);
  // const jumblingRefs = useRef([]);
  // const [circlePosition, setCirclePosition] = useState({ x: null, y: null });
  // const [submissionStatus, setSubmissionStatus] = useState([]);
  // const [shuffledPairedOptions, setShuffledPairedOptions] = useState([]);
  // const [correctAnswers, setCorrectAnswers] = useState([]);
  // const [dragging, setDragging] = useState(false);
  // const [jumblingSubmissionStatus, setjumblingSubmissionStatus] =
  // useState(false);
  // const [resultMessage, setResultMessage] = useState("");
  // const [rapidSubmittedStatus, setrapidSubmittedStatus] = useState(false);
  // const [selectedAnswersMcq, setSelectedAnswersMcq] = useState({});
  // const [submittedMcq, setSubmittedMcq] = useState(false);
  // const [userAnswersFib, setUserAnswersFib] = useState({});
  // const [submittedFib, setSubmittedFib] = useState(false);
  const [imagesData, setImagesData] = useState([]);

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  // const [isAllQuestionsSubmitted, setIsAllQuestionsSubmitted] = useState(false);
  const [isDisclaimerAcknowledged, setIsDisclaimerAcknowledged] =
    useState(false);

  ////////Slide Question states end////////////

  const categoryImageMap = {
    Chest: require("../../../assets/images/chest-icon.png"),
    "⁠Gastrointestinal": require("../../../assets/images/gastrointestinal-icon.png"),
    Liver: require("../../../assets/images/liver.png"),
    "⁠Genitourinary": require("../../../assets/images/g1.png"),
    Vascular: require("../../../assets/images/vascular-icon.png"),
    Lymphatics: require("../../../assets/images/lymphatic-icon.png"),
    "T⁠rauma": require("../../../assets/images/trauma-icon.png"),
    Venous: require("../../../assets/images/venous-icon.png"),
    "⁠Bleed": require("../../../assets/images/bleeding-icon.png"),
    "⁠Women’s health": require("../../../assets/images/womens-health-icon.png"),
    "⁠Men’s health": require("../../../assets/images/mens-health-icon.png"),
    Bundle: require("../../../assets/images/bundle.png"),
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const activeSlideObject = caseDetails?.slides?.find(
    (slide) => slide?.slideNumber === activeSlide
  );

  const slideNumbers = caseDetails?.slides?.map((slide) => slide.slideNumber);
  useEffect(() => {
    setOptions(slideNumbers);
  }, []);

  // const chooseSlide = (value) => {
  //   setActiveSlide(value);
  //   setActiveOption(value);
  //   toggleDropdown();
  // };

  // const [activeSlide, setActiveSlide] = useState(1);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [slidesPerPage, setSlidesPerPage] = useState(10);

  const updateSlidesPerPage = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 720) {
      setSlidesPerPage(5);
    } else if (screenWidth < 1300) {
      setSlidesPerPage(6);
    } else {
      setSlidesPerPage(10);
    }
  };

  useEffect(() => {
    updateSlidesPerPage();
    window.addEventListener("resize", updateSlidesPerPage);

    return () => {
      window.removeEventListener("resize", updateSlidesPerPage);
    };
  }, []);

  const chooseSlide = (value) => {
    setActiveSlide(value);

    let start = Math.max(0, value - Math.floor(slidesPerPage / 2));
    if (value + Math.floor(slidesPerPage / 2) >= options.length) {
      start = Math.max(0, options.length - slidesPerPage);
    }
    setCurrentSlideIndex(start);
  };

  const visibleSlides = options.slice(
    currentSlideIndex,
    currentSlideIndex + slidesPerPage
  );

  const hasLeftEllipsis = currentSlideIndex > 0;
  const hasRightEllipsis = currentSlideIndex + slidesPerPage < options.length;

  //   console.log("Options", options);
  ///Accoridan State/////////
  const [activeAccordions, setActiveAccordions] = useState([]); // State to manage open/close state of accordions
  const accordionRefs = useRef([]);
  // const [answeredQuestions, setAnsweredQuestions] = useState(
  //   Array(addedComponents.length).fill(false)
  // );
  //// img multiple pan and zoom states////
  const canvasRefMultiple = useRef(null);
  const containerRefMultiple = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [scale, setScale] = useState(1);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [start, setStart] = useState({ x: 0, y: 0 });
  const [panZoomActive, setPanZoomActive] = useState(false);
  ////img multiple pan and zoom states end///
  ////Image Sequence states start////////

  ////Image Sequence New states start////////
  const canvasRefSequence = useRef(null);
  const containerRefSequence = useRef(null);
  const [resetSequence, setResetSequence] = useState(false);
  const [scaleSequence, setScaleSequence] = useState(1);
  const [offsetSequence, setOffsetSequence] = useState({ x: 0, y: 0 });
  const [scrollActive, setScrollActive] = useState(true);
  const [panZoomActiveSequence, setPanZoomActiveSequence] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleDarkModeToggle = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  // useEffect(() => {
  //   console.log("answeredQuestions state:", answeredQuestions);
  // }, [answeredQuestions]);
  ///Image sequence states end////////////
  ///// function Image Multiple pan and zoom starts////////
  useEffect(() => {
    if (canvasRefMultiple.current) {
      updateCanvasSize();
      drawImage();
    }
  }, [currentIndex, scale, offset]);

  useEffect(() => {
    Swal.fire({
      title: "Disclaimer",
      text: "These are educational cases with questions formatted in various forms. For certain format of questions (Free hand), your answers may not exactly correspond to the answers fed to the system. These questions are to enhance your knowledge and no marks are used to score.",
      icon: "warning",
      confirmButtonText: "OK",
    }).then(() => {
      setIsDisclaimerAcknowledged(true);
    });
  }, []);

  const updateCanvasSize = useCallback(() => {
    if (!canvasRefMultiple.current || !containerRefMultiple.current) return;
    const canvas = canvasRefMultiple.current;
    const container = containerRefMultiple.current;

    canvas.width = container.clientWidth;
    canvas.height = container.clientHeight;
  }, []);

  useEffect(() => {
    const container = containerRefMultiple.current;

    const handleWheel = (e) => {
        if (!panZoomActive) return;

        const scaleFactor = 1.1;
        const minScale = 0.15; // Minimum zoom level (50% of the original size)
        const maxScale = 10; // Maximum zoom level (300% of the original size)

        let newScale = e.deltaY > 0 ? scale / scaleFactor : scale * scaleFactor;

        // Restrict the scale to the specified min and max levels
        newScale = Math.max(minScale, Math.min(newScale, maxScale));

        const img = new Image();
        img.src = imagesData[currentIndex]?.url;

        img.onload = () => {
            const canvas = canvasRefMultiple.current;
            const scaledWidth = img.width * newScale;
            const scaledHeight = img.height * newScale;

            let newOffsetX = offset.x;
            let newOffsetY = offset.y;

            newOffsetX = Math.min(
                0,
                Math.max(newOffsetX, canvas?.width - scaledWidth)
            );
            newOffsetY = Math.min(
                0,
                Math.max(newOffsetY, canvas?.height - scaledHeight)
            );

            setScale(newScale);
            setOffset({ x: newOffsetX, y: newOffsetY });
        };

        e.preventDefault();
        e.stopPropagation();
    };

    if (container) {
        container.addEventListener("wheel", handleWheel, { passive: false });

        return () => {
            container.removeEventListener("wheel", handleWheel);
        };
    }
}, [panZoomActive, scale, offset, currentIndex, imagesData]);


//   const drawImage = () => {
//     const canvas = canvasRefMultiple.current;
//     if (!canvas) return;

//     const ctx = canvas.getContext("2d");
//     if (!ctx) return;

//     const img = new Image();
//     img.src = imagesData[currentIndex]?.url;

//     img.onload = () => {
//       ctx.clearRect(0, 0, canvas.width, canvas.height);
//       const { width, height } = img;
//       const canvasWidth = canvas.width;
//       const canvasHeight = canvas.height;

//       // Calculate initial scale to fit image to canvas width
//       const initialScale = canvasWidth / width;
//       if (scale === 1) {
//         setScale(initialScale);
//       }

//       // Calculate scaled dimensions
//       const scaledWidth = width * scale;
//       const scaledHeight = height * scale;

//       // Calculate the position to center the image
//       const xPos = offset.x + (canvasWidth - scaledWidth) / 2;
//       const yPos = offset.y + (canvasHeight - scaledHeight) / 2;

//       ctx.drawImage(img, xPos, yPos, scaledWidth, scaledHeight);
//     };
//   };

const drawImage = () => {
    const canvas = canvasRefMultiple.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const img = new Image();
    img.src = imagesData[currentIndex]?.url;

    img.onload = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const { width, height } = img;
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Calculate aspect ratios
      const imgAspectRatio = width / height;
      const canvasAspectRatio = canvasWidth / canvasHeight;

      let drawWidth, drawHeight;

      if (imgAspectRatio > canvasAspectRatio) {
        // Image is wider than canvas, fit to height
        drawHeight = canvasHeight * scale;
        drawWidth = drawHeight * imgAspectRatio;
      } else {
        // Image is taller or equal aspect ratio to canvas, fit to height
        drawHeight = canvasHeight * scale;
        drawWidth = drawHeight * imgAspectRatio;
      }

      // Calculate the position to center the image with offset
      const xPos = offset.x + (canvasWidth - drawWidth) / 2;
      const yPos = offset.y + (canvasHeight - drawHeight) / 2;

      // Draw the image on the canvas with scaling and panning
      ctx.drawImage(img, xPos, yPos, drawWidth, drawHeight);
    };
  };

  const handleMouseDown = (e) => {
    if (!panZoomActive) return;
    if (e.button !== 0) return; // Check if left mouse button is clicked
    setIsDragging(true);
    setStart({ x: e.clientX - offset.x, y: e.clientY - offset.y });
  };

  const handleMouseMove = (e) => {
    if (!isDragging || !panZoomActive) return;
    const newOffset = {
      x: e.clientX - start.x,
      y: e.clientY - start.y,
    };

    setOffset(newOffset);
  };

  const handleMouseUp = () => {
    if (!panZoomActive) return;
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    if (!panZoomActive) return;
    setIsDragging(false);
  };

  const handlePanZoomButtonMultiple = () => {
    setPanZoomActive((prev) => !prev);
  };

  const handleResetButtonMultiple = () => {
    setScale(1);
    setOffset({ x: 0, y: 0 });
    drawImage(); // Redraw the image
  };
  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(document.fullscreenElement === fullcontainerRef.current);
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("MSFullscreenChange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullScreenChange
      );
    };
  }, []);

  const handleFullScreenToggle = () => {
    if (!document.fullscreenElement) {
      if (fullcontainerRef.current.requestFullscreen) {
        fullcontainerRef.current.requestFullscreen();
      } else if (fullcontainerRef.current.mozRequestFullScreen) {
        // Firefox
        fullcontainerRef.current.mozRequestFullScreen();
      } else if (fullcontainerRef.current.webkitRequestFullscreen) {
        // Chrome, Safari, Opera
        fullcontainerRef.current.webkitRequestFullscreen();
      } else if (fullcontainerRef.current.msRequestFullscreen) {
        // IE/Edge
        fullcontainerRef.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari, Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
      }
    }
  };

  ///// function Image Multiple pan and zoom end////////

  ///// function Image Sequence new Function start////////
  const handleScrollButtonClick = () => {
    setScrollActive((prev) => !prev);
    setPanZoomActiveSequence(false);
  };
  const handlePanZoomButtonClick = () => {
    setPanZoomActiveSequence((prev) => !prev);
    setScrollActive(false);
  };

  // const handleResetButtonClick = () => {
  //   setScaleSequence(1);
  //   setOffsetSequence({ x: 0, y: 0 });
  //   drawImage(); // Redraw the image
  // };

  const handleResetButtonClick = () => {
    setResetSequence(true);
    setTimeout(() => {
      setResetSequence(false); // Reset the flag after handling the reset
    }, 0);
  };
  ///// function Image Sequence new Function end////////

  useEffect(() => {
    if (activeSlideObject) {
      setImagesData(activeSlideObject?.caseImages);
    }
    handleResetButtonMultiple();
  }, [activeSlideObject]);

  const handleSubmit = () => {
    setUploadingCaseProgress(25);
  };
  const toggleConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal);
  };

  function stripHtmlTags(html, maxLength) {
    let doc = new DOMParser().parseFromString(html, "text/html");
    let plainText = doc.body.innerText;
    return plainText.length > maxLength
      ? plainText.slice(0, maxLength) + "..."
      : plainText;
  }

  // //////Slide Question all States///////

  const generateRandomString = (length) => {
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  // Function to upload a single file
  const uploadMedia = async (fileUrl, section) => {
    try {
      let blob;

      // Check if the URL is a blob URL
      if (fileUrl.startsWith("blob:")) {
        const response = await fetch(fileUrl);
        blob = await response.blob();
      }
      // Check if the URL is a base64 encoded string
      else if (fileUrl.startsWith("data:image")) {
        const base64Response = await fetch(fileUrl);
        blob = await base64Response.blob();
      } else {
        throw new Error("Unsupported file URL format");
      }

      const randomString = generateRandomString(8);
      const location = `${section}/${randomString}`;

      const formData = new FormData();
      formData.append("files", blob);
      formData.append("location", location);

      // console.log("Uploading from case preview page:",blob,location);

      // Send the Blob to the server
      const uploadResponse = await axios.post(
        "https://case.ir4u.info/api/v1/admin/case/media/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
          },
        }
      );

      // Return the public URL from the server response
      return uploadResponse.data.urls[0];
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  // Function to upload images in a given list with a specific location
  const uploadImages = async (images, section) => {
    return await Promise.all(
      images.map(async (img) => {
        // Check if the image URL is already a public URL
        if (
          !img.url.startsWith("http") &&
          (img.url.startsWith("blob:") || img.url.startsWith("data:image"))
        ) {
          return { ...img, url: await uploadMedia(img.url, section) };
        }
        return img;
      })
    );
  };

  // Function to handle the submission of files and update progress
  const handleFileSubmit = async () => {
    let totalImages = 0;
    // Assuming 4 sections per slide (caseImages, sequenceImage, discussionImage, findAbnormanlity)
    const totalSections = slides.length * 4;
    let uploadedSections = 0;

    // const thumbnail = caseDetails.thumbnail;
    // let updatedCaseDetails = { ...caseDetails };

    // // Upload Thumbnail
    // if (thumbnail) {
    //   updatedCaseDetails.thumbnail = await uploadMedia(
    //     thumbnail,
    //     "case/thumbnail"
    //   );
    //   // Dispatch the uploaded thumbnail to Redux
    //   dispatch(addThumbnail(updatedCaseDetails.thumbnail));
    //   uploadedSections++;
    //   setUploadingCaseProgress(
    //     Math.floor((uploadedSections / totalSections) * 100)
    //   );
    // }
    try {
      let updatedCaseDetails = { ...caseDetails };

      const thumbnail = updatedCaseDetails.thumbnail;
      if (thumbnail) {
        // Upload the thumbnail
        updatedCaseDetails.thumbnail = await uploadMedia(
          thumbnail,
          "case/thumbnail"
        );
      }

      // Dispatch the updated case details back to Redux
      dispatch(addThumbnail(updatedCaseDetails.thumbnail));
    } catch (error) {
      console.error("Error in file submission:", error);
    }

    for (let slide of slides) {
      const updatedSlide = { ...slide };

      console.log("updatedSlide from preview page:", updatedSlide);

      if (updatedSlide.caseImages) {
        updatedSlide.caseImages = await uploadImages(
          updatedSlide.caseImages,
          "case/caseimg"
        );
        uploadedSections++;
        setUploadingCaseProgress(
          Math.floor((uploadedSections / totalSections) * 100)
        );
      }

      if (updatedSlide.sequenceImage) {
        updatedSlide.sequenceImage = await uploadImages(
          updatedSlide.sequenceImage,
          "case/sequenceimg"
        );
        uploadedSections++;
        setUploadingCaseProgress(
          Math.floor((uploadedSections / totalSections) * 100)
        );
      }

      if (updatedSlide.discussionImage) {
        updatedSlide.discussionImage = await uploadImages(
          updatedSlide.discussionImage,
          "case/discussionimg"
        );
        uploadedSections++;
        setUploadingCaseProgress(
          Math.floor((uploadedSections / totalSections) * 100)
        );
      }

      if (updatedSlide.findAbnormanlity) {
        updatedSlide.findAbnormanlity = await Promise.all(
          updatedSlide.findAbnormanlity.map(async (item) => {
            if (
              !item.data.image.startsWith("http") &&
              (item.data.image.startsWith("blob:") ||
                item.data.image.startsWith("data:image"))
            ) {
              return {
                ...item,
                data: {
                  ...item.data,
                  image: await uploadMedia(
                    item.data.image,
                    "case/findabnormalities"
                  ),
                },
              };
            }
            return item;
          })
        );
        uploadedSections++;
        setUploadingCaseProgress(
          Math.floor((uploadedSections / totalSections) * 100)
        );
      }

      dispatch(
        updateSlide({
          slideNumber: updatedSlide.slideNumber,
          newData: updatedSlide,
        })
      );
    }

    if (uploadingCaseProgress !== 100) {
      setUploadingCaseProgress(100);
    }

    console.log("upadted case:", caseDetails);
  };

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };
  const updateAccordionHeight = (index) => {
    const accordionRef = accordionRefs.current[index];
    if (accordionRef) {
      const height = activeAccordions[index] ? accordionRef.scrollHeight : 0;
      accordionRef.style.maxHeight = `${height}px`; // Set the height dynamically
    }
  };
  const singleAnswer = () => {
    setAnswerType("single");
    setanswerTypeModal(false);
    updateAccordionHeight(0);
    updateAccordionHeight(1);
    updateAccordionHeight(2);
    updateAccordionHeight(3);
    updateAccordionHeight(4);
  };
  const groupAnswer = () => {
    setAnswerType("group");
    setanswerTypeModal(false);
    updateAccordionHeight(0);
    updateAccordionHeight(1);
    updateAccordionHeight(2);
    updateAccordionHeight(3);
    updateAccordionHeight(4);
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imagesData.length - 1 : prevIndex - 1
    );
    handleResetButtonMultiple();
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imagesData.length - 1 ? 0 : prevIndex + 1
    );
    handleResetButtonMultiple();
  };

  // useEffect(() => {
  //   setActiveSlideIndex(0);
  // }, [activeSlideObject]);

  const categoryImage = categoryImageMap[caseDetails?.categorie];

  // console.log("cases deatils:", cases);
  function stripHtmlTagsDescription(str) {
    if (!str) return "";
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  }
  const strippedDescription = stripHtmlTagsDescription(
    activeSlideObject?.description
  );

  return (
    <>
      {uploadingCaseProgress > 0 ? (
        <UploadCasePage uploadingCaseProgress={uploadingCaseProgress} />
      ) : (
        <div
          className={`ir-cs-cases-detail-page-main-container ${
            isDarkMode ? "dark-mode" : ""
          }`}
          ref={fullcontainerRef}
          style={{
            // backgroundColor: isFullScreen ? "#ffff" : "initial",
            backgroundColor: isFullScreen
              ? isDarkMode
                ? "#262626"
                : "#ffffff"
              : "initial",
          }}
        >
          <Header isDarkMode={isDarkMode} />
          {/* <div
          // className={`single-case-conatiner-sub ${
          //   isDarkMode ? "dark-mode" : ""
          // }`}
          > */}
          <div
            className="ir-ws-banner-padding-tb ir-ws-padding-lr-132  ir-cs-cases-detail-page-content"
            // ref={fullcontainerRef}
            // style={{
            //   // backgroundColor: isFullScreen ? "#ffff" : "initial",
            //   backgroundColor: isFullScreen
            //     ? isDarkMode
            //       ? "#262626"
            //       : "#ffffff"
            //     : "initial",
            // }}
          >
            {/* <div className="ir-ws-padding-lr-132"> */}
            <div className="ir-ws-case-title-detail-cont">
              <div className="ir-ws-case-head-deatil-cont">
                <div className="ir-ws-case-category-icon-cont">
                  <img
                    className="ir-ws-case-category-icon"
                    src={categoryImage}
                    alt="case"
                  />
                </div>
                <div className="ir-ws-case-category-title-cont">
                  <h1 className="ir-ws-font-size-xl ir-ws-case-title-text">
                    {activeSlideObject?.name}
                  </h1>
                </div>
                <div className="separation-line"></div>

                {strippedDescription !== "" ? (
                  <div className="ir-ws-case-category-description-cont">
                    <p className="ir-ws-case-description-text">
                      {strippedDescription.length > 300
                        ? `${strippedDescription.slice(0, 300)}...`
                        : strippedDescription}
                    </p>
                  </div>
                ) : (
                  <div
                    className="ir-ws-case-category-description-cont"
                    style={{ visibility: "hidden" }}
                  >
                    <p className="ir-ws-case-description-text">
                      {strippedDescription.length > 300
                        ? `${strippedDescription.slice(0, 300)}...`
                        : strippedDescription}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="ir-cs-content-main-container ">
              <div className="ir-cs-content-left-container ir-cs-case-sections ">
                <div
                  className="ir-ws-case-slide-cont"
                  // style={{ height: "100%" }}
                >
                  <div className="ir-ws-flex ir-ws-align-center ir-ws-case-slide-dropdown-main-cont">
                    <div className="ir-ws-case-slide-text">
                      {/* Slide #{activeSlide} */}
                      Image
                    </div>
                    {/* {options.length >= 1 && (
                      <div>
                        <ul
                          className={`ir-ws-case-dropdown-active-cont ${"ir-ws-case-dropdown-slide-active"}`}
                        >
                          {options.map((option, index) => (
                            <li
                              key={index}
                              className={
                                option === activeOption ? "active" : ""
                              }
                              onClick={() => chooseSlide(option)}
                            >
                              {option}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )} */}
                    {options.length >= 1 && (
                      <div className="ir-ws-case-dropdown-wrapper">
                        <ul className="ir-ws-case-dropdown-active-cont ir-ws-case-dropdown-slide-active">
                          {hasLeftEllipsis && <li className="ellipsis">...</li>}
                          {visibleSlides.map((option, index) => (
                            <li
                              key={index}
                              className={option === activeSlide ? "active" : ""}
                              onClick={() => chooseSlide(option)}
                            >
                              {option}
                            </li>
                          ))}
                          {hasRightEllipsis && (
                            <li className="ellipsis">...</li>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="ir-ws-flex ir-ws-case-single-answery-type-dark-cont">
                    <div className="ir-ws-flex"></div>

                    <div
                      className="ir-ws-case-dark-mode-cont"
                      onClick={handleDarkModeToggle}
                    >
                      <img
                        src={require("../../../assets/images/light-theme.png")}
                        alt={isDarkMode ? "light" : "dark"}
                        style={{ width: "30px", height: "30px" }}
                      />
                    </div>
                  </div>
                </div>

                {caseDetails.slides.map((slide, index) => (
                  <div
                    key={slide.slideNumber}
                    style={{
                      display:
                        slide.slideNumber === activeSlide ? "block" : "none",
                      // height: "100%",
                    }}
                    className="case-question-main-container"
                  >
                    <SingleCaseComponent
                      slideNumber={slide.slideNumber}
                      answerType={answerType}
                      setStartedAnswering={setStartedAnswering}
                      activeSlide={activeSlide}
                      caseDetails={caseDetails}
                    />
                  </div>
                ))}
              </div>
              <div className="ir-cs-content-right-container ">
                {activeSlideObject?.caseImages?.length === 0 &&
                  activeSlideObject?.sequenceImage?.length === 0 &&
                  !activeSlideObject.caseVideo && (
                    <div className="ir-cs-disuccion-preview-container ir-ws-position-relative ir-ws-flex ir-ws-justify-center ir-ws-align-center">
                      <img
                        src={require("../../../assets/images/case-static-image.png")}
                        alt="Static"
                      />
                    </div>
                  )}
                {activeSlideObject?.caseImages?.length !== 0 && (
                  <div
                    ref={containerRefMultiple}
                    className="ir-cs-disuccion-preview-container ir-ws-position-relative ir-ws-flex ir-ws-justify-center ir-ws-align-center"
                  >
                    <canvas
                      ref={canvasRefMultiple}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                      onMouseDown={handleMouseDown}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                      onMouseLeave={handleMouseLeave}
                    />
                    {imagesData.length > 1 && (
                      <div
                        className="ir-ws-case-carousel-btn ir-ws-carousel-left-btn"
                        onClick={goToPrevious}
                      >
                        <i className="bi bi-chevron-left"></i>
                      </div>
                    )}
                    {imagesData.length > 1 && (
                      <div
                        className="ir-ws-case-carousel-btn ir-ws-carousel-right-btn"
                        onClick={goToNext}
                      >
                        <i className="bi bi-chevron-right"></i>
                      </div>
                    )}
                  </div>
                )}
                {activeSlideObject?.caseImages?.length !== 0 && (
                  <div className="ir-ws-case-imgae-btn-cont">
                    <div className="ir-ws-case-image-seq-func-cont">
                      <div
                        className={`ir-ws-case-image-btn ${
                          panZoomActive ? "ir-ws-case-image-btn-active" : ""
                        }`}
                        title="Pan and Zoom"
                        onClick={handlePanZoomButtonMultiple}
                      >
                        <img
                          src={require("../../../assets/images/pan-zoom.png")}
                          alt="pan and zoom"
                        />
                      </div>
                      <div
                        className="ir-ws-case-image-btn"
                        title="Reset"
                        onClick={handleResetButtonMultiple}
                      >
                        <img
                          src={require("../../../assets/images/anticlock.png")}
                          alt="reset"
                        />
                      </div>
                      <div
                        className="ir-ws-case-image-btn"
                        title="Fullscreen"
                        // onClick={handleFullscreenButtonMultiple}
                        onClick={handleFullScreenToggle}
                      >
                        <img
                          src={require("../../../assets/images/fullScreen.png")}
                          alt="fullscreen"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {activeSlideObject?.caseImages?.length !== 0 &&
                  imagesData.length > 1 && (
                    <div className="ir-ws-case-carosuel-dot-cont">
                      {imagesData.map((_, index) => (
                        <span
                          key={index}
                          className={`ir-ws-case-carousel-dot ${
                            index === currentIndex
                              ? "ir-ws-case-carousel-dot-active"
                              : ""
                          }`}
                          onClick={() => setCurrentIndex(index)}
                        ></span>
                      ))}
                    </div>
                  )}

                {activeSlideObject?.caseVideo &&
                  activeSlideObject.caseVideo !== "" && (
                    <div className="ir-cs-disuccion-preview-container ir-ws-flex ir-ws-justify-center ir-ws-align-center">
                      <video controls className="ir-ws-width-100">
                        <source
                          src={activeSlideObject?.caseVideo}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
                {activeSlideObject?.sequenceImage?.length !== 0 && (
                  <div
                    className="ir-cs-disuccion-preview-container"
                    ref={containerRefSequence}
                  >
                    <ImageSequence
                      canvasRefSequence={canvasRefSequence}
                      containerRefSequence={containerRefSequence}
                      panZoomActiveSequence={panZoomActiveSequence}
                      setScaleSequence={setScaleSequence}
                      scaleSequence={scaleSequence}
                      offsetSequence={offsetSequence}
                      setOffsetSequence={setOffsetSequence}
                      slideSequenceImage={activeSlideObject?.sequenceImage}
                      scrollActive={scrollActive}
                      resetSequence={resetSequence}
                      setScrollActive={setScrollActive}
                      setPanZoomActiveSequence={setPanZoomActiveSequence}
                      activeSlideObject={activeSlideObject}
                    />
                  </div>
                )}
                {activeSlideObject?.sequenceImage?.length !== 0 && (
                  <div className="ir-ws-case-imgae-btn-cont">
                    <div className="ir-ws-case-image-seq-func-cont">
                      <div
                        className={`ir-ws-case-image-btn ${
                          scrollActive ? "ir-ws-case-image-btn-active" : ""
                        }`}
                        // className={`ir-ws-case-image-btn
                        // `}
                        title="scroll"
                        onClick={handleScrollButtonClick}
                      >
                        <img
                          src={require("../../../assets/images/mouse-1.png")}
                          alt="scroll"
                        />
                      </div>
                      <div
                        className={`ir-ws-case-image-btn ${
                          panZoomActiveSequence
                            ? "ir-ws-case-image-btn-active"
                            : ""
                        }`}
                        title="Pan and Zoom"
                        onClick={handlePanZoomButtonClick}
                      >
                        <img
                          src={require("../../../assets/images/pan-zoom.png")}
                          alt="pan and zoom"
                        />
                      </div>
                      <div
                        className="ir-ws-case-image-btn"
                        title="Reset"
                        onClick={handleResetButtonClick}
                      >
                        <img
                          src={require("../../../assets/images/anticlock.png")}
                          alt="reset"
                        />
                      </div>
                      <div
                        className="ir-ws-case-image-btn"
                        title="Fullscreen"
                        // onClick={handleFullscreenButtonClick}
                        onClick={handleFullScreenToggle}
                      >
                        <img
                          src={require("../../../assets/images/fullScreen.png")}
                          alt="fullscreen"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="ir-cs-case-actions">
                  <button
                    className={`ir-cs-favorite-button ${
                      isFavorite ? "active" : ""
                    }`}
                    // onClick={toggleFavorite}
                    // onClick={handleFavouriteClick}
                    disabled={isAdmin}
                    style={{
                      ...(isAdmin
                        ? { pointerEvents: "none", cursor: "default" }
                        : {}),
                    }}
                  >
                    <i
                      className={`bi ${
                        isFavorite ? "bi-heart-fill" : "bi-heart"
                      }`}
                    ></i>
                  </button>
                  <button
                    className="ir-cs-share-button"
                    // onClick={showShareModal}
                    disabled={isAdmin}
                    style={{
                      ...(isAdmin
                        ? { pointerEvents: "none", cursor: "default" }
                        : {}),
                    }}
                  >
                    <i className="bi bi-share"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
          {isDisclaimerAcknowledged && answerTypeModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
                <div className="ir-ws-signup-content-container">
                  <div className="ir-ws-signup-content-inner-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      How do you want to answer the questions?
                    </h3>
                  </div>
                  <div className="ir-ws-deleteModal-button-container">
                    <button
                      className="ir-ws-deleteModal-button-yes"
                      onClick={singleAnswer}
                    >
                      Tutor
                    </button>
                    <button
                      className="ir-ws-deleteModal-button-no"
                      onClick={groupAnswer}
                    >
                      Test
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* {shareModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container ir-ws-share-main-container">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
                <div className="iir-ws-signup-content-container">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={shareCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      Share Case
                    </h3>
                  </div>
                  <div className="ir-share-modal-inside-content">
                    <p>Invite your friends through social network</p>
                    <div className="ir-share-container">
                      <div className="ir-share-icon-container ir-share-icon-container-hover">
                        <FacebookShareButton
                          url={shareUrl}
                          quote={"Dummy text!"}
                          hashtag="#ir4u"
                        >
                          <FacebookIcon size={50} round />
                        </FacebookShareButton>
                      </div>
                      <div className="ir-share-icon-container ir-share-icon-container-hover">
                        <WhatsappShareButton
                          url={shareUrl}
                          quote={"Dummy text!"}
                        >
                          <WhatsappIcon size={50} round />
                        </WhatsappShareButton>
                      </div>
                      <div className="ir-share-icon-container ir-share-icon-container-hover">
                        {!tooltipText ? (
                          <div
                            className="ir-share-icon-copy-link ir-share-icon-link"
                            onClick={handleCopyLink}
                            title="Copy Link"
                          >
                            <i className="bi bi-link-45deg"></i>
                          </div>
                        ) : (
                          <div
                            className="ir-share-icon-copy-link ir-share-icon-link"
                            title="Link Copied"
                          >
                            <i class="bi bi-check-lg"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )} */}
          <div>
            <div className="ir-course-review-cancel-button-container">
              <div
                className="ir-ws-text-center ir-ws-default-btn-container"
                onClick={() => {
                  // handleSaveAndContinue();
                  navigate(-1);
                  // handleCreateCourse();
                }}
              >
                <button className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button">
                  <span>Back</span>
                </button>
              </div>
              <div
                className="ir-ws-text-center ir-ws-default-btn-container"
                onClick={() => {
                  handleSubmit();
                  handleFileSubmit();
                }}
              >
                <button
                  className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                  //  onClick={handleFileSubmit}
                >
                  <span>Review and Publish</span>
                </button>
              </div>
              <div
                className="ir-ws-text-center ir-ws-default-btn-container"
                onClick={toggleConfirmationModal}
              >
                <button className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button">
                  <span>Cancel</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {answerTypeModal && (
        <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
          <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
            <div className="ir-ws-signup-content-container">
              {/* <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={toggleModal}
          >
            <span>X</span>
          </div> */}
              <div className="ir-ws-signup-content-inner-container">
                <h3
                  className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title"
                  style={{ fontSize: "24px" }}
                >
                  How do you want to answer the questions?
                </h3>
              </div>
              <div className="ir-ws-deleteModal-button-container">
                <button
                  className="ir-ws-deleteModal-button-yes"
                  onClick={singleAnswer}
                >
                  Tutor
                </button>
                <button
                  className="ir-ws-deleteModal-button-no"
                  onClick={groupAnswer}
                >
                  Test
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {openConfirmationModal && (
        <ConfirmationModal
          toggleModal={toggleConfirmationModal}
          confirmationFunc={() => {
            handleCreateCase();
            navigate(-1);
          }}
          title="cancel"
        />
      )}
    </>
  );
};

export default CasePreviewPage;
