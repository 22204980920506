import { useNavigate } from "react-router-dom";

const LeaveMeetingModal = ({
  leaveModal,
  setLeaveModal,
  leaveMeeting,
  webinarId,
  videoRef,
  stopRecording,
  recording,
}) => {
  const navigate = useNavigate();
  const closeLeaveMeetingModal = () => {
    setLeaveModal(false);
  };
  console.log(webinarId);
  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={closeLeaveMeetingModal}
          >
            <span>X</span>
          </div>
          <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
              Are you sure you want to leave this Webinar?
            </h3>
          </div>
          <div className="ir-ws-deleteModal-button-container">
            <button
              className="ir-ws-deleteModal-button-yes"
              onClick={async () => {
                if (webinarId) {
                  if (recording) {
                    stopRecording();
                  } else {
                    await leaveMeeting(webinarId);
                    closeLeaveMeetingModal();
                    navigate("/");
                  }
                } else {
                  await leaveMeeting();
                  closeLeaveMeetingModal();
                  navigate("/");
                }
              }}
            >
              Yes
            </button>
            <button
              className="ir-ws-deleteModal-button-no"
              onClick={closeLeaveMeetingModal}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveMeetingModal;
