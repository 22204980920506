import "./WebinarAdminUserSection.scss";
import WebinarAdminUserListTab from "./WebinarAdminUserListTab";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";
const WebinarAdminUserSection = () => {
  const { userList } = useModalContext();
  return (
    <>
      <div className="ir-ws-webinar-admin-custom-tab">
        <p
          className="ir-ws-custom-tab-button  
         "
        >
          Users List
        </p>
      </div>
      <div className="ir-ws-admin-create-webinar-tab ir-ws-webinar-tb-main-container ir-ws-admin-create-course-container ">
        {userList?.map((user) => (
          <WebinarAdminUserListTab
            key={user.username}
            firstname={user.firstname}
            lastname={user.lastname}
            userId={user.id}
            email={user.email}
            username={user.username}
          />
        ))}
      </div>
    </>
  );
};

export default WebinarAdminUserSection;
