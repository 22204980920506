import React, {useState} from "react";
import ActiveRoomsTab from "./ActiveRoomsTab";
import InactiveRoomsTab from "./InactiveRoomTab";
import CustomTab from '../../CustomTab/CustomTab';
import CustomTabs from '../../CustomTab/CustomTabs';

const ChatRoomAdminTab = ({data, onAction}) => {
    const [active, setActive] = useState(0);
    const handleChange = newActive => setActive(newActive);

    return (
        <CustomTabs active={active} onChange={handleChange}>
            <CustomTab title="Active Rooms">
                <ActiveRoomsTab 
                    data={data} 
                    onAction={onAction}
                />
            </CustomTab>
            <CustomTab title="Inactive Rooms">
                <InactiveRoomsTab 
                    data={data} 
                    onAction={onAction}
                />
            </CustomTab>
        </CustomTabs>
    );
};

export default ChatRoomAdminTab;