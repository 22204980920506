import React from "react";
import CreateCase from "./CreateCase";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";
import AdminCaseList from "./AdminCaseList";
import AdminCaseTrashList from "./AdminCaseTrashList";
import AdminCaseDraft from "./AdminCaseDraft";

const AdminCreateCaseContent = ({ toggle, setToggle }) => {
  const { handleCreateCase, createCaseOpen, activeCaseTab, setActiveTabCase } =
    useModalContext();
  return (
    <div className="ir-ws-admin-create-webinar-tab ir-ws-webinar-tb-main-container ir-ws-admin-create-course-container">
      {/* {toggle
        ? null
        : !createCaseOpen && (
            <div className="ir-ws-create-btn-container ir-ws-create-webinar-button-container">
              <button
                className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-banner-btn"
                onClick={handleCreateCase}
              >
                <span>Create Case</span>
              </button>
            </div>
          )} */}
      {activeCaseTab === 1 && !createCaseOpen && (
        <div className="ir-ws-create-btn-container ir-ws-create-webinar-button-container">
          <button
            className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-banner-btn"
            onClick={handleCreateCase}
          >
            <span>Create Case</span>
          </button>
        </div>
      )}
      {activeCaseTab === 1 && createCaseOpen && <CreateCase />}
      {activeCaseTab === 2 && (
        <div>
          <AdminCaseList />
        </div>
      )}
      {activeCaseTab === 3 && (
        <div>
          <AdminCaseTrashList />
        </div>
      )}
      {activeCaseTab === 4 && (
        <div>
          <AdminCaseDraft />
        </div>
      )}
    </div>
  );
};

export default AdminCreateCaseContent;
