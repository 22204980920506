import React, { useState, useContext, useRef, useEffect, useMemo } from "react";
import axios from "../../../api/axios";
import "./ChatSidebar.scss";
import { RootStore } from "../../../store/store";
import { useParams } from "react-router-dom";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import Swal from "sweetalert2";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import DeleteConfirmation from "../ChatContent/DeleteConfirmation";
const max_file_limit = 9;
const MAX_FILE_SIZE = 10000000; // 3MB

const ChatSidebar = ({
  socket,
  data,
  setData,
  getChatHistory,
  activeChatRoom,
  updateActiveChat,
  navMenuItemActive,
  updateChatRoomFlag,
  updateChatRoom,
}) => {
  const routeParams = useParams();

  const [shareModal, setShareModal] = useState(false);
  const [newCaseModal, setNewCaseModal] = useState(false);
  const [displayedCaseString, setDisplayedCaseString] = useState("");

  const [favorite, setFavorite] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fillterUplaodFile, setFillerUploadFile] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [agree, setAgree] = useState(false);
  const [fileSizeLimit, setFileSizeLimit] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [profileData, setProfileData] = useState(null);
  const [enableInputField, setEnableInputField] = useState({});
  const [updateProfileInputData, setUpdateProfileInputData] = useState({});
  const [isRoomUpdating, setIsRoomUpdating] = useState(false);
  const [updateProfilePicModal, setUpdateProfilePicModal] = useState(false);
  const [updateProfilePic, setUpdateProfilePic] = useState(false);
  const [imageEdit, setImageEdit] = useState(false);
  const [tooltipText, setTooltipText] = useState(false);
  const [store, setStore] = useContext(RootStore);
  const { chatStore, userStore } = store;
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [deleteRoomId, setDeleteRoomId] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [deleteRoomDataLength, setDeleteRoomDataLength] = useState(null);
  const ref = useRef();
  // const shareUrlCopyRef = useRef();

  const chatData = useMemo(() => {
    return data.filter((el) => {
      let searchValue = el.name.toLowerCase();
      return searchValue.indexOf(displayedCaseString) !== -1;
    });
  }, [data, displayedCaseString]);

  const [activeMenus, setActiveMenus] = useState(
    Array(chatData.length).fill(false)
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".ir-drop-downmenu-trigger-container")) {
        setActiveMenus(Array(chatData.length).fill(false));
      }
    };
    document.body.addEventListener("click", handleClickOutside);
    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, [chatData.length]);

  const triggerOptionsHandle = (index) => {
    const newActiveMenus = Array(chatData.length).fill(false);
    newActiveMenus[index] = !activeMenus[index];
    setActiveMenus(newActiveMenus);
  };

  const getChatHistoryOfRoom = (room) => {
    if (room) {
      // let reqData = {
      // 	"token": 'Bearer ' + localStorage.getItem('jwtTokenApi2'),
      // 	"roomId": room,
      // }
      // socket.emit("chatdata_room", reqData)
      getChatHistory(room);
    }
  };

  const searchCaseChats = (event) => {
    let searchCaseQuery = event.target.value.toLowerCase();
    setDisplayedCaseString(searchCaseQuery);
  };

  const showShareModal = () => {
    setShareModal(true);
  };
  const shareCloseModalHandle = () => {
    setShareModal(false);
  };

  const newChatCaseModalHandle = () => {
    setNewCaseModal(true);
  };

  const [newCaseData, setNewCaseData] = useState({
    id: 0,
    name: "",
    description: "",
    image: [],
    images: [],
    deleteImages: [],
    status: "active",
    archive: "false",
  });

  useEffect(() => {
    if (updateChatRoomFlag) {
      // upen modal of update room and with active roomid
      let roomData = chatStore.groups.find((e) => e.id === activeChatRoom);
      updateRoom(roomData);
    }
  }, [updateChatRoomFlag]);

  const newCaseInputChange = (e) => {
    setNewCaseData({ ...newCaseData, [e.target.name]: e.target.value });
  };

  const updateFavoriteObject = () => {
    if (
      userStore?.profile?.chatgroups !== undefined &&
      userStore?.profile?.chatgroups !== ""
    ) {
      console.log(userStore.profile.chatgroups);
      if (typeof userStore.profile.chatgroups == "string") {
        setFavorite(userStore.profile.chatgroups.split(", "));
      } else {
        setFavorite(userStore.profile.chatgroups);
      }
    } else {
      setFavorite([]);
    }
  };

  const addToFavorite = async (room) => {
    const response = await axios({
      url: "user/favorite/" + room.id,
      method: "get",
      withCredentials: false,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      },
    });

    if (response) {
      let chatgroups = response.data.data;
      let userstore = { ...userStore };
      userstore.profile.chatgroups = chatgroups;
      setStore({ ...store, userstore });
      updateFavoriteObject();
      Swal.fire({
        title: "Success",
        text: "Added to favourites",
        icon: "success",
      });
    }
  };

  const removeFromFavorite = async (room) => {
    const response = await axios({
      url: "user/favorite/" + room.id,
      method: "DELETE",
      withCredentials: false,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      },
    });

    if (response) {
      let chatgroups = response.data.data;
      let userstore = { ...userStore };
      userstore.profile.chatgroups = chatgroups;
      setStore({ ...store, userstore });
      updateFavoriteObject();
      Swal.fire({
        title: "Success",
        text: "Removed from favourite",
        icon: "success",
      });
    }
  };

  const addToArchieve = async (room) => {
    console.log("clicked");
    const response = await axios({
      url: "chat/archive/" + room.id,
      method: "post",
      withCredentials: false,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      },
    });

    if (response) {
      // let chatgroups = response.data.data;
      let chatStore = { ...store.chatStore };
      let responseData = { ...response.data.data };
      console.log("responseData.image", responseData.image);

      if (responseData.image !== "" && responseData.image.indexOf(", ")) {
        responseData.image = responseData.image.split(", ");
      }

      let groups = [...chatStore.groups];
      let index = null;
      for (let i = 0; i < groups.length; i++) {
        let id = groups[i].id;
        if (id === responseData.id) {
          index = i;
          break;
        }
      }
      // let index = responseData?.id &&  groups.findIndex(responseData?.id) || null;

      if (index != null) {
        groups[index] = { ...responseData };
      }

      console.log("resposnse data is ", responseData);
      // chatStore.groups.push(responseData);

      // setArchive(true);
      setStore({
        ...store,
        chatStore: { ...chatStore, groups: [...groups] },
      });
    }
  };

  const removeFromArchive = async (room) => {
    const response = await axios({
      url: "chat/archive/" + room.id,
      method: "DELETE",
      withCredentials: false,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      },
    });

    if (response) {
      // let chatgroups = response.data.data;
      let chatStore = { ...store.chatStore };
      let responseData = { ...response.data.data };
      if (responseData.image !== "" && responseData.image.indexOf(", ")) {
        responseData.image = responseData.image.split(", ");
      }

      let groups = [...chatStore.groups];
      let index = null;
      for (let i = 0; i < groups.length; i++) {
        let id = groups[i].id;
        if (id === responseData.id) {
          index = i;
          break;
        }
      }
      // let index = responseData?.id &&  groups.findIndex(responseData?.id) || null;

      if (index != null) {
        groups[index] = { ...responseData };
      }

      console.log("resposnse data is ", responseData);
      // chatStore.groups.push(responseData);

      // setArchive(false);
      setStore({
        ...store,
        chatStore: { ...chatStore, groups: [...groups] },
      });
    }
  };

  // const newCaseSubmitFormHandle = async (e) => {
  //     const newCaseFormData = new FormData();
  //     newCaseFormData.append("name", newCaseData.name);
  //     newCaseFormData.append("description", newCaseData.description);
  //     newCaseFormData.append("status", newCaseData.status);
  //     newCaseFormData.append("archive", newCaseData.archive);
  //     for (let i = 0; i < uploadedFiles.length; i++) {
  //         newCaseFormData.append('image', uploadedFiles[i]);
  //     }
  //     if (isRoomUpdating) {
  //         newCaseFormData.append("images", newCaseData.images);
  //         newCaseFormData.append("deleteImages", newCaseData.deleteImages);
  //     }
  //     // newCaseFormData.append("image", uploadedFiles[0]);
  //     let url = "chat/group"
  //     if (isRoomUpdating) {
  //         url = "chat/group/" + newCaseData.id
  //     }

  //     e.preventDefault();
  //     const response = await axios({
  //         url: url,
  //         method: (isRoomUpdating ? 'put' : 'post'),
  //         data: newCaseFormData,
  //         withCredentials: false,
  //         headers: {
  //             'Content-Type': 'multiplart/form-data',
  //             'Authorization': 'Bearer ' + localStorage.getItem('jwtTokenApi2')
  //         }
  //     });
  //     if (response?.status === 200 && response.data.data.length > 0) {
  //         //todo check wheter the particular response item is existing in the groups data.data.id
  //         let responseData = response.data.data[0];
  //         responseData.image = responseData.image.indexOf(',') ? responseData.image.split(',') : [responseData.image];
  //         console.log("resposnse data is ", responseData);
  //         responseData["images"] = [...responseData.image]
  //         responseData["deleteImages"] = []
  //         chatStore.groups.push(responseData);
  //         setStore({
  //             ...store, chatStore: {
  //                 ...chatStore,
  //                 groups: [...chatStore.groups]
  //             }
  //         });
  //         setNewCaseModal(false);
  //         setNewCaseData({
  //             name: "",
  //             description: "",
  //             image: [],
  //             images: [],
  //             deleteImages: [],
  //             status: "",
  //             archive: ""
  //         })
  //         setIsRoomUpdating(false);
  //         setUploadedFiles([]);
  //         updateChatRoom(false);
  //         Swal.fire({
  //             title: 'Success!',
  //             text: 'Case Created Successfully',
  //             icon: 'success'
  //         });
  //     }

  //     if (isRoomUpdating && response?.status === 200) {
  //         let responseData = response.data.data;
  //         responseData.image = responseData.image.indexOf(',') ? responseData.image.split(',') : [responseData.image];
  //         console.log("resposnse data is ", responseData);
  //         let index = chatStore.groups.findIndex(e => e.id === responseData.id)
  //         responseData["images"] = [...responseData.image]
  //         responseData["deleteImages"] = []
  //         chatStore.groups[index] = {...responseData};
  //         // chatStore.groups.push(responseData);
  //         setStore({
  //             ...store, chatStore: {
  //                 ...chatStore,
  //                 groups: [...chatStore.groups]
  //             }
  //         });
  //         setNewCaseModal(false);
  //         setNewCaseData({
  //             id: 0,
  //             name: "",
  //             description: "",
  //             image: [],
  //             images: [],
  //             deleteImages: [],
  //             status: "active",
  //             archive: "false"
  //         })
  //         setIsRoomUpdating(false);
  //         setUploadedFiles([]);
  //         updateChatRoom(false);
  //         Swal.fire({
  //             title: 'Success!',
  //             text: 'Case Updated Successfully',
  //             icon: 'success'
  //         });
  //     }

  // }
  const newCaseSubmitFormHandle = async (e) => {
    try {
      const newCaseFormData = new FormData();
      newCaseFormData.append("name", newCaseData.name);
      newCaseFormData.append("description", newCaseData.description);
      newCaseFormData.append("status", newCaseData.status);
      newCaseFormData.append("archive", newCaseData.archive);
      for (let i = 0; i < uploadedFiles.length; i++) {
        newCaseFormData.append("image", uploadedFiles[i]);
      }
      if (isRoomUpdating) {
        newCaseFormData.append("images", newCaseData.images);
        newCaseFormData.append("deleteImages", newCaseData.deleteImages);
      }
      let url = "chat/group";
      if (isRoomUpdating) {
        url = "chat/group/" + newCaseData.id;
      }

      e.preventDefault();
      const response = await axios({
        url: url,
        method: isRoomUpdating ? "put" : "post",
        data: newCaseFormData,
        withCredentials: false,
        headers: {
          "Content-Type": "multiplart/form-data",
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
        },
      });

      if (response?.status === 200 && response.data.data.length > 0) {
        let responseData = response.data.data[0];
        responseData.image = responseData.image.indexOf(",")
          ? responseData.image.split(",")
          : [responseData.image];
        console.log("response data is ", responseData);
        responseData["images"] = [...responseData.image];
        responseData["deleteImages"] = [];
        chatStore.groups.push(responseData);
        setStore({
          ...store,
          chatStore: {
            ...chatStore,
            groups: [...chatStore.groups],
          },
        });
        setNewCaseModal(false);
        setNewCaseData({
          name: "",
          description: "",
          image: [],
          images: [],
          deleteImages: [],
          status: "",
          archive: "",
        });
        setIsRoomUpdating(false);
        setUploadedFiles([]);
        updateChatRoom(false);
        Swal.fire({
          title: "Success!",
          text: "Case Created Successfully",
          icon: "success",
        });
      }

      if (isRoomUpdating && response?.status === 200) {
        let responseData = response.data.data;
        responseData.image = responseData.image.indexOf(",")
          ? responseData.image.split(",")
          : [responseData.image];
        console.log("response data is ", responseData);
        let index = chatStore.groups.findIndex((e) => e.id === responseData.id);
        responseData["images"] = [...responseData.image];
        responseData["deleteImages"] = [];
        chatStore.groups[index] = { ...responseData };
        setStore({
          ...store,
          chatStore: {
            ...chatStore,
            groups: [...chatStore.groups],
          },
        });
        setNewCaseModal(false);
        setNewCaseData({
          id: 0,
          name: "",
          description: "",
          image: [],
          images: [],
          deleteImages: [],
          status: "active",
          archive: "false",
        });
        setIsRoomUpdating(false);
        setUploadedFiles([]);
        updateChatRoom(false);
        Swal.fire({
          title: "Success!",
          text: "Case Updated Successfully",
          icon: "success",
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        Swal.fire({
          title: "warning",
          text: "User exceeded creating groups!",
          icon: "warning",
        });
      }
    }
  };

  const updateRoom = (data) => {
    setIsRoomUpdating(true);
    console.log("Update Room", data);
    setNewCaseData({ ...newCaseData, ...data });
  };
  const deleteImage = (img, data) => {
    // find image in images
    let index = data.images.findIndex((a) => a === img);
    // delelte form images
    data.images.splice(index, 1);
    // add image to deleteImages
    data.deleteImages.push(img);

    data.description = "New Description udpate with deleted content";
    setNewCaseData({ ...newCaseData, ...data });
  };

  const toggleDeleteModal = (room, i, dataLength) => {
    if (i !== undefined) {
      setDeleteRoomId(room);
      setDeleteIndex(i);
      setDeleteRoomDataLength(dataLength);
      setShowDeleteModal(!showDeleteModal);
    } else {
      setShowDeleteModal(!showDeleteModal);
      setDeleteRoomId(null);
      setDeleteIndex(null);
      setDeleteRoomDataLength(null);
    }
  };

  const deleteRoom = async (room, currentRoom, totalRooms) => {
    const response = await axios({
      url: "chat/group/" + room.id,
      method: "delete",
      withCredentials: false,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      },
    });
    if (response.status === 200) {
      let groups = [...chatStore.groups];
      let index = groups.findIndex((e) => e.id === room.id);
      groups.splice(index, 1);
      // chatStore.groups.push(responseData);
      setStore({
        ...store,
        chatStore: {
          ...chatStore,
          groups: [...groups],
        },
      });
      let selectedRom = currentRoom;
      if (currentRoom === totalRooms - 1) {
        selectedRom = currentRoom - 1;
      } else if (currentRoom === 0) {
        selectedRom = currentRoom + 1;
      } else {
        selectedRom = currentRoom + 1;
      }
      updateActiveChat(data[selectedRom].id);
      toggleDeleteModal();
      Swal.fire({
        title: "Success!",
        text: "Case Deleted Successfully",
        icon: "success",
      });
    }
  };

  //empty input fields on close button
  const newChatCaseCloseModalHandle = () => {
    setNewCaseModal(false);
    setAgree(false);
    setFileSizeLimit(false);
  };

  const updateChatCaseCloseModalHandle = () => {
    setIsRoomUpdating(false);
    setFileSizeLimit(false);
    updateChatRoom(false);
  };

  const removeUploadItem = (id) => {
    const newUploadedFiles = uploadedFiles.filter((_, i) => i !== id);
    setUploadedFiles(newUploadedFiles);
  };

  const handleUploadedFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    let result = files.some((file) => {
      let file_size = file.size;
      console.log("file size is ", file_size);
      if (file_size < MAX_FILE_SIZE) {
        setFileSizeLimit(false);
        if (uploaded.findIndex((f) => f.name === file.name) === -1) {
          uploaded.push(file);
          if (uploaded.length === max_file_limit) setFileLimit(true);
          if (uploaded.length > max_file_limit) {
            alert("you can only upload 4 files");
            setFileLimit(false);
            limitExceeded = true;
            console.log(fileLimit);
            return true;
          }
        }
      } else {
        setFileSizeLimit(true);
      }
      return false;
    });

    if (!limitExceeded) setUploadedFiles(uploaded);
    return result;
  };

  const handleFileChange = (event) => {
    const chosenFiles = Array.prototype.slice.call(event.target.files);
    // handleUploadedFiles(chosenFiles);
    setFillerUploadFile(chosenFiles);
    setImageEdit(true);
    console.log("clicked");
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const onImageSave = async (editedImageObject, designState) => {
    const imageCropFile = await dataURLtoFile(
      editedImageObject.imageBase64,
      editedImageObject.fullName
    );
    console.log("saved", imageCropFile);
    let uploadedImagesArr = [...uploadedFiles];
    uploadedImagesArr.push(imageCropFile);
    setUploadedFiles(uploadedImagesArr);
    // submitImage(imageCropFile)
    handleUploadedFiles(uploadedImagesArr);
    setImageEdit(false);
    setFillerUploadFile([]);
    return true;
  };

  const closeImgEditor = () => {
    setImageEdit(false);
  };

  const handleAcceptChange = (event) => {
    setAgree(event.target.checked);
  };

  const enableInputFieldHandle = (name) => {
    const key = name;
    setEnableInputField({
      ...enableInputField,
      [key]: !enableInputField[key],
    });
  };

  const updateProfilePicHandle = (event) => {
    setUpdateProfilePicModal(true);
    setSelectedFile(event.target.files[0]);
    setUpdateProfilePic(URL.createObjectURL(event.target.files[0]));
  };

  const updateProfilePicCloseModalHandle = () => {
    setUpdateProfilePicModal(false);
  };

  const updateProfileInputChange = (e) => {
    console.log(e.target.name, e.target.value);
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
    setUpdateProfileInputData({
      ...updateProfileInputData,
      [e.target.name]: e.target.value,
    });
  };

  const upldateProfileSubmitFormHandle = async (e) => {
    e.preventDefault();
    const updateProfileFormData = new FormData();
    if (selectedFile !== "") {
      updateProfileFormData.append("image", selectedFile || "");
    }
    Object.keys(updateProfileInputData).map((k, i) => {
      console.log("keys are", updateProfileInputData);
      updateProfileFormData.append(k, updateProfileInputData[k]);
      return true;
    });

    const response = await axios({
      url: "user/profile/" + userStore.profile.id,
      method: "PUT",
      data: updateProfileFormData,
      withCredentials: false,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      },
    });
    if (response?.status === 200) {
      // Swal.fire( 'Good job!','You clicked the button','success');
      Swal.fire({
        title: "Success!",
        text: "Your Profile is Updated.",
        icon: "success",
      });

      setStore({
        ...store,
        userStore: {
          ...userStore,
          profile: {
            ...userStore.profile,
            ...response.data.data,
          },
        },
      });
      let profileInputData = {};
      Object.keys(updateProfileInputData).map((k, i) => {
        profileInputData[k] = response.data.data[k];
      });
      setUpdateProfileInputData(profileInputData);
      setProfileData(response.data.data);
      setUpdateProfileInputData({});
      setUpdateProfilePicModal(false);
    }
  };

  useEffect(() => {
    updateFavoriteObject();
    setProfileData(userStore.profile);
  }, [userStore.profile]);

  useEffect(() => {
    if (routeParams?.id && routeParams?.id !== ("" || undefined || null)) {
      let id = parseInt(routeParams.id);
      updateActiveChat(id);
    }
  }, [routeParams]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, []);

  const shareUrl = window.location.href + "/" + JSON.stringify(activeChatRoom);
  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(shareUrl);
    setTooltipText(true);
    setTimeout(() => setTooltipText(false), 2000);
  };

  return (
    <div className="ir-chat-sidebar-main-container app-sidebar">
      <div className="tab-content">
        <div
          className={`tab-pane fade ${
            navMenuItemActive === 0 ? "active show" : ""
          }`}
          id="chats"
        >
          <form>
            <div className="input-icon-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search Cases"
                onChange={searchCaseChats}
              />
              <i className="bi bi-search"></i>
            </div>
          </form>
          <div className="tab-content-scroll ps ps--active-y" ref={ref}>
            <div className="d-flex gap-4 flex-column">
              {/* <h5 className="mb-0 ir-tab-heading">Case Chats {JSON.stringify(routeParams)} {JSON.stringify(activeChatRoom)}</h5> */}
              <h5 className="mb-0 ir-tab-heading">Case Chats</h5>
              <div className="list-group gap-1">
                {/* {chatData.length > 0 && chatData.sort((a,b) => (b.id - a.id)).map((room, i) => { */}
                {chatData.length > 0 &&
                  chatData
                    .sort((a, b) => b.id - a.id)
                    .map((room, i) => {
                      return (
                        <div
                          className={
                            "list-group-item d-flex align-items-center " +
                            (parseInt(activeChatRoom) === room.id &&
                              "chat-list-side-active")
                          }
                          key={i}
                        >
                          <div
                            className={`avatar me-2 ir-chat-list-state-status ${
                              !room.archive
                                ? "ir-chat-list-state-active"
                                : "ir-chat-list-state-archieve"
                            }`}
                            onClick={() => getChatHistoryOfRoom(room.id)}
                          >
                            <img
                              src={room?.image[0]}
                              className="rounded-circle"
                              alt="case chat icon"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = require("../../../assets/images/chat-images/no-image.png");
                              }}
                            />
                          </div>
                          <div className="ir-side-list-content">
                            <div onClick={() => getChatHistoryOfRoom(room.id)}>
                              <h5 className="ir-room-title-container">
                                <p className="text-truncate ir-room-title">
                                  {room?.name}
                                </p>
                                <div
                                  className={`ir-chat-archive-lock ${
                                    !room.archive
                                      ? "ir-chat-list-state-archive-lock"
                                      : "ir-chat-list-state-archieve-lock-display"
                                  }`}
                                >
                                  {/* <span className="material-symbols-outlined">
                                                                lock
                                                            </span> */}
                                </div>
                              </h5>
                              <p className="text-truncate mb-1 ir-ws-chat-sidebar-content">
                                {room?.description}
                              </p>
                            </div>

                            <div className="ir-side-list-action-time-container">
                              <div className="text-muted ir-drop-downmenu-trigger-container">
                                <i
                                  className="bi bi-three-dots"
                                  onClick={() => triggerOptionsHandle(i)}
                                ></i>
                                {activeMenus[i] && (
                                  <div className={`ir-dropdown-container`}>
                                    <ul>
                                      <li
                                        className="dropdown-item"
                                        onClick={showShareModal}
                                      >
                                        Share
                                      </li>
                                      {(Number(room.createdBy) ===
                                        Number(profileData?.id || "") ||
                                        profileData?.role === "admin") && (
                                        <li
                                          className="dropdown-item"
                                          onClick={() => updateRoom(room)}
                                        >
                                          Update
                                        </li>
                                      )}
                                      {/* {
                                                                    (profileData.role === "admin") && (

                                                                        (profileData.role === "admin" && !room.archive)
                                                                            ? <li className="dropdown-item" onClick={() => addToArchieve(room)}>Add to Archive</li>
                                                                            : <li className="dropdown-item" onClick={() => removeFromArchive(room)}>Remove From Archive</li>)
                                                                } */}
                                      {favorite.includes(String(room.id)) ? (
                                        <li
                                          className="dropdown-item"
                                          onClick={() =>
                                            removeFromFavorite(room)
                                          }
                                        >
                                          Remove Favourite
                                        </li>
                                      ) : (
                                        <li
                                          className="dropdown-item"
                                          onClick={() => addToFavorite(room)}
                                        >
                                          Add to Favourites
                                        </li>
                                      )}

                                      <li className="dropdown-divider"></li>
                                      {((Number(room.createdBy) ===
                                        Number(profileData?.id || "") &&
                                        Number(room.chatCount) === 0) ||
                                        profileData?.role === "admin") && (
                                        <li
                                          className="dropdown-item text-danger"
                                          onClick={() =>
                                            toggleDeleteModal(
                                              room,
                                              i,
                                              data.length
                                            )
                                          }
                                        >
                                          Delete
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}
                              </div>
                              {/* <p className="text-muted ir-chat-list-time">
                                                        {moment().format("hh:mm A")}
                                                    </p> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
          <button
            className="btn btn-primary bg-app-default-color btn-lg btn-block ir-btn-default ir-create-case-btn"
            id="newChat"
            onClick={newChatCaseModalHandle}
          >
            <span>
              <i className="bi bi-chat-dots me-1"></i> Create Case
            </span>
          </button>
        </div>
        <div
          className={`tab-pane fade ${
            navMenuItemActive === 1 ? "active show" : ""
          }`}
          id="favourites"
        >
          <form>
            <div className="input-icon-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search Chats"
              />
              <i className="bi bi-search"></i>
            </div>
          </form>
          <div className="tab-content-scroll ps ps--active-y" ref={ref}>
            <div className="d-flex gap-4 flex-column">
              {/* <h5 className="mb-0 ir-tab-heading">Favourites  {favorite}</h5> */}
              <h5 className="mb-0 ir-tab-heading">Favourites</h5>
              <div className="list-group gap-1">
                {data.length > 0 &&
                  data
                    .filter((a) => favorite.includes(String(a.id)))
                    .sort((a, b) => b.id - a.id)
                    .map((room, i) => {
                      return (
                        <div
                          className={
                            "list-group-item d-flex align-items-center " +
                            (parseInt(activeChatRoom) === room.id &&
                              "chat-list-side-active")
                          }
                          key={i}
                          onClick={() => getChatHistoryOfRoom(room.id)}
                        >
                          <div
                            className={`avatar me-2 ir-chat-list-state-status ${
                              !room.archive
                                ? "ir-chat-list-state-active"
                                : "ir-chat-list-state-archieve"
                            }`}
                          >
                            <img
                              className="rounded-circle"
                              src={room?.image[0]}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = require("../../../assets/images/chat-images/no-image.png");
                              }}
                              alt="profile pic"
                            />
                          </div>
                          <div className="ir-side-list-content">
                            <div>
                              <h5 className="ir-room-title-container">
                                <p className="text-truncate ir-room-title">
                                  {room?.name}
                                </p>
                                <div
                                  className={`ir-chat-archive-lock ${
                                    !room.archive
                                      ? "ir-chat-list-state-archive-lock"
                                      : "ir-chat-list-state-archieve-lock-display"
                                  }`}
                                >
                                  <span className="material-symbols-outlined">
                                    lock
                                  </span>
                                </div>
                              </h5>
                              <p className="text-truncate mb-1">
                                {room?.description}
                              </p>
                            </div>
                            <div className="ir-side-list-action-time-container">
                              <div className="text-muted ir-drop-downmenu-trigger-container">
                                <i
                                  className="bi bi-three-dots"
                                  onClick={() => triggerOptionsHandle(i)}
                                ></i>
                                {activeMenus[i] && (
                                  <div className={`ir-dropdown-container`}>
                                    <ul>
                                      <li
                                        className="dropdown-item"
                                        onClick={showShareModal}
                                      >
                                        Share
                                      </li>
                                      {(Number(room.createdBy) ===
                                        Number(profileData?.id || "") ||
                                        profileData?.role === "admin") && (
                                        <li
                                          className="dropdown-item"
                                          onClick={() => updateRoom(room)}
                                        >
                                          Update
                                        </li>
                                      )}
                                      {profileData.role === "admin" &&
                                        (profileData.role === "admin" &&
                                        !room.archive ? (
                                          <li
                                            className="dropdown-item"
                                            onClick={() => addToArchieve(room)}
                                          >
                                            Add to Archive
                                          </li>
                                        ) : (
                                          <li
                                            className="dropdown-item"
                                            onClick={() =>
                                              removeFromArchive(room)
                                            }
                                          >
                                            Remove From Archive
                                          </li>
                                        ))}
                                      {favorite.includes(String(room.id)) ? (
                                        <li
                                          className="dropdown-item"
                                          onClick={() =>
                                            removeFromFavorite(room)
                                          }
                                        >
                                          Remove Favourite
                                        </li>
                                      ) : (
                                        <li
                                          className="dropdown-item"
                                          onClick={() => addToFavorite(room)}
                                        >
                                          Add to Favourites
                                        </li>
                                      )}

                                      <li className="dropdown-divider"></li>
                                      {((Number(room.createdBy) ===
                                        Number(profileData?.id || "") &&
                                        Number(room.chatCount) === 0) ||
                                        profileData?.role === "admin") && (
                                        <li
                                          className="dropdown-item text-danger"
                                          onClick={() =>
                                            toggleDeleteModal(
                                              room,
                                              i,
                                              data.length
                                            )
                                          }
                                        >
                                          Delete
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}
                              </div>
                              {/* <p className="text-muted mb-1 ir-chat-list-time">
                                                        {moment().format("hh:mm A")}
                                                    </p> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`tab-pane fade ${
            navMenuItemActive === 2 ? "active show" : ""
          }`}
          id="archive"
        >
          <form>
            <div className="input-icon-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search Chats"
              />
              <i className="bi bi-search"></i>
            </div>
          </form>
          <div className="tab-content-scroll ps ps--active-y" ref={ref}>
            <div className="d-flex gap-4 flex-column">
              <h5 className="mb-0 ir-tab-heading">Archived Cases</h5>
              <div className="list-group gap-1">
                {data.length > 0 &&
                  data
                    .filter((e) => e.archive === true)
                    .sort((a, b) => b.id - a.id)
                    .map((room, i) => {
                      return (
                        <div
                          className={
                            "list-group-item d-flex align-items-center " +
                            (parseInt(activeChatRoom) === room.id &&
                              "chat-list-side-active")
                          }
                          key={i}
                          onClick={() => getChatHistoryOfRoom(room.id)}
                        >
                          <div
                            className={`avatar me-2 ir-chat-list-state-status ${
                              !room.archive
                                ? "ir-chat-list-state-active"
                                : "ir-chat-list-state-archieve"
                            }`}
                          >
                            <img
                              src={room?.image[0]}
                              className="rounded-circle"
                              alt="case chat icon"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = require("../../../assets/images/chat-images/no-image.png");
                              }}
                            />
                            {/* <div className="new bg-app-default-color">
                                                    <span>+7</span>
                                                </div> */}
                          </div>
                          <div className="ir-side-list-content">
                            <div>
                              <h5 className="ir-room-title-container">
                                <p className="text-truncate ir-room-title">
                                  {room?.name}
                                </p>
                                <div
                                  className={`ir-chat-archive-lock ${
                                    !room.archive
                                      ? "ir-chat-list-state-archive-lock"
                                      : "ir-chat-list-state-archieve-lock-display"
                                  }`}
                                >
                                  <span className="material-symbols-outlined">
                                    lock
                                  </span>
                                </div>
                              </h5>
                              <p className="text-truncate mb-1">
                                {room?.description}
                              </p>
                            </div>
                            <div className="ir-side-list-action-time-container">
                              <div className="text-muted ir-drop-downmenu-trigger-container">
                                <i
                                  className="bi bi-three-dots"
                                  onClick={() => triggerOptionsHandle(i)}
                                ></i>
                                {activeMenus[i] && (
                                  <div className={`ir-dropdown-container`}>
                                    <ul>
                                      <li
                                        className="dropdown-item"
                                        onClick={showShareModal}
                                      >
                                        Share
                                      </li>
                                      {(Number(room.createdBy) ===
                                        Number(profileData?.id || "") ||
                                        profileData?.role === "admin") && (
                                        <li
                                          className="dropdown-item"
                                          onClick={() => updateRoom(room)}
                                        >
                                          Update
                                        </li>
                                      )}
                                      {profileData.role === "admin" &&
                                        (profileData.role === "admin" &&
                                        !room.archive ? (
                                          <li
                                            className="dropdown-item"
                                            onClick={() => addToArchieve(room)}
                                          >
                                            Add to Archive
                                          </li>
                                        ) : (
                                          <li
                                            className="dropdown-item"
                                            onClick={() =>
                                              removeFromArchive(room)
                                            }
                                          >
                                            Remove From Archive
                                          </li>
                                        ))}
                                      {favorite.includes(String(room.id)) ? (
                                        <li
                                          className="dropdown-item"
                                          onClick={() =>
                                            removeFromFavorite(room)
                                          }
                                        >
                                          Remove Favourite
                                        </li>
                                      ) : (
                                        <li
                                          className="dropdown-item"
                                          onClick={() => addToFavorite(room)}
                                        >
                                          Add to Favourites
                                        </li>
                                      )}

                                      <li className="dropdown-divider"></li>
                                      {((Number(room.createdBy) ===
                                        Number(profileData?.id || "") &&
                                        Number(room.chatCount) === 0) ||
                                        profileData?.role === "admin") && (
                                        <li
                                          className="dropdown-item text-danger"
                                          onClick={() =>
                                            toggleDeleteModal(
                                              room,
                                              i,
                                              data.length
                                            )
                                          }
                                        >
                                          Delete
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                )}
                              </div>
                              {/* <p className="text-muted mb-1 ir-chat-list-time">
                                                        {moment().format("hh:mm A")}
                                                    </p> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`tab-pane fade ${
            navMenuItemActive === 3 ? "active show" : ""
          }`}
          id="notifications"
        >
          <div className="tab-content-scroll ps ps--active-y" ref={ref}>
            <div className="d-flex gap-4 flex-column">
              <h5 className="mb-0 ir-tab-heading">Notifications</h5>
              <div className="list-group gap-1">
                {data.length > 0 &&
                  data
                    .sort((a, b) => b.id - a.id)
                    .map((room, i) => {
                      return (
                        <div
                          className={
                            "list-group-item d-flex align-items-center " +
                            (parseInt(activeChatRoom) === room.id &&
                              "chat-list-side-active")
                          }
                          key={i}
                          onClick={() => getChatHistoryOfRoom(room.id)}
                        >
                          <div
                            className={`avatar me-2 ir-chat-list-state-status ${
                              !room.archive
                                ? "ir-chat-list-state-active"
                                : "ir-chat-list-state-archieve"
                            }`}
                          >
                            <img
                              src={require("../../../assets/images/chat-images/case-chat-icon.png")}
                              className="rounded-circle"
                              alt="case chat icon"
                            />
                            {/* <div className="new bg-app-default-color">
                                                    <span>+7</span>
                                                </div> */}
                          </div>
                          <div className="ir-side-list-content">
                            <div>
                              <h5 className="ir-room-title-container">
                                <p className="text-truncate ir-room-title">
                                  {room?.name}
                                </p>
                                <div
                                  className={`ir-chat-archive-lock ${
                                    !room.archive
                                      ? "ir-chat-list-state-archive-lock"
                                      : "ir-chat-list-state-archieve-lock-display"
                                  }`}
                                >
                                  <span className="material-symbols-outlined">
                                    lock
                                  </span>
                                </div>
                              </h5>
                              <p className="text-truncate mb-1">
                                {room?.description}
                              </p>
                            </div>
                            <div className="ir-side-list-action-time-container">
                              <div className="text-muted ir-drop-downmenu-trigger-container">
                                <i
                                  className="bi bi-three-dots"
                                  onClick={() => triggerOptionsHandle(i)}
                                ></i>
                                {activeMenus[i] && (
                                  <div className={`ir-dropdown-container`}>
                                    <ul>
                                      <li
                                        className="dropdown-item"
                                        onClick={showShareModal}
                                      >
                                        Share
                                      </li>
                                      <li className="dropdown-item">
                                        Add to Favourites
                                      </li>
                                      <li className="dropdown-divider"></li>
                                      <li className="dropdown-item text-danger">
                                        Delete
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                              {/* <p className="text-muted mb-1 ir-chat-list-time">
                                                        {moment().format("hh:mm A")}
                                                    </p> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`tab-pane fade ${
            navMenuItemActive === 4 ? "active show" : ""
          }`}
          id="settings"
        >
          <div className="tab-content-scroll ps ps--active-y">
            <div className="d-flex gap-4 flex-column">
              <div className="list-group gap-1 position-relative">
                <div className="user-profile-img">
                  <img
                    src={require("../../../assets/images/chat-images/profile-bg-9.jpg")}
                    className="profile-img"
                    alt=""
                  />
                  <div className="overlay-content">
                    <div className="flex-grow-1">
                      <h5 className="text-white mb-0">Settings</h5>
                    </div>
                  </div>
                </div>
                <div className="text-center ir-side-profile-container position-relative">
                  <div className="mb-lg-3 mb-2">
                    <div className="mb-2 profile-user">
                      {userStore?.profile?.image === "" ? (
                        <p className="profile-bg-replace">
                          {userStore?.profile?.firstname[0] +
                            userStore?.profile?.lastname[0]}
                        </p>
                      ) : (
                        <img
                          className="avatar-lg"
                          src={userStore?.profile?.image}
                          alt="profile pic"
                        />
                      )}
                      <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                        <input
                          id="profile-img-file-input"
                          type="file"
                          className="profile-img-file-input"
                          accept="image/png, image/jpeg"
                          name="file"
                          onChange={updateProfilePicHandle}
                        />
                        <label
                          htmlFor="profile-img-file-input"
                          className="profile-photo-edit avatar-xs form-label"
                        >
                          <span className="avatar-title rounded-circle bg-light text-body">
                            <i className="bi bi-pencil"></i>
                          </span>
                        </label>
                      </div>
                    </div>
                    <h5 className="font-size-16 mb-1 text-truncate">
                      {userStore?.profile?.firstname || ""}{" "}
                      {userStore?.profile?.lastname || ""}
                    </h5>
                    {/* <p className="text-muted text-truncate mb-0">Front end Developer</p> */}
                  </div>
                </div>
                <form onSubmit={upldateProfileSubmitFormHandle}>
                  <div className="p-4 ir-profile-desc">
                    <div className="simplebar-wrapper">
                      <div className="simplebar-mask">
                        <div className="simplebar-offset">
                          <div
                            className="simplebar-content-wrapper"
                            tabIndex="0"
                            role="region"
                            aria-label="scrollable content"
                          >
                            <div className="simplebar-content">
                              <div>
                                <div className="d-flex py-2 ir-set-prof-content-container">
                                  <div className="ir-set-prof-info-container">
                                    <div className="mb-1 ir-set-prof-info-title-container">
                                      First Name
                                    </div>
                                    <input
                                      type="text"
                                      disabled={!enableInputField["firstname"]}
                                      className="ir-set-prof-info-content-container"
                                      name="firstname"
                                      value={profileData?.firstname || ""}
                                      onChange={updateProfileInputChange}
                                    />
                                  </div>
                                  <div className="ir-set-prof-info-icon-container">
                                    <i
                                      onClick={() =>
                                        enableInputFieldHandle("firstname")
                                      }
                                      className="bi bi-pencil-fill"
                                    ></i>
                                  </div>
                                </div>
                                <div className="d-flex py-2 ir-set-prof-content-container">
                                  <div className="ir-set-prof-info-container">
                                    <div className="mb-1 ir-set-prof-info-title-container">
                                      Last Name
                                    </div>
                                    <input
                                      type="text"
                                      disabled={!enableInputField["lastname"]}
                                      className="ir-set-prof-info-content-container"
                                      name="lastname"
                                      value={profileData?.lastname || ""}
                                      onChange={updateProfileInputChange}
                                    />
                                  </div>
                                  <div className="ir-set-prof-info-icon-container">
                                    <i
                                      onClick={() =>
                                        enableInputFieldHandle("lastname")
                                      }
                                      className="bi bi-pencil-fill"
                                    ></i>
                                  </div>
                                </div>
                                <div className="d-flex py-2 ir-set-prof-content-container">
                                  <div className="ir-set-prof-info-container">
                                    <div className="mb-1 ir-set-prof-info-title-container">
                                      Username
                                    </div>
                                    <input
                                      type="text"
                                      disabled
                                      className="ir-set-prof-info-content-container"
                                      value={profileData?.username || ""}
                                      name="username"
                                    />
                                  </div>
                                  {/* <div onClick={enableInputFieldHandle} className="ir-set-prof-info-icon-container">
                                                                        <i className="bi bi-pencil-fill"></i>
                                                                    </div> */}
                                </div>

                                <div className="d-flex py-2 ir-set-prof-content-container">
                                  <div className="ir-set-prof-info-container">
                                    <div className="mb-1 ir-set-prof-info-title-container">
                                      Email
                                    </div>
                                    <input
                                      type="email"
                                      disabled={!enableInputField["email"]}
                                      className="ir-set-prof-info-content-container"
                                      name="email"
                                      value={profileData?.email || ""}
                                      onChange={updateProfileInputChange}
                                    />
                                  </div>
                                  <div className="ir-set-prof-info-icon-container">
                                    <i
                                      onClick={() =>
                                        enableInputFieldHandle("email")
                                      }
                                      className="bi bi-pencil-fill"
                                    ></i>
                                  </div>
                                </div>
                                <div className="d-flex py-2 ir-set-prof-content-container">
                                  <div className="ir-set-prof-info-container">
                                    <div className="mb-1 ir-set-prof-info-title-container">
                                      Phone Number
                                    </div>
                                    <input
                                      type="text"
                                      disabled={
                                        !enableInputField["phonenumber"]
                                      }
                                      className="ir-set-prof-info-content-container"
                                      // defaultValue={userStore?.profile?.phonenumber || "NIL"}
                                      name="phonenumber"
                                      value={profileData?.phonenumber || ""}
                                      onChange={updateProfileInputChange}
                                    />
                                  </div>
                                  <div className="ir-set-prof-info-icon-container">
                                    <i
                                      onClick={() =>
                                        enableInputFieldHandle("phonenumber")
                                      }
                                      className="bi bi-pencil-fill"
                                    ></i>
                                  </div>
                                </div>
                                <div className="d-flex py-2 ir-set-prof-content-container">
                                  <div className="ir-set-prof-info-container">
                                    <div className="mb-1 ir-set-prof-info-title-container">
                                      Gender
                                    </div>
                                    <input
                                      type="text"
                                      disabled={!enableInputField["gender"]}
                                      className="ir-set-prof-info-content-container"
                                      // defaultValue={userStore?.profile?.gender || "NIL"}
                                      name="gender"
                                      value={profileData?.gender || ""}
                                      onChange={updateProfileInputChange}
                                    />
                                  </div>
                                  <div className="ir-set-prof-info-icon-container">
                                    <i
                                      onClick={() =>
                                        enableInputFieldHandle("gender")
                                      }
                                      className="bi bi-pencil-fill"
                                    ></i>
                                  </div>
                                </div>
                                <div className="d-flex py-2 ir-set-prof-content-container">
                                  <div className="ir-set-prof-info-container">
                                    <div className="mb-1 ir-set-prof-info-title-container">
                                      Location
                                    </div>
                                    <input
                                      type="text"
                                      disabled={!enableInputField["country"]}
                                      className="ir-set-prof-info-content-container"
                                      // defaultValue={userStore?.profile?.country || "NIL"}
                                      name="country"
                                      value={profileData?.country || ""}
                                      onChange={updateProfileInputChange}
                                    />
                                  </div>
                                  <div className="ir-set-prof-info-icon-container">
                                    <i
                                      onClick={() =>
                                        enableInputFieldHandle("country")
                                      }
                                      className="bi bi-pencil-fill"
                                    ></i>
                                  </div>
                                </div>
                                <div className="d-flex py-2 ir-set-prof-content-container">
                                  <div className="ir-set-prof-info-container">
                                    <div className="mb-1 ir-set-prof-info-title-container">
                                      Password
                                    </div>
                                    <input
                                      type="text"
                                      disabled={!enableInputField["password"]}
                                      className="ir-set-prof-info-content-container"
                                      // defaultValue={userStore?.profile?.password}
                                      name="password"
                                      value={profileData?.password || ""}
                                      onChange={updateProfileInputChange}
                                    />
                                  </div>
                                  <div className="ir-set-prof-info-icon-container">
                                    <i
                                      onClick={() =>
                                        enableInputFieldHandle("password")
                                      }
                                      className="bi bi-pencil-fill"
                                    ></i>
                                  </div>
                                </div>
                              </div>
                              {/* <button className="btn btn-submit" type="submit">Submit</button> */}
                              <button
                                className="btn btn-primary bg-app-default-color btn-lg btn-block full-width-btn ir-btn-default"
                                type="submit"
                              >
                                {/* <i className="bi bi-chat-dots me-1"></i>  */}
                                <span>Update Profile</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`tab-pane fade ${
            navMenuItemActive === 5 ? "active show" : ""
          }`}
          id="profile"
        >
          <div className="tab-content-scroll ps ps--active-y">
            <div className="d-flex gap-4 flex-column">
              <div className="list-group gap-1 position-relative">
                <div className="user-profile-img">
                  <img
                    src="https://doot-light.react.themesbrand.com/static/media/img-4.c7a84ad5058b9382090e.jpg"
                    className="profile-img"
                    alt=""
                  />
                  {/* <img src={require('../../../assets/images/chat-images/profile-bg.jpg')}className="profile-img" alt="" /> */}
                  <div className="overlay-content">
                    <div className="flex-grow-1">
                      <h5 className="text-white mb-0">My Profile</h5>
                    </div>
                  </div>
                </div>
                <div className="text-center ir-side-profile-container position-relative">
                  <div className="mb-lg-3 mb-2">
                    {userStore?.profile?.image === "" ? (
                      <p className="profile-bg-replace">
                        {userStore?.profile?.firstname[0] +
                          userStore?.profile?.lastname[0]}
                      </p>
                    ) : (
                      <img
                        className="avatar-lg"
                        src={userStore?.profile?.image}
                        alt="profile pic"
                      />
                    )}

                    <h5 className="font-size-16 mb-1 text-truncate">
                      {userStore?.profile?.firstname || ""}{" "}
                      {userStore?.profile?.lastname || ""}
                    </h5>
                    {/* <p className="text-muted text-truncate mb-0">Front end Developer</p> */}
                  </div>
                </div>
                <div className="p-4 ir-profile-desc">
                  <div className="simplebar-wrapper">
                    <div className="simplebar-mask">
                      <div className="simplebar-offset">
                        <div
                          className="simplebar-content-wrapper"
                          tabIndex="0"
                          role="region"
                          aria-label="scrollable content"
                        >
                          <div className="simplebar-content">
                            <div>
                              <div className="d-flex py-2">
                                <div className="flex-shrink-0 me-3">
                                  <i className="bi bi-person align-middle text-muted"></i>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="ir-prof-details mb-0">
                                    {userStore?.profile?.firstname || ""}{" "}
                                    {userStore?.profile?.lastname || ""}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex py-2">
                                <div className="flex-shrink-0 me-3">
                                  <i className="bi bi-person align-middle text-muted"></i>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="ir-prof-details mb-0">
                                    {userStore?.profile?.username || ""}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex py-2">
                                <div className="flex-shrink-0 me-3">
                                  <i className="bi bi-chat-dots align-middle text-muted"></i>
                                </div>
                                <div className="flex-grow-1">
                                  <p className="ir-prof-details mb-0">
                                    {userStore?.profile?.email || ""}
                                  </p>
                                </div>
                              </div>
                              {userStore?.profile?.gender === "" ? (
                                ""
                              ) : (
                                <div className="d-flex py-2">
                                  <div className="flex-shrink-0 me-3">
                                    <i className="bi bi-person align-middle text-muted"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <p className="ir-prof-details mb-0">
                                      {userStore?.profile?.gender || ""}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {userStore?.profile?.phonenumber === "" ? (
                                ""
                              ) : (
                                <div className="d-flex py-2">
                                  <div className="flex-shrink-0 me-3">
                                    <i className="bi bi-telephone align-middle text-muted"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <p className="ir-prof-details mb-0">
                                      {userStore?.profile?.phonenumber || ""}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {userStore?.profile?.country === "" ? (
                                ""
                              ) : (
                                <div className="d-flex py-2">
                                  <div className="flex-shrink-0 me-3">
                                    <i className="bi bi-geo-alt align-middle text-muted"></i>
                                  </div>
                                  <div className="flex-grow-1">
                                    <p className="ir-prof-details mb-0">
                                      {userStore?.profile?.country || ""}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                            {/* <hr />
                                                        <div>
                                                            <div>
                                                                <h5 className="font-size-11 text-muted text-uppercase mb-3">Attached Files</h5>
                                                            </div>
                                                            <div>
                                                                <div className="p-2 border mb-2 card">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 avatar-xs ms-1 me-3">
                                                                            <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                                                                                <i className="bi bi-file-earmark-text"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 overflow-hidden">
                                                                            <h5 className="text-truncate mb-1">design-phase-1-approved.pdf</h5>
                                                                            <p className="text-muted font-size-13 mb-0">12.5 MB</p>
                                                                        </div>
                                                                        <div className="flex-shrink-0 ms-3">
                                                                            <div className="d-flex gap-2">
                                                                                <div className="d-flex align-items-center justify-content-center">
                                                                                    <a className="text-muted px-1">
                                                                                        <i className="bi bi-download"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-2 border mb-2 card">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 avatar-xs ms-1 me-3">
                                                                            <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                                                                                <i className="bi bi-card-image"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 overflow-hidden">
                                                                            <h5 className="text-truncate mb-1">Image-1.jpg</h5>
                                                                            <p className="text-muted font-size-13 mb-0">4.2 MB</p>
                                                                        </div>
                                                                        <div className="flex-shrink-0 ms-3">
                                                                            <div className="d-flex gap-2">
                                                                                <div className="d-flex align-items-center justify-content-center">
                                                                                    <a className="text-muted px-1">
                                                                                        <i className="bi bi-download"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-2 border mb-2 card">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 avatar-xs ms-1 me-3">
                                                                            <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                                                                                <i className="bi bi-card-image"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 overflow-hidden">
                                                                            <h5 className="text-truncate mb-1">Image-2.jpg</h5>
                                                                            <p className="text-muted font-size-13 mb-0">3.1 MB</p>
                                                                        </div>
                                                                        <div className="flex-shrink-0 ms-3">
                                                                            <div className="d-flex gap-2">
                                                                                <div className="d-flex align-items-center justify-content-center">
                                                                                    <a className="text-muted px-1">
                                                                                        <i className="bi bi-download"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-2 border mb-2 card">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 avatar-xs ms-1 me-3">
                                                                            <div className="avatar-title bg-soft-primary text-primary rounded-circle">
                                                                                <i className="bi bi-file-earmark-text"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 overflow-hidden">
                                                                            <h5 className="text-truncate mb-1">Landing-A.zip</h5>
                                                                            <p className="text-muted font-size-13 mb-0">6.7 MB</p>
                                                                        </div>
                                                                        <div className="flex-shrink-0 ms-3">
                                                                            <div className="d-flex gap-2">
                                                                                <div className="d-flex align-items-center justify-content-center">
                                                                                    <a className="text-muted px-1">
                                                                                        <i className="bi bi-download"></i>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* updateRoom */}
      {newCaseModal && (
        <div
          className={`ir-ws-position-fixed ir-ws-sign-popup-container ${
            imageEdit ? "imageEditModalMainContainer" : ""
          }`}
        >
          <div
            className={`ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container`}
          >
            <div className="ir-ws-signup-content-container">
              <div
                className="ir-ws-position-absolute ir-ws-signup-close-container"
                onClick={newChatCaseCloseModalHandle}
              >
                <i className="bi bi-x-lg"></i>
              </div>
              <div className="ir-ws-signup-content-inner-container">
                <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                  Create Case
                </h3>
              </div>
              {fileSizeLimit === false ? (
                ""
              ) : (
                <p className="ir-file-exceeded">
                  File size should be less than 1MB
                </p>
              )}
              <form
                className={`ir-ws-signup-form-container ${
                  imageEdit ? "imageEditModalOpened" : ""
                }`}
                onSubmit={newCaseSubmitFormHandle}
              >
                <div className="ir-ws-signup-content-group">
                  <input
                    className="ir-ws-signup-input-field"
                    type="text"
                    name="name"
                    onChange={newCaseInputChange}
                    value={newCaseData.name}
                    required
                  />
                  <span className="ir-ws-signup-highlight"></span>
                  <span className="ir-ws-signup-bar"></span>
                  <label className="ir-ws-signup-label">Case Name</label>
                </div>
                <div className="ir-ws-signup-content-group">
                  <textarea
                    className="ir-ws-signup-input-field"
                    type="text"
                    name="description"
                    onChange={newCaseInputChange}
                    value={newCaseData.description}
                    required
                  ></textarea>
                  <span className="ir-ws-signup-highlight"></span>
                  <span className="ir-ws-signup-bar"></span>
                  <label className="ir-ws-signup-label">Case Description</label>
                </div>
                <div className="ir-images-row images">
                  {newCaseData?.images?.length > 0 &&
                    newCaseData.images.map((d, i) => {
                      return (
                        <>
                          <div className="ir-image-col">
                            <a onClick={() => deleteImage(d, newCaseData)}>X</a>
                            <img src={d} alt="a" height={80} />
                            {/* { d} */}
                          </div>
                        </>
                      );
                    })}
                </div>

                {/* <div className="ir-ws-signup-content-group"> */}
                <div className="ir-ws-file-field">
                  <div className="ir-ws-file-path-wrapper">
                    {uploadedFiles.map((file, id) => (
                      <div key={id} className="file-field-wrapper">
                        <span className="file-field-name">{file.name}</span>
                        <span
                          className="remove-file-item"
                          onClick={() => removeUploadItem(id)}
                        >
                          X
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="ir-ws-create-case-file-btn-container">
                    <span className="btn blue-gradient btn-sm">Add file</span>
                    <input
                      type="file"
                      multiple={false}
                      onChange={handleFileChange}
                      className={`${
                        uploadedFiles.length === 0
                          ? "doesnt-exists"
                          : "input-file-exist"
                      }`}
                    />
                  </div>
                </div>
                <div className="ir-accept-terms-container">
                  <div className="ir-checkbox ir-ws-text-center">
                    <input
                      onChange={handleAcceptChange}
                      type="checkbox"
                      id="terms-conditions"
                    />
                    <label htmlFor="terms-conditions">
                      Accept Terms and Conditions.
                    </label>
                    <a
                      href="https://ir4u.info/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read guidelines here
                    </a>
                  </div>
                </div>
                {/* <div className="ir-modal-btn-container"> */}
                <div className="ir-ws-text-center ir-ws-default-btn-container">
                  <button
                    className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                    disabled={!agree}
                    type="submit"
                  >
                    <span>Create Case</span>
                  </button>
                </div>
              </form>

              {imageEdit && (
                <FilerobotImageEditor
                  source={URL.createObjectURL(fillterUplaodFile[0])}
                  onSave={onImageSave}
                  onBeforeSave={(savedImageData, save) => {
                    savedImageData.name = fillterUplaodFile[0].name;
                    return false;
                  }}
                  Text={{
                    text: "Double click to add text ",
                    fontSize: 22,
                  }}
                  Pen={{
                    selectAnnotationAfterDrawing: true,
                  }}
                  tabsIds={[TABS.ANNOTATE, TABS.ADJUST]}
                  Rotate={{ angle: 90, componentType: "slider" }}
                  Crop={{
                    noPresets: true,
                    ratio: "custom",
                  }}
                  defaultTabId={TABS.ADJUST}
                  defaultToolId={TOOLS.Text}
                  onClose={closeImgEditor}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {shareModal && (
        <div className="ir-ws-position-fixed ir-ws-sign-popup-container ir-ws-share-main-container">
          <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
            <div className="ir-ws-signup-content-container">
              <div
                className="ir-ws-position-absolute ir-ws-signup-close-container"
                onClick={shareCloseModalHandle}
              >
                <i className="bi bi-x-lg"></i>
              </div>
              <div className="ir-ws-signup-content-inner-container">
                <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                  Share Case
                </h3>
              </div>
              <div className="ir-share-modal-inside-content">
                <p>Invite your friends through social network</p>
                <div className="ir-share-container">
                  <div className="ir-share-icon-container ir-share-icon-container-hover">
                    <FacebookShareButton
                      url={shareUrl}
                      quote={"Dummy text!"}
                      hashtag="#ir4u"
                    >
                      <FacebookIcon size={50} round />
                    </FacebookShareButton>
                  </div>
                  <div className="ir-share-icon-container ir-share-icon-container-hover">
                    <WhatsappShareButton url={shareUrl} quote={"Dummy text!"}>
                      <WhatsappIcon size={50} round />
                    </WhatsappShareButton>
                  </div>
                  <div className="ir-share-icon-container ir-share-icon-container-hover">
                    {/* <div
                      className="ir-share-icon-copy-link"
                      onClick={() => navigator.clipboard.writeText(shareUrl)}
                      title="Copy Link"
                    >
                      <i className="bi bi-link-45deg"></i>
                    </div> */}
                    {!tooltipText ? (
                      <div
                        className="ir-share-icon-copy-link ir-share-icon-link"
                        onClick={handleCopyLink}
                        title="Copy Link"
                      >
                        <i className="bi bi-link-45deg"></i>
                      </div>
                    ) : (
                      <div
                        className="ir-share-icon-copy-link ir-share-icon-link"
                        title="Link Copied"
                      >
                        <i class="bi bi-check-lg"></i>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {updateProfilePicModal && (
        <div className="iks-mint-cust-modal">
          <div className="iks-mint-modal-container">
            <div className="iks-mint-modal-content">
              <div className="ir-form-title">
                <h2>Update Profile Pic</h2>
                <button onClick={updateProfilePicCloseModalHandle}>
                  <i className="bi bi-x-lg"></i>
                </button>
              </div>
              <form
                className="ir-case-form-container"
                onSubmit={upldateProfileSubmitFormHandle}
              >
                <div className="it-case-update-profile-img-container">
                  <img
                    src={updateProfilePic}
                    alt="update profile pic"
                    className="ir-update-prof-pic-onupdate"
                  />
                </div>
                {/* </div> */}
                <div className="ir-modal-btn-container">
                  <button
                    className="btn btn-submit ir-btn-default"
                    type="submit"
                  >
                    <span>Update</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {isRoomUpdating && (
        <div
          className={`ir-ws-position-fixed ir-ws-sign-popup-container ${
            imageEdit ? "imageEditModalMainContainer" : ""
          }`}
        >
          <div
            className={`ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container`}
          >
            <div className="ir-ws-signup-content-container">
              <div
                className="ir-ws-position-absolute ir-ws-signup-close-container"
                onClick={updateChatCaseCloseModalHandle}
              >
                <i className="bi bi-x-lg"></i>
              </div>
              <div className="ir-ws-signup-content-inner-container">
                <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                  Update Case
                </h3>
              </div>
              {fileSizeLimit === false ? (
                ""
              ) : (
                <p className="ir-file-exceeded">
                  File size should be less than 1MB
                </p>
              )}
              <form
                className={`ir-ws-signup-form-container ${
                  imageEdit ? "imageEditModalOpened" : ""
                }`}
                onSubmit={newCaseSubmitFormHandle}
              >
                <div className="ir-ws-signup-content-group">
                  <input
                    className="ir-ws-signup-input-field"
                    type="text"
                    name="name"
                    onChange={newCaseInputChange}
                    value={newCaseData.name}
                    required
                  />
                  <span className="ir-ws-signup-highlight"></span>
                  <span className="ir-ws-signup-bar"></span>
                  <label className="ir-ws-signup-label">Case Name</label>
                </div>
                <div className="ir-ws-signup-content-group">
                  <textarea
                    className="ir-ws-signup-input-field"
                    type="text"
                    name="description"
                    onChange={newCaseInputChange}
                    value={newCaseData.description}
                    required
                  ></textarea>
                  <span className="ir-ws-signup-highlight"></span>
                  <span className="ir-ws-signup-bar"></span>
                  <label className="ir-ws-signup-label">Case Description</label>
                </div>
                <div className="ir-images-row images">
                  {newCaseData?.images?.length > 0 &&
                    newCaseData.images.map((d, i) => {
                      return (
                        <>
                          <div className="ir-image-col">
                            <a onClick={() => deleteImage(d, newCaseData)}>X</a>
                            <img src={d} alt="a" height={80} />
                            {/* { d} */}
                          </div>
                        </>
                      );
                    })}
                </div>

                {/* <div className="ir-ws-signup-content-group"> */}
                <div className="ir-ws-file-field">
                  <div className="ir-ws-file-path-wrapper">
                    {uploadedFiles.map((file, id) => (
                      <div key={id} className="file-field-wrapper">
                        <span className="file-field-name">{file.name}</span>
                        <span
                          className="remove-file-item"
                          onClick={() => removeUploadItem(id)}
                        >
                          X
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="ir-ws-create-case-file-btn-container">
                    <span className="btn blue-gradient btn-sm">Add file</span>
                    <input
                      type="file"
                      multiple={false}
                      onChange={handleFileChange}
                      className={`${
                        uploadedFiles.length === 0
                          ? "doesnt-exists"
                          : "input-file-exist"
                      }`}
                    />
                  </div>
                </div>
                <div className="ir-ws-text-center ir-ws-default-btn-container">
                  <button
                    className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                    type="submit"
                  >
                    <span>Update Case</span>
                  </button>
                </div>
              </form>
              {imageEdit && (
                <FilerobotImageEditor
                  source={URL.createObjectURL(fillterUplaodFile[0])}
                  onSave={onImageSave}
                  onBeforeSave={(savedImageData, save) => {
                    savedImageData.name = fillterUplaodFile[0].name;
                    return false;
                  }}
                  Text={{ text: "Double click to add text ", fontSize: 22 }}
                  tabsIds={[TABS.ANNOTATE, TABS.ADJUST]}
                  defaultTabId={TABS.ADJUST}
                  defaultToolId={TOOLS.Text}
                  Rotate={{ angle: 90, componentType: "slider" }}
                  Crop={{
                    noPresets: true,
                    ratio: "custom",
                  }}
                  onClose={closeImgEditor}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {showDeleteModal && (
        <DeleteConfirmation
          toggleModal={toggleDeleteModal}
          confirmationFunc={() =>
            deleteRoom(deleteRoomId, deleteIndex, deleteRoomDataLength)
          }
        />
      )}
    </div>
  );
};

export default ChatSidebar;
