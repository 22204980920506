import React, { useState } from "react";
import axios from "axios";
import "./ArchiveAdminDataModal.scss";
import { BaseURL } from "../../../api/axios";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { usePaymentContext } from "../../../context/PaymentContext";

const ArchiveAdminDataModal = ({ closeModal, activeRoomsData }) => {
 
  const [imgUrl, setImgUrl] = useState(null);
  const [itemId, setItemsId] = useState(null);
  const [title, setTitle] = useState(null);
  const [currency, setCurrency] = useState("inr");
  const [price, setPrice] = useState("");

  const { paymentResponses, setPaymentResponses } = usePaymentContext();


  useEffect(() => {
    setImgUrl(activeRoomsData?.data?.data?.image);
    setItemsId(activeRoomsData?.data?.data?.id);
    setTitle(activeRoomsData?.data?.data?.name);
  }, []);



  const handleSubmit = () => {
    const formData = new FormData();
    // formData.append("image", imgUrl);
    formData.append("name", title);
    formData.append("price", price);
    // formData.append("currency", "inr");
    formData.append("quantity", 1);
    // formData.append("id", itemId);

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      "Content-Type": "multipart/form-data",
    };

    axios
      .post(`${BaseURL}/payment/product`, formData, { headers })
      .then((response) => {
        console.log("Product created:", response?.data);

        
        // const newPaymentResponses = {
        //   ...paymentResponses,
        //   [activeRoomsData?.data?.data?.id]: response?.data?.data?.paymentLink,
        // };
        // console.log("New Payment Responses:", newPaymentResponses);
  
        // setPaymentResponses(newPaymentResponses);
  
        // console.log("Payment responses updated:", paymentResponses);


        const newPaymentResponses = {
          ...paymentResponses,
          [itemId]: response?.data?.data?.paymentLink,
        };
        setPaymentResponses(newPaymentResponses);
        console.log('new payment id',newPaymentResponses)

        localStorage.setItem('paymentResponses', JSON.stringify(newPaymentResponses));

        closeModal();
        Swal.fire({
          title: 'Success!',
          text: 'Room is successfully archived',
          icon: 'success'
      });
      })
      .catch((error) => {
        console.error("Error creating product:", error);
      });
  };

  // const handleCancel = () => {};
  return (
    <div className="archive-admin-modal">
      <div className="archive-admin-modal-content">
        <h2>Archive Data</h2>
        <div className="input-field">
          <p>Title:{title}</p>
          {/* <label>Title:</label> */}
          {/* <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          /> */}
        </div>
        <div className="input-field">
          <p>Item ID: {itemId}</p>
          {/* <label>Title:</label> */}
          {/* <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          /> */}
        </div>
        <div className="input-field">
        <p>Image URL: {imgUrl}</p>
        {/* {console.log(imgUrl)} */}
        {/* <label>Image URL:</label>
        <input
            type="text"
            value={imgUrl}
            onChange={(e) => setImgUrl(e.target.value)}
          /> */}
        </div>
        <div className="input-field">
          <label>Currency:</label>
          <select
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          >
            <option value="inr">INR</option>
            <option value="usd">USD</option>
          </select>
        </div>
        <div className="input-field">
          <label>Price:</label>
          <input
            type="text"
            value={price}
            onChange={(e) => setPrice(parseInt(e.target.value))}
          />
        </div>

        <div className="button-container">
          <button className="submit-button" onClick={() => handleSubmit()}>
            Submit
          </button>
          <button className="cancel-button" onClick={() => closeModal()}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ArchiveAdminDataModal;







