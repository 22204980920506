import { useLocation } from "react-router-dom";
import "./LinkModal.scss";
const LinkModal = ({ linkModal, setLinkModal, presenterData }) => {
  let location = useLocation();
  let locationUrl = window.location.origin;
  const handleCloseLinkModal = () => {
    setLinkModal(false);
  };
  const date = new Date();
  const formattedDate = date.toLocaleString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    } else {
      // If the popup was blocked, inform the user to allow popups
      alert("Please allow popups to open the link in a new tab.");
    }
  };
  const sendMail = () => {
    const subject = "Meeting Link";
    const body = `Meeting Title: ${presenterData.title}\nDate and Time: ${formattedDate}\nPresenter 1: ${locationUrl}${presenterData.presenter}\nPasscode: ${presenterData.passCode}`;

    const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=&su=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    openInNewTab(mailtoLink);
  };

  const sendWhatsApp = () => {
    const text = `Meeting Title: ${presenterData.title}\nDate and Time: ${formattedDate}\nPresenter 1: ${locationUrl}${presenterData.presenter}\nPasscode: ${presenterData.passCode}`;

    const whatsappLink = `https://web.whatsapp.com/send?text=${encodeURIComponent(
      text
    )}`;

    openInNewTab(whatsappLink);
  };
  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={handleCloseLinkModal}
          >
            <span>X</span>
          </div>
          <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
              Share The Meeting Link
            </h3>
            <div className="ir-ws-webinar-link-modal-icon-container">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="512.000000pt"
                height="512.000000pt"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
                className="ir-ws-webinar-link-modal-icon ir-ws-gmail-icon"
                onClick={sendMail}
              >
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    d="M435 4193 c-197 -27 -373 -188 -420 -384 -22 -96 -22 -2402 0 -2498
45 -188 198 -335 390 -376 106 -22 4205 -22 4310 0 194 41 345 187 390 376 22
96 22 2402 0 2498 -40 168 -158 298 -331 363 l-59 23 -2125 1 c-1169 1 -2138
-1 -2155 -3z m4299 -221 l50 -23 -25 -23 c-13 -13 -512 -434 -1107 -935 -653
-550 -1089 -910 -1098 -907 -12 5 -2140 1791 -2193 1841 l-24 23 31 16 c17 9
48 20 69 25 22 5 930 8 2143 8 l2105 -2 49 -23z m-3790 -800 c394 -332 716
-607 716 -612 0 -8 -1422 -1211 -1440 -1218 -4 -2 -10 7 -14 20 -8 30 -8 2366
0 2396 4 13 10 22 14 20 5 -2 330 -274 724 -606z m3970 586 c8 -30 8 -2366 0
-2396 -4 -13 -10 -22 -14 -20 -24 9 -1440 1212 -1437 1221 3 11 1428 1215
1438 1216 4 1 9 -9 13 -21z m-2740 -1623 c175 -147 332 -274 348 -282 17 -9
40 -12 57 -8 18 4 160 116 373 295 294 249 345 287 358 276 8 -7 332 -280 720
-606 388 -326 716 -603 729 -616 l25 -23 -50 -23 -49 -23 -2125 0 -2125 0 -49
23 c-43 20 -48 24 -35 37 8 8 343 291 744 629 615 517 732 612 745 602 8 -7
159 -133 334 -281z"
                  />
                </g>
              </svg>
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="512.000000pt"
                height="512.000000pt"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
                className="ir-ws-webinar-link-modal-icon ir-ws-whatsapp-icon"
                onClick={sendWhatsApp}
              >
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    d="M2330 5110 c-494 -48 -950 -230 -1350 -538 -195 -150 -448 -432 -594
-662 -63 -99 -186 -351 -230 -471 -204 -556 -204 -1204 -1 -1755 37 -101 150
-339 203 -429 l29 -50 -143 -519 c-79 -286 -144 -532 -144 -547 0 -39 38 -79
80 -85 27 -4 156 32 539 151 277 85 509 155 516 155 7 0 73 -31 146 -69 638
-331 1389 -380 2058 -135 120 44 372 167 471 230 321 204 620 503 824 824 63
99 186 351 230 471 49 134 102 340 128 499 18 113 21 176 21 380 0 278 -15
409 -78 649 -48 180 -92 297 -180 476 -132 269 -269 460 -489 681 -221 220
-412 357 -681 489 -246 121 -474 193 -740 235 -147 23 -475 34 -615 20z m615
-219 c512 -88 948 -319 1310 -692 345 -356 560 -785 642 -1284 26 -156 26
-554 0 -710 -82 -499 -297 -928 -642 -1284 -362 -373 -800 -605 -1310 -692
-155 -27 -461 -36 -617 -20 -350 38 -658 138 -969 317 -42 24 -87 44 -100 44
-13 0 -223 -61 -467 -136 -243 -75 -445 -135 -447 -133 -2 2 53 204 121 449
86 308 124 456 121 480 -3 19 -25 69 -51 111 -165 273 -281 613 -322 949 -22
178 -15 482 15 655 83 485 296 903 636 1254 402 414 874 643 1475 715 19 2
136 3 260 1 172 -2 253 -8 345 -24z"
                  />
                  <path
                    d="M1475 4150 c-122 -13 -148 -24 -200 -82 -227 -253 -367 -613 -352
-908 9 -176 44 -298 143 -511 263 -561 690 -1048 1174 -1336 289 -172 568
-264 896 -294 607 -55 985 187 1120 719 26 102 30 153 14 182 -6 10 -72 56
-148 101 -75 46 -217 134 -316 197 -98 63 -202 125 -230 138 -62 29 -151 32
-203 7 -36 -17 -44 -26 -144 -161 -66 -89 -219 -256 -252 -273 -23 -13 -52 -2
-162 60 -239 134 -566 394 -727 576 -120 137 -208 280 -208 341 0 8 34 54 75
102 132 153 180 279 150 395 -36 140 -275 603 -359 696 -44 49 -132 65 -271
51z m185 -272 c27 -45 96 -174 152 -285 121 -241 125 -263 68 -357 -19 -32
-72 -98 -116 -147 -88 -95 -97 -116 -84 -194 47 -279 385 -657 869 -973 225
-148 369 -210 443 -193 98 23 244 152 384 340 l89 119 33 -13 c18 -8 112 -65
210 -128 97 -62 218 -137 267 -167 50 -29 92 -55 95 -57 18 -16 -81 -278 -130
-341 -64 -83 -136 -143 -224 -186 -119 -59 -200 -77 -373 -83 -505 -19 -984
177 -1420 583 -298 277 -601 718 -739 1076 -100 258 -83 509 54 786 41 83 148
246 180 275 18 16 53 23 139 25 l52 2 51 -82z"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkModal;

/* className="ir-ws-webinar-link-modal-icon"
                onClick={sendMail}*/
/*className="ir-ws-webinar-link-modal-icon"
                onClick={sendWhatsApp}*/
