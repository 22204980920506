import React from 'react';
import './CaseFeatures.scss';

const CaseFeatures = () => {
  return (
    <div className="features-container ir-ws-banner-padding-tb ir-ws-padding-lr-132">
      <h1 className="features-title ir-ws-heading-default-color">Explore Our Features</h1>
      <p className="ir-ws-heading-default-color features-description">Our platform offers a variety of interactive tools designed to enhance your learning experience in {" "} 
      <span className='ir-title-features'>Interventional Radiology</span>
      </p>
     
      <div className="features-grid">
        <div className="feature-card">
          <img src={require("../../assets/images/interaction.png")} alt="icon" className='icon' />
          {/* <div className="userrInteractionIcon"></div> */}
          <h2 className="feature-title">User Interactions with Cases</h2>
          <p className="feature-description">Engage with realistic case studies that simulate real-life scenarios. This feature allows you to practice decision-making and diagnostic skills in a controlled, risk-free environment.</p>
        </div>
        <div className="feature-card">
        <img src={require("../../assets/images/mcq-icon.png")} alt="icon" className='icon' />
          {/* <div className="mcqIcon"></div> */}
          <h2 className="feature-title">Multiple Choice Questions (MCQs)</h2>
          <p className="feature-description">Test your knowledge with a diverse collection of MCQs. Each question is designed to challenge your understanding and reinforce key concepts, helping you to retain critical information.</p>
        </div>
        <div className="feature-card">
        <img src={require("../../assets/images/fill-in-the-blank-icon.png")} alt="icon" className='icon' />
          {/* <div className="fillInTheBlankIcon"></div> */}
          <h2 className="feature-title">Fill in the Blanks</h2>
          <p className="feature-description">Improve your recall and understanding by filling in the blanks with the correct medical terms and concepts. This exercise is perfect for solidifying your foundational knowledge.</p>
        </div>
        <div className="feature-card">
        <img src={require("../../assets/images/abnormality-icon.png")} alt="icon" className='icon' />
          {/* <div className="abnormalityIcon"></div> */}
          <h2 className="feature-title">Find Abnormality</h2>
          <p className="feature-description">Hone your diagnostic abilities by identifying abnormalities in medical images. This hands-on approach enhances your visual assessment skills, which are crucial in interventional radiology.</p>
        </div>
        <div className="feature-card">
        <img src={require("../../assets/images/rapid-icon.png")} alt="icon" className='icon' />
          {/* <div className="rapidIcon"></div> */}
          <h2 className="feature-title">Rapid Questions</h2>
          <p className="feature-description">Prepare for high-pressure situations with rapid-fire questions that test your quick thinking and decision-making abilities. This feature is ideal for exam preparation and real-world readiness.</p>
        </div>
        <div className="feature-card">
        <img src={require("../../assets/images/jumbling-icon.png")} alt="icon" className='icon' />
          {/* <div className="jumblingIcon"></div> */}
          <h2 className="feature-title">Jumbling</h2>
          <p className="feature-description">Strengthen your understanding of procedural steps, anatomical structures, and more through jumbling exercises. This interactive activity adds a fun and challenging element to your learning experience.</p>
        </div>
      </div>
    </div>
  );
};

export default CaseFeatures;
