import "./CreateWebinarModal.scss";
import "../../App.scss";
import { useModalContext } from "./ModalContext";
import FileEditor from "./FileEditor";
import { useEffect, useState, useRef } from "react";
const CreateWebinarModal = ({ type, setUpdateModal, updateModal, id }) => {
  const {
    handleSubmit,
    inputField,
    closeModal,
    handleFileChange,
    handleUpdateWebinar,
    isEditorOpen,
    file,
    setFile,
    isLoading,
    setPrice,
    setDateAndTime,
    setTitle,
    setDescription,
    setIsEditorOpen,
    webinarData,
    price,
    dateAndTime,
    title,
    description,
    thumbnail,
  } = useModalContext();

  const selectedWebinar = id
    ? webinarData.find((webinar) => webinar.id === id)
    : null;
  const [errorMessage, setErrorMessage] = useState("");

  function convertUTCToLocalAndFormat(utcTimeString) {
    const utcDateTime = new Date(utcTimeString);
    const localTimeZoneOffset = new Date().getTimezoneOffset();
    const localDateTime = new Date(
      utcDateTime.getTime() - localTimeZoneOffset * 60000
    );

    const localTimeFormat = localDateTime.toISOString().slice(0, 16);

    return localTimeFormat;
  }
  const inputRef = useRef();
  const handleContainerClick = () => {
    // Programmatically trigger the click on the input element
    console.log("clicked");
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  useEffect(() => {
    if (selectedWebinar) {
      setDateAndTime(convertUTCToLocalAndFormat(selectedWebinar.dateAndTime));
      setTitle(selectedWebinar.title);
      setDescription(selectedWebinar.description);
      setPrice(`$${selectedWebinar.price}`);
    }
  }, [selectedWebinar]);

  const extractedDataInput = [
    {
      id: 1,
      title: "Date and Time",
      type: "datetime-local",
      state: dateAndTime,
      setState: setDateAndTime,
    },
    {
      id: 2,
      title: "Webinar Name",
      type: "text",
      state: title,
      setState: setTitle,
    },
    {
      id: 3,
      title: "Description",
      type: "text",
      state: description,
      setState: setDescription,
    },
    {
      id: 4,
      title: "Set Price",
      type: "text",
      state: price,
      setState: setPrice,
    },
  ];
  const [fileName, setFileName] = useState(file.fullName);
  useEffect(() => {
    setFileName(file.fullName);
  }, [file.fullName]);

  const handleClearFile = () => {
    setFileName("");
  };

  const handleCloseUpdateModal = () => {
    setUpdateModal(false);
    setPrice("");
    setDateAndTime("");
    setTitle("");
    setDescription("");
    setFile("");
    setIsEditorOpen(false);
  };
  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={
              type === "Update Webinar" ? handleCloseUpdateModal : closeModal
            }
          >
            <span>X</span>
          </div>
          <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
              {type}
            </h3>
          </div>
          <form className="ir-ws-signup-form-container">
            {isEditorOpen ? (
              <FileEditor />
            ) : (
              <>
                {!selectedWebinar
                  ? inputField.map((item, index) => (
                      <div className="ir-ws-signup-content-group" key={index}>
                        <input
                          className="ir-ws-signup-input-field"
                          type={item.type}
                          name={item.title}
                          required
                          value={item.state}
                          placeholder={item.title === "Set Price" ? "$" : null}
                          onKeyDown={(e) => {
                            if (e.key === "Backspace") {
                              setErrorMessage("");
                              return;
                            }
                            if (
                              item.title === "Set Price" &&
                              (isNaN(Number(e.key)) || e.key === " ")
                            ) {
                              e.preventDefault();
                              setErrorMessage("Enter a number");
                            } else {
                              setErrorMessage("");
                            }
                          }}
                          onChange={(e) => {
                            const inputValue = e.target.value;

                            if (item.title === "Set Price") {
                              if (!inputValue.startsWith("$")) {
                                item.setState("$" + inputValue);
                              } else {
                                item.setState(inputValue);
                              }
                            } else {
                              item.setState(inputValue);
                            }
                          }}
                        />
                        <span className="ir-ws-signup-highlight"></span>
                        <span className="ir-ws-signup-bar"></span>
                        <label className="ir-ws-signup-label">
                          {item.title}
                        </label>
                        {item.title === "Set Price" && errorMessage && (
                          <p>{errorMessage}</p>
                        )}
                      </div>
                    ))
                  : extractedDataInput.map((item, index) => (
                      <div className="ir-ws-signup-content-group" key={index}>
                        <input
                          className="ir-ws-signup-input-field"
                          type={item.type}
                          name={item.title}
                          required
                          value={item.state}
                          placeholder={item.title}
                          onKeyDown={(e) => {
                            if (e.key === "Backspace") {
                              setErrorMessage("");
                              return;
                            }
                            if (
                              item.title === "Set Price" &&
                              (isNaN(Number(e.key)) || e.key === " ")
                            ) {
                              e.preventDefault();
                              setErrorMessage("Enter a number");
                            } else {
                              setErrorMessage("");
                            }
                          }}
                          onChange={(e) => {
                            const inputValue = e.target.value;

                            if (item.title === "Set Price") {
                              if (!inputValue.startsWith("$")) {
                                item.setState("$" + inputValue);
                              } else {
                                item.setState(inputValue);
                              }
                            } else {
                              item.setState(inputValue);
                            }
                          }}
                        />
                        <span className="ir-ws-signup-highlight"></span>
                        <span className="ir-ws-signup-bar"></span>
                        <label className="ir-ws-signup-label">
                          {item.title}
                        </label>
                        {item.title === "Set Price" && errorMessage && (
                          <p>{errorMessage}</p>
                        )}
                      </div>
                    ))}

                <div className="ir-ws-file-field">
                  <div className="ir-ws-file-path-wrapper">
                    {fileName ? (
                      <div className="file-field-wrapper">
                        <span className="file-field-name">{fileName}</span>
                        <span
                          className="remove-file-item"
                          onClick={handleClearFile}
                        >
                          X
                        </span>
                      </div>
                    ) : null}
                  </div>

                  <div className="ir-ws-create-case-file-btn-container">
                    {isEditorOpen ? null : (
                      <div className="ir-ws-create-btn-container ir-ws-create-webinar-button-container">
                        <div
                          className=" ir-ws-banner-btn ir-ws-create-course-add-file-btn btn blue-gradient btn-sm "
                          onClick={handleContainerClick}
                        >
                          <i class="bi bi-file-earmark-image"></i>
                          <span>Add File</span>
                        </div>
                      </div>
                    )}

                    <input
                      ref={inputRef}
                      type="file"
                      className="doesnt-exists"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>

                {isEditorOpen ? null : (
                  <div className="ir-ws-text-center ir-ws-default-btn-container">
                    <button
                      className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                      type="submit"
                      disabled={isLoading}
                      onClick={async (e) => {
                        e.preventDefault();
                        if (type === "Create Webinar") {
                          await handleSubmit();
                        } else if (type === "Update Webinar") {
                          try {
                            await handleUpdateWebinar(id);
                            handleCloseUpdateModal();
                          } catch (error) {
                            console.error("Update Webinar failed:", error);
                          }
                        }
                      }}
                    >
                      <span>{type}</span>
                    </button>
                  </div>
                )}
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateWebinarModal;
