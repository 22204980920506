export const ConfirmationTypes = {
    "DELETEROOM": "deleteroom",
    "ARCHIVE": "archive",
    "UNARCHIVE": "unarchive",
    "FREEZE": "freeze",
    "UNFREEZE": "unfreeze",
    "DELETECONTENT": "deletecontent",
    "INACTIVEUSER": "inactiveuser",
    "ACTIVEUSER": "activeuser",
    "DELETEUSER": "deleteuser"
}
