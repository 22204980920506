import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import { v4 as uuidv4 } from "uuid";
import "react-quill/dist/quill.snow.css";
import { useSelector, useDispatch } from "react-redux";
import {
  addFormData,
  resetFormData,
  addVideoPreview,
} from "../../Store/CourseSlice";
import axios from "axios";
import { faLaptopHouse } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../ConfirmationModal";
import { useModalContext } from "../../../../components/WebinarAdminCreateWebinarTab/ModalContext";
import ThumbnailCropModal from "./ThumbnailCropModal";

import useDispatchInputChange from "../../../../hooks/useDispatchInputChange ";
import Swal from "sweetalert2";

const CreateCourseFirstPage = ({ onSaveAndContinue }) => {
  const [value, setValue] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState("");
  const [coursePrice, setCoursePrice] = useState("");
  const [isUploaded, setIsuploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [points, setPoints] = useState([]);
  const {
    handleCreateCourse,
    courseUpdateId,
    draftCourseUpdateId,
    setDraftCourseUpdateId,
  } = useModalContext();
  console.log(
    "this is the id of the course which we want to update",
    courseUpdateId
  );

  const [checkboxStates, setCheckboxStates] = useState({
    Duration: false,
    "Downloadable Resources": false,
    Exercises: false,
    "Access on mobile": false,
  });
  const [learnPointArray, setLearnPointArray] = useState([]);

  const [courseIncludesArray, setCourseIncludesArray] = useState([]);
  const [requirementsArray, setRequirementsArray] = useState([]);
  // const [courseIncludesArray, setCourseIncludesArray] = useState([
  //   { id: 1, text: "" },
  // ]);
  // const [requirementsArray, setRequirementsArray] = useState([
  //   { id: 1, text: "" },
  // ]);

  // const [learnPointArray, setLearnPointArray] = useState([{ id: 1, text: "" }]);

  const [completeDescription, setCompleteDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState(false);
  const [videoPreview, setVideoPreview] = useState(null);
  const [isFormvalid, setFormvalid] = useState(false);
  const [totalTitleWord, setTitleWord] = useState(60);
  const [totalSubTitle, setTotalSubtitle] = useState(120);

  const dispatch = useDispatch();
  const formData = useSelector((state) => state.course.formData);
  const sectionsData = useSelector((state) => state.course.sections);

  const courseDraftData = useSelector((state) => state.course);
  const saveInputChangeToDraft = useDispatchInputChange();

  const [videoSelected, setVideoSelected] = useState(false);
  const [videoUpload, setVideoUpload] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [selectedVideoName, setSelectedVideoName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const [hlsLink, setHlsLink] = useState(null);
  const videoFileInputRef = useRef(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isOpenLanguage, setIsOpenLanguage] = useState(false);
  const [cropImageModal, setCropImageModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [cropThumbnail, setCropThumbnail] = useState(null);

  const [isOpenLevel, setIsOpenLevel] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState("");

  const [isOpenCategory, setIsOpenCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [taught, setTaught] = useState("");
  const [instructorName, setInstructorName] = useState("");
  const [instructorProfile, setInstructorProfile] = useState("");
  const [imgPublicUrl, setImgPublicUrl] = useState("");
  const [videoPublicUrl, setVideoPublicUrl] = useState("");

  const modules = {
    toolbar: [
      ["bold", "italic"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };
  const descStyle = {
    backgroundColor: "white",
  };
  const toggleConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal);
  };
  console.log(formData);

  // useEffect(()=>{
  //   saveInputChangeToDraft('description', value);
  //   saveInputChangeToDraft('basicInfo.language', selectedLanguage);
  //   saveInputChangeToDraft('basicInfo.level', selectedLevel);
  //   saveInputChangeToDraft('basicInfo.category', selectedCategory);
  // },[value, selectedLanguage,  selectedLevel,selectedCategory])

  // Draft handler
  // const handleDraftInputData = (field, value) => {
  //   dispatch(addDraftData({ formData: { [field]: value }, sections: [] }));
  // };

  useEffect(() => {
    const setInputValue = (id, value) => {
      const input = document.getElementById(id);
      if (input) input.value = value;
    };

    // Initialize input fields with stored data when component mounts
    setInputValue("title", formData.title);
    setInputValue("subtitle", formData.subtitle);
    setInputValue("taught", formData.taught);

    // setInputValue("description", formData.description);
    // setValue(formData.description || "");
    // setInputValue("description", formData.description);
    if (formData.description) {
      setValue(formData.description);
      // setInputValue("description", formData.description);
    } else {
      setValue("");
    }

    // Initialize dropdowns
    setInputValue("dropdown1", formData.basicInfo.language);
    setInputValue("dropdown2", formData.basicInfo.level);
    setInputValue("dropdown3", formData.basicInfo.category);
    setInputValue("learnPoint", learnPointArray.join("\n"));
    // setInputValue("courseIncludes", courseIncludesArray.join("\n"));
    setInputValue("requirements", requirementsArray.join("\n"));
    // Initialize learn points, course includes, and requirements
    // formData.learnPointArray.forEach((point, index) => {
    //   setInputValue(`learnPoint${index}`, point.text);
    // });

    // formData.courseIncludesArray.forEach((point, index) => {
    //   setInputValue(`courseIncludes${index}`, point.text);
    // });

    // formData.requirementsArray.forEach((point, index) => {
    //   setInputValue(`requirements${index}`, point.text);
    // });

    // Initialize other input fields
    setInputValue("instructor-name", formData.instructorName);
    setInputValue("instructor-Profile", formData.instructorProfile);

    // // Update state for learnPointArray, courseIncludesArray, and requirementsArray
    // if (formData.learnPointArray.length === 0) {
    //   setLearnPointArray([{ id: 1, text: "" }]);
    // } else {
    //   setLearnPointArray(formData.learnPointArray);
    // }
    // if (formData.courseIncludesArray.length === 0) {
    //   setCourseIncludesArray([{ id: 1, text: "" }]);
    // } else {
    //   setCourseIncludesArray(formData.courseIncludesArray);
    // }
    // if (formData.requirementsArray.length === 0) {
    //   setRequirementsArray([{ id: 1, text: "" }]);
    // } else {
    //   setRequirementsArray(formData.requirementsArray);
    // }
    // setCourseIncludesArray(
    //   formData.courseIncludesArray || [{ id: 1, text: "" }]
    // );
    // setRequirementsArray(formData.requirementsArray || [{ id: 1, text: "" }]);
    setSelectedLanguage(formData.basicInfo.language || "");
    setSelectedLevel(formData.basicInfo.level || "");
    setSelectedCategory(formData.basicInfo.category || "");

    setLearnPointArray(formData.learnPointArray || []);
    // setCourseIncludesArray(formData.courseIncludesArray || []);
    // const updatedCheckboxStates = { ...checkboxStates }; // Copy existing state
    // Object.keys(updatedCheckboxStates).forEach((key) => {
    //   updatedCheckboxStates[key] = courseIncludesArray.includes(key);
    // });
    // setCheckboxStates(updatedCheckboxStates);
    setCourseIncludesArray(formData.courseIncludesArray || []);
    if (formData.courseIncludesArray.length === 0) {
      // If courseIncludesArray is empty, initialize checkboxStates with default values
      setCheckboxStates({
        Duration: false,
        "Downloadable Resources": false,
        Exercises: false,
        "Access on mobile": false,
      });
    } else {
      // Otherwise, initialize checkboxStates based on courseIncludesArray

      const initialCheckboxStates = { ...checkboxStates };
      Object.keys(initialCheckboxStates).forEach((key) => {
        initialCheckboxStates[key] = formData.courseIncludesArray.includes(key);
      });
      setCheckboxStates(initialCheckboxStates);
    }
    setRequirementsArray(formData.requirementsArray || []);
    setCompleteDescription(formData.completeDescription || "");

    setImagePreview(formData.imagePreview || null);
    setVideoPreview(formData.videoPreview || null);
    if (formData.selectedImageName) {
      setSelectedImage(true);
      setSelectedImageName(formData.selectedImageName || "");
    }
    if (formData.selectedVideoName) {
      setVideoSelected(true);
      setSelectedVideoName(formData.selectedVideoName || "");
    }
    // setCoursePrice(formData.coursePrice || "");
    setCoursePrice("$" + (formData.coursePrice || ""));
  }, [formData]);
  // console.log(learnPointArray.length);
  console.log("Lgging value of description", value);
  const onSaveAndContinueHandler = () => {
    // Reset draft form data
    // dispatch(resetFormData());

    const title = document.getElementById("title").value.trim();
    const subtitle = document.getElementById("subtitle").value.trim();
    const description = value.trim();
    // const language = document.getElementById("dropdown1").value;
    // const level = document.getElementById("dropdown2").value;
    // const category = document.getElementById("dropdown3").value;
    const taught = document.getElementById("taught").value.trim();
    const instructorName = document
      .getElementById("instructor-name")
      .value.trim();
    const instructorProfile = document
      .getElementById("instructor-Profile")
      .value.trim();

    const learnPoints = Array.from(
      document.getElementsByName("learnPoint")
    ).map((input) => input.value.trim());
    // const courseIncludes = Array.from(
    //   document.getElementsByName("courseIncludes")
    // ).map((input) => input.value.trim());
    const requirements = Array.from(
      document.getElementsByName("requirements")
    ).map((input) => input.value.trim());
    const completeDescription = document
      .getElementById("CompleteDescription")
      .value.trim();
    const coursePrice = document.getElementById("coursePrice").value.trim();

    const requiredFields = [
      title,
      // subtitle,
      description,
      selectedLanguage,
      selectedLevel,
      selectedCategory,
      // taught,
      instructorName,
      instructorProfile,
      // ...learnPoints,
      // ...courseIncludes,
      ...requirements,
      completeDescription,
      coursePrice,
      selectedImageName,
      // selectedVideoName,
    ];
    const isEmpty = requiredFields.some((field) => field === "");
    setFormvalid(isEmpty);
    if (isEmpty) {
      document.getElementById("title").style.border =
        title === "" ? "1px solid red" : "none";
      // document.getElementById("subtitle").style.border =
      //   subtitle === "" ? "1px solid red" : "none";
      document.getElementById("description").style.border =
        description === "" ? "1px solid red" : "none";
      // document.getElementById("taught").style.border =
      //   taught === "" ? "1px solid red" : "none";
      document.getElementById("instructor-name").style.border =
        instructorName === "" ? "1px solid red" : "none";
      document.getElementById("instructor-Profile").style.border =
        instructorProfile === "" ? "1px solid red" : "none";

      document.getElementById("promotionalImage").style.border =
        selectedImageName === "" ? "1px solid red" : "none";

      // document.getElementById("promotionalVideo").style.border =
      //   selectedVideoName === "" ? "1px solid red" : "none";
      document.getElementById("coursePrice").style.border =
        coursePrice === "$" ? "1px solid red" : "none";
      if (selectedLanguage === "") {
        document.getElementById("dropdown1").style.border = "1px solid red";
      }
      if (selectedLevel === "") {
        document.getElementById("dropdown2").style.border = "1px solid red";
      }
      if (selectedCategory === "") {
        document.getElementById("dropdown3").style.border = "1px solid red";
      }

      if (requirementsArray.length === 0) {
        document.getElementById("requirements").style.border = "1px solid red";
      }

      document.getElementById("CompleteDescription").style.border =
        completeDescription === "" ? "1px solid red" : "none";
    } else {
      setFormvalid(false);
      const formData = {
        title,
        subtitle,
        description,
        basicInfo: {
          selectedLanguage,
          selectedLevel,
          selectedCategory,
        },
        taught,
        instructorName,
        instructorProfile,
        imagePreview,
        videoPreview,
        completeDescription,
        coursePrice,
      };

      dispatch(
        addFormData({
          title: title,
          subtitle: subtitle,
          description: description,
          basicInfo: {
            language: selectedLanguage,
            level: selectedLevel,
            category: selectedCategory,
          },
          taught: taught,
          learnPointArray: learnPointArray.filter(
            (point) => point.trim() !== ""
          ),

          courseIncludesArray: courseIncludesArray,
          requirementsArray: requirementsArray.filter(
            (point) => point.trim() !== ""
          ),
          completeDescription: completeDescription,
          instructorName: instructorName,
          instructorProfile: instructorProfile,
          selectedImageName: selectedImageName,
          selectedVideoName: selectedVideoName,
          imagePreview: imagePreview,
          // videoPreview: videoPreview,
          videoprevUrl: videoPreview,
          coursePrice: coursePrice.replace(/[^\d]/g, ""),
        })
      );
      // console.log("videoUrl", videoprevUrl);
      console.log("videopreview from first page", videoPreview);
      onSaveAndContinue();
      console.log("fromdata", formData);
    }
  };
  useEffect(() => {
    dispatch(addVideoPreview({ videoPreview }));
  }, [videoPreview]);

  // useEffect(() => {
  //   saveInputChangeToDraft("description", value);
  // }, [value]);
  console.log("description value", value);
  useEffect(() => {
    // saveInputChangeToDraft('description', value);
    saveInputChangeToDraft("courseIncludesArray", courseIncludesArray);

    // saveInputChangeToDraft('courseIncludesArray', checkboxStates);
  }, [courseIncludesArray]);

  console.log("this is the videopreview", videoPreview);
  // const handleCloseImageCrop = () => {
  //   setSelectedImage([]);
  //   setCropImage("");
  //   setImageThumbnail("");
  //   setCropImageModal(false);
  //   const fileInput = document.querySelector('input[type="file"]');
  //   if (fileInput) {
  //     fileInput.value = "";
  //   }
  // };
  const ImagehandleFileUpload = () => {
    if (!selectedImage) {
      document.getElementById("file-upload").click();
    }
  };
  // const imageHandleInputChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   setSelectedImageName(selectedFile.name);
  //   if (selectedFile) {
  //     const imageUrl = URL.createObjectURL(selectedFile);
  //     setCropThumbnail(imageUrl);
  //     setCropImageModal(true);
  //   }
  //   setImagePreview(selectedFile);
  // };

  // const uploadImage = async (file) => {
  //   try {
  //     let location =formData.title.replace(/\s/g,"")
  //     const url = "https://courses.ir4u.info/api/course/image";

  //     const formData = new FormData();
  //     formData.append('fileName', file.name);
  //     formData.append('contentType', file.type);
  //     formData.append('location',location );
  //     const response = await axios.post(url, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });

  //     const signedUrl = response.data.signedUrl;

  //     // await axios.put(signedUrl, file, {
  //     //   headers: {
  //     //     'Content-Type': file.type,
  //     //   },
  //     // });

  //     console.log("response from upload img API",response,signedUrl);

  //     // saveInputChangeToDraft('image', signedUrl);
  //   } catch (error) {
  //     console.error('Error uploading image:', error);
  //   }
  // };

  // Function to upload the image
  const uploadImage = async (file) => {
    try {
      const location = formData.title.replace(/[^a-zA-Z\s]/g, "").replace(/\s+/g, '')
      const url = "https://courses.ir4u.info/api/course/image";

      const requestBody = JSON.stringify({
        fileName: file.name,
        contentType: file.type,
        location: location,
      });

      console.log("Uploading image with data:", {
        fileName: file.name,
        contentType: file.type,
        location: location,
      });

      const response = await fetch(url, {
        method: "POST",
        body: requestBody,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        const signedUrl = data.signedUrl;

        await uploadToPresignedUrl(file, signedUrl, location, file.name);

        // const publicImageUrl = signedUrl.split("?")[0]; // Remove query params if any
        // console.log(publicImageUrl);
      } else {
        console.error("Failed to obtain pre-signed URL:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const uploadToPresignedUrl = async (
    file,
    signedUrl,
    fileLocation,
    finalFileName
  ) => {
    try {
      const response = await fetch(signedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });
      if (response.ok) {
        const imageUrl = `https://course-video-encoding-input.s3.amazonaws.com/${fileLocation}/${finalFileName}`;
        console.log("Image URL:", imageUrl);
        setImgPublicUrl(imageUrl);
        saveInputChangeToDraft("imagePreview", imageUrl); // Dispatch the action to update the image URL in the Redux store
      } else {
        console.error("Error uploading image:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading file to pre-signed URL:", error);
    }
  };

  const imageHandleInputChange = async (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      console.error("No file selected");
      return;
    }

    setSelectedImage(true);
    if (selectedFile) {
      setSelectedImageName(selectedFile.name);
      const imageUrl = URL.createObjectURL(selectedFile);
      setCropThumbnail(imageUrl);
      setCropImageModal(true);
      // setSelectedFile(selectedFile); // Set selected file
    }
    setImagePreview(selectedFile);

    saveInputChangeToDraft("selectedImageName", selectedFile.name);

    uploadImage(selectedFile);
  };

  // const handleSaveCroppedImage = (croppedImageSrc, selectedFile) => {
  //   // Update imagePreview with the cropped image src
  //   setImagePreview((prevImage) => ({
  //     ...prevImage,
  //     src: croppedImageSrc,
  //   }));
  //   setSelectedImageName(selectedFile?.name);
  // };
  const handleSaveCroppedImage = (croppedImageBlob) => {
    setImagePreview(
      new File([croppedImageBlob], selectedImageName, {
        type: imagePreview.type,
      })
    );
    setSelectedImageName(imagePreview?.name);
  };
  console.log(imagePreview);
  // useEffect(() => {
  //   if (imagePreview !== null) {
  //     setSelectedImageName(imagePreview?.name);
  //   }
  // }, [imagePreview]);
  console.log(selectedImageName);
  // const imageHandleInputChange = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   setSelectedImageName(file?.name);
  //   setSelectedImage(true);
  //   reader.onload = () => {
  //     const location = "imageprev";
  //     // uploadImage(file, location);
  //     setImagePreview(file);
  //   };

  //   reader.readAsDataURL(file);
  // };

  // const deleteSelectedImage = () => {
  //   setSelectedImageName("");
  //   setImagePreview(null);
  //   setSelectedImage(false);
  //   const fileInput = document.getElementById("file-upload");
  //   if (fileInput) {
  //     fileInput.value = "";
  //   }
  // };
  const deleteSelectedImage = () => {
    setSelectedImageName("");
    setCropThumbnail(null);
    setCropImageModal(false);
    setImagePreview(null);
    setSelectedImage(false);
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };
  const handleVideoFileUpload = () => {
    if (!videoSelected) {
      videoFileInputRef.current.click();
    }
  };
  const handleInputFocus = (event) => {
    event.target.style.border = "1px solid #ccc";
  };

  // Function to upload the video
  const uploadVideo = async (file) => {
    const lastDotIndex = file.name.lastIndexOf(".");
    const fileExtension = file.name.slice(lastDotIndex);
    const sanitizedFileName = file.name
      .slice(0, lastDotIndex)
      .replace(/[^\w\s]/gi, "")
      .toLowerCase();
    const finalFileName = sanitizedFileName + fileExtension;
    const location = formData.title.replace(/\s/g, "");
    const url = "https://courses.ir4u.info/api/course/upload";

    try {
      const requestBody = JSON.stringify({
        filename: finalFileName,
        contentType: file.type,
        location: location,
      });

      console.log("Uploading video with data:", requestBody);

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          fileName: finalFileName,
          contentType: file.type,
          location: location,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        const signedUrl = data.signedUrl;

        if (!signedUrl) {
          throw new Error("No signed URL received");
        }

        console.log("Signed video URL:", signedUrl);
        await uploadVideoToPresignedUrl(
          file,
          signedUrl,
          location,
          finalFileName
        );
      } else {
        console.error("Failed to obtain pre-signed URL:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading video:", error);
      throw error;
    }
  };

  const uploadVideoToPresignedUrl = async (
    file,
    signedUrl,
    fileLocation,
    finalFileName
  ) => {
    try {
      const response = await fetch(signedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });

      if (response.ok) {
        const nameWithoutExtension = finalFileName.slice(
          0,
          finalFileName.lastIndexOf(".")
        );
        const videoUrl = `https://dou8xtfkj2dbq.cloudfront.net/${fileLocation}/${nameWithoutExtension}.m3u8`;
        console.log("Video URL:", videoUrl);
        setVideoPublicUrl(videoUrl);
        // saveInputChangeToDraft('videoPreview', videoUrl);  // Dispatch the action to update the video URL in the Redux store
      } else {
        console.error("Error uploading video:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading file to pre-signed URL:", error);
    }
  };

  const handleVideoInputChange = async (event) => {
    const file = event.target.files[0];

    console.log("video file uploded in creeate course first page:", file);

    setSelectedFile(file);
    setVideoSelected(true);
    setSelectedVideoName(file?.name);

    saveInputChangeToDraft("selectedVideoName", file?.name);

    if (file && file.type.startsWith("video/")) {
      const reader = new FileReader();
      reader.onload = async () => {
        // setVideoPreview(reader.result);
        const videoLocation = "prevideo";
        // await uploadFile(file, videoLocation);
        setVideoPreview(file);
        console.log("videoPreview", file);
      };
      reader.readAsDataURL(file);

      // saveInputChangeToDraft('videoprevUrl', file);

      // try {
      //   await uploadVideo(file);
      // } catch (error) {
      //   console.error("Error uploading video:", error);
      // }
    } else {
      alert("Please select a valid video file (MP4, etc.).");
    }
  };

  /////------Learn Add Point Function------//////
  // const handleLearnPointInputChange = (id, newText) => {
  //   const updatedPoints = learnPointArray.map((point) =>
  //     point.id === id ? { ...point, text: newText } : point
  //   );
  //   setLearnPointArray(updatedPoints);
  // };

  // const handleLearnAddPoint = () => {
  //   if (learnPointArray.some((point) => point.text === "")) {
  //     return;
  //   }
  //   const newId = learnPointArray.length + 1;
  //   setLearnPointArray([...learnPointArray, { id: newId, text: "" }]);
  // };

  // const handleLearnDeletePoint = (idToDelete) => {
  //   if (learnPointArray.length === 1) {
  //     return;
  //   }

  //   const updatedPoints = learnPointArray.filter(
  //     (point) => point.id !== idToDelete
  //   );
  //   setLearnPointArray(
  //     updatedPoints.map((point, index) => ({ ...point, id: index + 1 }))
  //   );
  // };

  /////------This Course Includes Point Function------//////
  // const handleCourseInCludesInputChange = (id, newText) => {
  //   const updatedPoints = courseIncludesArray.map((point) =>
  //     point.id === id ? { ...point, text: newText } : point
  //   );
  //   setCourseIncludesArray(updatedPoints);
  // };

  // const handleCourseIncludesAddPoint = () => {
  //   if (courseIncludesArray.some((point) => point.text === "")) {
  //     return;
  //   }
  //   const newId = courseIncludesArray.length + 1;
  //   setCourseIncludesArray([...courseIncludesArray, { id: newId, text: "" }]);
  // };

  // const handleCourseIncludesDeletePoint = (idToDelete) => {
  //   if (courseIncludesArray.length === 1) {
  //     return;
  //   }

  //   const updatedPoints = courseIncludesArray.filter(
  //     (point) => point.id !== idToDelete
  //   );
  //   setCourseIncludesArray(
  //     updatedPoints.map((point, index) => ({ ...point, id: index + 1 }))
  //   );
  // };

  /////------Requirements Function------//////
  // const handleRequirementsInputChange = (id, newText) => {
  //   const updatedPoints = requirementsArray.map((point) =>
  //     point.id === id ? { ...point, text: newText } : point
  //   );
  //   setRequirementsArray(updatedPoints);
  // };

  // const handleRequirementsAddPoint = () => {
  //   if (requirementsArray.some((point) => point.text === "")) {
  //     return;
  //   }
  //   const newId = requirementsArray.length + 1;
  //   setRequirementsArray([...requirementsArray, { id: newId, text: "" }]);
  // };

  // const handleRequirementsDeletePoint = (idToDelete) => {
  //   if (requirementsArray.length === 1) {
  //     return;
  //   }

  //   const updatedPoints = requirementsArray.filter(
  //     (point) => point.id !== idToDelete
  //   );
  //   setRequirementsArray(
  //     updatedPoints.map((point, index) => ({ ...point, id: index + 1 }))
  //   );
  // };
  const handleLearnPointChange = (e) => {
    const inputValue = e.target.value;
    const pointsArray = inputValue.split("\n");
    setLearnPointArray(pointsArray);

    saveInputChangeToDraft("learnPointArray", pointsArray);
  };
  // const handleCourseIncludesPointChange = (e) => {
  //   const inputValue = e.target.value;
  //   const pointsArray = inputValue.split("\n");
  //   setCourseIncludesArray(pointsArray);
  // };
  const handleRequirementsPointChange = (e) => {
    const inputValue = e.target.value;
    const pointsArray = inputValue.split("\n");
    setRequirementsArray(pointsArray);

    saveInputChangeToDraft("requirementsArray", pointsArray);
  };
  // console.log(learnPointArray, courseIncludesArray, requirementsArray);

  /////-----other function-------////

  const delteSelectedVideo = () => {
    setSelectedFile(null);
    setVideoSelected(false);
    setSelectedVideoName("");
    setVideoUpload(false);
    // setUploadSuccessful(false);
    const fileInput = document.getElementById("video-upload");
    if (fileInput) {
      fileInput.value = "";
    }
  };
  const titleHandleChange = (e) => {
    const inputValue = e.target.value;
    const enteredWordCount = inputValue.length;
    const remainingWordCount = 60 - enteredWordCount;
    setTitleWord(remainingWordCount);

    saveInputChangeToDraft("title", inputValue);
  };
  const subTitleHandleChange = (e) => {
    const inputValue = e.target.value;
    const enteredWordCount = inputValue.length;
    const remainingWordCount = 120 - enteredWordCount;
    setTotalSubtitle(remainingWordCount);

    saveInputChangeToDraft("subtitle", inputValue);
  };

  // const handleInputChange = (e) => {
  //   // Prevent removing the dollar sign
  //   if (e.target.value.startsWith("$")) {
  //     setCoursePrice("$" + e.target.value.slice(1));
  //   } else {
  //     setCoursePrice("$" + e.target.value);
  //   }
  // };

  // const handleDescriptionChange = (content, _, __, editor) => {
  //   setValue(content);

  //   // or if we need html editor.getHTML()
  //   const newValue = editor.getHTML();

  //   console.log("handleDescriptionChange description value", newValue);

  //   saveInputChangeToDraft('description', newValue);
  // };

  const handleDescriptionChange = (content, delta, source, editor) => {
    setValue(content);
    const newValue = editor.getContents();
    console.log("handleDescriptionChange description value", newValue);
    saveInputChangeToDraft("description", content);
  };

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    // Remove any non-digit characters
    const cleanedInput = userInput.replace(/\D/g, "");
    // Update the state with the cleaned input
    setCoursePrice("$ " + cleanedInput);

    saveInputChangeToDraft("coursePrice", cleanedInput);
  };

  const toggleDropdownLanguage = () => {
    setIsOpenLanguage(!isOpenLanguage);
  };

  const toggleDropdownLevel = () => {
    setIsOpenLevel(!isOpenLevel);
  };

  const toggleDropdownCategory = () => {
    setIsOpenCategory(!isOpenCategory);
  };

  const handleLanguageSelection = (language) => {
    setSelectedLanguage(language);
    setIsOpenLanguage(false);

    saveInputChangeToDraft("basicInfo.language", language);
  };

  const handleLevelSelection = (level) => {
    setSelectedLevel(level);
    setIsOpenLevel(false);

    saveInputChangeToDraft("basicInfo.level", level);
  };

  const handleCategorySelection = (category) => {
    setSelectedCategory(category);
    setIsOpenCategory(false);

    saveInputChangeToDraft("basicInfo.category", category);
  };

  const handleTaught = (e) => {
    const inputValue = e.target.value;
    setTaught(e.target.value);

    saveInputChangeToDraft("taught", inputValue);
  };

  const handleInstructorName = (e) => {
    const inputValue = e.target.value;
    setInstructorName(e.target.value);

    saveInputChangeToDraft("instructorName", inputValue);
  };

  const handleInstructorProfile = (e) => {
    const inputValue = e.target.value;
    setInstructorProfile(e.target.value);

    saveInputChangeToDraft("instructorProfile", inputValue);
  };

  const handleCheckboxChange = (placeholder) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [placeholder]: !prevState[placeholder],
    }));

    if (!checkboxStates[placeholder]) {
      setCourseIncludesArray([...courseIncludesArray, placeholder]);
    } else {
      const newArray = courseIncludesArray.filter(
        (item) => item !== placeholder
      );
      setCourseIncludesArray(newArray);

      // saveInputChangeToDraft('courseIncludesArray', newArray);
    }
  };
  console.log(courseIncludesArray);

  // const saveAsDraftHandler = async () => {
  //   const url = "https://courses.ir4u.info/api/course/draft";
  //   console.log("api draft data:", formData);
  //   try {
  //     const response = await fetch(url, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ formData }),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const data = await response.json();
  //     console.log("Draft saved successfully:", data);

  //     Swal.fire("Success", "Draft saved successfully", "success");
  //     // Reset draft form data
  //     dispatch(resetFormData());
  //   } catch (error) {
  //     console.error("Failed to save draft:", error);
  //   }
  // };

  const saveAsDraftHandler = async () => {
    if (draftCourseUpdateId) {
      console.log("Updating draft:", draftCourseUpdateId);

      const requestBody = {
        draftId: draftCourseUpdateId,
        formData,
      };
      console.log("requestBody:", requestBody);

      try {
        const updateUrl = `https://courses.ir4u.info/api/course/draft`;
        const response = await fetch(updateUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("Draft updated successfully:", data);
        setDraftCourseUpdateId(null);
        Swal.fire({
          icon: "success",
          title: "Draft Updated",
          text: "Your draft has been successfully updated!",
        });
        // Reset draft form data
        dispatch(resetFormData());
      } catch (error) {
        console.error("Error updating draft:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to update the draft. Please try again later.",
        });
      }
    } else {
      console.log("Creating new draft");
      try {
        const response = await fetch(
          "https://courses.ir4u.info/api/course/draft",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ formData }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("Draft saved successfully:", data);
        Swal.fire({
          icon: "success",
          title: "Draft Saved",
          text: "Your draft has been successfully saved!",
        });
        // Reset draft form data
        dispatch(resetFormData());
      } catch (error) {
        console.error("Error saving draft:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to save the draft. Please try again later.",
        });
      }
    }
  };

  return (
    <div>
      <div className="ir-ws-create-course-container-inputes">
        <div className="ir-ws-course-input-title-container">
          <label className="ir-ws-course-title-label" htmlFor="title">
            Course Title<span className="ir-ws-course-mandatory-star">*</span>
          </label>
          <div className="ir-ws-course-inpute-latters-container">
            <input
              className="ir-ws-course-title-inpute"
              type="text"
              id="title"
              name="title"
              style={{ border: totalTitleWord <= 0 ? "1px solid red" : "" }}
              placeholder="Type course title"
              onChange={titleHandleChange}
              onFocus={handleInputFocus}
            />
            <span>{totalTitleWord}</span>
          </div>
        </div>
        <div className="ir-ws-course-input-title-container">
          <label className="ir-ws-course-title-label" htmlFor="subtitle">
            Course Subtitle
          </label>
          <div className="ir-ws-course-inpute-latters-container">
            <input
              className="ir-ws-course-title-inpute"
              type="text"
              id="subtitle"
              name="subtitle"
              placeholder="Type course subtitle"
              style={{ border: totalSubTitle <= 0 ? "1px solid red" : "" }}
              onChange={subTitleHandleChange}
              onFocus={handleInputFocus}
            />
            <span style={{ right: "10px" }}>{totalSubTitle}</span>
          </div>
        </div>
        <div>
          <label className="ir-ws-course-title-label" htmlFor="description">
            Course Description
            <span className="ir-ws-course-mandatory-star">*</span>
          </label>
          <ReactQuill
            className="ir-ws-course-description-input"
            id="description"
            theme="snow"
            modules={modules}
            style={descStyle}
            value={value}
            onChange={handleDescriptionChange}
          />
        </div>
        {/* <div className="ir-ws-course-dropdown-container">
          <label className="ir-ws-course-title-label" htmlFor="info">
            Basic Info<span className="ir-ws-course-mandatory-star">*</span>
          </label>

          <div className="ir-ws-course-dropdown-menus">
            <div className="ir-ws-select-container">
              <select
                id="dropdown1"
                className="ir-ws-course-dropdown-selection"
              >
                <option value="">Choose Language</option>
                <option value="English">English</option>
              </select>
              <div className="ir-ws-course-dropdown-icon-container">
                <i class="bi bi-caret-down-fill"></i>
              </div>
            </div>
            <div className="ir-ws-select-container">
              <select
                id="dropdown2"
                className="ir-ws-course-dropdown-selection"
              >
                <option value="">Choose Level</option>
                <option value="Beginner">Beginner</option>
                <option value="Intermediate">Intermediate </option>
                <option value="Expert">Expert</option>
              </select>
              <div className="ir-ws-course-dropdown-icon-container">
                <i class="bi bi-caret-down-fill"></i>
              </div>
            </div>
            <div className="ir-ws-select-container">
              <select
                id="dropdown3"
                className="ir-ws-course-dropdown-selection"
              >
                <option value="">Choose Catagory</option>
                <option value="Stomach">Stomach</option>
                <option value="kidney">kidney</option>
                <option value="Heart">Heart</option>
              </select>
              <div className="ir-ws-course-dropdown-icon-container">
                <i class="bi bi-caret-down-fill"></i>
              </div>
            </div>

          </div>
        </div> */}
        <div className="ir-ws-course-dropdown-container">
          <label className="ir-ws-course-title-label" htmlFor="info">
            Basic Info<span className="ir-ws-course-mandatory-star">*</span>
          </label>
          <div className="ir-ws-select-container">
            <div>
              <div
                className="ir-ws-flex ir-ws-create-course-dropdown-box"
                onClick={toggleDropdownLanguage}
                id="dropdown1"
              >
                <p>{selectedLanguage || "Choose Language"}</p>
                <i class="bi bi-chevron-down"></i>
              </div>
              <div
                className={`ir-ws-create-course-category-list ${
                  isOpenLanguage ? "open" : ""
                }`}
              >
                <div
                  className="ir-ws-flex ir-ws-create-course-dropdown-box"
                  onClick={() => handleLanguageSelection("English")}
                >
                  <p>English</p>
                </div>
              </div>
            </div>
            <div>
              <div
                className="ir-ws-flex ir-ws-create-course-dropdown-box"
                onClick={toggleDropdownLevel}
                id="dropdown2"
              >
                <p>{selectedLevel || "Choose Level"}</p>
                <i class="bi bi-chevron-down"></i>
              </div>
              <div
                className={`ir-ws-create-course-category-list ${
                  isOpenLevel ? "open" : ""
                }`}
              >
                <div
                  className="ir-ws-flex ir-ws-create-course-dropdown-box"
                  onClick={() => handleLevelSelection("Beginner")}
                >
                  <p>Beginner</p>
                </div>
                <div
                  className="ir-ws-flex ir-ws-create-course-dropdown-box"
                  onClick={() => handleLevelSelection("Intermediate")}
                >
                  <p>Intermediate</p>
                </div>
                <div
                  className="ir-ws-flex ir-ws-create-course-dropdown-box"
                  onClick={() => handleLevelSelection("Expert")}
                >
                  <p>Expert</p>
                </div>
              </div>
            </div>
            <div>
              <div
                className="ir-ws-flex ir-ws-create-course-dropdown-box"
                onClick={toggleDropdownCategory}
                id="dropdown3"
              >
                <p>{selectedCategory || "Choose Category"}</p>
                <i class="bi bi-chevron-down"></i>
              </div>
              <div
                className={`ir-ws-create-course-category-list ${
                  isOpenCategory ? "open" : ""
                }`}
              >
                <div
                  className="ir-ws-flex ir-ws-create-course-dropdown-box"
                  onClick={() => handleCategorySelection("Chest")}
                >
                  <p>Chest</p>
                </div>
                <div
                  className="ir-ws-flex ir-ws-create-course-dropdown-box"
                  onClick={() => handleCategorySelection("⁠Gastrointestinal")}
                >
                  <p>⁠Gastrointestinal</p>
                </div>
                <div
                  className="ir-ws-flex ir-ws-create-course-dropdown-box"
                  onClick={() => handleCategorySelection("Liver")}
                >
                  <p>Liver</p>
                </div>
                <div
                  className="ir-ws-flex ir-ws-create-course-dropdown-box"
                  onClick={() => handleCategorySelection("⁠Genitourinary")}
                >
                  <p>⁠Genitourinary</p>
                </div>
                <div
                  className="ir-ws-flex ir-ws-create-course-dropdown-box"
                  onClick={() => handleCategorySelection("Vascular")}
                >
                  <p>Vascular</p>
                </div>
                <div
                  className="ir-ws-flex ir-ws-create-course-dropdown-box"
                  onClick={() => handleCategorySelection("Lymphatics")}
                >
                  <p>Lymphatics</p>
                </div>
                <div
                  className="ir-ws-flex ir-ws-create-course-dropdown-box"
                  onClick={() => handleCategorySelection("T⁠rauma")}
                >
                  <p>T⁠rauma</p>
                </div>
                <div
                  className="ir-ws-flex ir-ws-create-course-dropdown-box"
                  onClick={() => handleCategorySelection("Venous")}
                >
                  <p>Venous</p>
                </div>
                <div
                  className="ir-ws-flex ir-ws-create-course-dropdown-box"
                  onClick={() => handleCategorySelection("⁠Bleed")}
                >
                  <p>⁠Bleed</p>
                </div>
                <div
                  className="ir-ws-flex ir-ws-create-course-dropdown-box"
                  onClick={() => handleCategorySelection("⁠Women’s health")}
                >
                  <p>⁠Women’s health</p>
                </div>
                <div
                  className="ir-ws-flex ir-ws-create-course-dropdown-box"
                  onClick={() => handleCategorySelection("⁠Men’s health")}
                >
                  <p>⁠Men’s health</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ir-ws-course-input-title-container">
          <label className="ir-ws-course-title-label" htmlFor="taught">
            What is primarily taught in your course?
          </label>
          <input
            className="ir-ws-course-title-inpute"
            type="text"
            id="taught"
            name="taught"
            placeholder="e.g. IVC Filter"
            onFocus={handleInputFocus}
            onChange={handleTaught}
          />
        </div>
        <div className="ir-ws-course-input-title-container">
          <label className="ir-ws-course-title-label" htmlFor="learn">
            What you'll learn?
          </label>
          <textarea
            className="ir-ws-course-title-inpute ir-ws-course-textarea"
            type="text"
            id="learnPoint"
            name="learnPoint"
            placeholder="Write Points"
            value={learnPointArray.join("\n")} // Join points array with newline for display
            onChange={handleLearnPointChange}
            onFocus={handleInputFocus}
          />
        </div>
        <div>
          <label className="ir-ws-course-title-label" htmlFor="requirements">
            This course includes?
          </label>
          <div className="ir-ws-create-course-includes-point-container">
            <div className="ir-ws-create-course-includes-checkbox-container">
              <div className="ir-ws-flex ir-ws-create-course-input-checkbox-container">
                <input
                  className="ir-ws-course-title-inpute"
                  placeholder="Duration"
                  readOnly
                  value={checkboxStates["Duration"] ? "Duration" : ""}
                />
                <div
                  className="ir-ws-create-course-checkbox"
                  onClick={() => handleCheckboxChange("Duration")}
                >
                  <div
                    className={` ${
                      checkboxStates["Duration"]
                        ? "ir-ws-create-course-active-checkbox"
                        : ""
                    }`}
                  ></div>
                </div>
              </div>
              <div className="ir-ws-flex ir-ws-create-course-input-checkbox-container">
                <input
                  className="ir-ws-course-title-inpute"
                  placeholder="Downloadable Resources"
                  readOnly
                  value={
                    checkboxStates["Downloadable Resources"]
                      ? "Downloadable Resources"
                      : ""
                  }
                />
                <div
                  className="ir-ws-create-course-checkbox"
                  onClick={() => handleCheckboxChange("Downloadable Resources")}
                >
                  <div
                    className={` ${
                      checkboxStates["Downloadable Resources"]
                        ? "ir-ws-create-course-active-checkbox "
                        : ""
                    }`}
                  ></div>
                </div>
              </div>
            </div>
            <div className="ir-ws-create-course-includes-checkbox-container">
              <div className="ir-ws-flex ir-ws-create-course-input-checkbox-container">
                <input
                  className="ir-ws-course-title-inpute"
                  placeholder="Exercises"
                  readOnly
                  value={checkboxStates["Exercises"] ? "Exercises" : ""}
                />
                <div
                  className="ir-ws-create-course-checkbox"
                  onClick={() => handleCheckboxChange("Exercises")}
                >
                  <div
                    className={` ${
                      checkboxStates["Exercises"]
                        ? "ir-ws-create-course-active-checkbox"
                        : ""
                    }`}
                  ></div>
                </div>
              </div>
              <div className="ir-ws-flex ir-ws-create-course-input-checkbox-container">
                <input
                  className="ir-ws-course-title-inpute"
                  placeholder="Access on mobile"
                  readOnly
                  value={
                    checkboxStates["Access on mobile"] ? "Access on mobile" : ""
                  }
                />
                <div
                  className="ir-ws-create-course-checkbox"
                  onClick={() => handleCheckboxChange("Access on mobile")}
                >
                  <div
                    className={`${
                      checkboxStates["Access on mobile"]
                        ? "ir-ws-create-course-active-checkbox "
                        : ""
                    }`}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="ir-ws-course-input-title-container">
          <label className="ir-ws-course-title-label" htmlFor="course-includes">
            This course includes?
          </label>
          <textarea
            className="ir-ws-course-title-inpute ir-ws-course-textarea"
            type="text"
            id="courseIncludes"
            name="courseIncludes"
            placeholder="Write Points"
            value={courseIncludesArray.join("\n")} // Join points array with newline for display
            onChange={handleCourseIncludesPointChange}
          />
        </div> */}
        <div className="ir-ws-course-input-title-container">
          <label className="ir-ws-course-title-label" htmlFor="requirements">
            Requirements<span className="ir-ws-course-mandatory-star">*</span>
          </label>
          <textarea
            className="ir-ws-course-title-inpute ir-ws-course-textarea"
            type="text"
            id="requirements"
            name="requirements"
            placeholder="Write Points"
            value={requirementsArray.join("\n")} // Join points array with newline for display
            onChange={handleRequirementsPointChange}
            onFocus={handleInputFocus}
          />
        </div>
        {/* <div className="ir-ws-course-input-title-container">
          <label className="ir-ws-course-title-label" htmlFor="learn">
            What you'll learn?
            <span className="ir-ws-course-mandatory-star">*</span>
          </label>
          {learnPointArray.map((point, index) => (
            <div
              key={point.id}
              className="ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center ir-ws-course-input-point-container"
            >
              <div
                className="ir-ws-flex ir-ws-align-center ir-ws-position-relative "
                style={{ width: "100%" }}
              >
                <input
                  type="text"
                  value={point.text}
                  id="learnPoint"
                  name="learnPoint"
                  onChange={(e) =>
                    handleLearnPointInputChange(point.id, e.target.value)
                  }
                  onFocus={handleInputFocus}
                  className="ir-ws-course-title-inpute ir-ws-course-points"
                  placeholder="Write Points"
                />
                <svg
                  height="24"
                  viewBox="0 0 48 48"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ir-ws-course-delete-points-icon"
                  onClick={() => handleLearnDeletePoint(point.id)}
                >
                  <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
                  <path d="M0 0h48v48H0z" fill="none" />
                </svg>
              </div>
              <div
                className="ir-ws-create-btn-container ir-ws-save-course-btn-container ir-ws-course-add-point-btn-container "
                onClick={
                  index === learnPointArray.length - 1
                    ? handleLearnAddPoint
                    : null
                }
                disabled={index !== learnPointArray.length - 1}
              >
                <button className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-banner-btn ir-ws-course-add-point-btn">
                  <span>+ Add point</span>
                </button>
              </div>
            </div>
          ))}
        </div> */}
        {/* <div className="ir-ws-course-input-title-container">
          <label className="ir-ws-course-title-label" htmlFor="course-includes">
            This course includes?
            <span className="ir-ws-course-mandatory-star">*</span>
          </label>
          {courseIncludesArray.map((point, index) => (
            <div
              key={point.id}
              className="ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center ir-ws-course-input-point-container"
            >
              <div
                className="ir-ws-flex ir-ws-align-center ir-ws-position-relative "
                style={{ width: "100%" }}
              >
                <input
                  type="text"
                  value={point.text}
                  id="courseIncludes"
                  name="courseIncludes"
                  onFocus={handleInputFocus}
                  onChange={(e) =>
                    handleCourseInCludesInputChange(point.id, e.target.value)
                  }
                  className="ir-ws-course-title-inpute ir-ws-course-points"
                  placeholder="Write Points"
                />
                <svg
                  height="24"
                  viewBox="0 0 48 48"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ir-ws-course-delete-points-icon"
                  onClick={() => handleCourseIncludesDeletePoint(point.id)}
                >
                  <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
                  <path d="M0 0h48v48H0z" fill="none" />
                </svg>
              </div>
              <div
                className="ir-ws-create-btn-container ir-ws-save-course-btn-container ir-ws-course-add-point-btn-container "
                onClick={
                  index === courseIncludesArray.length - 1
                    ? handleCourseIncludesAddPoint
                    : null
                }
                disabled={index !== courseIncludesArray.length - 1}
              >
                <button className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-banner-btn ir-ws-course-add-point-btn">
                  <span>+ Add point</span>
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="ir-ws-course-input-title-container">
          <label className="ir-ws-course-title-label" htmlFor="requirements">
            Requirements<span className="ir-ws-course-mandatory-star">*</span>
          </label>
          {requirementsArray.map((point, index) => (
            <div
              key={point.id}
              className="ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center ir-ws-course-input-point-container"
            >
              <div
                className="ir-ws-flex ir-ws-align-center ir-ws-position-relative "
                style={{ width: "100%" }}
              >
                <input
                  type="text"
                  value={point.text}
                  id="requirements"
                  name="requirements"
                  onFocus={handleInputFocus}
                  onChange={(e) =>
                    handleRequirementsInputChange(point.id, e.target.value)
                  }
                  className="ir-ws-course-title-inpute ir-ws-course-points"
                  placeholder="Write Points"
                />
                <svg
                  height="24"
                  viewBox="0 0 48 48"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ir-ws-course-delete-points-icon"
                  onClick={() => handleRequirementsDeletePoint(point.id)}
                >
                  <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
                  <path d="M0 0h48v48H0z" fill="none" />
                </svg>
              </div>
              <div
                className="ir-ws-create-btn-container ir-ws-save-course-btn-container ir-ws-course-add-point-btn-container "
                onClick={
                  index === requirementsArray.length - 1
                    ? handleRequirementsAddPoint
                    : null
                }
                disabled={index !== requirementsArray.length - 1}
              >
                <button className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-banner-btn ir-ws-course-add-point-btn">
                  <span>+ Add point</span>
                </button>
              </div>
            </div>
          ))}
        </div> */}

        <div className="ir-ws-course-input-title-container">
          <label
            className="ir-ws-course-title-label"
            htmlFor="complete-description"
          >
            Complete description
            <span className="ir-ws-course-mandatory-star">*</span>
          </label>
          <textarea
            className="ir-ws-course-title-inpute ir-ws-course-textarea"
            type="text"
            id="CompleteDescription"
            name="CompleteDescription"
            placeholder="Write complete description"
            value={completeDescription}
            onChange={(e) => {
              setCompleteDescription(e.target.value);
              saveInputChangeToDraft("completeDescription", e.target.value);
            }}
            onFocus={handleInputFocus}
          />
        </div>
        <div className="ir-ws-course-input-title-container">
          <label className="ir-ws-course-title-label">
            Course Image<span className="ir-ws-course-mandatory-star">*</span>
          </label>
          <div
            id="promotionalImage"
            className="ir-ws-course-image-upload"
            onClick={ImagehandleFileUpload}
          >
            {selectedImage ? (
              <div className="ir-ws-course-selected-video-container">
                <div className="ir-ws-flex ir-ws-align-center ir-ws-course-selected-video-image-container">
                  <p title={selectedImageName}>
                    {selectedImageName?.length > 30
                      ? `${selectedImageName?.slice(0, 30)}...`
                      : selectedImageName}
                  </p>
                  <svg
                    viewBox="0 0 48 48"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30px"
                    height="30px"
                  >
                    <path d="M0 0h48v48H0z" fill="none"></path>
                    <path
                      d="M42 38V10c0-2.21-1.79-4-4-4H10c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4zM17 27l5 6.01L29 24l9 12H10l7-9z"
                      fill="#ffffff"
                      class="fill-000000"
                    ></path>
                  </svg>
                </div>

                <svg
                  height="30"
                  viewBox="0 0 48 48"
                  width="30"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ir-course-selected-image-video-delete-icon"
                  onClick={deleteSelectedImage}
                >
                  <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
                  <path d="M0 0h48v48H0z" fill="none" />
                </svg>

                {!isUploaded ? (
                  <img
                    src={require("../../../../assets/images/complete.png")}
                    alt="loader"
                    className="ir-ws-course-promotional-video-uploaded"
                  />
                ) : (
                  <img
                    src={require("../../../../assets/images/loader.png")}
                    alt="loader"
                    className="ir-ws-course-promotional-video-loader"
                  />
                )}
              </div>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="custom-svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                />
              </svg>
            )}
            <input
              id="file-upload"
              type="file"
              className="ir-ws-course-image-input"
              onChange={imageHandleInputChange}
            />
          </div>
        </div>
        <div className="ir-ws-course-input-title-container">
          <label className="ir-ws-course-title-label">
            Promotional video
            {/* <span className="ir-ws-course-mandatory-star">*</span> */}
          </label>
          <div
            id="promotionalVideo"
            className="ir-ws-course-image-upload"
            onClick={handleVideoFileUpload}
          >
            {videoSelected ? (
              <div className="ir-ws-course-selected-video-container">
                <div className="ir-ws-flex ir-ws-align-center ir-ws-course-selected-video-image-container">
                  <p title={selectedVideoName}>
                    {selectedVideoName.length > 30
                      ? `${selectedVideoName.slice(0, 30)}...`
                      : selectedVideoName}
                  </p>
                  <svg
                    viewBox="0 0 512 512"
                    xmlns="http://www.w3.org/2000/svg"
                    height="30px"
                    width="30px"
                  >
                    <path
                      d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48Zm74.77 217.3-114.45 69.14a10.78 10.78 0 0 1-16.32-9.31V186.87a10.78 10.78 0 0 1 16.32-9.31l114.45 69.14a10.89 10.89 0 0 1 0 18.6Z"
                      fill="#ffffff"
                      class="fill-000000"
                    ></path>
                  </svg>
                </div>
                <svg
                  height="30"
                  viewBox="0 0 48 48"
                  width="30"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ir-course-selected-image-video-delete-icon"
                  onClick={delteSelectedVideo}
                >
                  <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
                  <path d="M0 0h48v48H0z" fill="none" />
                </svg>

                {!isUploaded ? (
                  <img
                    src={require("../../../../assets/images/complete.png")}
                    alt="loader"
                    className="ir-ws-course-promotional-video-uploaded"
                  />
                ) : (
                  <img
                    src={require("../../../../assets/images/loader.png")}
                    alt="loader"
                    className="ir-ws-course-promotional-video-loader"
                  />
                )}
              </div>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="custom-svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                />
              </svg>
            )}
            <input
              id="video-upload"
              ref={videoFileInputRef}
              accept="video/*"
              type="file"
              className="ir-ws-course-image-input"
              onChange={handleVideoInputChange}
            />
          </div>
          <p className="ir-ws-course-promotion-video-helper-text">
            Do not use special characters in video file name
          </p>
        </div>

        {/* <button style={{ width: "150px" }} onClick={handleVideoSubmit}>
          Upload Video
        </button> */}
        <div className="ir-ws-course-input-title-container">
          <label className="ir-ws-course-title-label" htmlFor="instructor-name">
            Instructor Name
            <span className="ir-ws-course-mandatory-star">*</span>
          </label>
          <input
            className="ir-ws-course-title-inpute ir-ws-instructor-input"
            type="text"
            id="instructor-name"
            name="instructor-name"
            placeholder="Type instructor name"
            onFocus={handleInputFocus}
            onChange={handleInstructorName}
          />
        </div>
        <div className="ir-ws-course-input-title-container">
          <label
            className="ir-ws-course-title-label"
            htmlFor="instructor-Profile"
          >
            Instructor Profile
            <span className="ir-ws-course-mandatory-star">*</span>
          </label>
          <textarea
            className="ir-ws-course-title-inpute ir-ws-course-textarea"
            type="text"
            id="instructor-Profile"
            name="instructor-profile"
            placeholder="Type instructor profile"
            onFocus={handleInputFocus}
            onChange={handleInstructorProfile}
          />
        </div>
        <div className="ir-ws-course-input-title-container">
          <label className="ir-ws-course-title-label" htmlFor="coursePrice">
            Price of the course?
            <span className="ir-ws-course-mandatory-star">*</span>
          </label>
          <input
            className="ir-ws-course-title-inpute"
            type="text"
            id="coursePrice"
            name="coursePrice"
            placeholder="$"
            value={coursePrice}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
          />
        </div>
      </div>
      <div>
        <div
          className="ir-ws-flex ir-ws-align-center"
          style={{ width: "70%", justifyContent: "space-between" }}
        >
          <div
            className="ir-ws-create-btn-container ir-ws-create-course-save-btn ir-ws-save-course-btn-container "
            style={{ marginBottom: "20px" }}
          >
            <button
              onClick={onSaveAndContinueHandler}
              className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-banner-btn "
              disabled={isUploaded}
              style={{ cursor: isUploaded ? "not-allowed" : "pointer" }}
            >
              <span>Save and Continue</span>
            </button>
          </div>
          <div
            className="ir-ws-create-btn-container ir-ws-create-course-save-btn ir-ws-save-course-btn-container  "
            style={{ marginBottom: "20px" }}
            onClick={toggleConfirmationModal}
          >
            <button className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-banner-btn ">
              <span>Cancel</span>
            </button>
          </div>

          <div
            className="ir-ws-create-btn-container ir-ws-create-course-save-btn ir-ws-save-course-btn-container "
            style={{ marginBottom: "20px", marginRight: "0px" }}
          >
            <button
              onClick={saveAsDraftHandler}
              className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-banner-btn "
            >
              <span>Save as draft</span>
            </button>
          </div>
        </div>
        {isFormvalid && (
          <p className="ir-ws-invalid-field-message" style={{ marginTop: "0" }}>
            Please fill out all required fields
          </p>
        )}
      </div>
      {openConfirmationModal && (
        <ConfirmationModal
          toggleModal={toggleConfirmationModal}
          confirmationFunc={() => {
            dispatch(resetFormData());
            handleCreateCourse();
          }}
          title="cancel"
        />
      )}
      {cropImageModal && (
        <ThumbnailCropModal
          cropThumbnail={cropThumbnail}
          setCropImageModal={setCropImageModal}
          deleteSelectedImage={deleteSelectedImage}
          handleSaveCroppedImage={handleSaveCroppedImage}
          imagePreview={imagePreview}
        />
      )}
    </div>
  );
};

export default CreateCourseFirstPage;
