import React, { useEffect, useState } from "react";

const Mcq = ({
  activeSlideObject,
  activeAccordions,
  accordionRefs,
  toggleAccordion,
  handleAnswerSelect,
  submittedMcq,
  answerType,
  mcqData,
  questionIndex,
  markQuestionAsAnswered,
  activeQuestionindex,
  goToNextSlide,
  totalQuestions,
  handleShowLearningPopup,
  isLearningPointExist,
  isAllQuestionsSubmitted,
  handleSubmitAllAnser,
}) => {
  const [newSelectedAnwer, setNewSelectedAnwer] = useState("");
  const [isSubmitted, setIssubmitted] = useState(false);

  const isLastQuestion = questionIndex === totalQuestions - 1;

  // useEffect(() => {
  //   if (isAllQuestionsSubmitted) {
  //     markQuestionAsAnswered(questionIndex);
  //   }
  // }, [isAllQuestionsSubmitted]);

  const newCheckAnswer = () => {
    setIssubmitted(true);
    const yourAnswerElement = document.getElementById(
      `your-answer-${mcqData?.id}`
    );
    const correctAnswerElement = document.getElementById(
      `correct-answer-${mcqData?.id}`
    );

    if (newSelectedAnwer === mcqData?.data.correctAnswer) {
      yourAnswerElement.textContent = "Your answer is Correct";
      yourAnswerElement.className = "your-answer correct";
    } else {
      yourAnswerElement.textContent = "Your answer is Wrong";
      yourAnswerElement.className = "your-answer wrong";
    }

    correctAnswerElement.textContent = `The answer is ${mcqData?.data.correctAnswer}`;
    correctAnswerElement.className = "correct-answer";
  };

  const submitHandler = (questionIndex) => {
    newCheckAnswer();
    markQuestionAsAnswered(questionIndex);
  };

  const newHandleAnswerSelect = (answer) => {
    setNewSelectedAnwer(answer);
  };
  return (
    <>
      {mcqData && (
        <>
          <div className="ir-ws-case-accordion-cont">
            <div className="ir-ws-case-accordion-header ir-ws-case-accordian-active-border">
              <span className="ir-ws-case-slide-title-text">
                <p className="mul-question">
                  {/* Question:{" "} */}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: mcqData.data.question,
                    }}
                  />
                </p>
              </span>
              {/* <span className="ir-cs-section-icon">
                {activeAccordions[questionIndex] ? (
                  <i className="bi bi-chevron-up"></i>
                ) : (
                  <i className="bi bi-chevron-down"></i>
                )}
              </span> */}
            </div>
            <div
              className="ir-ws-case-accordion-accordion-content"
              // style={{ height: "100%" }}
            >
              <div className="ir-ws-case-content-box">
                <div
                  key={questionIndex}
                  className="multiple-question-data-main"
                >
                  <form className="multiple-question-options-main">
                    {mcqData?.data.answers
                      ?.filter((answer) => answer.trim() !== "")
                      .map((answer, answerIndex) => (
                        <div
                          key={answerIndex}
                          className="multiple-question-options-content"
                        >
                          <input
                            type="radio"
                            id={`answer-${answerIndex}`}
                            name={`question-${mcqData.id}`}
                            value={answer}
                            className="mul-radio-answer"
                            onChange={() => {
                              handleAnswerSelect(mcqData.id, answer);
                              newHandleAnswerSelect(answer);
                              // console.log("answerType single case page:", answerType);

                              if (answerType === "group")
                                markQuestionAsAnswered(questionIndex);

                              // markQuestionAsAnswered(questionIndex)
                            }}
                            // disabled={isSubmitted}
                            disabled={
                              (answerType === "group" &&
                                isAllQuestionsSubmitted) ||
                              isSubmitted
                            }
                          />
                          <label
                            htmlFor={`answer-${questionIndex}`}
                            className="mul-options"
                          >
                            {answer}
                          </label>
                        </div>
                      ))}
                  </form>
                  <div className="result-message" id={`result-${mcqData.id}`}>
                    <span
                      className="your-answer"
                      id={`your-answer-${mcqData.id}`}
                    ></span>
                    <span
                      className="correct-answer"
                      id={`correct-answer-${mcqData.id}`}
                    ></span>
                  </div>
                  {answerType === "single" && (
                    <div
                      className=" ir-ws-default-btn-container"
                      style={{ width: "100%", marginTop: "10px" }}
                    >
                      <button
                        // onClick={newCheckAnswer}
                        onClick={() => submitHandler(questionIndex)}
                        id={`submit-button-${mcqData.id}`}
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                        disabled={isSubmitted}
                        style={{ width: "240px" }}
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  )}
                  {/* {!isLastQuestion && answerType === "group" && (
                    <div
                      className=" ir-ws-default-btn-container"
                      style={{
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                        // onClick={checkAnswerFid}
                        onClick={goToNextSlide}
                        // onClick={() => submitHandler(questionIndex)}
                        disabled={isLastQuestion}
                        style={{ width: "240px" }}
                      >
                        <span>Next</span>
                      </button>
                    </div>
                  )} */}

                  {answerType === "group" && (
                    <div
                      className="ir-ws-default-btn-container"
                      style={{
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      {isLastQuestion ? (
                        <button
                          className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                          onClick={handleSubmitAllAnser}
                          style={{ width: "240px" }}
                          disabled={isAllQuestionsSubmitted}
                        >
                          <span>Finish</span>
                        </button>
                      ) : (
                        <button
                          className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                          onClick={goToNextSlide}
                          style={{ width: "240px" }}
                        >
                          <span>Next</span>
                        </button>
                      )}
                    </div>
                  )}

                  {(isLearningPointExist && isLastQuestion && isSubmitted) ||
                  (isLearningPointExist &&
                    answerType === "group" &&
                    isLastQuestion &&
                    isAllQuestionsSubmitted) ? (
                    <div
                      className="ir-ws-default-btn-container"
                      style={{
                        width: "100%",
                        marginTop: "20px",
                        marginLeft: "5px",
                      }}
                    >
                      <button
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                        // onClick={checkJumblingAnswers}
                        onClick={() => handleShowLearningPopup()}
                        style={{ width: "240px" }}
                      >
                        <span>Learning Points</span>
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Mcq;
