import { useEffect, useState, useRef } from "react";
import "./WebinarPaymentSuccess.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import CssLoader from "./CssLoader";

const WebinarPaymentFailure = () => {
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState("");
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const sessionId = new URLSearchParams(window.location.search).get(
    "session_id"
  );

  const apiCalled = useRef(false);

  useEffect(() => {
    const successPayment = async () => {
      try {
        const response = await axios.post(
          `https://webinar-staging.backend.ir4u.info/api/v1/payment/payment-cancel?sessionId=${sessionId}`,
          null,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          // Swal.fire("Success", "Webinar link has been sent to mail", "success");
          setOrderPlaced(true);
        }
        console.log("Cancel payment response:", response.data);
      } catch (error) {
        console.error("Error Successful payment:", error);

        if (error.response && error.response.status === 500 && retryCount < 3) {
          setTimeout(() => {
            setRetryCount(retryCount + 1);
            apiCalled.current = false;
          }, 2000);
        } else {
          console.error("Maximum retry attempts reached or non-500 error.");
        }
      }
    };

    if (!apiCalled.current && !orderPlaced) {
      apiCalled.current = true;
      successPayment();
    }
  }, [sessionId, orderPlaced, retryCount]);

  useEffect(() => {
    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const formattedDate = `${day < 10 ? "0" : ""}${day} - ${
        month < 10 ? "0" : ""
      }${month} - ${year}`;
      setCurrentDate(formattedDate);
    };

    getCurrentDate();
  }, []);

  return (
    <>
      {!orderPlaced && (
        <div className="ir-ws-webinar-payment-success-container ir-ws-flex ir-ws-justify-center ir-ws-align-center ir-ws-webinar-payment-failure-container">
          <div className="payment-process-container">
            <p className="order-processing-text">Payment cancelling</p>
            <p className="order-dnc-text">
              Please do not click back button or close button
            </p>
            <div className="css-loader-box">
              <CssLoader />
            </div>
          </div>
        </div>
      )}
      {orderPlaced && (
        <div className="ir-ws-webinar-payment-success-container ir-ws-flex ir-ws-justify-center ir-ws-align-center ir-ws-webinar-payment-failure-container">
          <div className="ir-ws-webinar-payment-success-box">
            <div className="ir-ws-flex ir-ws-justify-center ir-ws-align-center">
              <a href="/">
                <img
                  src={require("../../assets/images/ir4u2.png")}
                  alt="logo"
                  className="ir-ws-webinar-payment-ir4u-logo"
                />
              </a>
            </div>
            <div className="ir-ws-flex ir-ws-justify-center ir-ws-align-center ir-ws-webinar-payment-failure-logo-container ir-ws-webinar-payment-success-logo-container">
              <img
                src={require("../../assets/images/cancelled.png")}
                alt="Success"
                className="ir-ws-webinar-payment-success-logo"
              />
              <p className="ir-ws-webinar-payment-failure-text">
                Transaction failed!
              </p>
              <p className="ir-ws-webinar-payment-receipt-date-text">
                {currentDate}
              </p>
              <p className="ir-ws-webinar-payment-receipt-text">
                Your payment has been failed. <br />
                Please try again
              </p>
              <div
                className="ir-ws-text-center ir-ws-default-btn-container ir-ws-webinar-payment-status-button-container"
                onClick={() => navigate("/")}
              >
                <button className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ">
                  <span>OK</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WebinarPaymentFailure;
