import axios from "axios";

export const BaseURL = "https://chat.ir4u.info"
// export const BaseURL = "http://127.0.0.1:5000";
export const appURL = "http://18.214.114.58/";

export default axios.create({
    baseURL: BaseURL
})

axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    if(!config.headers["Authorization"]) {
        config.headers["Authorization"] = 'Bearer ' + localStorage.getItem('jwtTokenApi2');
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});