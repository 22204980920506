import React, {useState, useEffect, useContext} from "react";
import axios from "../../../../api/axios";
import { RootStore } from "../../../../store/store";
import ChatRoomAdminTab from "../ChatRoomAdminTab/ChatRoomAdminTab";
import UserAdminTab from "../UserAdminTab/UserAdminTab";
import './ChatAdminContent.scss';
import ArchivedAdminTab from "../ArchivedAdminTab/ArchivedAdminTab";
import Swal from "sweetalert2";
import AdminConfirmModal from "../../AdminConfirmModal/AdminConfirmModal";
import { ConfirmationTypes } from "../../../../constants";
import FinanceAdminTab from "../FinanceAdminTab/FinanceAdminTab";
import ArchiveAdminDataModal from "../../ArchiveAdminDataModal/ArchiveAdminDataModal";
import { usePaymentContext } from "../../../../context/PaymentContext";

const ChatAdminContent = (props) => {
    const [store, setStore] = useContext(RootStore)
    const { chatStore, userStore, socketConnection } = store;
    const [archive, setArchive] = useState(false);

    
    const [selectedFile, setSelectedFile] = useState('');
    const [profileData, setProfileData] = useState(null);
    const [enableInputField, setEnableInputField] = useState({});
    const [updateProfileInputData, setUpdateProfileInputData] = useState({});
    const [updateProfilePicModal, setUpdateProfilePicModal] = useState(false);
    const [updateProfilePic, setUpdateProfilePic] = useState(false);
    const [currentChatInfo, setCurrentChatInfo] = useState(null);
    const [currentUserInfo, setCurrentUserInfo] = useState(null);
    const [actionType, setActionType] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    //currentChatId, confirmationType
    //create on fn
    // showconfirmation true
    //handleConfirmSubmit

    const [showAdminDataModal, setShowAdminDataModal] = useState(false);
    const [activeRoomsData, setActiveRoomsData] = useState([]);

    console.log(activeRoomsData);

    const { paymentResponses,setPaymentResponses } = usePaymentContext();

    useEffect(() => {
        console.log("Payment responses updated:", paymentResponses);
      }, [paymentResponses]);

    const { connection, ioState } = socketConnection;

    const handleAction = (_chatInfo, _actionType) => {
        setCurrentChatInfo(_chatInfo);
        setActionType(_actionType);
        setShowConfirmation(true);
    }

    const handleUserAction = (_userInfo, _actionType) => {
        setCurrentUserInfo(_userInfo);
        setActionType(_actionType);
        setShowConfirmation(true);
    }



    const handleConfirm = async () => {
        if(actionType === ConfirmationTypes.FREEZE ) {
            await freezeRoom(currentChatInfo.id)
        } else if(actionType === ConfirmationTypes.UNFREEZE) {
            await unFreezeRoom(currentChatInfo.id)
        } else if(actionType === ConfirmationTypes.ARCHIVE) {
            await addToArchieve(currentChatInfo.id)
            setShowAdminDataModal(true);
        } else if(actionType === ConfirmationTypes.UNARCHIVE) {
            await removeFromArchive(currentChatInfo.id)
        } else if(actionType === ConfirmationTypes.DELETEROOM) {
            await deleteRoom(currentChatInfo.id)
        } else if(actionType === ConfirmationTypes.DELETECONTENT) {
            await deleteRoomConent(currentChatInfo.id)
        } else if(actionType === ConfirmationTypes.ACTIVEUSER) {
            await activeUser(currentUserInfo.id)
        } else if(actionType === ConfirmationTypes.INACTIVEUSER) {
            await inactiveUser(currentUserInfo.id)
        } else if (actionType === ConfirmationTypes.DELETEUSER) {
            await deleteUser(currentUserInfo.id)
        }

        setShowConfirmation(false);
    }

    const handleCancel = () => {
        setCurrentChatInfo(null);
        setActionType(null);
        setShowConfirmation(false);
    }

    const addToArchieve = async (id) => {
        console.log("clicked")
        const response = await axios({
            url: "chat/archive/" + id,
            method: 'post',
            withCredentials: false,
            headers: {
                'Content-Type': 'multiplart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('jwtTokenApi2')
            }
        });

        if (response) {
            // let chatgroups = response.data.data;

            setActiveRoomsData(response);
           
            let chatStore = { ...store.chatStore }
            let responseData = {...response.data.data};
            
            if(responseData.image !== "" && responseData.image.indexOf(', ')) {
                responseData.image = responseData.image.split(', ')
            }
            
            let groups = [...chatStore.groups]
            let index = null;
            for(let i = 0; i<groups.length; i++) {
                let id = groups[i].id;
                if(id === responseData.id) {
                    index = i;
                    break;
                }
            }
            // let index = responseData?.id &&  groups.findIndex(responseData?.id) || null;

            if(index != null) {
                groups[index] = {...responseData}
            }

            console.log("resposnse data is ", responseData);
            // chatStore.groups.push(responseData);
            
            setArchive(true);
            setStore({
                ...store, chatStore: { ...chatStore, groups: [...groups] }
            });
        }
    }

    const removeFromArchive = async (id) => {
        const response = await axios({
            url: "chat/archive/" + id,
            method: 'DELETE',
            withCredentials: false,
            headers: {
                'Content-Type': 'multiplart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('jwtTokenApi2')
            }
        });



        const updatedPaymentResponses = { ...paymentResponses };
        delete updatedPaymentResponses[id];
        setPaymentResponses(updatedPaymentResponses);

        localStorage.setItem('paymentResponses', JSON.stringify(updatedPaymentResponses));

        if (response) {
            Swal.fire({
                title: 'Success!',
                text: 'Room is successfully unarchived.',
                icon: 'success'
            });
            let chatStore = { ...store.chatStore }
            let responseData = {...response.data.data};
            if(responseData.image !== "" && responseData.image.indexOf(', ')) {
                responseData.image = responseData.image.split(', ')
            }

            let groups = [...chatStore.groups]
            let index = null;
            for(let i = 0; i<groups.length; i++) {
                let id = groups[i].id;
                if(id === responseData.id) {
                    index = i;
                    break;
                }
            }
            // let index = responseData?.id &&  groups.findIndex(responseData?.id) || null;

            if(index != null) {
                groups[index] = {...responseData}
            }

            console.log("resposnse data is ", responseData);
            // chatStore.groups.push(responseData);
            
            setArchive(false);
            setStore({
                ...store, chatStore: { ...chatStore, groups: [...groups] }
            });
        }
    }
    
    const freezeRoom = async (id) => {
        console.log("Freeze room", id);
        const response = await axios({
            url: "chat/freeze/" + id,
            method: 'post',
            withCredentials: false,
            headers: {
                'Content-Type': 'multiplart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('jwtTokenApi2')
            }
        });
        console.log("freeze response is", response)
        if (response) {
            Swal.fire({
                title: 'Success!',
                text: 'Room is successfully freezed',
                icon: 'success'
            });
            // let chatgroups = response.data.data;
            let chatStore = { ...store.chatStore }
            let responseData = {...response.data.data};
            if(responseData.image !== "" && responseData.image.indexOf(', ')) {
                responseData.image = responseData.image.split(', ')
            }

            let groups = [...chatStore.groups]
            let index = null;
            for(let i = 0; i<groups.length; i++) {
                let id = groups[i].id;
                if(id === responseData.id) {
                    index = i;
                    break;
                }
            }
            // let index = responseData?.id &&  groups.findIndex(responseData?.id) || null;

            if(index != null) {
                groups[index] = {...responseData}
            }

            console.log("resposnse data is ", responseData);
            // chatStore.groups.push(responseData);

           
            setStore({
                ...store, chatStore: { ...chatStore, groups: [...groups] }
            });
        }
    }

    const unFreezeRoom = async (id) => {
        const response = await axios({
            url: "chat/freeze/" + id,
            method: 'DELETE',
            withCredentials: false,
            headers: {
                'Content-Type': 'multiplart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('jwtTokenApi2')
            }
        });

        if (response) {
            Swal.fire({
                title: 'Success!',
                text: 'Room is successfully unfreezed',
                icon: 'success'
            });
            // let chatgroups = response.data.data;
            let chatStore = { ...store.chatStore }
            let responseData = {...response.data.data};
            if(responseData.image !== "" && responseData.image.indexOf(', ')) {
                responseData.image = responseData.image.split(', ')
            }

            let groups = [...chatStore.groups]
            let index = null;
            for(let i = 0; i<groups.length; i++) {
                let id = groups[i].id;
                if(id === responseData.id) {
                    index = i;
                    break;
                }
            }
            // let index = responseData?.id &&  groups.findIndex(responseData?.id) || null;

            if(index != null) {
                groups[index] = {...responseData}
            }

            console.log("resposnse data is ", responseData);
            // chatStore.groups.push(responseData);
            
           
            setStore({
                ...store, chatStore: { ...chatStore, groups: [...groups] }
            });
        }
    }

    const deleteRoom = async (id, currentRoom, totalRooms) => {
        const response = await axios({
            url: "chat/group/" + id,
            method: "delete",
            withCredentials: false,
            headers: {
                'Content-Type': 'multiplart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('jwtTokenApi2')
            }
        });
        if (response.status === 200) {
            Swal.fire({
                title: 'Success!',
                text: 'Room is successfully deleted',
                icon: 'success'
            });
            let groups = [ ...chatStore.groups ]
            let index = groups.findIndex(e => e.id === id)
            groups.splice(index, 1);
            // chatStore.groups.push(responseData);
            setStore({
                ...store, chatStore: {
                    ...chatStore,
                    groups: [...groups]
                }
            });
            let selectedRom = currentRoom;
            if(currentRoom == (totalRooms-1)){
                selectedRom = currentRoom - 1
            }else if(currentRoom == 0){
                selectedRom = currentRoom+1;
            }else {
                selectedRom = currentRoom +1;
            }
        }
    }

    const deleteRoomConent = async (id) => {
        const response = await axios({
            url: "chat/history/" + id,
            method: "delete",
            withCredentials: false,
            headers: {
                'Content-Type': 'multiplart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('jwtTokenApi2')
            }
        });
        if (response.status === 200) {
            Swal.fire({
                title: 'Success!',
                text: 'Room content is successfully deleted',
                icon: 'success'
            });
            
        }
    }

    const activeUser = async (id, status="inactive") => {
        console.log("clicked active user", id, status );
        let userStore = [...store.userStore.users];

        let index = userStore.findIndex((e) => e.id === id);
        let fd = new FormData();
        fd.append("status", status);

        const response = await axios({
            url: 'user/profile/' + id,
            method: 'PUT',
            withCredentials: false,
            data: fd,
            headers: {
                'Content-Type': 'multiplart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('jwtTokenApi2')
            }
        });
        console.log("userStore admin", response)
        if(response) {
            Swal.fire({
                title: 'Success!',
                text: 'User is successfully active',
                icon: 'success'
            });
            userStore[index] = {...response.data.data}
            setStore({
                ...store, userStore: {
                    ...userStore,
                    users: [...userStore]
                }
            });
        }
    }

    const inactiveUser = async (id, status="active") => {
        console.log("clicked")
        let userStore = [...store.userStore.users];

        let index = userStore.findIndex((e) => e.id === id);
        let fd = new FormData();
        fd.append("status", status);

        const response = await axios({
            url: 'user/profile/' + id,
            method: 'PUT',
            withCredentials: false,
            data: fd,
            headers: {
                'Content-Type': 'multiplart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('jwtTokenApi2')
            }
        });
        console.log("userStore admin", response)
        if(response) {
            Swal.fire({
                title: 'Success!',
                text: 'User is successfully ianctive',
                icon: 'success'
            });
            userStore[index] = {...response.data.data}
            setStore({
                ...store, userStore: {
                    ...userStore,
                    users: [...userStore]
                }
            });
        }
    }

    const deleteUser = async (id) => {
        console.log("clicked")
        let userStore = [...store.userStore.users];

        let index = userStore.findIndex((e) => e.id === id);

        const response = await axios({
            url: 'user/profile/' + id,
            method: 'DELETE',
            withCredentials: false,
            headers: {
                'Content-Type': 'multiplart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('jwtTokenApi2')
            }
        });
        console.log("userStore admin", response)
        if(response) {
            Swal.fire({
                title: 'Success!',
                text: 'User is successfully deleted',
                icon: 'success'
            });
            userStore[index] = {...response.data.data}
            setStore({
                ...store, userStore: {
                    ...userStore,
                    users: [...userStore]
                }
            });
        }
    }

    const enableInputFieldHandle = (name) => {
        const key = name;
        setEnableInputField({
            ...enableInputField,
            [key]: !enableInputField[key]
        });
    }

    const updateProfilePicHandle = (event) => {
        setUpdateProfilePicModal(true);
        setSelectedFile(event.target.files[0]);
        setUpdateProfilePic(URL.createObjectURL(event.target.files[0]));
    }

    const updateProfilePicCloseModalHandle = () => {
        setUpdateProfilePicModal(false);
    }
   
    const updateProfileInputChange = (e) => {
        console.log(e.target.name, e.target.value)
        setProfileData({ ...profileData, [e.target.name]: e.target.value })
        setUpdateProfileInputData({ ...updateProfileInputData, [e.target.name]: e.target.value });
    }

    const upldateProfileSubmitFormHandle = async (e) => {
        e.preventDefault();
        const updateProfileFormData = new FormData();
        if (selectedFile !== "") {
            updateProfileFormData.append('image', selectedFile || '')
        }
        Object.keys(updateProfileInputData).map((k, i) => {
            console.log("keys are", updateProfileInputData)
            updateProfileFormData.append(k, updateProfileInputData[k]);
            return true;
        });

        const response = await axios({
            url: 'user/profile/' + userStore.profile.id,
            method: 'PUT',
            data: updateProfileFormData,
            withCredentials: false,
            headers: {
                'Content-Type': 'multiplart/form-data',
                'Authorization': 'Bearer ' + localStorage.getItem('jwtTokenApi2')
            }
        });
        if (response?.status === 200) {
            // Swal.fire( 'Good job!','You clicked the button','success');
            Swal.fire({
                title: 'Success!',
                text: 'Your Profile is Updated.',
                icon: 'success'
            });

            setStore({
                ...store, userStore: {
                    ...userStore,
                    profile: {
                        ...userStore.profile,
                        ...response.data.data
                    }
                }
            });
            let profileInputData = {};
            Object.keys(updateProfileInputData).map((k, i) => {
                profileInputData[k] = response.data.data[k];
            })
            setUpdateProfileInputData(profileInputData)
            setProfileData(response.data.data);
            setUpdateProfileInputData({});
            setUpdateProfilePicModal(false);
        }

    }

    useEffect(() => {
        setProfileData(userStore.profile)
    }, [userStore.profile]);

    useEffect(() => {
        if (!connection) props.socket.connect();
        if (!connection && ioState) {
            props.socket.on("disconnect", (reason) => {
                props.socket.connect();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection])
    return (
        <div className="ir-chat-admin-content-main-container">
            <div className="ir-ct-admn-cntnt-inner-container">
                <div className="tab-content">
                    <div className={`tab-pane fade ${props.navMenuItemActive === 0 ? "active show" : ""}`} id="chatsAdminTab" >
                        <h2>Chat Rooms</h2>
                        <div className={`ir-ct-admn-cntnt-in-container ${archive ? "true" : "false"}`}>
                            <div className="ir-chat-rooms-tab-admin-container">
                            {/* {JSON.stringify(chatStore)} */}
                                <ChatRoomAdminTab 
                                    data={chatStore} 
                                    onAction={handleAction}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane fade ${props.navMenuItemActive === 1 ? "active show" : ""}`} id="usersAdminTab" >
                        <h2>Users</h2>
                        <div className="ir-ct-admn-cntnt-in-container">
                            <div className="ir-chat-rooms-tab-admin-container">
                            {/* {JSON.stringify(store.userStore.users)} */}
                                <UserAdminTab 
                                    data={props.users}
                                    onAction={handleUserAction}
                                    inactiveUser={inactiveUser}
                                    deleteUser={deleteUser}
                                    activeUser={activeUser}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane fade ${props.navMenuItemActive === 2 ? "active show" : ""}`} id="archiveAdminTab" >
                        <h2>Archived Rooms</h2>
                        <div className="ir-ct-admn-cntnt-in-container">
                            <div className="ir-chat-rooms-tab-admin-container ir-archived-admin-main-container">
                                
                            {/* {JSON.stringify(chatStore)} */}
                                <ArchivedAdminTab 
                                    data={chatStore}
                                    addToArchieve={addToArchieve}
                                    removeFromArchive={removeFromArchive}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane fade ${props.navMenuItemActive === 3 ? "active show" : ""}`} id="financeAdminTab" >
                        <h2>Finance</h2>
                        <div className="ir-ct-admn-cntnt-in-container">
                            <div className="ir-chat-rooms-tab-admin-container">
                            {/* {JSON.stringify(chatStore)} */}
                                <FinanceAdminTab 
                                    data={chatStore}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane fade ${props.navMenuItemActive === 4 ? "active show" : ""}`} id="profileAdminTab" >
                        <h2>Profile Settings</h2>
                        <div className="ir-ct-admn-cntnt-in-container">
                            <div className="ir-chat-rooms-tab-admin-container">
                                <div className="ir-customtabs-main-container">
                                    <span className="ir-customa-tabs-link activeTab">Profile Settings</span>
                                </div>
                                <div className="ir-customtabs-child-container">
                                    <div className="ir-customtab-main-container">
                                        <div className="ir-chat-rooms-tab-container ir-active-rooms-tab-main-container">
                                            <div className="ir-chat-admin-inside-tab-container">
                                                {/* {JSON.stringify(userStore?.profile)} */}
                                                <div className="ir-ad-profile-avatar-container">
                                                    <h4>Avatar</h4>
                                                    <div className="ir-ad-profile-pic-main-container">
                                                        <div className="profile-user">
                                                            {userStore?.profile?.image === "" ?
                                                                <p className="profile-bg-replace">{userStore?.profile?.firstname[0] + userStore?.profile?.lastname[0]}</p>
                                                                :
                                                                <img
                                                                    className="avatar-lg"
                                                                    src={userStore?.profile?.image}
                                                                    alt="profile pic"
                                                                />
                                                            }
                                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                                <input
                                                                    id="profile-img-file-input"
                                                                    type="file"
                                                                    className="profile-img-file-input"
                                                                    accept="image/png, image/jpeg"
                                                                    name="file"
                                                                    onChange={updateProfilePicHandle}
                                                                />
                                                                <label htmlFor="profile-img-file-input" className="profile-photo-edit avatar-xs form-label">
                                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                                        <i className="bi bi-pencil"></i>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ir-ad-profile-details-container">
                                                    <h4>Personal Details</h4>
                                                    <form onSubmit={upldateProfileSubmitFormHandle}>
                                                        <div className="ir-profile-desc">
                                                            <div className="simplebar-wrapper">
                                                                <div className="simplebar-mask">
                                                                    <div className="simplebar-offset">
                                                                        <div className="simplebar-content-wrapper" tabIndex="0" role="region" aria-label="scrollable content">
                                                                            <div className="simplebar-content">
                                                                                <div>
                                                                                    <div className="d-flex py-2 ir-set-prof-content-container">
                                                                                        <div className="ir-set-prof-info-container">
                                                                                            <div className="text-muted mb-1 ir-set-prof-info-title-container">
                                                                                                First Name
                                                                                            </div>
                                                                                            <input
                                                                                                type="text"
                                                                                                disabled={!enableInputField["firstname"]}
                                                                                                className="font-size-14 ir-set-prof-info-content-container"
                                                                                                name="firstname"
                                                                                                value={profileData?.firstname || ''}
                                                                                                onChange={updateProfileInputChange}

                                                                                            />
                                                                                        </div>
                                                                                        <div onClick={() => enableInputFieldHandle("firstname")} className="ir-set-prof-info-icon-container">
                                                                                            <i className="material-symbols-outlined">
                                                                                                edit
                                                                                            </i>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex py-2 ir-set-prof-content-container">
                                                                                        <div className="ir-set-prof-info-container">
                                                                                            <div className="text-muted mb-1 ir-set-prof-info-title-container">
                                                                                                Last Name
                                                                                            </div>
                                                                                            <input
                                                                                                type="text"
                                                                                                disabled={!enableInputField["lastname"]}
                                                                                                className="font-size-14 ir-set-prof-info-content-container"
                                                                                                name="lastname"
                                                                                                value={profileData?.lastname || ''}
                                                                                                onChange={updateProfileInputChange}
                                                                                            />
                                                                                        </div>
                                                                                        <div onClick={() => enableInputFieldHandle("lastname")} className="ir-set-prof-info-icon-container">
                                                                                            <i className="material-symbols-outlined">
                                                                                                edit
                                                                                            </i>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex py-2 ir-set-prof-content-container">
                                                                                        <div className="ir-set-prof-info-container">
                                                                                            <div className="text-muted mb-1 ir-set-prof-info-title-container">
                                                                                                Username
                                                                                            </div>
                                                                                            <input
                                                                                                type="text"
                                                                                                disabled
                                                                                                className="font-size-14 ir-set-prof-info-content-container"
                                                                                                value={profileData?.username || ""}
                                                                                                name="username"
                                                                                            />
                                                                                        </div>
                                                                                        {/* <div onClick={enableInputFieldHandle} className="ir-set-prof-info-icon-container">
                                                                                            <i className="material-symbols-outlined">
                                                                                                edit
                                                                                            </i>
                                                                                        </div> */}
                                                                                    </div>

                                                                                    <div className="d-flex py-2 ir-set-prof-content-container">
                                                                                        <div className="ir-set-prof-info-container">
                                                                                            <div className="text-muted mb-1 ir-set-prof-info-title-container">
                                                                                                Email
                                                                                            </div>
                                                                                            <input
                                                                                                type="email"
                                                                                                disabled={!enableInputField["email"]}
                                                                                                className="font-size-14 ir-set-prof-info-content-container"
                                                                                                name="email"
                                                                                                value={profileData?.email || ''}
                                                                                                onChange={updateProfileInputChange}
                                                                                            />
                                                                                        </div>
                                                                                        <div onClick={() => enableInputFieldHandle("email")} className="ir-set-prof-info-icon-container">
                                                                                            <i className="material-symbols-outlined">
                                                                                                edit
                                                                                            </i>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex py-2 ir-set-prof-content-container">
                                                                                        <div className="ir-set-prof-info-container">
                                                                                            <div className="text-muted mb-1 ir-set-prof-info-title-container">
                                                                                                Phone Number
                                                                                            </div>
                                                                                            <input
                                                                                                type="text"
                                                                                                disabled={!enableInputField["phonenumber"]}
                                                                                                className="font-size-14 ir-set-prof-info-content-container"
                                                                                                // defaultValue={userStore?.profile?.phonenumber || "NIL"}
                                                                                                name="phonenumber"
                                                                                                value={profileData?.phonenumber || ''}
                                                                                                onChange={updateProfileInputChange}
                                                                                            />
                                                                                        </div>
                                                                                        <div onClick={() => enableInputFieldHandle("phonenumber")} className="ir-set-prof-info-icon-container">
                                                                                            <i className="material-symbols-outlined">
                                                                                                edit
                                                                                            </i>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex py-2 ir-set-prof-content-container">
                                                                                        <div className="ir-set-prof-info-container">
                                                                                            <div className="text-muted mb-1 ir-set-prof-info-title-container">
                                                                                                Gender
                                                                                            </div>
                                                                                            <input
                                                                                                type="text"
                                                                                                disabled={!enableInputField["gender"]}
                                                                                                className="font-size-14 ir-set-prof-info-content-container"
                                                                                                // defaultValue={userStore?.profile?.gender || "NIL"}
                                                                                                name="gender"
                                                                                                value={profileData?.gender || ''}
                                                                                                onChange={updateProfileInputChange}
                                                                                            />
                                                                                        </div>
                                                                                        <div onClick={() => enableInputFieldHandle("gender")} className="ir-set-prof-info-icon-container">
                                                                                            <i className="material-symbols-outlined">
                                                                                                edit
                                                                                            </i>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex py-2 ir-set-prof-content-container">
                                                                                        <div className="ir-set-prof-info-container">
                                                                                            <div className="text-muted mb-1 ir-set-prof-info-title-container">
                                                                                                Location
                                                                                            </div>
                                                                                            <input
                                                                                                type="text"
                                                                                                disabled={!enableInputField["country"]}
                                                                                                className="font-size-14 ir-set-prof-info-content-container"
                                                                                                // defaultValue={userStore?.profile?.country || "NIL"}
                                                                                                name="country"
                                                                                                value={profileData?.country || ''}
                                                                                                onChange={updateProfileInputChange}
                                                                                            />
                                                                                        </div>
                                                                                        <div onClick={() => enableInputFieldHandle("country")} className="ir-set-prof-info-icon-container">
                                                                                            <i className="material-symbols-outlined">
                                                                                                edit
                                                                                            </i>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="d-flex py-2 ir-set-prof-content-container">
                                                                                        <div className="ir-set-prof-info-container">
                                                                                            <div className="text-muted mb-1 ir-set-prof-info-title-container">
                                                                                                Password
                                                                                            </div>
                                                                                            <input
                                                                                                type="text"
                                                                                                disabled={!enableInputField["password"]}
                                                                                                className="font-size-14 ir-set-prof-info-content-container"
                                                                                                // defaultValue={userStore?.profile?.password}
                                                                                                name="password"
                                                                                                value={profileData?.password || ''}
                                                                                                onChange={updateProfileInputChange}
                                                                                            />
                                                                                        </div>
                                                                                        <div onClick={() => enableInputFieldHandle("password")} className="ir-set-prof-info-icon-container">
                                                                                            <i className="material-symbols-outlined">
                                                                                                edit
                                                                                            </i>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <button className="btn btn-submit" type="submit">Submit</button> */}
                                                                                <button className="btn btn-primary bg-app-default-color btn-lg btn-block full-width-btn" type="submit">
                                                                                    {/* <i className="bi bi-chat-dots me-1"></i>  */}
                                                                                    Update Profile
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showConfirmation && (
                <AdminConfirmModal 
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}

            {showAdminDataModal && (
        <ArchiveAdminDataModal
          closeModal={() => setShowAdminDataModal(false)}
          activeRoomsData={activeRoomsData}
        />
      )}
        </div>
    );
};

export default ChatAdminContent;