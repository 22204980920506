// import { useDispatch } from 'react-redux';
// import { addDraftData } from "../../src/pages/Courses/Store/CourseSlice.js";

// const useDispatchInputChange = () => {
//   const dispatch = useDispatch();

//   const saveInputChangeToDraft = (field, value) => {
//     dispatch(addDraftData({ formData: { [field]: value }, sections: [] }));
//   };

//   return saveInputChangeToDraft;
// };

// export default useDispatchInputChange

// import { useDispatch, useSelector } from 'react-redux';
// import { addDraftData } from "../../src/pages/Courses/Store/CourseSlice.js";

// const useDispatchInputChange = () => {
//   const dispatch = useDispatch();
//   const formData = useSelector(state => state.formData); 

//   const saveInputChangeToDraft = (parentField, fieldName, value) => {
//     const updatedFormData = {
//       ...formData,
//       [parentField]: {
//         ...formData[parentField],
//         [fieldName]: value
//       }
//     };
//     dispatch(addDraftData(updatedFormData));
//   };

//   return saveInputChangeToDraft;
// };

// export default useDispatchInputChange;

import { useDispatch } from 'react-redux';
import { addDraftData } from "../../src/pages/Courses/Store/CourseSlice.js";

const useDispatchInputChange = () => {
  const dispatch = useDispatch();

  const saveInputChangeToDraft = (field, value) => {
    const formData = createNestedObject(field, value);
    dispatch(addDraftData({ formData }));
  };

  const createNestedObject = (field, value) => {
    const keys = field.split('.');
    const nestedObject = keys.reduceRight((acc, key) => ({ [key]: acc }), value);
    return nestedObject;
  };

  return saveInputChangeToDraft;
};

export default useDispatchInputChange;
