import React from "react";
import { useNavigate } from "react-router-dom";
import "./HomeCasesFeatureContainer.scss";

const HomeCasesFeatureContainer = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/cases");
    window.scrollTo(0, 0);  
  };
  return (
    <div className="ir-ws-cases-inside-gradient-container">
      <div className="ir-ws-padding-tb-100 ir-ws-padding-lr-132 ir-ws-hme-cases-main-container">
        <h2 className="ir-ws-text-center ir-ws-hme-prvw-title ir-ws-home-impro-text">
          Improve your knowledge through our Interactive Case Portal
        </h2>
        <div className="ir-ws-hme-cases-inner-container">
          <div className="ir-ws-hme-cases-img-container">
            <img
              src={require("../../assets/images/cases-2.webp")}
              alt="home chat img container"
            />
          </div>
          <div className="ir-ws-hme-cases-content-container">
            <h3 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-hme-cases-title">
              Introducing IR4U Smart Cases
            </h3>
            <h4 className="ir-ws-hme-cases-scnd-title">
              Verify your diagnosis on the go with real time results
            </h4>
            <p className="ir-default-color ir-ws-hme-chat-para">
              Utilise our Chat feature to connect with experienced
              interventional radiologists, seek clarifications, ask questions &
              gain a deeper understanding of IR Concepts.
            </p>
            <div className="ir-ws-hme-chat-btn-container">
              <button
                className="ir-ws-app-bg ir-ws-text-uppercase ir-color-white ir-ws-no-border ir-ws-hme-chat-btn"
                onClick={handleClick}
              >
                <span>Explore Cases Now</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCasesFeatureContainer;
