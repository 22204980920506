import React, { useState, useEffect } from "react";
import "./Banner.scss";

const Carousel = ({ children }) => {
	const [counter, setCounter] = useState(1);
	const [pause, setPause] = useState(false);
	const content = children;

	const handleNext = () => {
		if (counter !== content.length) {
			setCounter(counter + 1);
		} else {
			setCounter(1);
		}
	};

	const handlePre = () => {
		if (counter !== 1) {
			setCounter(counter - 1);
		} else {
			setCounter(content.length);
		}
	};

	const handlePage = page => {
		setCounter(page);
	};

	const handleMouse = () => {
		setPause(!pause);
	};

	useEffect(() => {
		let interval = setInterval(() => {
			if (!pause) {
				handleNext();
			} else {
				clearInterval(interval);
			}
		}, 5000);
		return () => clearInterval(interval);
	});

  return (
	<>
		{/* <div
			className="ir-ws-slide"
			onMouseEnter={handleMouse}
			onMouseLeave={handleMouse}
		// > */}
		<>
			{content.map((item, index) => (
			<div
				className={`ir-carousel-show-item ${counter - 1 === index ? "ir-ws-show" : "ir-ws-not-show"}`}
				key={index}
				onMouseEnter={handleMouse}
				onMouseLeave={handleMouse}
			>
				{item}
			</div>
			))}

			
			<img src={require('../../assets/images/hme-banner-car-close-icon.png')} onClick={handleNext} className="ir-ws-position-absolute ir-ws-hme-banr-close-icon" alt="close next"/>

			<button className="ir-ws-arrown-btn ir-ws-prev" onClick={handlePre}>
			&#10094;
			</button>
			<button className="ir-ws-arrown-btn ir-ws-next" onClick={handleNext}>
			&#10095;
			</button>
		</>
		{/* </div> */}

		<div className="ir-ws-dots-container">
			{content.map((item, index) => (
			<span
				key={index}
				className={counter - 1 === index ? "dot active" : "dot"}
				onClick={() => handlePage(index + 1)}
			/>
			))}
		</div>
	</>
  );
};

export default Carousel;
