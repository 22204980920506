import React, { useState, useContext, useRef, useEffect } from "react";
import { RootStore } from "../../../store/store";
import { usePaymentContext } from "../../../context/PaymentContext";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import "./ChatContent.scss";
import moment from "moment";
import { useParams } from "react-router-dom";
// import EmojiPicker from 'emoji-picker-react';
import AssetRender from "./assetRender";
import axios, { BaseURL } from "../../../api/axios";
// import { appURL } from "../../../api/axios";
import WhiteBoard from "./WhiteBoard";
import NoImage from "../../../assets/images/chat-images/no-image.png";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import LoadingImage from "../../../assets/images/chat-images/loading.svg";
import DeleteConfirmation from "./DeleteConfirmation";
import Progressbar from "./Progressbar";

const mimeType = "video/webm";

const ChatContent = (props) => {
  const routeParams = useParams();
  const [store, setStore] = useContext(RootStore);
  const { chatStore, userStore, socketConnection } = store;
  const { chatHistory, groups } = chatStore;
  const { profile } = userStore;

  const { connection, ioState } = socketConnection;
  const [tooltipText, setTooltipText] = useState(false);
  const [progress, setProgress] = useState(0);
  const [shareModal, setShareModal] = useState(false);
  const [chatMessage, setChatMessage] = useState("");
  const [addAttachActive, setAddAttachActive] = useState(false);
  const [openCamModal, setOpenCamModal] = useState(false);
  const [openWhiteBoardModal, setOpenWhiteBoardModal] = useState(false);
  const [activeCaseName, setActiveCaseName] = useState("");
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openAttachDocsModal, setOpenAttachDocsModal] = useState(false);
  const [openImageGalleryModal, setOpenImageGalleryModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState([] || {});
  const [selectedDoc, setSelectedDoc] = useState([]);
  // const [isTyping, setIsTyping] = useState(false);
  // const [typingMessage, setTypingMessage] = useState({});
  const [permission, setPermission] = useState(false);
  const [nextButtonDisable, setNextButtonDisable] = useState(true);
  const [prevButtonDisable, setPreButtonDisable] = useState(true);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [videoChunks, setVideoChunks] = useState([]);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [isUpload, setIsUpload] = useState(false);
  const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);
  const [disableUploadBtn, setDisableUploadBtn] = useState(false);
  const [convertedVideoURL, setConvertedVideoURL] = useState(null);

  const [uploaded, setUploaded] = useState(null);
  const mediaRecorder = useRef(null);
  const liveVideoFeedRef = useRef(null);

  const [activeRooms, setActiveRooms] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [onlyAchive, setOnlyAchive] = useState(false);

  const [textareaHeight, setTextareaHeight] = useState(50);
  const [showModal, setShowModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const textareaStyle = {
    height: `${textareaHeight}px`,
    minHeight: "50px",
    maxHeight: "100px",
  };

  // const { paymentResponses, setPaymentResponses } = usePaymentContext();
  //   console.log("paymentResponses", paymentResponses);

  useEffect(() => {
    let isPreviousDiabled = carouselActiveIndex <= 0 ? true : false;
    const allImages =
      chatHistory[props.activeChatRoom]
        ?.filter((i) => i.images !== "")
        .map((e) => e.images) || [];
    let isNextDiabled =
      carouselActiveIndex >= allImages.length - 1 ? true : false;

    setPreButtonDisable(isPreviousDiabled);
    setNextButtonDisable(isNextDiabled);
  }, [carouselActiveIndex]);

  // useEffect(() => {
  //     // Check if carouselActiveIndex is at the beginning or end to disable/enable buttons.
  //     setPreButtonDisable(carouselActiveIndex <= 0);
  //     setNextButtonDisable(carouselActiveIndex >= (groupData?.image?.length || 0) - 1);
  // }, [carouselActiveIndex, groupData]);

  // const [showPicker, setShowPicker] = useState(false);

  const scrollTarget = useRef(null);
  // const videoRef = useRef();
  /**
   * Socket connection and reconnection logic handeled here
   */

  const updateChatTimes = (arr) => {
    if (arr.length > 0) {
      arr.map((item) => {
        const now = moment(new Date());
        const end = moment(item.createdOn);
        const duration = moment.duration(now.diff(end));
        const seconds = duration.asSeconds();

        let secondsValue = Math.floor(seconds);
        item["displayTime"] =
          secondsValue > 1 ? secondsValue + " second ago" : "Second ago";

        // minutes
        if (seconds > 60) {
          let minutesValue = Math.floor(seconds / 60);
          item["displayTime"] =
            minutesValue > 1 ? minutesValue + " minutes ago" : "Minute ago";
        }

        // hours
        if (seconds > 60 * 60) {
          let hoursValue = Math.floor(seconds / (60 * 60));
          item["displayTime"] =
            hoursValue > 1 ? hoursValue + " hours ago" : "An hour ago";
        }

        // days
        if (seconds > 60 * 60 * 24) {
          let dayValue = Math.floor(seconds / (60 * 60 * 24));
          item["displayTime"] =
            dayValue > 1 ? dayValue + " days ago" : "Day ago";
        }
        return item;
      });
    }
    return arr;
  };

  const addAttachHandle = () => {
    setAddAttachActive(!addAttachActive);
  };

  if (addAttachActive === true) {
    const concernedElement = document.querySelector(
      ".ir-ws-add-attachment-main-container"
    );
    document.addEventListener("mousedown", (event) => {
      if (!concernedElement.contains(event.target)) {
        setAddAttachActive(false);
      }
    });
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      scrollTarget?.current?.scrollIntoView({ behavior: "instant" });
    }, 400);
  };

  const showShareModal = () => {
    setShareModal(true);
  };
  const shareCloseModalHandle = () => {
    setShareModal(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // build chat object
    const processedChatMessage = chatMessage.replace(/'/g, "''");
    const obj = {
      conversationId: "",
      userId: profile?.id || 0,
      message: processedChatMessage,
      groupId: props.activeChatRoom,
      images: "",
      videos: "",
      documents: "",
      status: "active",
    };
    console.log("At ChatContent socket", props.socket);
    if (profile?.id) {
      await props.socket.emit("chat_message", obj);
      setChatMessage("");
      setTextareaHeight(50);
      // setTimeout(() => props.getChatHistory(props.activeChatRoom), 1000)
    }
  };

  const handleChatSendMessage = (event) => {
    const newChatMessage = event.target.value;
    setChatMessage(newChatMessage);
    if (newChatMessage === "") {
      // Reset textarea height to the default (50px) when content is empty
      setTextareaHeight(50);
    } else {
      // Calculate and update the textarea's height
      const newHeight = Math.min(100, Math.max(50, event.target.scrollHeight));
      setTextareaHeight(newHeight);
    }
    // const newHeight = Math.min(100, Math.max(50, event.target.scrollHeight));
    // setTextareaHeight(newHeight);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      // Prevent the default Enter key behavior (usually creating a new line)
      event.preventDefault();
      // Trigger form submission
      handleSubmit(event);
    }
  };

  const camCloseModalHandle = () => {
    setOpenCamModal(false);
  };

  const openCanvasModal = () => {
    setAddAttachActive(false);
    setOpenWhiteBoardModal(true);
  };

  const whiteBoardCloseModalHandle = () => {
    setOpenWhiteBoardModal(false);
  };

  const imageCloseModalHandle = () => {
    setOpenImageModal(false);
    setConvertedVideoURL(null);
    setProgress(0);
    setSelectedImage([] || {});
  };

  const attachDocsCloseModalHandle = () => {
    setOpenAttachDocsModal(false);
  };

  // const onEmojiClick = (event, emojiObject) => {
  //     setChatMessage((prevInput) => prevInput + emojiObject.emoji);
  //     setShowPicker(false);
  // }; sendAttachDocsHandle

  // const sendAttachImageHandle = (event) => {
  //   setAddAttachActive(false);
  //   if (event.target.files && event.target.files.length > 0) {
  //     setSelectedImage(event.target.files);
  //     setUploaded(null);
  //     setOpenImageModal(true);
  //     setDisableUploadBtn(false);
  //   }
  // };

  // const sendAttachImageHandle = async (event) => {
  //   setAddAttachActive(false);
  //   if (event.target.files && event.target.files.length > 0) {
  //       setSelectedImage(event.target.files); // Set selected files directly
  //       setUploaded(null);
  //       for (let i = 0; i < event.target.files.length; i++) {
  //           const file = event.target.files[i]; // Get each file
  //           if (file.type.startsWith('video/')) {
  //             setOpenImageModal(true);
  //               const ffmpeg = new FFmpeg();
  //               await ffmpeg.load();
  //               await ffmpeg.writeFile('input.mp4', await fetchFile(file));
  //               await ffmpeg.exec(['-i', 'input.mp4', '-b:v', '500k', 'output.mp4']); // Set the desired bitrate here (500k in this example)
  //               const data = await ffmpeg.readFile('output.mp4');
  //               const convertedBlob = new Blob([data.buffer], { type: 'video/mp4' });
  //               setConvertedVideoURL(URL.createObjectURL(convertedBlob));
  //               // setSelectedImage(URL.createObjectURL(convertedBlob))
  //               setDisableUploadBtn(false);
  //               return; // Convert the first video only
  //           }
  //       }

  //       setOpenImageModal(true);
  //       setDisableUploadBtn(false);
  //   }
  // };

  const sendAttachImageHandle = async (event) => {
    setAddAttachActive(false);
    if (event.target.files && event.target.files.length > 0) {
      setSelectedImage(event.target.files); // Set selected files directly
      setUploaded(null);

      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i]; // Get each file

        if (file.type.startsWith("video/")) {
          setOpenImageModal(true);
          setProgress(1);
          const ffmpeg = new FFmpeg();

          await ffmpeg.load();
          setProgress(25);
          await ffmpeg.writeFile("input.mp4", await fetchFile(file));
          setProgress(50);
          await ffmpeg.exec(["-i", "input.mp4", "-b:v", "500k", "output.mp4"]); // Set the desired bitrate here (500k in this example)
          setProgress(75);
          const data = await ffmpeg.readFile("output.mp4");
          setProgress(100);
          const convertedBlob = new Blob([data.buffer], { type: "video/mp4" });
          setConvertedVideoURL(URL.createObjectURL(convertedBlob));
          // setSelectedImage(URL.createObjectURL(convertedBlob))
          setDisableUploadBtn(false);
          return; // Convert the first video only
        }
      }

      setOpenImageModal(true);
      setDisableUploadBtn(false);
    }
  };

  const sendAttachDocsHandle = (event) => {
    setAddAttachActive(false);
    if (event.target.files && event.target.files.length > 0) {
      setSelectedDoc(event.target.files[0]);
      setOpenAttachDocsModal(true);
    }
  };

  const uploadDocument = async (e) => {
    let fd = new FormData();
    fd.append("file", selectedDoc);
    const response = await axios({
      url: "uploadFile",
      method: "post",
      data: fd,
      withCredentials: false,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      },
    });

    if (response.data.data) {
      const obj = {
        conversationId: "",
        userId: profile?.id || 0,
        message: "",
        images: "",
        videos: "",
        documents: response.data.data[0],
        groupId: props.activeChatRoom,
        status: "active",
      };
      if (profile?.id) {
        props.socket.emit("chat_message", obj);
        setChatMessage("");
        setTextareaHeight(50);
        setSelectedDoc([]);
        // setTimeout(() => props.getChatHistory(props.activeChatRoom), 1000)
      }
      setOpenAttachDocsModal(false);
    } else {
      /// todo : handle error here....
      console.log("Unsupported file format!!!");
    }
  };

  const whiteBoardSubmit = async (file) => {
    let fd = new FormData();
    fd.append("uploadFiles", file);

    const response = await axios({
      url: "uploadFile",
      method: "post",
      data: fd,
      withCredentials: false,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      },
    });

    if (response.data.data) {
      const obj = {
        conversationId: "",
        userId: profile?.id || 0,
        message: "",
        images: response.data.data[0],
        videos: "",
        groupId: props.activeChatRoom,
        status: "active",
      };
      if (profile?.id) {
        props.socket.emit("chat_message", obj);
        setChatMessage("");
        setTextareaHeight(50);
        setSelectedImage([]);

        // setTimeout(() => props.getChatHistory(props.activeChatRoom), 1000)
      }
    } else {
      /// todo : handle error here....
      console.log("Unsupported file format!!!");
    }
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const onImageSave = async (editedImageObject, designState) => {
    const imageCropFile = await dataURLtoFile(
      editedImageObject.imageBase64,
      editedImageObject.fullName
    );
    console.log("saved", imageCropFile);
    submitImage(imageCropFile);
    return true;
  };

  // const submitImage = async (file = null) => {
  //   // console.log("Selected image", selectedImage)
  //   let fd = new FormData();
  //   if (file == null) {
  //     if (selectedImage.length > 0) {
  //       for (let i = 0; i < selectedImage.length; i++) {
  //         fd.append("uploadFiles", selectedImage[i]);
  //       }
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     fd.append("uploadFiles", file);
  //   }

  //   setUploaded(null);

  //   const response = await axios({
  //     url: "uploadFile",
  //     method: "post",
  //     data: fd,
  //     withCredentials: false,
  //     headers: {
  //       "Content-Type": "multiplart/form-data",
  //       Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
  //     },
  //     onUploadProgress: (data) => {
  //       setUploaded(Math.round((data.loaded / data.total) * 100));
  //       setDisableUploadBtn(true);
  //     },
  //   });

  //   if (response.data.data) {
  //     const obj = {
  //       conversationId: "",
  //       userId: profile?.id || 0,
  //       message: "",
  //       images: response.data.data[0],
  //       videos: "",
  //       groupId: props.activeChatRoom,
  //       status: "active",
  //     };
  //     if (profile?.id) {
  //       props.socket.emit("chat_message", obj);
  //       setChatMessage("");
  //       setTextareaHeight(50);
  //       imageCloseModalHandle();
  //       setSelectedImage([]);
  //       setConvertedVideoURL(null)
  //       // setTimeout(() => props.getChatHistory(props.activeChatRoom), 1000)
  //     }
  //   } else {
  //     /// todo : handle error here....
  //     console.log("Unsupported file format!!!");
  //   }
  // };
  //   const submitImage = async (file = null) => {
  //     console.log("Selected image", selectedImage)
  //     let fd = new FormData();

  //     // Check if convertedVideoURL is set and file is a video
  //     if (convertedVideoURL && file && file.type.startsWith('video/')) {
  //         // Append the converted video to FormData
  //         console.log("converted files");
  //         fd.append("uploadFiles", convertedVideoURL);
  //     } else {
  //         if (file == null) {
  //             if (selectedImage.length > 0) {
  //                 for (let i = 0; i < selectedImage.length; i++) {
  //                   console.log("Image is being sent to the api")
  //                     fd.append("uploadFiles", selectedImage[i]);
  //                 }
  //             } else {
  //                 return false;
  //             }
  //         } else {
  //           console.log("File is being sent to the api")
  //             fd.append("uploadFiles", file);
  //         }
  //     }

  //     setUploaded(null);

  //     const response = await axios({
  //         url: "uploadFile",
  //         method: "post",
  //         data: fd,
  //         withCredentials: false,
  //         headers: {
  //             "Content-Type": "multiplart/form-data",
  //             Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
  //         },
  //         onUploadProgress: (data) => {
  //             setUploaded(Math.round((data.loaded / data.total) * 100));
  //             setDisableUploadBtn(true);
  //         },
  //     });

  //     if (response.data.data) {
  //         const obj = {
  //             conversationId: "",
  //             userId: profile?.id || 0,
  //             message: "",
  //             images: response.data.data[0],
  //             videos: "", // Modify this to include converted video URL if available
  //             groupId: props.activeChatRoom,
  //             status: "active",
  //         };
  //         if (profile?.id) {
  //             props.socket.emit("chat_message", obj);
  //             setChatMessage("");
  //             setTextareaHeight(50);
  //             imageCloseModalHandle();
  //             setSelectedImage([]);
  //             setConvertedVideoURL(null);
  //             // setTimeout(() => props.getChatHistory(props.activeChatRoom), 1000)
  //         }
  //     } else {
  //         /// todo : handle error here....
  //         console.log("Unsupported file format!!!");
  //     }
  // };

  const submitImage = async () => {
    let fd = new FormData();

    // Check if convertedVideoURL is set
    if (convertedVideoURL) {
      console.log("converetd files");
      const blob = await fetch(convertedVideoURL).then((res) => res.blob());

      // Append converted video blob with a file name to FormData
      fd.append("uploadFiles", blob, "converted_video.mp4");
    } else if (selectedImage.length > 0) {
      // Append selected image files
      for (let i = 0; i < selectedImage.length; i++) {
        console.log("Selected image file");
        fd.append("uploadFiles", selectedImage[i]);
      }
    } else {
      return false; // No files to upload
    }

    setUploaded(null);

    const response = await axios({
      url: "uploadFile",
      method: "post",
      data: fd,
      withCredentials: false,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      },
      onUploadProgress: (data) => {
        setUploaded(Math.round((data.loaded / data.total) * 100));
        setDisableUploadBtn(true);
      },
    });
    console.log(response);
    if (response.data.data) {
      // Construct message object with uploaded file URLs
      const obj = {
        conversationId: "",
        userId: profile?.id || 0,
        message: "",
        images: response.data.data[0], // Assuming API returns image URLs
        videos: "", // Modify this to include converted video URL if available
        groupId: props.activeChatRoom,
        status: "active",
      };
      if (profile?.id) {
        props.socket.emit("chat_message", obj);
        setChatMessage("");
        setTextareaHeight(50);
        imageCloseModalHandle();
        setSelectedImage([]);
        setConvertedVideoURL(null);
        setProgress(0);
      }
    } else {
      console.log("Unsupported file format!!!");
    }
  };

  const getCameraPermission = async () => {
    setRecordedVideo(null);
    if ("MediaRecorder" in window) {
      try {
        const videoConstraints = {
          audio: false,
          video: true,
        };
        const audioConstraints = { audio: true };
        // create audio and video streams separately
        const audioStream = await navigator.mediaDevices.getUserMedia(
          audioConstraints
        );
        const videoStream = await navigator.mediaDevices.getUserMedia(
          videoConstraints
        );
        console.log(videoStream);

        setPermission(true);
        //combine both audio and video streams
        const combinedStream = new MediaStream([
          ...videoStream.getVideoTracks(),
          ...audioStream.getAudioTracks(),
        ]);
        setStream(combinedStream);
        //set videostream to live feed player
        let video = liveVideoFeedRef.current;
        console.log("get Permissions", video);
        video.srcObject = videoStream;
        video.play();
      } catch (err) {
        console.log(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const startRecording = async () => {
    setRecordingStatus("recording");
    navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then((stream) => {
        let video = liveVideoFeedRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.log(err);
      });
    const media = new MediaRecorder(stream, { mimeType });
    mediaRecorder.current = media;
    mediaRecorder.current.start();
    let localVideoChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localVideoChunks.push(event.data);
    };
    setVideoChunks(localVideoChunks);
  };

  const stopRecording = () => {
    // video.srcObject = null;

    setPermission(false);
    setRecordingStatus("inactive");
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      const videoBlob = new Blob(videoChunks, { type: mimeType });
      const videoUrl = URL.createObjectURL(videoBlob);
      setRecordedVideo(videoUrl);
      setVideoChunks([]);
    };

    navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then((stream) => {
        let video = liveVideoFeedRef.current;
        console.log("inside navigator", stream);
        console.log("video is", video);
        // video.srcObject = stream;
        const tracks = stream.getTracks();
        for (var i = 0; i < tracks.length; i++) {
          var track = tracks[i];
          track.stop();
        }
        video.srcObject = null;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitVideo = async () => {
    console.log("recordedVideo", recordedVideo);
    const mediaBlob = await fetch(recordedVideo).then((response) =>
      response.blob()
    );
    const videoFile = new File([mediaBlob], "chat_video_recording.mp4", {
      type: "video/mp4",
    });
    console.log("Video File", videoFile);

    let fd = new FormData();
    fd.append("uploadFiles", videoFile);

    const response = await axios({
      url: "uploadFile",
      method: "post",
      data: fd,
      withCredentials: false,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      },
    });

    if (response.data.data) {
      const obj = {
        conversationId: "",
        userId: profile?.id || 0,
        message: "",
        images: "",
        videos: response.data.data[0],
        groupId: props.activeChatRoom,
        status: "active",
      };
      if (profile?.id) {
        props.socket.emit("chat_message", obj);
        setChatMessage("");
        setTextareaHeight(50);
        setOpenCamModal(false);
        setRecordedVideo([]);
        // setTimeout(() => props.getChatHistory(props.activeChatRoom), 1000)
      }
    } else {
      /// todo : handle error here....
      console.log("Unsupported file format!!!");
    }
  };

  const imageClicked = (popImg) => {
    setOpenImageGalleryModal(true);
    const allImages = chatHistory[props.activeChatRoom]
      .filter((i) => i.images !== "")
      .map((e) => e.images);
    console.log("allImages", allImages);
    let activeIndex = allImages.findIndex((e) => e === popImg);
    setCarouselActiveIndex(activeIndex);
    console.log("pop up ActiveIndex is", popImg);
  };

  const closeImageGalleryModal = () => {
    setOpenImageGalleryModal(false);
  };

  const onCarouselMenuItemclick = (carouselActiveIndex) => {
    setCarouselActiveIndex(carouselActiveIndex);
  };

  const leftCarouselIndex = () => {
    if (carouselActiveIndex > 0) {
      setCarouselActiveIndex(carouselActiveIndex - 1);
    }
  };
  const rightCarouselIndex = () => {
    const allImages = chatHistory[props.activeChatRoom]
      .filter((i) => i.images !== "")
      .map((e) => e.images);
    if (carouselActiveIndex < allImages.length - 1) {
      setCarouselActiveIndex(carouselActiveIndex + 1);
      // setNextButtonDisable(true);
    }
  };

  useEffect(() => {
    if (!connection) props.socket.connect();
    if (!connection && ioState) {
      props.socket.on("disconnect", (reason) => {
        props.socket.connect();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  useEffect(() => {
    scrollToBottom();
    props.socket.on("chatRoom_messages", (res) => {
      scrollToBottom();
    });
  }, []);

  /**
   * This code will handle the scorll if chat history get updated scrolls to bottome of chat window
   */
  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  useEffect(() => {
    if (props.isChatLoading) {
      setTimeout(() => {
        props.updateChatLoading(false);
      }, 5000);
    }
  }, [props.isChatLoading]);

  //   useEffect(() => {
  //     const activeRoomDetails = groups.find((e) => e.id === props.activeChatRoom);
  //     console.log("activeRoomDetails", groups, activeRoomDetails);
  //     setActiveRooms(activeRoomDetails);
  //     setIsArchived(activeRoomDetails?.archive);
  //     setActiveCaseName(activeRoomDetails?.name);
  //     scrollToBottom();
  //     setChatMessage("");
  //     setTextareaHeight(50);
  //     props.updateChatLoading(true);
  //   }, [props.activeChatRoom]);
  //   const activeRoomDetails = groups.find((e) => e.id === props.activeChatRoom);
  //   console.log(activeRoomDetails);

  useEffect(() => {
    console.log("groups:", groups);
    console.log("props.activeChatRoom:", props.activeChatRoom);
    if (groups.length !== 0 && props.activeChatRoom !== null) {
      const activeRoomDetails = groups.find(
        (room) => room.id === parseInt(props.activeChatRoom)
      );

      console.log("activeRoomDetails", activeRoomDetails);
      if (activeRoomDetails !== undefined) {
        setActiveRooms(activeRoomDetails);
        setIsArchived(activeRoomDetails.archive);
        setActiveCaseName(activeRoomDetails.name);
        scrollToBottom();
        setChatMessage("");
        setTextareaHeight(50);
        props.updateChatLoading(true);
      }
    }
  }, [props.activeChatRoom, groups]);

  useEffect(() => {
    if (routeParams?.id && routeParams?.id !== ("" || undefined || null)) {
      let id = parseInt(routeParams.id);
      props.updateActiveChat(id);
    }
  }, [routeParams]);

  // useEffect(() => {
  //     if (chatMessage?.length < 4) {
  //         console.log("Socket", props.socket);
  //         setIsTyping(true)
  //         props.socket.emit("typing", { user: props.profile.username, groupId: props.activeChatRoom, message: chatMessage });
  //     }
  //     if (chatMessage?.length === 0) setIsTyping(false)
  // }, [chatMessage])

  // useEffect(() => {
  //     if (Object.keys(chatStore.typing).length > 0 && Object.keys(chatStore.typing[props.activeChatRoom]).length > 0) {
  //         // setIsTyping(true) .join(", ") + " is typing..."
  //         setIsTyping(true)
  //         setTypingMessage({ [props.activeChatRoom]: chatStore.typing[props.activeChatRoom] })
  //     } else {
  //         setIsTyping(false)
  //         setTypingMessage({ [props.activeChatRoom]: "" })
  //     }
  // }, [chatStore.typing]);

  useEffect(() => {
    props.socket.on("remote_typing", (data) => {
      let typingList = { ...chatStore.typing };
      if (
        Object.keys(typingList).length >= 0 &&
        !Object.keys(typingList).includes(String(data.groupId))
      ) {
        typingList[data.groupId] = [];
      }
      let index = typingList[data.groupId].indexOf((e) => e === data.user);
      if (index > -1) {
        typingList[data.groupId].push(data.user);
      }
      console.log("Typing Started", typingList);
      setStore({
        ...store,
        chatStore: {
          ...chatStore,
          typing: { ...typingList },
        },
      });
    });

    props.socket.on("chatRoom_messages", (data) => {
      console.log("ChatRoom_Message", data);
      props.updateChatLoading(false);
      if (data.length > 0) {
        let roomId = data[0].groupId;
        let chatHistoryData = { ...chatStore.chatHistory };
        if (!Object.keys(chatHistoryData).includes(String(roomId))) {
          chatHistoryData[roomId] = [];
        }
        chatHistoryData[roomId] = [...data];
        console.log("Socket Message Data", chatHistoryData);
        setStore({
          ...store,
          chatStore: {
            ...chatStore,
            chatHistory: { ...chatHistoryData },
          },
        });
      }
    });

    props.socket.on("remote_stop_typing", (data) => {
      let typingList = { ...chatStore.typing };
      if (
        Object.keys(typingList).length > 0 &&
        Object.keys(typingList).includes(String(data.groupId))
      ) {
        let index = typingList[data.groupId].findIndex((e) => e === data.user);
        if (index !== -1) {
          typingList[data.groupId].splice(index, 1);
          if (typingList[data.groupId].length === 0)
            delete typingList[data.groupId];
          setStore({
            ...store,
            chatStore: {
              ...chatStore,
              typing: { ...typingList },
            },
          });
        }
      }
      console.log("Typing Stopped", chatStore.typing);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.socket]);

  // React.useEffect(() => {
  //     const storedPaymentResponses = JSON.parse(localStorage.getItem('paymentResponses'));
  //     if (storedPaymentResponses) {
  //       setPaymentResponses(storedPaymentResponses);
  //     }
  //   }, [setPaymentResponses]);

  const handleBuyNowClick = async (room) => {
    const headers = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
        "Content-Type": "application/json",
      },
    };

    try {
      const paymentLinkForProduct = await axios.get(
        `${BaseURL}/payment/paymentLinks`,
        headers
      );
      const matchingProduct = paymentLinkForProduct.data.data.find(
        (product) => product.description === room.name
      );
      const matchingProductlink = matchingProduct.payment_url;
      if (paymentLinkForProduct) {
        window.location.assign(matchingProductlink);
      } else {
        console.log("there is not paymentLinkForProduct");
      }
    } catch (error) {
      // Handle any errors that occurred during the request
      console.error("Error fetching data:", error.message);
    }
  };

  const handleDeleteClick = (room) => {
    // console.log(room)
    const requestData = {
      room_id: room?.groupId,
      conversation_id: room?.id,
    };

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      "Content-Type": "application/json",
    };

    axios
      .post(`${BaseURL}/chat/history/delete`, requestData, { headers })
      .then((response) => {
        console.log("Product deleted:", response?.data);
        if (response?.data?.status == 200) {
          let index = chatHistory[room?.groupId].findIndex(
            (item) => item.id == room.id
          );
          chatHistory[room?.groupId].splice(index, 1);
          setStore({
            ...store,
            chatStore: {
              ...chatStore,
              chatHistory: { ...chatHistory },
            },
          });
          handleModalToggle();
        }
      })
      .catch((error) => {
        console.error("Error deleting product:", error);
      });
  };

  const handleModalToggle = (item) => {
    if (item) {
      setShowModal(!showModal);
      setItemToDelete(item);
    } else {
      setShowModal(!showModal);
      setItemToDelete(null);
    }
  };
  const shareUrl =
    window.location.href + "/" + JSON.stringify(props.activeChatRoom);

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(shareUrl);
    setTooltipText(true);
    setTimeout(() => setTooltipText(false), 2000);
  };
  return (
    <div className="ir-chat-content-messages-main-container w-100 overflow-hidden position-relative">
      {onlyAchive ? (
        <div className="buyNowBtnParent">
          <p>
            This room is Archived. To access this room please purchase by
            clicking below Buy Now button
          </p>
          <button
            className="buyNowBtn"
            onClick={() => handleBuyNowClick(activeRooms)}
            disabled={activeRooms?.paymentInfo?.paymentLink === null}
          >
            Buy Now
          </button>
        </div>
      ) : (
        <>
          {props.isChatLoading && (
            <div className="loadingContainer">
              <div className="loadingIcon">
                <img src={LoadingImage} alt="loading..." />
              </div>
            </div>
          )}

          <div className="ir-chat-topbar">
            <div className="align-tem-center d-flex ir-chat-top-header">
              <h2>{activeCaseName}</h2>
              <div className="ir-chat-top-head-elements" title="Share">
                {/* <div className="ir-chat-top-head-invite ir-chat-top-head-heart" onClick={addToFavourites}>
                                    <i className="bi bi-heart"></i>
                                </div> */}
                <div
                  className="ir-chat-top-head-invite"
                  onClick={showShareModal}
                >
                  <i className="bi bi-share"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="ir-chat-conversation p-3 p-lg-4 positin-relative">
            <div className="simplebar-content">
              {/* <div>{JSON.stringify(chatStore.chatHistory)}</div> */}
              <ul className="list-unstyled chat-conversation-list">
                {chatHistory?.[props.activeChatRoom] &&
                  chatHistory[props.activeChatRoom].length > 0 &&
                  updateChatTimes(chatHistory[props.activeChatRoom])
                    .sort(
                      (a, b) => new Date(a.createdOn - new Date(b.createdOn))
                    )
                    .map((item, i) => {
                      let str = item?.userName;
                      let matches = str?.match(/\b(\w)/g); // ['J','S','O','N']
                      let profileDp = matches?.join(""); // JSON
                      if (
                        item?.images !== "" &&
                        item?.images !== undefined &&
                        typeof item?.images !== "string"
                      ) {
                        item.images = JSON.parse(item.images);
                      }
                      return (
                        <li
                          className={
                            "chat-list " +
                            (parseInt(profile.id) === parseInt(item.userId) &&
                              "chat-list-outgoing")
                          }
                          key={i}
                        >
                          <div className="conversation-list">
                            <div className="ir-chat-profile-dp-container">
                              {item.userImage === "" ? (
                                <p className="ir-chat-profile-dp">
                                  {profileDp}
                                </p>
                              ) : (
                                <img
                                  className="chat-avatar"
                                  src={item?.userImage || NoImage}
                                  alt="profile pic"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = NoImage;
                                  }}
                                />
                              )}
                            </div>
                            <div className="ir-chat-conv-info-container">
                              <div className="ir-chat-conv-info-inner-container">
                                <div className="ir-chat-conv-user-details">
                                  <div className="ir-chat-conv-name">
                                    {item.userName || "Deleted User"}
                                  </div>
                                  <div className="ir-chat-conv-time">
                                    {item?.displayTime || item.createdOn}
                                    {profile?.role === "admin" && (
                                      <span
                                        className="ir-chat-content-delete-icon material-symbols-outlined"
                                        onClick={() => handleModalToggle(item)}
                                      >
                                        delete
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="user-chat-content">
                                  <div className="ctext-content">
                                    {" "}
                                    <AssetRender
                                      imageClicked={imageClicked}
                                      data={item}
                                      options={{
                                        videoControl: false,
                                        height: 80,
                                      }}
                                    />{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                <li>
                  <div ref={scrollTarget} className="ir-scroll-target"></div>
                </li>
              </ul>
            </div>
          </div>
          <div className="ir-chat-conversation-send-container">
            <form
              className="app-content-footer d-flex gap-3"
              onSubmit={handleSubmit}
            >
              <div className="input-wrapper">
                <div className={`app-content-footer-buttons`}>
                  <div
                    data-bs-toggle="tooltip"
                    className={`app-footer-plus`}
                    onClick={addAttachHandle}
                    title="Upload"
                  >
                    <i className="bi bi-paperclip"></i>
                  </div>
                </div>
                {/* {addAttachActive && ( */}
                <div
                  className={`ir-ws-add-attachment-main-container ${
                    addAttachActive ? "addActiveOpen" : ""
                  } `}
                >
                  <div className="add-attach-menu-item">
                    <label htmlFor="sendAattachDocs">
                      <i className="bi bi-file-earmark"></i>
                      <input
                        id="sendAattachDocs"
                        type="file"
                        accept="*"
                        onChange={sendAttachDocsHandle}
                      />
                      Documents
                    </label>
                  </div>
                  <div className="add-attach-menu-item">
                    <label htmlFor="sendAttachImage">
                      <i className="bi bi-image"></i>
                      <input
                        id="sendAttachImage"
                        type="file"
                        onChange={sendAttachImageHandle}
                        onClick={(e) => {
                          e.target.value = null;
                        }}
                      />
                      Photos & Videos
                    </label>
                  </div>
                  <div
                    className="add-attach-menu-item"
                    onClick={() => setOpenCamModal(true)}
                  >
                    <i className="bi bi-camera-video"></i> Record Video
                  </div>
                  <div
                    className="add-attach-menu-item"
                    onClick={openCanvasModal}
                  >
                    <i className="bi bi-easel-fill"></i> Whiteboard
                  </div>
                </div>
                <textarea
                  style={textareaStyle}
                  className="form-control chat-text-area"
                  placeholder="Write something and send..."
                  value={chatMessage || ""}
                  onChange={handleChatSendMessage}
                  onKeyPress={handleEnterKeyPress}
                ></textarea>
                <button
                  className="btn bg-app-default-color app-content-footer-send"
                  disabled={!chatMessage}
                  type="submit"
                >
                  <i className="bi bi-send"></i>
                </button>
              </div>
            </form>
          </div>
          {shareModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container ir-ws-share-main-container">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
                <div className="iir-ws-signup-content-container">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={shareCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      Share Case
                    </h3>
                  </div>
                  <div className="ir-share-modal-inside-content">
                    <p>Invite your friends through social network</p>
                    <div className="ir-share-container">
                      <div className="ir-share-icon-container ir-share-icon-container-hover">
                        <FacebookShareButton
                          url={shareUrl}
                          quote={"Dummy text!"}
                          hashtag="#ir4u"
                        >
                          <FacebookIcon size={50} round />
                        </FacebookShareButton>
                      </div>
                      <div className="ir-share-icon-container ir-share-icon-container-hover">
                        <WhatsappShareButton
                          url={shareUrl}
                          quote={"Dummy text!"}
                        >
                          <WhatsappIcon size={50} round />
                        </WhatsappShareButton>
                      </div>
                      <div className="ir-share-icon-container ir-share-icon-container-hover">
                        {!tooltipText ? (
                          <div
                            className="ir-share-icon-copy-link ir-share-icon-link"
                            onClick={handleCopyLink}
                            title="Copy Link"
                          >
                            <i className="bi bi-link-45deg"></i>
                          </div>
                        ) : (
                          <div
                            className="ir-share-icon-copy-link ir-share-icon-link"
                            title="Link Copied"
                          >
                            <i class="bi bi-check-lg"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {openCamModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
                <div className="ir-ws-signup-content-container">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={camCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      Record Video
                    </h3>
                  </div>
                  <div className="ir-ws-signup-form-container ">
                    <div className="ir-share-container">
                      <div className="audio-controls">
                        <video
                          className="ir-ws-width-100 ir-ws-height-100-percent ir-ws-video-upload"
                          style={{
                            display:
                              recordingStatus === "recording"
                                ? "block"
                                : "none",
                          }}
                          ref={liveVideoFeedRef}
                        ></video>
                      </div>
                      {recordedVideo ? (
                        <div className="audio-player">
                          <video
                            className="ir-ws-width-100 ir-ws-height-100-percent ir-ws-video-upload"
                            src={recordedVideo}
                            autoPlay
                            controls
                          ></video>
                        </div>
                      ) : null}
                    </div>
                    <div className="ir-modal-btn-container record-btn-container">
                      {!permission && !recordedVideo ? (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={getCameraPermission}
                          >
                            <span>Get Permissions</span>
                          </button>
                        </div>
                      ) : null}
                      {permission && recordingStatus === "inactive" ? (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={startRecording}
                          >
                            <span>Start recording</span>
                          </button>
                        </div>
                      ) : null}
                      {recordingStatus === "recording" ? (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={stopRecording}
                          >
                            <span>Stop recording</span>
                          </button>
                        </div>
                      ) : null}
                      {recordedVideo ? (
                        <div className="ir-ws-text-center ir-ws-default-btn-container">
                          <button
                            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                            onClick={submitVideo}
                          >
                            <span>Upload Recording</span>
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {openWhiteBoardModal && (
            <div className="ir-whiteboard-modal">
              <div className="ir-whiteboard-modal-container">
                <div className="ir-whiteboard-modal-content">
                  <div className="ir-form-title">
                    <button onClick={whiteBoardCloseModalHandle}>
                      <span className="material-symbols-outlined">
                        arrow_back
                      </span>
                    </button>
                    <h2>Whiteboard</h2>
                  </div>
                  <div className="ir-whiteboard-modal-inside-content">
                    <div className="ir-share-container">
                      <WhiteBoard
                        isUpload={isUpload}
                        updateIsUpload={(data) => setIsUpload(data)}
                        uploadFile={(file) => whiteBoardSubmit(file)}
                        closeModel={setOpenWhiteBoardModal}
                        submitImage={submitImage}
                      />
                      <div className="ir-modal-btn-container ir-wbd-upload-btn-container">
                        <button
                          className="btn btn-submit"
                          onClick={() => {
                            setIsUpload(true);
                          }}
                        >
                          <span className="material-symbols-outlined">
                            done
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {openImageModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container ir-ws-chat-upload-img-modal-container">
                <div className="iks-mint-modal-content">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={imageCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-title-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      {progress >= 1 ? "Upload Video" : "Upload Image"}
                    </h3>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    {/* <p className="ir-ws-app-color ir-ws-font-size-xs ir-ws-text-center">
                                        {selectedImage.length > 0 && (selectedImage[0].name || "NO Image")}
                                    </p> */}
                    <div className="ir-share-container">
                      {selectedImage[0].name.match(
                        /\.(jpg|jpeg|png|gif|bmp|tiff|tif|webp)$/
                      ) ? (
                        <FilerobotImageEditor
                          source={URL.createObjectURL(selectedImage[0])}
                          onSave={onImageSave}
                          onBeforeSave={(savedImageData, save) => {
                            savedImageData.name = selectedImage[0].name;
                            return false;
                          }}
                          Text={{
                            text: "Double click to add text ",
                            fontSize: 22,
                          }}
                          Pen={{
                            selectAnnotationAfterDrawing: true,
                          }}
                          tabsIds={[TABS.ANNOTATE, TABS.ADJUST]}
                          Rotate={{ angle: 90, componentType: "slider" }}
                          Crop={{
                            noPresets: true,
                            ratio: "custom",
                          }}
                        />
                      ) : (
                        <>
                          {convertedVideoURL === null && (
                            <Progressbar progress={progress} />
                          )}
                          {convertedVideoURL && (
                            <video
                              className="ir-ws-width-100 ir-ws-height-100-percent ir-ws-video-upload"
                              height={80}
                              loop={false}
                              controls
                            >
                              <source src={convertedVideoURL} />
                            </video>
                          )}
                          {uploaded && (
                            <div className="progress mt-2">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                aria-valuenow={uploaded}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{ width: `${uploaded}%` }}
                              >
                                {`${uploaded}%`}
                              </div>
                            </div>
                          )}
                          {convertedVideoURL !== null && (
                            <div className="ir-ws-text-center ir-ws-default-btn-container">
                              <button
                                className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                                disabled={disableUploadBtn}
                                onClick={(e) => submitImage()}
                              >
                                <span>Upload</span>
                              </button>
                            </div>
                          )}
                          {/* <div className="ir-modal-btn-container record-btn-container">
                                                    <button disabled={disableUploadBtn}  className="btn btn-submit" onClick={(e) => submitImage()}>Upload</button>
                                                </div> */}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {openAttachDocsModal && (
            <div className="ir-ws-position-fixed ir-ws-sign-popup-container ir-attach-document-modal">
              <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
                <div className="ir-ws-signup-content-container">
                  <div
                    className="ir-ws-position-absolute ir-ws-signup-close-container"
                    onClick={attachDocsCloseModalHandle}
                  >
                    <i className="bi bi-x-lg"></i>
                  </div>
                  <div className="ir-ws-signup-content-inner-container">
                    <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
                      Upload Documents
                    </h3>
                  </div>
                  <div className="ir-share-modal-inside-content">
                    <p className="ir-ws-app-color ir-ws-font-size-xs">
                      {selectedDoc.name}
                    </p>
                    <div className="ir-ws-text-center ir-share-container">
                      <img
                        className="ir-ws-block ir-ws-margin-o-auto ir-modal-chat-preview"
                        src={require("../../../assets/images/chat-images/file-icon.png")}
                        alt="file-preview"
                      />
                      <span className="ir-ws-block ir-ws-font-size-xxs ir-chat-modal-file-size">
                        {selectedDoc.size / 1000} kB
                      </span>
                    </div>
                    <div className="ir-ws-text-center ir-ws-default-btn-container">
                      <button
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn"
                        onClick={uploadDocument}
                      >
                        <span>Upload</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {openImageGalleryModal && (
            <div className="iks-mint-cust-modal ir-chat-inside-modal-container ir-image-preview-main-modal">
              <div className="iks-mint-modal-container">
                <div className="ir-thumb-image-inner-container">
                  <div className="ir-image-large-thumb-title-container">
                    <h3>{activeCaseName}</h3>
                    <div className="ir-image-head-thumb-right">
                      <button onClick={closeImageGalleryModal}>
                        <i className="bi bi-x-lg"></i>
                      </button>
                    </div>
                  </div>
                  <div className="ir-modal-thumb-container-large-image">
                    {chatHistory?.[props.activeChatRoom] &&
                      chatHistory[props.activeChatRoom].length > 0 &&
                      chatHistory[props.activeChatRoom]
                        .filter((i) => i.images !== "")
                        .map((item, i) => {
                          if (
                            item.images !== "" &&
                            item.images !== undefined &&
                            typeof item.images !== "string"
                          ) {
                            item.images = JSON.parse(item.images);
                          }
                          return (
                            <div>
                              <div
                                key={i}
                                className={`iks-cine-content iks-cine-content-${i} ${
                                  i === carouselActiveIndex
                                    ? "iks-carousel-active"
                                    : "iks-carousel-inactive"
                                }`}
                              >
                                <AssetRender
                                  data={item}
                                  imageClicked={() => {}}
                                  options={{
                                    fullScreen: false,
                                    height: "100%",
                                    videoControl: true,
                                  }}
                                />
                                {/* <img className="ir-car-img" src={item.images} alt={"chat group"} /> */}
                              </div>
                            </div>
                          );
                        })}

                    <button
                      onClick={leftCarouselIndex}
                      disabled={prevButtonDisable}
                      className={`ir-left-car-btn ir-carousel-action-btns`}
                    >
                      <i className="bi bi-chevron-left"></i>
                    </button>
                    <button
                      onClick={rightCarouselIndex}
                      disabled={nextButtonDisable}
                      className={`ir-right-car-btn ir-carousel-action-btns`}
                    >
                      <i className="bi bi-chevron-right"></i>
                    </button>
                  </div>
                  <div className="ir-thumbanil-modal-image">
                    {chatHistory?.[props.activeChatRoom] &&
                      chatHistory[props.activeChatRoom].length > 0 &&
                      chatHistory[props.activeChatRoom]
                        .filter((i) => i.images !== "")
                        .map((item, i) => {
                          if (
                            item.images !== "" &&
                            item.images !== undefined &&
                            typeof item.images !== "string"
                          ) {
                            item.images = JSON.parse(item.images);
                          }
                          return (
                            <div
                              key={i}
                              onClick={() => onCarouselMenuItemclick(i)}
                              className={`ir-car-item-container ${
                                i === carouselActiveIndex
                                  ? "iks-carousel-item-active"
                                  : "iks-carousel-item-inactive"
                              }`}
                            >
                              <AssetRender
                                data={item}
                                imageClicked={(data) => {
                                  onCarouselMenuItemclick(i);
                                }}
                                options={{
                                  fullScreen: false,
                                  videoControl: false,
                                }}
                                className="ir-car-item-img"
                              />
                              {/* <img onClick={() => onCarouselMenuItemclick(i)} className="ir-car-item-img" src={item.images} alt={"chat group"} /> */}
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {showModal && (
        <DeleteConfirmation
          toggleModal={handleModalToggle}
          confirmationFunc={() => {
            handleDeleteClick(itemToDelete);
          }}
        />
      )}
    </div>
  );
};

export default ChatContent;
