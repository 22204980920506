import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";
import axios from "../../api/axios";
import { useContext, useEffect, useState } from "react";
import { RootStore } from "../../store/store";
import Swal from "sweetalert2";

const DeleteUserModal = ({ userId, setDeleteUserModal, username }) => {
  const [allChatUser, SetAllChatUser] = useState([]);
  const { handleDeleteUser } = useModalContext();
  const [store, setStore] = useContext(RootStore);
  const { userStore } = store;

  const handleCloseCancelModal = () => {
    setDeleteUserModal(false);
  };

  useEffect(() => {
    const getAllChatUser = async () => {
      try {
        const response = await axios({
          url: "user/all",
          method: "get",
          withCredentials: false,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
          },
        });
        console.log("all Chat user list", response?.data?.data);
        SetAllChatUser(response?.data?.data);
        let allUsers = [...response?.data?.data];
        userStore.users = allUsers;
        setStore({ ...store, userStore });
      } catch (error) {
        console.log(error);
      }
    };
    getAllChatUser();
  }, []);

  const selectedChatUserObject = allChatUser?.find(
    (user) => user?.username === username
  );

  const deleteUser = async (id) => {
    console.log("clicked");
    let userStore = [...store.userStore.users];

    let index = userStore.findIndex((e) => e.id === id);

    const response = await axios({
      url: "user/profile/" + id,
      method: "DELETE",
      withCredentials: false,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      },
    });
    console.log("userStore admin", response);
    if (response) {
      Swal.fire({
        title: "Success!",
        text: "User is successfully deleted",
        icon: "success",
      });
      userStore[index] = { ...response.data.data };
      setStore({
        ...store,
        userStore: {
          ...userStore,
          users: [...userStore],
        },
      });
    }
  };

  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={handleCloseCancelModal}
          >
            <span>X</span>
          </div>
          <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
              Are you sure you want to delete this user?
            </h3>
          </div>
          <div className="ir-ws-deleteModal-button-container">
            <button
              className="ir-ws-deleteModal-button-yes"
              onClick={async () => {
                try {
                  if (userId && selectedChatUserObject?.id) {
                    await handleDeleteUser(userId);
                    await deleteUser(selectedChatUserObject.id);
                    handleCloseCancelModal();
                  }
                } catch (error) {
                  console.error("Delete Webinar failed:", error);
                }
              }}
            >
              Yes
            </button>
            <button
              className="ir-ws-deleteModal-button-no"
              onClick={handleCloseCancelModal}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserModal;
