import { useContext, useEffect, useState } from "react";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";
import { RootStore } from "../../store/store";
import Swal from "sweetalert2";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";

const UpdateUserModal = ({ updateUserModal, setUpdateUserModal }) => {
  // const { setUpdateUserModal } = useModalContext();
  const [profileData, setProfileData] = useState(null);
  const [updateProfileInputData, setUpdateProfileInputData] = useState({});
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [store, setStore] = useContext(RootStore);
  const { userStore } = store;
  const handleCloseUpdateUserModal = () => {
    setUpdateUserModal(false);
  };
  useEffect(() => {
    const token = localStorage.getItem("jwtTokenApi2");
    if (!token) {
      navigate("/signin?redirect=chat-room");
    } else {
      setToken(token);
      // getProducts() commented for the reason because of this line the chat is not loading in the begining of the page need to fix it
      if (Object.keys(userStore.profile).length <= 0) {
        getProfile();
      }
    }
  }, []);

  useEffect(() => {
    const userProfileDetails = async () => {
      try {
        const response = await axios.get(
          `https://backend.ir4u.info/api/v1/user/profile`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );
        console.log("User profile from 1st api response", response);
        setUserId(response.data.id);
        // if (response.data?.isEmailVerified) {
        //   setEmailVerified(true);
        // }
      } catch (error) {
        console.log(error);
      }
    };
    userProfileDetails();
  }, []);
  const getProfile = async () => {
    const response = await axios({
      url: "user/profile",
      method: "get",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      },
    });
    console.log("User profile from 2nd api response", response);
    userStore.profile = response?.data?.data;
    setStore({ ...store, userStore });
  };

  const updateProfileInputChange = (e) => {
    console.log(e.target.name, e.target.value);
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
    setUpdateProfileInputData({
      ...updateProfileInputData,
      [e.target.name]: e.target.value,
    });
  };

  const updateProfileSubmitFormHandle = async () => {
    // e.preventDefault();
    const updateProfileFormData = new FormData();
    if (selectedFile !== "") {
      updateProfileFormData.append("image", selectedFile || "");
    }
    Object.keys(updateProfileInputData).map((k, i) => {
      console.log("keys are", updateProfileInputData);
      updateProfileFormData.append(k, updateProfileInputData[k]);
      return true;
    });

    console.log(updateProfileInputData);

    const response = await axios({
      url: "user/profile/" + userStore.profile.id,
      method: "PUT",
      data: updateProfileFormData,
      withCredentials: false,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi2"),
      },
    });
    console.log("Updated user profile response 2nd api", response);
    if (response?.status === 200) {
      // Swal.fire( 'Good job!','You clicked the button','success');
      Swal.fire({
        title: "Success!",
        text: "Your Profile is Updated.",
        icon: "success",
      });

      setStore({
        ...store,
        userStore: {
          ...userStore,
          profile: {
            ...userStore.profile,
            ...response.data.data,
          },
        },
      });
      let profileInputData = {};
      Object.keys(updateProfileInputData).map((k, i) => {
        profileInputData[k] = response.data.data[k];
      });
      setUpdateProfileInputData(profileInputData);
      setProfileData(response.data.data);
      setUpdateProfileInputData({});
      setUpdateUserModal(false);

      // window.location.reload();
    }
  };

  const handleUpdateProfile = async (userId) => {
    // e.preventDefault();
    try {
      const updateProfileFormData = new FormData();
      if (selectedFile !== "") {
        updateProfileFormData.append("image", selectedFile || "");
      }
      Object.keys(updateProfileInputData).map((k, i) => {
        console.log("keys are", updateProfileInputData);
        updateProfileFormData.append(k, updateProfileInputData[k]);
        return true;
      });

      // console.log(updateProfileInputData);
      const updateProfile = {
        firstname: updateProfileInputData?.firstname,
        lastname: updateProfileInputData?.lastname,
        email: updateProfileInputData?.email,
        password: profileData?.password || updateProfileInputData?.password,
      };
      console.log(updateProfile);
      const response = await axios.put(
        `https://backend.ir4u.info/api/v1/user/${userId}`,
        updateProfile,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );
      console.log("Updated user profile response 1st api", response);
      if (response.status !== 200) {
        // Throw an error for non-200 status
        throw new Error("Update profile failed");
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 400) {
        Swal.fire("Error", "Email already exist", "error"); // Throw an error for non-200 status
      }
      throw error;
    }
  };
  useEffect(() => {
    setProfileData(userStore.profile);
  }, [userStore.profile]);

  return (
    <div
      className="ir-ws-position-fixed ir-ws-sign-popup-container "
      // style={{ marginTop: "50px" }}
    >
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={handleCloseUpdateUserModal}
          >
            <span>X</span>
          </div>
          <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
              Update Profile
            </h3>
          </div>
          <form
            className="ir-ws-signup-form-container"
            onSubmit={async (e) => {
              e.preventDefault();
              if (userId) {
                try {
                  await handleUpdateProfile(userId);
                  await updateProfileSubmitFormHandle();
                } catch (error) {
                  console.error(error);
                }
              } else {
                console.error("userId is not available");
              }
            }}
          >
            <div className="ir-ws-signup-content-group">
              <input
                className="ir-ws-signup-input-field"
                type="text"
                name="firstname"
                value={profileData?.firstname || ""}
                onChange={updateProfileInputChange}
              />
              <span className="ir-ws-signup-highlight"></span>
              <span className="ir-ws-signup-bar"></span>
              <label className="ir-ws-signup-label">Firstname</label>
            </div>
            <div className="ir-ws-signup-content-group">
              <input
                className="ir-ws-signup-input-field"
                type="text"
                name="lastname"
                value={profileData?.lastname || ""}
                onChange={updateProfileInputChange}
              />
              <span className="ir-ws-signup-highlight"></span>
              <span className="ir-ws-signup-bar"></span>
              <label className="ir-ws-signup-label">Lastname</label>
            </div>
            <div className="ir-ws-signup-content-group">
              <input
                className="ir-ws-signup-input-field"
                type="text"
                name="email"
                value={profileData?.email || ""}
                onChange={updateProfileInputChange}
              />
              <span className="ir-ws-signup-highlight"></span>
              <span className="ir-ws-signup-bar"></span>
              <label className="ir-ws-signup-label">Email</label>
            </div>
            <div className="ir-ws-signup-content-group">
              <input
                className="ir-ws-signup-input-field"
                type="password"
                name="password"
                value={profileData?.password || ""}
                onChange={updateProfileInputChange}
              />
              <span className="ir-ws-signup-highlight"></span>
              <span className="ir-ws-signup-bar"></span>
              <label className="ir-ws-signup-label">Password</label>
            </div>

            <div className="ir-ws-text-center ir-ws-default-btn-container">
              <button
                className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                type="submit"
              >
                <span>Update Profile</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateUserModal;
