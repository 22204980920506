import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import HomeJoinContainer from "../../components/HomeJoinContainer/HomeJoinContainer";
import './Aboutus.scss';

const Aboutus = () => {
    return (
        <>
            <Header />
            <div className="ir-ws-padding-lr-132 ir-ws-about-main-container">
                <h2 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-hme-cases-title ir-ws-about-heading-container">About Us</h2>
                <div className="ir-ws-about-content">
                    <p className="ir-default-color">
                        IR4U, "Interventional Radiology 4U," is a dedicated online platform designed to cater to a wide audience, including medical students, healthcare professionals, and the general public. 
                        The brainchild of Dr. Ram Kishore Gurajala MD, IR4U was born out of a visionary idea to create an educational website that democratizes access to knowledge. 
                        Dr. Gurajala's passion for radiology and his commitment to breaking down financial barriers have led to the creation of this invaluable resource.
                    </p>
                    <p className="ir-default-color">
                        IR4U's mission is to empower individuals by providing them with the knowledge and tools needed to excel in interventional radiology. 
                        Through its user-friendly interface and rich content offerings, IR4U aims to bridge the gap between medical education and practice, making high-quality medical education accessible to all.
                    </p>
                    <p className="ir-default-color">
                        As a prestigious and user-centric resource, IR4U continues to evolve and expand its offerings, staying true to its founder's vision of advancing interventional radiology education and 
                        fostering a community of informed healthcare professionals and enthusiasts. <br />Whether you are a medical student embarking on your educational journey or an experienced healthcare 
                        professional seeking to broaden your knowledge, IR4U welcomes you to explore its comprehensive and enriching world of interventional radiology.
                    </p>
                </div>
                <h3 className="ir-ws-heading-default-color ir-ws-about-key-features">Key Features of IR4U</h3>
                <ul>
                    <li className="ir-default-color">
                        <b>Field Level Case Studies:</b> IR4U offers a comprehensive collection of real-world case studies in the field of interventional radiology. These cases provide a valuable learning experience, allowing users to explore various medical scenarios and treatment approaches.
                    </li>
                    <li className="ir-default-color">
                        <b>Interactive Expert Chat:</b> The platform includes a chat feature that enables users to directly communicate with experienced experts in the field of interventional radiology. This interactive element fosters knowledge sharing and facilitates discussions on complex medical topics.

                        </li>
                    <li className="ir-default-color">
                        <b>IR Courses:</b> IR4U hosts a range of educational courses related to interventional radiology. These courses are designed to cater to different levels of expertise, from beginners to advanced practitioners, ensuring that learners can acquire the knowledge and skills they need.
                        </li>
                    <li className="ir-default-color">
                        <b>Presentations:</b> Users can access a repository of presentations on interventional radiology topics. These presentations serve as valuable resources for both learning and reference purposes, making it easier for individuals to grasp complex concepts.
                        </li>
                    <li className="ir-default-color">
                        <b>Study Material:</b> IR4U provides a wealth of study materials, including articles, research papers, and reference guides. These resources are curated to support in-depth learning and research within the field of interventional radiology.
                        </li>
                    <li className="ir-default-color">
                        <b>Expert Reviews:</b> The platform boasts endorsements and reviews from experts in the field, highlighting the credibility and usefulness of IR4U as a reputable source of information in the domain of interventional radiology.
                    </li>
                </ul>
            </div>
            <HomeJoinContainer />
            <Footer />
        </>
    )
};
export default Aboutus;