import {
  ContentShare,
  RemoteVideos,
  ChatBubble,
  useRosterState,
  useAttendeeStatus,
  VideoTileGrid,
  RemoteVideo,
  useLocalAudioOutput,
  useToggleLocalMute,
} from "amazon-chime-sdk-component-library-react";
import axios from "axios";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import "./UserScreen.scss";
import io from "socket.io-client";
import LeaveMeetingModal from "./LeaveMeetingModal";

// const socket = io.connect("https://free.hexanova.tech");
// const socket = io.connect("https://webinar-staging.backend.ir4u.info/");
const socket = io.connect(
  // "https://socket-server-production-5b95.up.railway.app/"
  "https://courses.ir4u.info/chat"
);
const UserScreen = ({ webinarTitle, username, webinarId }) => {
  const { leaveMeeting, handleCopyMeetingUrl } = useModalContext();
  const [presenter1Id, setPresenter1Id] = useState("");
  const [attendeeDetails, setAttendeeDetails] = useState([]);
  const [messageSenderId, setMessageSenderId] = useState("");
  const [inputText, setInputText] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [leaveModal, setLeaveModal] = useState(false);
  const BASE_URL = "https://webinar-staging.backend.ir4u.info";
  const token = localStorage.getItem("jwtTokenApi1");
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  // const { isAudioOn, toggleAudio } = useLocalAudioOutput();
  const { muted, toggleMute } = useToggleLocalMute();
  const [activeParticipantBox, setActiveParticipantBox] = useState(true);
  const [activeChatBox, setActiveChatBox] = useState(true);
  const handleOpenLeaveModal = () => {
    setLeaveModal(true);
  };
  const sendMessage = async () => {
    if (inputText !== "") {
      const messageData = {
        id: messageSenderId,
        variant: "incoming",
        author: username,
        message: inputText,
        time: `${new Date(Date.now()).getHours()}:${new Date(
          Date.now()
        ).getMinutes()}`,
      };
      await socket.emit("send_message", messageData);
      setMessageList((list) => [...list, messageData]);
      setInputText("");
    }
  };

  useEffect(() => {
    if (!muted) {
      toggleMute();
    }
  }, []);
  useEffect(() => {
    socket.off("receive_message");
    socket.on("receive_message", (data) => {
      setMessageList((list) => [...list, data]);
    });
    return () => {
      socket.off("receive_message");
    };
  }, [socket]);

  function getInitials(name) {
    const words = name.split(" ");
    const initials = words.map((word) => word.charAt(0).toUpperCase());
    const initialsString = initials.join("");

    return initialsString;
  }

  useEffect(() => {
    if (attendees.length > 0) {
      setMessageSenderId(attendees[0].chimeAttendeeId);
    }
    if (attendees.length > 1) {
      setPresenter1Id(attendees[1].chimeAttendeeId);
    }
  }, [attendees]);

  const extractedAttendeeIds = attendees.map(
    (attendee) => attendee.chimeAttendeeId
  );
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setFullScreen(document.fullscreenElement !== null);
    };
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  const handleFullScreen = () => {
    const targetDiv = document.getElementById("fullScreenDiv");

    if (fullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      setFullScreen(false);
    } else {
      if (targetDiv.requestFullscreen) {
        targetDiv.requestFullscreen();
      }
      setFullScreen(true);
    }
  };

  const lastFetchTimeRef = useRef(0);
  useEffect(() => {
    const fetchAttendeeDetails = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/api/v1/attendee/details`,
          {
            attendeeIds: extractedAttendeeIds,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setAttendeeDetails(response.data);
      } catch (error) {
        console.error("Error fetching attendee details:", error);
      }
    };

    const currentTime = Date.now();

    // Check if 15 seconds have passed since the last fetch
    if (currentTime - lastFetchTimeRef.current >= 3000) {
      // Perform the fetch
      fetchAttendeeDetails();

      // Update the last fetch time
      lastFetchTimeRef.current = currentTime;
    }
  }, [extractedAttendeeIds]);

  // const { sharingContent, videoEnabled } = useAttendeeStatus(presenter1Id);

  const navigate = useNavigate();

  const messageBoxRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
    // console.log(sharingContent);
  }, [messageList]);

  const scrollToBottom = () => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
  };

  const [shouldHide, setShouldHide] = useState(false);

  useEffect(() => {
    // Check if the element with class eGphtH exists
    const eGphtHElement = document.querySelector(".eGphtH");

    // If the element exists, set shouldHide to true
    if (eGphtHElement) {
      setShouldHide(true);
    }
  }, []);

  const toggleParticipantBox = () => {
    setActiveParticipantBox(!activeParticipantBox);
    // setToggleActive(false);
  };
  const toggleActiveChatBox = () => {
    setActiveChatBox(!activeChatBox);
    // setToggleActive(false);
  };
  return (
    <div className="ir-ws-webinar-presenter-main-container">
      <div className="ir-ws-webinar-presenter-inner-main-container">
        <div className="ir-ws-webinar-presenter-inner-sub-container">
          <div className="ir-ws-flex ir-ws-justify-space-btw ir-ws-gray-bg ir-ws-align-center ir-ws-webinar-presenter-header">
            <div className="ir-ws-webinar-presenter-logo-container">
              <a href="/">
                <img
                  src={require("../../../assets/images/ir4u2.png")}
                  alt="logo"
                  className="ir-ws-webinar-presenter-logo"
                />
              </a>
            </div>
            <p className="ir-margin-0 ir-ws-webinar-presenter-webinar-name-text">
              {webinarTitle}
            </p>
            <div
              className="ir-ws-webinar-presenter-logo-pack"
              style={{ display: "none" }}
            >
              <div className="ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center ir-ws-webinar-presenter-logo-pack-record-container">
                <p className="ir-ws-webinar-presenter-recording-text">
                  Recording
                </p>

                <div className="ir-ws-webinar-presenter-recorder-logo">
                  <div className="ir-ws-webinar-presenter-recoredr-logo-red-btn"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="ir-ws-webinar-presenter-main-screen-container ir-ws-webinar-user-main-screen-container">
            <div className="ir-ws-height-100-percent ir-ws-webinar-presenter-inner-screen-container">
              <div className="ir-ws-height-100-percent ir-ws-webinar-presenter-inner-sub-screen-container">
                <div
                  //  className="ir-ws-webinar-presenter-participants-box"

                  className={`ir-ws-webinar-presenter-participants-box ${
                    activeParticipantBox
                      ? "ir-ws-webinar-presenter-none"
                      : "ir-ws-webinar-presenter-block"
                  }`}
                >
                  <div className="ir-ws-webinar-presenter-participants-number-container">
                    <p className="ir-ws-webinar-presenter-text">Participants</p>
                    <p className="ir-ws-webinar-presenter-number-text">
                      {attendeeDetails?.length}
                    </p>
                    <span
                      class="material-symbols-outlined ir-ws-webinar-participant-close-btn"
                      onClick={toggleParticipantBox}
                    >
                      close
                    </span>
                  </div>
                  <div className="ir-ws-webinar-presenter-participants-container">
                    {attendeeDetails?.map((attendee, index) => (
                      <div
                        className="ir-ws-webinar-participants-occupations-container"
                        key={index}
                      >
                        <div className="ir-ws-webinar-participants-user-logo">
                          <p></p>
                        </div>
                        <div>
                          <p className="ir-ws-webinar-participants-text">
                            {attendee.username}
                          </p>
                          <p className="ir-ws-webinar-occupations-text">
                            {attendee.role}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="ir-ws-webinar-presenter-meeting-container">
                  {/* {videoEnabled || sharingContent ? ( */}
                  <div
                    id="fullScreenDiv"
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <VideoTileGrid
                      className="ir-ws-presenter-local-video"
                      style={{ background: "black" }}
                    >
                      <RemoteVideos className="ir-ws-presenter-local-video" />
                      <ContentShare
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </VideoTileGrid>
                    {!fullScreen ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="ir-ws-webinar-participant-full-screen-active"
                        onClick={handleFullScreen}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="ir-ws-webinar-participant-full-screen-inactive"
                        onClick={handleFullScreen}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25"
                        />
                      </svg>
                    )}
                  </div>

                  <div className="ir-ws-webinar-presenter-meeting-control-container ir-ws-webinar-user-meeting-control-container ir-ws-webinar-user-chat-leave-box">
                    <span
                      class="material-symbols-outlined control-icon control-icon-inactive ir-ws-webinar-user-group-btn"
                      onClick={toggleParticipantBox}
                    >
                      group
                    </span>
                    <button
                      className="ir-ws-webinar-presenter-meeting-leave-btn"
                      onClick={handleOpenLeaveModal}
                    >
                      Leave
                    </button>
                    <span
                      class="material-symbols-outlined control-icon control-icon-inactive ir-ws-webinar-user-chat-btn"
                      onClick={toggleActiveChatBox}
                    >
                      chat
                    </span>
                  </div>
                </div>
                <div
                  // className="ir-ws-webinar-presenter-message-notes-container ir-ws-webinar-user-chat-message-container"
                  className={`ir-ws-webinar-presenter-message-notes-container ${
                    activeChatBox
                      ? "ir-ws-webinar-chat-none"
                      : "ir-ws-webinar-chat-block"
                  }`}
                >
                  <div className="ir-ws-webinar-presenter-message-conatiner ir-ws-webinar-user-chat-container">
                    <div className="ir-ws-webinar-presenter-chat-text-box">
                      <p className="ir-ws-webinar-presenter-chat-text">Chat</p>
                      <span
                        class="material-symbols-outlined ir-ws-webinar-participant-close-btn"
                        onClick={toggleActiveChatBox}
                      >
                        close
                      </span>
                    </div>
                    <div
                      className="ir-ws-webinar-presenter-message-box ir-ws-webinar-user-chat-message-box"
                      ref={messageBoxRef}
                    >
                      {messageList?.map((message, index) =>
                        message.author === username ? (
                          <div
                            className="ir-ws-webinar-presenter-message-sender-reciever-box"
                            key={`${message.id}-${index}`}
                          >
                            <div className="ir-ws-webinar-sender-message">
                              <ChatBubble
                                variant={message.variant}
                                senderName={message.author}
                                time={message.time}
                                style={{
                                  borderRadius: "15px",
                                  fontSize: "12px",
                                  lineHeight: "1.2",
                                  padding: "8px",
                                  background: "#85CCFF",
                                  color: "#000",
                                }}
                              >
                                {message.message}
                              </ChatBubble>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="ir-ws-webinar-presenter-message-receiver-box"
                            key={`${message.id}-${index}`}
                          >
                            <div className="ir-ws-webinar-presenter-message-sender">
                              <p className="ir-ws-webinar-presenter-message-name">
                                {getInitials(message.author)}
                              </p>
                            </div>
                            <ChatBubble
                              variant={message.variant}
                              senderName={message.author}
                              time={message.time}
                              style={{
                                color: "#000",
                                borderRadius: "15px",
                                background: "#85CCFF",
                                fontSize: "12px",
                                lineHeight: "1.2",
                                padding: "8px",
                              }}
                            >
                              {message.message}
                            </ChatBubble>
                          </div>
                        )
                      )}
                    </div>
                    <div className="ir-ws-webinar-presenter-message-input-container">
                      <div className="ir-ws-webinar-presenter-message-input-send-btn-container">
                        <textarea
                          placeholder="Type message here"
                          className="ir-ws-webinar-presenter-message-input"
                          value={inputText}
                          onChange={(e) => setInputText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                              e.preventDefault();
                              sendMessage();
                            }
                          }}
                        />

                        <svg
                          viewBox="0 0 512 512"
                          xmlns="http://www.w3.org/2000/svg"
                          className="ir-ws-webinar-message-send-btn"
                          onClick={sendMessage}
                        >
                          <title />
                          <g data-name="1" id="_1">
                            <path d="M291.48,449.94A15,15,0,0,1,278,441.5L207.5,296.57,62.57,226.08a15,15,0,0,1,1-27.41L435.48,49.08A15,15,0,0,1,455,68.6L305.4,440.54A15,15,0,0,1,292,449.93Zm-185.38-236,119.18,58a15,15,0,0,1,6.93,6.93l58,119.18L414,90Z" />
                            <path d="M218.72,300.35a15,15,0,0,1-10.6-25.61L430.47,52.39a15,15,0,1,1,21.21,21.22L229.33,296A15,15,0,0,1,218.72,300.35Z" />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {leaveModal && (
        <LeaveMeetingModal
          leaveModal={leaveModal}
          setLeaveModal={setLeaveModal}
          leaveMeeting={leaveMeeting}
        />
      )}
    </div>
  );
};

export default UserScreen;
