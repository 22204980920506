import React, { useEffect, useState } from "react";

const FindAbnormality = ({
  activeSlideObject,
  activeAccordions,
  accordionRefs,
  toggleAccordion,
  handleImageClick,
  circlePosition,
  answerType,
  submissionStatus,
  checkSelectedPoint,
  Fad,
  questionIndex,
  markQuestionAsAnswered,
  activeQuestionindex,
  goToNextSlide,
  totalQuestions,
  slideNumber,
  handleShowLearningPopup,
  isLearningPointExist,
  isAllQuestionsSubmitted,
  handleSubmitAllAnser,
}) => {
  // console.log(questionIndex, "question index");
  const [newcirclePosition, setCirclePosition] = useState({ x: null, y: null });
  const [resultMessage, setResultMessage] = useState(false);
  const [groupAnswerSubmitted, setGroupAnswerSubmitted] = useState(false);

  const isLastQuestion = questionIndex === totalQuestions - 1;

  const singleHandleImageClick = (evt, questionIndex) => {
    const rect = evt.target.getBoundingClientRect();
    const x = evt.clientX - rect.left;
    const y = evt.clientY - rect.top;
    setCirclePosition({ x, y });
  };

  console.log("this is the findabnormality data:", Fad);

  const singleCheckSelectedPoint = (questionIndex) => {
    setResultMessage(true);
    const tolerance = 20;
    const abnormanlityPoint = Fad.data.point;

    // console.log(
    //   "this is the findabnormality abnormanlityPoint:",
    //   abnormanlityPoint
    // );
    // console.log(
    //   "this is the findabnormality newcirclePosition:",
    //   newcirclePosition
    // );

    const diffX = Math.abs(newcirclePosition.x - abnormanlityPoint.x);
    const diffY = Math.abs(newcirclePosition.y - abnormanlityPoint.y);

    const message =
      diffX <= tolerance && diffY <= tolerance ? "Correct!" : "Incorrect!";
    setResultMessage(message);
    updateResultMessage(message);
  };

  const updateResultMessage = (message) => {
    const containerId = `resultContainer-${slideNumber}`;
    const resultContainer = document.getElementById(containerId);
    if (resultContainer) {
      resultContainer.textContent = message;
      resultContainer.style.color = message === "Correct!" ? "green" : "red";
    }
  };

  const submitHandler = (questionIndex) => {
    singleCheckSelectedPoint(questionIndex);
    markQuestionAsAnswered(questionIndex);
  };

  // useEffect(() => {
  //   if (isAllQuestionsSubmitted) {
  //     markQuestionAsAnswered(questionIndex);
  //   }
  // }, [isAllQuestionsSubmitted]);

  return (
    <>
      {Fad && (
        <>
          <div className="ir-ws-case-accordion-cont">
            <div
              // className={`ir-ws-case-accordion-header ${
              //   activeAccordions[questionIndex]
              //     ? "ir-ws-case-accordian-active-border"
              //     : "ir-ws-case-accordian-inactive-border "
              // }`}
              // onClick={() => toggleAccordion(questionIndex)}
              className="ir-ws-case-accordion-header ir-ws-case-accordian-active-border"
            >
              <span className="ir-ws-case-slide-title-text">
                <p className="mul-question">
                  {/* Question:{" "} */}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: Fad?.data.data,
                    }}
                  />
                </p>
              </span>
            </div>
            <div
              className="ir-ws-case-accordion-accordion-content"
              // ref={(el) => (accordionRefs.current[questionIndex] = el)}
              // style={{
              //   maxHeight: activeAccordions[questionIndex]
              //     ? `${accordionRefs.current[questionIndex]?.scrollHeight}px`
              //     : "0px",
              // }}
              // style={{ height: "100%" }}
            >
              <div className="ir-ws-case-content-box">
                {/* {activeSlideObject?.findAbnormanlity?.map((question, index) => ( */}
                <div
                  className="abnormality-question-data-main"
                  style={
                    {
                      // position: "relative",
                      // display: "inline-block",
                    }
                  }
                >
                  <h4 className="abnormality-question">
                    Find the abnormality in the image ?
                  </h4>
                  <div className="fib-main-conatiner">
                    <div className="fib-user-img-con">
                      <img
                        height={500}
                        width={500}
                        src={Fad?.data?.image}
                        alt="selected"
                        style={{ marginBottom: "0px", userSelect: "none" }}
                        // onClick={handleImageClick}
                        onClick={(evt) => {
                          handleImageClick(evt, questionIndex);
                          singleHandleImageClick(evt, questionIndex);

                          if (answerType === "group")
                            markQuestionAsAnswered(questionIndex);
                          // setGroupAnswerSubmitted(true);

                          // markQuestionAsAnswered(questionIndex)
                        }}
                        className="abnormality-img"
                        disabled={
                          (answerType === "group" && isAllQuestionsSubmitted) ||
                          resultMessage
                        }
                      />
                      {circlePosition.x !== null &&
                        circlePosition.y !== null && (
                          <div
                            style={{
                              position: "absolute",
                              left: circlePosition.x - 25,
                              top: circlePosition.y + 25,
                              width: 50,
                              height: 50,
                              border: "2px solid red",
                            }}
                          ></div>
                        )}
                    </div>
                    <div
                      className="abnormality-result-msg-container"
                      // id="resultContainer"

                      id={`resultContainer-${slideNumber}`}
                    ></div>
                    {resultMessage ||
                      (isAllQuestionsSubmitted && (
                        <div className="fib-result-img-con">
                          <img
                            height={500}
                            width={500}
                            src={Fad?.data?.image}
                            alt="selected"
                            className="abnormality-img"
                            style={{ userSelector: "none" }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              left: Math.floor(Fad.data.point.x) - 25,
                              top: Math.floor(Fad.data.point.y) - 20,
                              width: 50,
                              height: 50,
                              border: "2px solid green",
                            }}
                          ></div>
                        </div>
                      ))}
                    {resultMessage ||
                      (isAllQuestionsSubmitted && (
                        <div className="your-answer correct">
                          This is correct answer!
                        </div>
                      ))}
                  </div>

                  {answerType === "single" && (
                    <div
                      className=" ir-ws-default-btn-container"
                      style={{
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                        // disabled={submissionStatus[index] ? true : false}
                        disabled={resultMessage}
                        // onClick={() => singleCheckSelectedPoint(index)}
                        onClick={() => submitHandler(questionIndex)}
                        style={{ width: "240px" }}
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  )}
                  {/* {!isLastQuestion && answerType === "group" && (
                    <div
                      className=" ir-ws-default-btn-container"
                      style={{
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                        // onClick={checkAnswerFid}
                        onClick={goToNextSlide}
                        // onClick={() => submitHandler(questionIndex)}
                        disabled={isLastQuestion}
                        style={{ width: "240px" }}
                      >
                        <span>Next</span>
                      </button>
                    </div>
                  )} */}
                  {answerType === "group" && (
                    <div
                      className="ir-ws-default-btn-container"
                      style={{
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      {isLastQuestion ? (
                        <button
                          className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                          onClick={handleSubmitAllAnser}
                          style={{ width: "240px" }}
                          disabled={isAllQuestionsSubmitted}
                        >
                          <span>Finish</span>
                        </button>
                      ) : (
                        <button
                          className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                          onClick={goToNextSlide}
                          style={{ width: "240px" }}
                          disabled={isLastQuestion}
                        >
                          <span>Next</span>
                        </button>
                      )}
                    </div>
                  )}
                  {(isLearningPointExist && isLastQuestion && resultMessage) ||
                  (isLearningPointExist &&
                    answerType === "group" &&
                    isLastQuestion &&
                    isAllQuestionsSubmitted) ? (
                    <div
                      className="ir-ws-default-btn-container"
                      style={{
                        width: "100%",
                        marginTop: "20px",
                        marginLeft: "5px",
                      }}
                    >
                      <button
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                        // onClick={checkJumblingAnswers}
                        onClick={() => handleShowLearningPopup()}
                        style={{ width: "240px" }}
                      >
                        <span>Learning Points</span>
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FindAbnormality;
