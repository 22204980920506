// import React, { useState } from "react";
// import Cropper from "react-easy-crop";

// const CropImageModal = ({
//   cropImage,
//   setImageThumbnail,
//   setCropImageModal,
//   handleCloseImageCrop,
// }) => {
//   console.log(cropImage);
//   const [crop, setCrop] = useState({ x: 0, y: 0 });
//   const [zoom, setZoom] = useState(1);

//   const onCropComplete = (croppedArea, croppedAreaPixels) => {
//     try {
//       const canvas = document.createElement("canvas");
//       const image = new Image();

//       image.src = cropImage;
//       image.onload = () => {
//         canvas.width = croppedAreaPixels.width;
//         canvas.height = croppedAreaPixels.height;

//         const ctx = canvas.getContext("2d");

//         // Calculate scale factors
//         const scaleX = image.naturalWidth / image.width;
//         const scaleY = image.naturalHeight / image.height;

//         // Draw cropped image
//         ctx.drawImage(
//           image,
//           croppedAreaPixels.x * scaleX,
//           croppedAreaPixels.y * scaleY,
//           croppedAreaPixels.width * scaleX,
//           croppedAreaPixels.height * scaleY,
//           0,
//           0,
//           canvas.width,
//           canvas.height
//         );

//         const base64Image = canvas.toDataURL("image/jpeg");
//         setImageThumbnail(base64Image);
//       };
//     } catch (error) {
//       console.error("Error cropping image:", error);
//     }
//   };

//   const handleSave = (e) => {
//     e.preventDefault();
//     setCropImageModal(false);
//   };

//   return (
//     <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
//       <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
//         <div className="ir-ws-signup-content-container">
//           <div
//             className="ir-ws-position-absolute ir-ws-signup-close-container"
//             onClick={handleCloseImageCrop}
//           >
//             <span>X</span>
//           </div>
//           <div className="ir-ws-signup-content-inner-container">
//             <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
//               {/* {type} */}
//             </h3>
//           </div>
//           <form className="ir-ws-signup-form-container">
//             <div>
//               <div className="ir-ws-webinar-image-crop-container">
//                 <Cropper
//                   image={cropImage}
//                   crop={crop}
//                   zoom={zoom}
//                   aspect={350 / 350}
//                   cropSize={{ width: 350, height: 350 }}
//                   onCropChange={setCrop}
//                   onCropComplete={onCropComplete}
//                   onZoomChange={setZoom}
//                   minZoom={1}
//                 />
//               </div>

//               <div
//                 className="ir-ws-webinar-image-editor-btn-container "
//                 style={{ marginTop: "70px" }}
//               >
//                 <div className="ir-ws-webinar-image-controls">
//                   <div
//                     className="ir-ws-zoom-button"
//                     onClick={(e) => {
//                       e.preventDefault();
//                       setZoom(zoom - 0.03);
//                     }}
//                   >
//                     <img
//                       src={require("../../../assets/images/chat-images/magnifying-glass (1).png")}
//                       className="ir-ws-zoom-button-images"
//                       alt="zoom button"
//                     />
//                   </div>
//                   <div
//                     className="ir-ws-zoom-button"
//                     onClick={(e) => {
//                       e.preventDefault();
//                       setZoom(zoom + 0.03);
//                     }}
//                   >
//                     <img
//                       src={require("../../../assets/images/chat-images/magnifying-glass.png")}
//                       className="ir-ws-zoom-button-images"
//                       alt="zoom button"
//                     />
//                   </div>
//                 </div>

//                 <div className="ir-ws-text-center ir-ws-default-btn-container">
//                   <button
//                     className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
//                     style={{ width: "200px", height: "50px" }}
//                     onClick={handleSave}
//                   >
//                     <span>Save</span>
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CropImageModal;

import React, { useState } from "react";
import "cropperjs/dist/cropper.css";
import Cropper from "react-cropper";

const CropImageModal = ({
  cropImage,
  setImageThumbnail,
  setCropImageModal,
  handleCloseImageCrop,
}) => {
  const [cropper, setCropper] = useState(null);

  const handleSave = (e) => {
    e.preventDefault();
    if (cropper) {
      const canvas = cropper.getCroppedCanvas();
      canvas.toBlob((blob) => {
        const blobUrl = URL.createObjectURL(blob);
        setImageThumbnail(blobUrl);
        setCropImageModal(false);
      }, "image/jpeg");
    }
  };

  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={handleCloseImageCrop}
          >
            <span>X</span>
          </div>
          <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
              {/* {type} */}
            </h3>
          </div>
          <form className="ir-ws-signup-form-container">
            <div>
              <div className="ir-ws-webinar-image-crop-container">
                <Cropper
                  src={cropImage}
                  style={{ height: 400, width: "100%" }}
                  initialAspectRatio={16 / 9}
                  guides={false}
                  cropBoxResizable={true}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  background={false}
                />
              </div>
              <div className="ir-ws-text-center ir-ws-default-btn-container">
                <button
                  className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                  style={{ width: "200px", height: "50px" }}
                  onClick={handleSave}
                >
                  <span>Save</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CropImageModal;
