import React, { useState } from "react";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";
import ConfirmationModal from "../AdminCreateCourseTab/ConfirmationModal";
import { useNavigate } from "react-router-dom";

const AdminCourseTrashList = () => {
  const { deletedCourses, restoreCourseFunction } = useModalContext();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [courseId, setCourseId] = useState("");
  const toggleConfirmationModal = (id) => {
    setCourseId(id);
    setOpenConfirmationModal(!openConfirmationModal);
  };
  const handleRestoreConfirmation = async () => {
    if (courseId) {
      await restoreCourseFunction(courseId);
      setCourseId("");
      setOpenConfirmationModal(false);
    }
  };
  const navigate = useNavigate();
  return (
    <div>
      {deletedCourses?.map((course, index) => (
        <div className="ir-ws-webinar-list-tab" key={index}>
          <div className="ir-ws-admin-webinar-content">
            <div className="ir-ws-admin-webinar-title-container">
              <img
                src={course.formData.imagePreview}
                className="ir-ws-course-list-image"
                alt="course"
              />
              <p className="ir-ws-webinar-title-text">
                {course.formData.title}
              </p>
            </div>
            <div className="ir-ws-admin-list-button-container">
              <button
                className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                onClick={() => navigate(`/course/${course._id}`)}
              >
                View Course
              </button>
              <button
                className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                onClick={() => toggleConfirmationModal(course._id)}
              >
                Restore
              </button>
            </div>
          </div>
        </div>
      ))}
      {openConfirmationModal && (
        <ConfirmationModal
          toggleModal={toggleConfirmationModal}
          confirmationFunc={handleRestoreConfirmation}
          title="restore"
        />
      )}
    </div>
  );
};

export default AdminCourseTrashList;
