import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./HomePreviewTabContainer.scss";
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";

const HomePreviewTabContainer = () => {
  const [modalChatOne, setModalChatOne] = useState(false);
  const [modalChatTwo, setModalChatTwo] = useState(false);
  const [modalChatThree, setModalChatThree] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);
  const navigate = useNavigate();

  const openModalChatOne = () => {
    setModalChatOne(!modalChatOne);
  };

  const closeModalChatOne = () => {
    setModalChatOne(!modalChatOne);
  };
  const openModalChatTwo = () => {
    setModalChatTwo(!modalChatTwo);
  };

  const closeModalChatTwo = () => {
    setModalChatTwo(!modalChatTwo);
  };

  const openModalChatThree = () => {
    setModalChatThree(!modalChatThree);
  };

  const closeModalChatThree = () => {
    setModalChatThree(!modalChatThree);
  };

  const spinner = () => {
    setVideoLoading(!videoLoading);
  };

  const navigateToCases = () => {
    navigate("/cases");
  };

  const navigateToCourses = () => {
    navigate("/courses");
  };

  const navigateToWebinar = () => {
    navigate("/webinar");
  };

  const [activeIndex, setActiveIndex] = useState(1);
  const handleClick = (index) => setActiveIndex(index);
  const checkActive = (index, className) =>
    activeIndex === index ? className : "";

  return (
    <div className="ir-ws-padding-tb-100 ir-ws-padding-lr-132 ir-ws-hme-prvw-tab-main-container">
      <h2 className="ir-ws-text-center ir-ws-hme-prvw-title">
        Preview our popular features, Watch videos to know more
      </h2>
      <div className="ir-ws-hme-tabs-container">
        <div className="ir-ws-flex ir-ws-hme-tabs-header-container">
          <div
            className={`ir-ws-position-relative ir-ws-text-center ir-ws-prvw-tab-title-container ${checkActive(
              1,
              "ir-ws-tab-prvw-active"
            )}`}
            onClick={() => handleClick(1)}
          >
            <img
              className="ir-ws-block ir-ws-prvw-tab-img"
              src={require("../../assets/images/chat.png")}
              alt="Chat icon"
            />
            <div className="ir-ws-prvw-tab-title">Chat</div>
          </div>
          <div
            className={`ir-ws-position-relative ir-ws-text-center ir-ws-prvw-tab-title-container ${checkActive(
              2,
              "ir-ws-tab-prvw-active"
            )}`}
            onClick={() => handleClick(2)}
          >
            <img
              className="ir-ws-block ir-ws-prvw-tab-img"
              src={require("../../assets/images/cases.png")}
              alt="cases icon"
            />
            <div className="ir-ws-prvw-tab-title">Cases</div>
          </div>
          <div
            className={`ir-ws-position-relative ir-ws-text-center ir-ws-prvw-tab-title-container ${checkActive(
              3,
              "ir-ws-tab-prvw-active"
            )}`}
            onClick={() => handleClick(3)}
          >
            <img
              className="ir-ws-block ir-ws-prvw-tab-img"
              src={require("../../assets/images/courses.png")}
              alt="Courses icon"
            />
            <div className="ir-ws-prvw-tab-title">Courses</div>
          </div>
          <div
            className={`ir-ws-position-relative ir-ws-text-center ir-ws-prvw-tab-title-container ${checkActive(
              4,
              "ir-ws-tab-prvw-active"
            )}`}
            onClick={() => handleClick(4)}
          >
            <img
              className="ir-ws-block ir-ws-prvw-tab-img"
              src={require("../../assets/images/webinar.png")}
              alt="Webinar icon"
            />
            <div className="ir-ws-prvw-tab-title">Webinar</div>
          </div>
        </div>
        <div className="ir-ws-hme-prvw-tabs-panel-main-container">
          <div className="ir-ws-hme-tab-prvw-panels-container">
            <div
              className={`ir-ws-flex ir-ws-hme-prvw-panel-container ${checkActive(
                1,
                "ir-ws-tab-prvw-active"
              )}`}
            >
              {/* <div className="ir-ws-flex ir-ws-hme-prvw-panel-inner-container"> */}
              <div className="ir-ws-hme-prvw-card-container ir-ws-width-33">
                <div className="ir-ws-card-main-container">
                  <div className="ir-ws-text-center ir-ws-position-relative ir-ws-card-header ir-ws-card-header-bg-overlay">
                    <img
                      className="ir-ws-prvw-card-thumbnail-container"
                      src={require("../../assets/images/chat-2.webp")}
                      alt="Courses thumbnail"
                    />
                    <div
                      className="ir-ws-position-absolute ir-ws-prvw-card-play-icon-container"
                      onClick={openModalChatOne}
                    >
                      <img
                        src={require("../../assets/images/play-icon.png")}
                        alt="play icon"
                        className="ir-ws-prvw-card-play-icon"
                      />
                    </div>
                  </div>
                  <div className="ir-ws-card-body">
                    <h4 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-card-body-title">
                      IVC filter placement gone wrong
                    </h4>
                    <ul className="ir-ws-card-body-list-container">
                      <li className="ir-default-color ir-ws-card-list-item">
                        Elderly female present with lower extremity swelling.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        High risk for PE.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        US demonstrate acute femoral vein DVT.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Contraindications for anticoagulation.
                        <br />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ir-ws-hme-prvw-card-container ir-ws-width-33">
                <div className="ir-ws-card-main-container">
                  <div className="ir-ws-text-center ir-ws-position-relative ir-ws-card-header ir-ws-card-header-bg-overlay">
                    <img
                      className="ir-ws-prvw-card-thumbnail-container"
                      src={require("../../assets/images/cather-1.webp")}
                      alt="Courses thumbnail"
                    />
                    <div
                      className="ir-ws-position-absolute ir-ws-prvw-card-play-icon-container"
                      onClick={openModalChatTwo}
                    >
                      <img
                        src={require("../../assets/images/play-icon.png")}
                        alt="play icon"
                        className="ir-ws-prvw-card-play-icon"
                      />
                    </div>
                  </div>
                  <div className="ir-ws-card-body">
                    <h4 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-card-body-title">
                      How bad can a tunneled dialysis catheter be placed
                    </h4>
                    <ul className="ir-ws-card-body-list-container">
                      <li className="ir-default-color ir-ws-card-list-item">
                        Young male with CKD requiring emergent hemodialysis.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Bedside placement of Tunneled dialysis catheter, which
                        is non-functional.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Abnormal findings on Fluoroscopy.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Successfully, managed by Interventional radiology.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ir-ws-hme-prvw-card-container ir-ws-width-33">
                <div className="ir-ws-card-main-container">
                  <div className="ir-ws-text-center ir-ws-position-relative ir-ws-card-header ir-ws-card-header-bg-overlay">
                    <img
                      className="ir-ws-prvw-card-thumbnail-container"
                      src={require("../../assets/images/anatomy-2.webp")}
                      alt="Courses thumbnail"
                    />
                    <div
                      className="ir-ws-position-absolute ir-ws-prvw-card-play-icon-container"
                      onClick={openModalChatThree}
                    >
                      <img
                        src={require("../../assets/images/play-icon.png")}
                        alt="play icon"
                        className="ir-ws-prvw-card-play-icon"
                      />
                    </div>
                  </div>
                  <div className="ir-ws-card-body">
                    <h4 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-card-body-title">
                      Know the anatomy
                    </h4>
                    <ul className="ir-ws-card-body-list-container">
                      <li className="ir-default-color ir-ws-card-list-item">
                        Elderly male presented to ED with acute abdominal pain.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        An Interventional radiologist should have the mind of a
                        clinician, surgeon and a diagnostic radiologist.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Assist in procedural planning.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Assist in interpret, diagnose or investigate the
                        problem.
                      </li>
                      {/* <li className="ir-default-color ir-ws-card-list-item">
                                                    Elevated WBC
                                                </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
            <div
              className={`ir-ws-flex ir-ws-hme-prvw-panel-container ${checkActive(
                2,
                "ir-ws-tab-prvw-active"
              )}`}
            >
              {/* <div className="ir-ws-flex ir-ws-hme-prvw-panel-inner-container"> */}
              <div className="ir-ws-hme-prvw-card-container ir-ws-width-33">
                <div className="ir-ws-card-main-container">
                  <div className="ir-ws-text-center ir-ws-position-relative ir-ws-card-header">
                    <img
                      className="ir-ws-prvw-card-thumbnail-container"
                      src={require("../../assets/images/case-1.jpg")}
                      alt="Courses thumbnail"
                    />
                    <div
                      className="ir-ws-position-absolute ir-ws-prvw-card-play-icon-container"
                      onClick={navigateToCases}
                    >
                      <img
                        src={require("../../assets/images/play-icon.png")}
                        alt="play icon"
                        className="ir-ws-prvw-card-play-icon"
                      />
                    </div>
                  </div>
                  <div className="ir-ws-card-body">
                    <h4 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-card-body-title">
                      Cholecystostomy
                    </h4>
                    <ul className="ir-ws-card-body-list-container">
                      <li className="ir-default-color ir-ws-card-list-item">
                        Elderly patient presenting with acute abdominal pain.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        High risk for surgery.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Percutaneous drainage requested.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ir-ws-hme-prvw-card-container ir-ws-width-33">
                <div className="ir-ws-card-main-container">
                  <div className="ir-ws-text-center ir-ws-position-relative ir-ws-card-header">
                    <img
                      className="ir-ws-prvw-card-thumbnail-container"
                      src={require("../../assets/images/case-2.jpg")}
                      alt="Courses thumbnail"
                    />
                    <div
                      className="ir-ws-position-absolute ir-ws-prvw-card-play-icon-container"
                      onClick={navigateToCases}
                    >
                      <img
                        src={require("../../assets/images/play-icon.png")}
                        alt="play icon"
                        className="ir-ws-prvw-card-play-icon"
                      />
                    </div>
                  </div>
                  <div className="ir-ws-card-body">
                    <h4 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-card-body-title">
                      Intermittent GI bleed
                    </h4>
                    <ul className="ir-ws-card-body-list-container">
                      <li className="ir-default-color ir-ws-card-list-item">
                        Middle age patient with acute abdominal pain.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Acute on chronic pancreatitis.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Pseduocysts drainages.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Now presents with drop in Hb, requiring trnasfusions.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ir-ws-hme-prvw-card-container ir-ws-width-33">
                <div className="ir-ws-card-main-container">
                  <div className="ir-ws-text-center ir-ws-position-relative ir-ws-card-header">
                    <img
                      className="ir-ws-prvw-card-thumbnail-container"
                      src={require("../../assets/images/case-3.jpg")}
                      alt="Courses thumbnail"
                    />
                    <div
                      className="ir-ws-position-absolute ir-ws-prvw-card-play-icon-container"
                      onClick={navigateToCases}
                    >
                      <img
                        src={require("../../assets/images/play-icon.png")}
                        alt="play icon"
                        className="ir-ws-prvw-card-play-icon"
                      />
                    </div>
                  </div>
                  <div className="ir-ws-card-body">
                    <h4 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-card-body-title">
                      Acute GI Bleed
                    </h4>
                    <ul className="ir-ws-card-body-list-container">
                      <li className="ir-default-color ir-ws-card-list-item">
                        Young female with acute on chronic pancreatitis.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Underwent cystogastrostomy for drainage.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Presents with drop in Hb.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Initial negative endoscopy and CT scans.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Repeat endoscopy demonstrates massive GI bleed.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
            <div
              className={`ir-ws-flex ir-ws-hme-prvw-panel-container ${checkActive(
                3,
                "ir-ws-tab-prvw-active"
              )}`}
            >
              {/* <div className="ir-ws-flex ir-ws-hme-prvw-panel-inner-container"> */}
              <div className="ir-ws-hme-prvw-card-container ir-ws-width-33">
                <div className="ir-ws-card-main-container">
                  <div className="ir-ws-text-center ir-ws-position-relative ir-ws-card-header">
                    <img
                      className="ir-ws-prvw-card-thumbnail-container"
                      src={require("../../assets/images/courses-small-1.jpg")}
                      alt="Courses thumbnail"
                    />
                    <div
                      className="ir-ws-position-absolute ir-ws-prvw-card-play-icon-container"
                      onClick={navigateToCourses}
                    >
                      <img
                        src={require("../../assets/images/play-icon.png")}
                        alt="play icon"
                        className="ir-ws-prvw-card-play-icon"
                      />
                    </div>
                  </div>
                  <div className="ir-ws-card-body">
                    <h4 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-card-body-title">
                      Basics in Interventional Radiology
                    </h4>
                    <ul className="ir-ws-card-body-list-container">
                      <li className="ir-default-color ir-ws-card-list-item">
                        Interventional radiology (IR) is referred as surgical
                        radiology.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        It requires years of training to master the skills
                        needed to perform the wide breadth of minimally invasive
                        procedures.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        The training ranges from learning the basics to latest
                        advancements.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ir-ws-hme-prvw-card-container ir-ws-width-33">
                <div className="ir-ws-card-main-container">
                  <div className="ir-ws-text-center ir-ws-position-relative ir-ws-card-header">
                    <img
                      className="ir-ws-prvw-card-thumbnail-container"
                      src={require("../../assets/images/courses-small-2.jpg")}
                      alt="Courses thumbnail"
                    />
                    <div
                      className="ir-ws-position-absolute ir-ws-prvw-card-play-icon-container"
                      onClick={navigateToCourses}
                    >
                      <img
                        src={require("../../assets/images/play-icon.png")}
                        alt="play icon"
                        className="ir-ws-prvw-card-play-icon"
                      />
                    </div>
                  </div>
                  <div className="ir-ws-card-body">
                    <h4 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-card-body-title">
                      Women Interventions
                    </h4>
                    <ul className="ir-ws-card-body-list-container">
                      <li className="ir-default-color ir-ws-card-list-item">
                        Over the past few decades, role of Interventional
                        radiology in womens health has accelerated.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Interventional radiology is fundamental in the treatment
                        of multiple conditions that affect women.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        These including uterine fibroids, infertility, chronic
                        pelvic pain and varicose veins.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ir-ws-hme-prvw-card-container ir-ws-width-33">
                <div className="ir-ws-card-main-container">
                  <div className="ir-ws-text-center ir-ws-position-relative ir-ws-card-header">
                    <img
                      className="ir-ws-prvw-card-thumbnail-container"
                      src={require("../../assets/images/courses-small-3.jpg")}
                      alt="Courses thumbnail"
                    />
                    <div
                      className="ir-ws-position-absolute ir-ws-prvw-card-play-icon-container"
                      onClick={navigateToCourses}
                    >
                      <img
                        src={require("../../assets/images/play-icon.png")}
                        alt="play icon"
                        className="ir-ws-prvw-card-play-icon"
                      />
                    </div>
                  </div>
                  <div className="ir-ws-card-body">
                    <h4 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-card-body-title">
                      Oncologic Interventions
                    </h4>
                    <ul className="ir-ws-card-body-list-container">
                      <li className="ir-default-color ir-ws-card-list-item">
                        Interventional radiology plays a prominent role in the
                        oncological managements.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        IR input begins with establishing the initial diagnosis
                        of cancer and involvement now extends to minimally
                        invasive treatment of malignancy.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Often in combination with other modalities.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
            <div
              className={`ir-ws-flex ir-ws-hme-prvw-panel-container ${checkActive(
                4,
                "ir-ws-tab-prvw-active"
              )}`}
            >
              {/* <div className="ir-ws-flex ir-ws-hme-prvw-panel-inner-container"> */}
              <div className="ir-ws-hme-prvw-card-container ir-ws-width-33">
                <div className="ir-ws-card-main-container">
                  <div className="ir-ws-text-center ir-ws-position-relative ir-ws-card-header">
                    <img
                      className="ir-ws-prvw-card-thumbnail-container"
                      src={require("../../assets/images/web-2h.jpg")}
                      alt="Courses thumbnail"
                    />
                    <div
                      className="ir-ws-position-absolute ir-ws-prvw-card-play-icon-container"
                      onClick={navigateToWebinar}
                    >
                      <img
                        src={require("../../assets/images/play-icon.png")}
                        alt="play icon"
                        className="ir-ws-prvw-card-play-icon"
                      />
                    </div>
                  </div>
                  <div className="ir-ws-card-body">
                    <h4 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-card-body-title">
                      Gastrointestinal bleeding Interventions
                    </h4>
                    <ul className="ir-ws-card-body-list-container">
                      <li className="ir-default-color ir-ws-card-list-item">
                        A multidisciplinary perspective in the management of
                        gastrointestinal bleeding.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Speakers from pertaining fields provide a comprehensive
                        perspective in these topics to enhance the understanding
                        of the pathology and management of bleeding.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ir-ws-hme-prvw-card-container ir-ws-width-33">
                <div className="ir-ws-card-main-container">
                  <div className="ir-ws-text-center ir-ws-position-relative ir-ws-card-header">
                    <img
                      className="ir-ws-prvw-card-thumbnail-container"
                      src={require("../../assets/images/web-1h.jpg")}
                      alt="Courses thumbnail"
                    />
                    <div
                      className="ir-ws-position-absolute ir-ws-prvw-card-play-icon-container"
                      onClick={navigateToWebinar}
                    >
                      <img
                        src={require("../../assets/images/play-icon.png")}
                        alt="play icon"
                        className="ir-ws-prvw-card-play-icon"
                      />
                    </div>
                  </div>
                  <div className="ir-ws-card-body">
                    <h4 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-card-body-title">
                      Lymphatic Interventions
                    </h4>
                    <ul className="ir-ws-card-body-list-container">
                      <li className="ir-default-color ir-ws-card-list-item">
                        Lymphatics are the overflow system for the veins.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        Lymphangiogram and embolization has a great role in
                        managing the leaks.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        A multidisciplinary perspective in the assessment,
                        management of various lymphatic disorders.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ir-ws-hme-prvw-card-container ir-ws-width-33">
                <div className="ir-ws-card-main-container">
                  <div className="ir-ws-text-center ir-ws-position-relative ir-ws-card-header">
                    <img
                      className="ir-ws-prvw-card-thumbnail-container"
                      src={require("../../assets/images/web-3h.jpg")}
                      alt="Courses thumbnail"
                    />
                    <div
                      className="ir-ws-position-absolute ir-ws-prvw-card-play-icon-container"
                      onClick={navigateToWebinar}
                    >
                      <img
                        src={require("../../assets/images/play-icon.png")}
                        alt="play icon"
                        className="ir-ws-prvw-card-play-icon"
                      />
                    </div>
                  </div>
                  <div className="ir-ws-card-body">
                    <h4 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-card-body-title">
                      Venous sampling
                    </h4>
                    <ul className="ir-ws-card-body-list-container">
                      <li className="ir-default-color ir-ws-card-list-item">
                        Venous sampling procedures are generally safe and useful
                        procedures to localize abnormal tissue and tumors
                        including in the pituitary, parathyroids, pancreas,
                        adrenals, kidneys, and ovaries.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        It is imperative for the competent interventional
                        radiologist to understand the clinical, anatomical, and
                        technical aspects of the procedures should the need
                        arise in their clinical setting.
                      </li>
                      <li className="ir-default-color ir-ws-card-list-item">
                        A multidisciplinary perspective in the assessing the
                        role of venous samplings, techniques and knowledge
                        sharing.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="ir-ws-hme-prvw-signup-container">
        <div className="ir-ws-hme-prvw-signup-inner-container">
          <div className="ir-ws-prvw-signup-content-container">
            <h4 className="ir-ws-heading-default-color ir-ws-prvw-signup-title">
              <span className="ir-ws-text-uppercase">Sign up</span> to Access
              All These Features and More
            </h4>
            <div className="ir-ws-flex ir-ws-align-center ir-ws-prvw-signup-dot-main-container">
              <div className="ir-ws-prvw-signup-dot-small"></div>
              <div className="ir-ws-prvw-signup-dot-small"></div>
              <div className="ir-ws-prvw-signup-dot-large"></div>
            </div>
            <p className="ir-default-color">
              Communicate with experts and interact with the best in the
              Interventional Radiology field.{" "}
            </p>
          </div>
          <div className="ir-ws-prvw-signup-btn-container">
            <a
              href="/signup"
              className="ir-ws-app-bg ir-ws-text-uppercase ir-color-white ir-ws-no-border ir-ws-prvw-signup-btn"
            >
              <span>Join IR4U it's free</span>
            </a>
          </div>
        </div>
      </div>
      {modalChatOne ? (
        <section className="modal__bg">
          <div className="modal__align">
            <div className="modal__content" modal={modalChatOne}>
              <IoCloseOutline
                className="modal__close"
                arial-label="Close modal"
                onClick={closeModalChatOne}
              />
              <div className="modal__video-align">
                {videoLoading ? (
                  <div className="modal__spinner">
                    <BiLoaderAlt
                      className="modal__spinner-style"
                      fadeIn="none"
                    />
                  </div>
                ) : null}
                <iframe
                  className="modal__video-style"
                  onLoad={spinner}
                  loading="lazy"
                  width="800"
                  height="500"
                  src="https://d3skdvaex782lg.cloudfront.net/ir4u-chatvideo-1.1-finaledit.mp4"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      ) : null}
      {modalChatTwo ? (
        <section className="modal__bg">
          <div className="modal__align">
            <div className="modal__content" modal={modalChatTwo}>
              <IoCloseOutline
                className="modal__close"
                arial-label="Close modal"
                onClick={closeModalChatTwo}
              />
              <div className="modal__video-align">
                {videoLoading ? (
                  <div className="modal__spinner">
                    <BiLoaderAlt
                      className="modal__spinner-style"
                      fadeIn="none"
                    />
                  </div>
                ) : null}
                <iframe
                  className="modal__video-style"
                  onLoad={spinner}
                  loading="lazy"
                  width="800"
                  height="500"
                  src="https://d3skdvaex782lg.cloudfront.net/ir4u-chatvideo-2.1-finaledit.mp4"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      ) : null}
      {modalChatThree ? (
        <section className="modal__bg">
          <div className="modal__align">
            <div className="modal__content" modal={modalChatThree}>
              <IoCloseOutline
                className="modal__close"
                arial-label="Close modal"
                onClick={closeModalChatThree}
              />
              <div className="modal__video-align">
                {videoLoading ? (
                  <div className="modal__spinner">
                    <BiLoaderAlt
                      className="modal__spinner-style"
                      fadeIn="none"
                    />
                  </div>
                ) : null}
                <iframe
                  className="modal__video-style"
                  onLoad={spinner}
                  loading="lazy"
                  width="800"
                  height="500"
                  src="https://d3skdvaex782lg.cloudfront.net/ir4u-chatvideo-3.1-finaledit.mp4"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </div>
  );
};

export default HomePreviewTabContainer;
