import React, { useState } from "react";
import ReactQuill from "react-quill";
import ConfirmationModal from "../../Courses/AdminCreateCourseTab/ConfirmationModal";

const MultipleChoiceQuestion = ({
  onDelete,
  id,
  onQuestionDataChange,
  question,
  answers,
  correctAnswer,
}) => {
  const [questionData, setQuestionData] = useState({
    question: question || "",
    answers: answers || ["", "", ""],
    correctAnswer: correctAnswer || "",
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteClick = () => {
    onDelete(id);
  };

  const modules = {
    toolbar: [["bold", "italic"]],
  };

  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...questionData.answers];
    updatedAnswers[index] = value;
    setQuestionData({ ...questionData, answers: updatedAnswers });
    onQuestionDataChange({ ...questionData, answers: updatedAnswers }, id);
  };

  const handleCorrectAnswerChange = (value) => {
    setQuestionData({ ...questionData, correctAnswer: value });
    onQuestionDataChange({ ...questionData, correctAnswer: value }, id);
  };

  const handleQuestionChange = (content) => {
    setQuestionData({ ...questionData, question: content });
    onQuestionDataChange({ ...questionData, question: content }, id);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  return (
    <div className="ir-ws-multiple-choice-question-container">
      <div className="ir-ws-multiple-choice-question-box">
        <ReactQuill
          placeholder="Type Multiple Choice Question"
          theme="snow"
          modules={modules}
          value={questionData?.question}
          onChange={handleQuestionChange}
        />

        <svg
          height="30"
          viewBox="0 0 48 48"
          width="30"
          xmlns="http://www.w3.org/2000/svg"
          className="ir-ws-creat-case-icon ir-ws-creat-case-icon-margin"
          onClick={toggleDeleteModal}
        >
          <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
          <path d="M0 0h48v48H0z" fill="none" />
        </svg>
      </div>
      <div className="ir-ws-create-course-mcq-option-container">
        {questionData.answers.map((answer, index) => (
          <input
            key={index}
            placeholder={`Type answer ${String.fromCharCode(65 + index)}`}
            className="ir-ws-create-course-input-tag"
            value={questionData.answers[index]}
            onChange={(e) => handleAnswerChange(index, e.target.value)}
          />
        ))}
      </div>
      <div className="ir-ws-create-course-mcq-answer-container">
        <input
          placeholder="Type correct answer"
          className="ir-ws-create-course-input-tag"
          value={questionData?.correctAnswer}
          onChange={(e) => handleCorrectAnswerChange(e.target.value)}
        />
      </div>
      {showDeleteModal && (
        <ConfirmationModal
          toggleModal={toggleDeleteModal}
          confirmationFunc={handleDeleteClick}
          title="delete"
        />
      )}
    </div>
  );
};

export default MultipleChoiceQuestion;
