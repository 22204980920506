import React, { useState, useRef } from "react";
import "./CreateCourse.scss";
import CreateCourseFirstPage from "./CreateCoursePage/CreateCourseFirstPage";
import CreateCourseSecondPage from "./CreateCoursePage/CreateCourseSecondPage";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";

const CreateCourse = () => {
  // const [currentPage, setCurrentPage] = useState(1);
  // const handleSaveAndContinue = () => {
  //   setCurrentPage(2);
  // };
  const { handleSaveAndContinue, currentPage, setCurrentPage } =
    useModalContext();
  return (
    <div className="ir-ws-create-course-container">
      <h2 className="ir-ws-create-course-heading">Create Courses</h2>
      {currentPage === 1 ? (
        <CreateCourseFirstPage onSaveAndContinue={handleSaveAndContinue} />
      ) : (
        <CreateCourseSecondPage
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};

export default CreateCourse;
