import React, { useCallback, useEffect, useState, useRef } from "react";

const ImageSequence = ({
  canvasRefSequence,
  panZoomActiveSequence,
  offsetSequence,
  setOffsetSequence,
  scaleSequence,
  containerRefSequence,
  setScaleSequence,
  slideSequenceImage,
  scrollActive,
  resetSequence,
  setScrollActive,
  setPanZoomActiveSequence,
  activeSlideObject,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [start, setStart] = useState({ x: 0, y: 0 });
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState([]);
  const imagesRef = useRef([]);

  // Preload all images
  useEffect(() => {
    const preloadImages = () => {
      const loadedImages = [];
      slideSequenceImage.forEach((image) => {
        const img = new Image();
        img.src = image.url;
        img.onload = () => {
          loadedImages.push(img);
          if (loadedImages.length === slideSequenceImage.length) {
            setImages(loadedImages);
            imagesRef.current = loadedImages;
            drawImage(); // Draw the image after loading
          }
        };
      });
    };
    preloadImages();
  }, [slideSequenceImage, activeSlideObject]);

  const updateCanvasSize = useCallback(() => {
    if (!canvasRefSequence.current || !containerRefSequence.current) return;
    const canvas = canvasRefSequence.current;
    const container = containerRefSequence.current;

    canvas.width = container.clientWidth;
    canvas.height = container.clientHeight;
  }, [canvasRefSequence, containerRefSequence]);

  useEffect(() => {
    if (canvasRefSequence.current) {
      updateCanvasSize();
      drawImage();
    }
  }, [
    scaleSequence,
    offsetSequence,
    currentImageIndex,
    canvasRefSequence,
    containerRefSequence,
    activeSlideObject,
  ]);

  const drawImage = () => {
    const canvas = canvasRefSequence.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const img = imagesRef.current[currentImageIndex];
    if (!img) return;

    const { width: imgWidth, height: imgHeight } = img;
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;

    // Calculate aspect ratios
    const imgAspectRatio = imgWidth / imgHeight;
    const canvasAspectRatio = canvasWidth / canvasHeight;

    let drawWidth, drawHeight;

    if (imgAspectRatio > canvasAspectRatio) {
        // Image is wider than canvas, fit to width
        drawWidth = canvasWidth * scaleSequence;
        drawHeight = drawWidth / imgAspectRatio;
    } else {
        // Image is taller or has equal aspect ratio to canvas, fit to height
        drawHeight = canvasHeight * scaleSequence;
        drawWidth = drawHeight * imgAspectRatio;
    }

    // Calculate the position to center the image with offset
    const xPos = offsetSequence.x + (canvasWidth - drawWidth) / 2;
    const yPos = offsetSequence.y + (canvasHeight - drawHeight) / 2;

    // Clear the canvas before drawing the new image
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);

    // Draw the image on the canvas with scaling and panning
    ctx.drawImage(img, xPos, yPos, drawWidth, drawHeight);
};


  const handleMouseDown = (e) => {
    if (!panZoomActiveSequence) return;
    if (e.button !== 0) return; // Check if left mouse button is clicked
    setIsDragging(true);
    setStart({ x: e.clientX - offsetSequence.x, y: e.clientY - offsetSequence.y });
  };

  const handleMouseMove = (e) => {
    if (!isDragging || !panZoomActiveSequence) return;
    const newOffset = {
      x: e.clientX - start.x,
      y: e.clientY - start.y,
    };

    setOffsetSequence(newOffset);
  };

  const handleMouseUp = () => {
    if (!panZoomActiveSequence) return;
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    if (!panZoomActiveSequence) return;
    setIsDragging(false);
  };

//   const handleWheel = (e) => {
//     if (scrollActive) {
//       e.preventDefault();
//       e.stopPropagation();

//       const delta = Math.sign(e.deltaY); // Scroll direction: 1 for down, -1 for up
//       const newIndex = Math.max(
//         0,
//         Math.min(currentImageIndex + delta, slideSequenceImage.length - 1)
//       );

//       if (newIndex !== currentImageIndex) {
//         setCurrentImageIndex(newIndex);
//         drawImage(); // Re-draw the new image
//       }
//     } else if (panZoomActiveSequence) {
//       const scaleFactor = 1.1;
//       const newScale =
//         e.deltaY > 0
//           ? scaleSequence / scaleFactor
//           : scaleSequence * scaleFactor;

//       setScaleSequence(newScale);

//       e.preventDefault();
//       e.stopPropagation();
//     }
//   };

  useEffect(() => {
    const container = containerRefSequence.current;

    const handleWheel = (e) => {
        if (scrollActive) {
            e.preventDefault();
            e.stopPropagation();

            const delta = Math.sign(e.deltaY); // Scroll direction: 1 for down, -1 for up
            const newIndex = Math.max(
                0,
                Math.min(currentImageIndex + delta, slideSequenceImage.length - 1)
            );

            if (newIndex !== currentImageIndex) {
                setCurrentImageIndex(newIndex);
                drawImage(); // Re-draw the new image
            }
        } else if (panZoomActiveSequence) {
            e.preventDefault();
            e.stopPropagation();

            const scaleFactor = 1.1;
            const minScale = 0.15; // Minimum zoom level (50% of the original size)
            const maxScale = 10; // Maximum zoom level (300% of the original size)

            let newScale =
                e.deltaY > 0
                    ? scaleSequence / scaleFactor
                    : scaleSequence * scaleFactor;

            // Restrict the scale to the specified min and max levels
            newScale = Math.max(minScale, Math.min(newScale, maxScale));

            setScaleSequence(newScale);
        }
    };

    if (container) {
        container.addEventListener("wheel", handleWheel, { passive: false });
        return () => {
            container.removeEventListener("wheel", handleWheel);
        };
    }
}, [
    panZoomActiveSequence,
    scaleSequence,
    offsetSequence,
    scrollActive,
    currentImageIndex,
    slideSequenceImage,
    canvasRefSequence,
    containerRefSequence,
]);


  useEffect(() => {
    if (resetSequence) {
      setCurrentImageIndex(0);
      setOffsetSequence({ x: 0, y: 0 });
      setScaleSequence(1);
      setScrollActive(true);
      setPanZoomActiveSequence(false);
      drawImage(); // Reset and redraw the initial image
    }
  }, [resetSequence]);

  return (
    <canvas
      ref={canvasRefSequence}
      style={{
        width: "100%",
        height: "100%",
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
    />
  );
};

export default ImageSequence;
