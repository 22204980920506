import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import './Privacy.scss';

const Terms = () => {
    return(
        <div className="ir-ws-privacy-main-container">
            <Header />
            <div className="ir-ws-padding-lr-132 ir-ws-about-main-container ir-ws-gray-bg">
                <div className="ir-ws-inner-privacy-main-container">
                    <h2 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-hme-cases-title ir-ws-about-heading-container ir-ws-privacy-main-heading">Terms and Conditions for IR4U</h2>
                    <div className="ir-ws-privacy-policy-content-container">
                        <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">Welcome to IR4U's Privacy Policy</h3>
                        <p className="ir-ws-heading-default-color ir-ws-privacy-para">
                            By accessing and using the services provided by IR4U ("we," "our," or "us"), you agree to be bound by the following terms and conditions. 
                            Please read these terms carefully before using our services.
                        </p>
                    </div>
                    <div className="ir-ws-privacy-policy-content-container">
                        <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">Acceptance of Terms</h3>
                        <p className="ir-ws-heading-default-color ir-ws-privacy-para">
                            By accessing or using our services, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. 
                            If you do not agree with any part of these terms, please do not use our services.
                        </p>
                    </div>
                    <div className="ir-ws-privacy-policy-content-container">
                        <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">Use of Services</h3>
                        <p className="ir-ws-heading-default-color ir-ws-privacy-para">
                            You may use our services for personal or business purposes, provided that you comply with these terms and all applicable laws and regulations.
                        </p>
                    </div>
                    <div className="ir-ws-privacy-policy-content-container">
                        <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">User Accounts</h3>
                        <ul className="ir-ws-privacy-list-container">
                            <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                                To access certain features of our services, you may be required to create a user account. 
                                You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
                            </li>
                            <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                                You agree to provide accurate and complete information when creating your account and to update this information as necessary to keep it accurate.
                            </li>
                        </ul>
                    </div>
                    <div className="ir-ws-privacy-policy-content-container">
                        <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">Privacy</h3>
                        <p className="ir-ws-heading-default-color ir-ws-privacy-para">
                            Your use of our services is also governed by our Privacy Policy, which can be found in <a href="/privacy">Privacy and Policy</a>. By using our services, you consent to the collection, use, and sharing of your information as described in the Privacy Policy.
                        </p>
                    </div>
                    <div className="ir-ws-privacy-policy-content-container">
                        <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">Intellectual Property</h3>
                        <ul className="ir-ws-privacy-list-container">
                            <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                                All content, trademarks, logos, and intellectual property on our website and services are owned by or licensed to IR4U and are protected by copyright and other intellectual property laws.
                            </li>
                            <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                                You may not use, reproduce, distribute, or display any of our content without our prior written permission.
                            </li>
                        </ul>
                    </div>
                    <div className="ir-ws-privacy-policy-content-container">
                        <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">Prohibited Activities</h3>
                        <p className="ir-ws-heading-default-color ir-ws-privacy-para">You agree not to engage in any of the following activities:</p>
                        <ul className="ir-ws-privacy-list-container">
                            <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                                Violating any applicable laws or regulations.
                            </li>
                            <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                                Transmitting any harmful or malicious code, viruses, or malware.
                            </li>
                            <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                                Attempting to gain unauthorized access to our systems or networks.
                            </li>
                            <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                                Interfering with or disrupting the operation of our services.
                            </li>
                            <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                                Impersonating another person or entity.
                            </li>
                            <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                                Harassing, abusing, or threatening other users or our staff.
                            </li>
                        </ul>
                    </div>
                    <div className="ir-ws-privacy-policy-content-container">
                        <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">Termination</h3>
                        <p className="ir-ws-heading-default-color ir-ws-privacy-para">We reserve the right to terminate or suspend your access to our services at our discretion, without notice, for any violation of these terms or for any other reason.</p>
                    </div>
                    <div className="ir-ws-privacy-policy-content-container">
                        <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">Disclaimers and Limitations of Liability</h3>
                        <ul className="ir-ws-privacy-list-container">
                            <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                                Our services are provided "as is" and "as available" without warranties of any kind, either express or implied.
                            </li>
                            <li className="ir-ws-heading-default-color ir-ws-privacy-list">
                                We are not liable for any direct, indirect, incidental, special, or consequential damages arising from your use of our services.
                            </li>
                        </ul>
                    </div>
                    <div className="ir-ws-privacy-policy-content-container">
                        <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">Governing Law and Dispute Resolution</h3>
                        <p className="ir-ws-heading-default-color ir-ws-privacy-para">
                            These terms are governed by the laws of the U.S. District Courts and Southern Districts of Ohio. 
                            Any disputes arising from or relating to these terms shall be resolved through arbitration in accordance with the rules of the IR4U in Cleveland, OH.
                        </p>
                    </div>
                    <div className="ir-ws-privacy-policy-content-container">
                        <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">Changes to Terms</h3>
                        <p className="ir-ws-heading-default-color ir-ws-privacy-para">
                            TWe reserve the right to update or modify these terms and conditions at any time. 
                            Any changes will be posted on our website, and the date of the last revision will be updated. 
                            Your continued use of our services after any changes indicate your acceptance of the updated terms.
                        </p>
                    </div>
                    <div className="ir-ws-privacy-policy-content-container">
                        <h3 className="ir-ws-heading-default-color ir-ws-privay-subtitle">Contact Information</h3>
                        <p className="ir-ws-heading-default-color ir-ws-privacy-para">
                            If you have any questions, concerns, or requests regarding your personal information or this Privacy Policy, please contact us at:
                            <br />
                            +1 216 236 4624 <br />
                            Cleveland, OH <br />
                            info@ir4u.info
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Terms;