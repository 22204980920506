import { useState } from "react";
import WebinarAdminCreateWebinarTab from "../WebinarAdminCreateWebinarTab/WebinarAdminCreateWebinarTab";
import WebinarAdminCustomTab from "../WebinarAdminCustomTab/WebinarAdminCustomTab";
import "./WebinarAdminContent.scss";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";
import WebinarAdminUserSection from "../WebinarAdminUserSection/WebinarAdminUserSection";
import CourseAdminContent from "../../pages/Courses/CourseAdminContent/CourseAdminContentTab";
import AdminCreateCourseContent from "../../pages/Courses/CourseAdminContent/AdminCreateCourseContent";
import ChatAdminRoomContent from "../../pages/ChatAdminRoom/ChatAdminRoomContent";
import ChatAdminRoomTab from "../../pages/ChatAdminRoom/ChatAdminRoomTab";
import { PaymentProvider } from "../../context/PaymentContext";
import { BaseURL as ENDPOINT } from "../../api/axios";
import { io } from "socket.io-client";
import CaseAdminContent from "../../pages/AdminCases/CaseAdminContent";
import AdminCreateCaseContent from "../../pages/AdminCases/AdminCreateCaseContent";
const WebinarAdminContent = () => {
  const [toggle, setToggle] = useState(false);
  // const [activeTab, setActiveTab] = useState(1);
  const { setActiveAltTitle, activeAltTitle } = useModalContext();

  const socketio = io(ENDPOINT, {
    autoConnect: false,
    reconnectionDelayMax: 10000,
    auth: {
      token: "Bearer " + localStorage.getItem("jwtTokenApi2"),
    },
    query: {
      "my-key": "my-value",
    },
  });
  return (
    <div className="ir-ws-width-100 ir-ws-webiner-admin-container">
      <div className="ir-ws-height-100-percent ir-ws-position-relative ir-ws-webiner-admin-inner-container">
        {activeAltTitle === "Webinar" && (
          <>
            <p className="ir-ws-webinar-text">Webinar</p>
            <WebinarAdminCustomTab toggle={toggle} setToggle={setToggle} />
            <WebinarAdminCreateWebinarTab
              toggle={toggle}
              setToggle={setToggle}
            />
          </>
        )}
        {activeAltTitle === "User" && (
          <>
            <p className="ir-ws-webinar-text">User</p>
            <WebinarAdminUserSection />
          </>
        )}
        {activeAltTitle === "Courses" && (
          <>
            <p className="ir-ws-webinar-text">Courses </p>
            {/* <WebinarAdminCustomTab toggle={toggle} setToggle={setToggle} /> */}
            <CourseAdminContent toggle={toggle} setToggle={setToggle} />
            <AdminCreateCourseContent toggle={toggle} setToggle={setToggle} />
          </>
        )}
        {activeAltTitle === "Chat Rooms" && (
          <>
            <p className="ir-ws-webinar-text">Chat Rooms</p>
            {/* <WebinarAdminCustomTab toggle={toggle} setToggle={setToggle} /> */}
            <ChatAdminRoomContent />
            <PaymentProvider>
              <ChatAdminRoomTab socket={socketio} />
            </PaymentProvider>
            {/* <AdminCreateCourseContent toggle={toggle} setToggle={setToggle} /> */}
          </>
        )}
        {activeAltTitle === "Cases" && (
          <>
            <p className="ir-ws-webinar-text">Cases </p>
            {/* <CourseAdminContent toggle={toggle} setToggle={setToggle} /> */}
            {/* <AdminCreateCourseContent toggle={toggle} setToggle={setToggle} /> */}
            <CaseAdminContent toggle={toggle} setToggle={setToggle} />
            <AdminCreateCaseContent toggle={toggle} setToggle={setToggle} />
          </>
        )}
      </div>
    </div>
  );
};

export default WebinarAdminContent;
