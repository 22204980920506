import { useState } from "react";
import CreateCourse from "../AdminCreateCourseTab/CreateCourse";
import "./AdminCreateCourseContent.scss";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";
import AdminCourseList from "./AdminCourseList";
import AdminCourseTrashList from "./AdminCourseTrashList";
import AdminCourseDraft from "./AdminCourseDraft";

const AdminCreateCourseContent = ({ toggle, setToggle }) => {
  // const [createCourseOpen, setCreatecourseOpen] = useState(false)
  // const handleCreateCourse = () => {
  //   setCreatecourseOpen(true)
  // }
  const {
    activeTabCourse,
    setActiveTabCourse,
    handleCreateCourse,
    createCourseOpen,
  } = useModalContext();

  return (
    <div className="ir-ws-admin-create-webinar-tab ir-ws-webinar-tb-main-container ir-ws-admin-create-course-container">
      {activeTabCourse === 1 && !createCourseOpen && (
        <div className="ir-ws-create-btn-container ir-ws-create-webinar-button-container">
          <button
            className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-banner-btn"
            onClick={handleCreateCourse}
          >
            <span>Create Course</span>
          </button>
        </div>
      )}
      {activeTabCourse === 1 && createCourseOpen && <CreateCourse />}
      {activeTabCourse === 2 && (
        <div>
          <AdminCourseList />
        </div>
      )}
      {activeTabCourse === 3 && (
        <div>
          <AdminCourseTrashList />
        </div>
      )}
      {activeTabCourse === 4 && (
        <div>
          <AdminCourseDraft />
        </div>
      )}
    </div>
  );
};

export default AdminCreateCourseContent;
