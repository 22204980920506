import React, { useEffect, useState } from "react";
import "./CasesList.scss";
import { useLocation, useNavigate } from "react-router-dom";
import ModalPlayer from "../../pages/Courses/CoursePreview/ModalPlayer";
import BookNowModal from "../HomeWebinarFeatureContainer/BookNowModal";
import TermsAndConditionModal from "../HomeWebinarFeatureContainer/TermsAndConditionModal";
import axios from "axios";
import kidneyImage from "../../assets/images/kidney-white.png";
import heartImage from "../../assets/images/heart-white.png";
import liverImage from "../../assets/images/liver-white.png";
import lungsImage from "../../assets/images/lungs-white.png";
import pancreasImage from "../../assets/images/pancreas-white.png";
import stomachImage from "../../assets/images/stomach-white.png";
import brainImage from "../../assets/images/brain-white.png";
import bladderImage from "../../assets/images/bladder-white.png";
import chestImage from "../../assets/images/chest-white-icon.png";
import gastrointestinalImage from "../../assets/images/gastrointestinal-white-icon.png";
import genitourinaryImage from "../../assets/images/g3.png";
import vascularImage from "../../assets/images/vascular-white-icon.png";
import lymphaticsImage from "../../assets/images/lymphatic-white-icon.png";
import traumaImage from "../../assets/images/trauma-white-icon.png";
import venousImage from "../../assets/images/venous-white-icon.png";
import bleedImage from "../../assets/images/bleeding-white-icon.png";
import womensHealthImage from "../../assets/images/womens-health-white-icon.png";
import mensHealthImage from "../../assets/images/mens-health-white-icon.png";
import bundleImage from "../../assets/images/bundle-white.png";

const CasesList = ({ cases, casesListRef }) => {
  const [casesToShow, setCasesToShow] = useState(6);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [videoSource, setVideoSource] = useState("");
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [bookNowModal, setBookNowModal] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [username, setUsername] = useState("");
  const [userEmail, setUseremail] = useState("");
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
  const userId = localStorage.getItem("userId");
  const [caseId, setCaseId] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [userBoughtCases, setUserBoughtCases] = useState([]);

  const { pathname } = useLocation();

  const categoryImageMap = {
    Chest: chestImage,
    "⁠Gastrointestinal": gastrointestinalImage,
    Liver: liverImage,
    "⁠Genitourinary": genitourinaryImage,
    Vascular: vascularImage,
    Lymphatics: lymphaticsImage,
    "T⁠rauma": traumaImage,
    Venous: venousImage,
    "⁠Bleed": bleedImage,
    "⁠Women’s health": womensHealthImage,
    "⁠Men’s health": mensHealthImage,
    Bundle: bundleImage,
  };

  const handleToggleVideo = (source) => {
    setVideoSource(source);
    setVideoModalOpen(!videoModalOpen);
    document.body.classList.toggle("modal-open");
  };
  // const [reversedCases, setReversedCases] = useState([...cases].reverse());
  console.log(cases);
  function stripHtmlTags(html, maxLength) {
    let doc = new DOMParser().parseFromString(html, "text/html");
    let plainText = doc.body.innerText;
    return plainText.length > maxLength
      ? plainText.slice(0, maxLength) + "..."
      : plainText;
  }

  const navigate = useNavigate();

  const handleShowMoreCases = () => {
    setCasesToShow(casesToShow + 6);
  };

  const handleShowLessCases = () => {
    setCasesToShow(casesToShow - 6);
  };
  useEffect(() => {
    const fetchCaseTransactions = async () => {
      try {
        const token = localStorage.getItem("jwtTokenApi1");
        const response = await axios.get(
          `https://case.ir4u.info/api/v1/user/case/transaction/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const caseTransactionData = response.data;
        console.log("caseTransactionData", caseTransactionData);
        setTransactions(caseTransactionData);
      } catch (error) {
        console.error("Error fetching case transactions:", error);
      }
    };
    fetchCaseTransactions();
  }, []);
  // console.log(reversedCases);
  useEffect(() => {
    const boughtCases = transactions
      ?.filter((transaction) => transaction?.status === "SUCCESS")
      ?.map((transaction) => transaction?.productId);
    setUserBoughtCases(boughtCases);
  }, [transactions]);
  const handleTermsAndCondition = (id) => {
    setTermsAndCondition(!termsAndCondition);
    setCaseId(id);
  };

  useEffect(() => {
    const userProfileDetails = async () => {
      try {
        const response = await axios.get(
          `https://backend.ir4u.info/api/v1/user/profile`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );
        // console.log("User profile from 1st api response", response);
        setUseremail(response.data?.email);
        setUsername(response.data?.username);
        // setUserId(response.data?.id);
        if (response.data?.isEmailVerified) {
          setEmailVerified(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    userProfileDetails();
  }, []);
  const buyNewCase = (id) => {
    // To navigate to the previous URL
    localStorage.setItem("prevURL", pathname);

    if (loggedIn) {
      if (emailVerified) {
        // handleRegisterUserMeeting(webinar.id);
        // handleBuyNowClick(webinar);
        handleTermsAndCondition(id);
        // console.log("CLicked");
        // setSendWebinarId(webinar.id);
      } else {
        setBookNowModal(true);
      }
    } else if (!loggedIn) {
      setBookNowModal(true);
    }
  };

  const BuyConfirmationFunc = async () => {
    const data = {
      caseId: caseId,
      userEmail: userEmail,
      successUrl: `${origin}/success`,
      cancelUrl: `${origin}/failed`,
      userId: userId,
    };
    const token = localStorage.getItem("jwtTokenApi1");
    // console.log(courseData);
    try {
      const response = await axios.post(
        "https://case.ir4u.info/api/v1/user/case/create-checkout-session",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response);
      if (response.status === 200) {
        const sessionUrl = response.data.url;
        window.location.href = sessionUrl;
      }
    } catch (error) {
      console.log(error, "error while buying the case");
    }
  };

  return (
    <div className="ir-cs-list-main-container" ref={casesListRef}>
      <div className="ir-ws-banner-padding-tb ir-ws-padding-lr-132 ir-cs-inner-list-container">
        <h2 className="ir-ws-heading-default-color active-case-heading">
          Active Cases
        </h2>
        <div className="ir-cs-list-grid-container">
          {cases
            .reverse()
            ?.slice(0, casesToShow)
            ?.map((caseItem, index) => {
              const slides = caseItem.slides || [];
              const firstSlide = slides[0] || {};
              const caseImages = firstSlide.caseImages || [];
              const imageUrl = caseItem?.thumbnail;
              const title = firstSlide?.name;
              const description = firstSlide?.description;
              const price = caseItem.casePrice || 0;
              const previewVideo = firstSlide?.caseVideo;
              const caseBought = userBoughtCases?.includes(caseItem._id);
              const categoryImage = categoryImageMap[caseItem?.categorie];
              return (
                <div className="ir-cs-list-grid-ind-container" key={index}>
                  <div className="ir-cs-list-grid-ind-inner">
                    <div className="ir-ws-flex ir-ws-course-list-main-box ir-ws-case-list-main-box">
                      <div className="ir-cs-list-grid-img-container">
                        <img
                          className=" ir-cs-case-list-grid-img "
                          src={
                            imageUrl ||
                            require("../../assets/images/placeholder.jpg")
                          }
                          alt="case-image"
                        />

                        <div className="catogey-img-container">
                          <div className="cat-main">
                            <img src={categoryImage} alt="category" />
                          </div>
                          {/* <h4>Title</h4> */}
                        </div>
                      </div>
                      <div className="ir-cs-list-grid-content-container">
                        <div className="ir-cs-list-grid-content">
                          {/* <h3 className="cl-case-title ">{caseItem.bundleTitle? caseItem.bundleTitle: firstSlide?.name}</h3> */}
                          <h3 className="cl-case-title">
                            {caseItem.categorie === 'Bundle' ? caseItem.bundleTitle : firstSlide?.name}
                          </h3>
                          <p
                            className="ir-default-color cl-case-description"
                            title={stripHtmlTags(firstSlide?.description)}
                          >
                            {stripHtmlTags(firstSlide?.description, 100)}
                          </p>
                        </div>
                        <div className="ir-ws-flex ir-cs-list-grid-price-read-container price-buy-container">
                          {caseBought || isAdmin ? (
                            <div
                              className="ir-cs-list-grid-price-container"
                              // style={{
                              //   ...(isAdmin
                              //     ? { pointerEvents: "none", cursor: "default" }
                              //     : {}),
                              // }}
                              // disabled={isAdmin}
                              // onClick={() => buyNewCase(caseItem._id)}
                              onClick={() => navigate(`/case/${caseItem._id}`)}
                            >
                              View
                            </div>
                          ) : (
                            <div
                              className="ir-cs-list-grid-price-container"
                              style={{
                                ...(isAdmin
                                  ? { pointerEvents: "none", cursor: "default" }
                                  : {}),
                              }}
                              disabled={isAdmin}
                              onClick={() => buyNewCase(caseItem._id)}
                            >
                              Buy
                            </div>
                          )}
                          {caseBought ? null : (
                            <div className="price-continer">
                              <img
                                src={require("../../assets/images/shopping.png")}
                                alt="users"
                                className="price-tag-icon"
                              />
                              <h4 className="ir-case-pice-tag">${price}</h4>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="ir-ws-course-show-button-container">
          <div className="max-w-1200">
            {casesToShow > 6 && (
              <>
                <div
                  onClick={handleShowLessCases}
                  className="ir-ws-course-homepage-show-more-text"
                >
                  <p>Show Less</p>
                </div>
                <div className="ir-ws-course-homepage-show-more-text">
                  <p>|</p>
                </div>
              </>
            )}

            {cases.reverse().length > casesToShow && (
              <div
                onClick={handleShowMoreCases}
                className="ir-ws-course-homepage-show-more-text"
              >
                <p>Show More</p>
              </div>
            )}
          </div>
        </div>
        {/* <div className="ir-ws-webinar-list-page-dotted-border"></div> */}
      </div>
      {videoModalOpen && (
        <ModalPlayer videoSource={videoSource} onClick={handleToggleVideo} />
      )}
      {bookNowModal && loggedIn && !emailVerified && (
        <BookNowModal
          type="Verify Email"
          emailVerified={!emailVerified}
          loggedIn={loggedIn}
          username={username}
          setBookNowModal={setBookNowModal}
          category={"Case"}
        />
      )}
      {bookNowModal && !loggedIn && (
        <BookNowModal
          type="Sign up"
          loggedIn={!loggedIn}
          setBookNowModal={setBookNowModal}
          category={"Case"}
        />
      )}
      {termsAndCondition && (
        <TermsAndConditionModal
          handleTermsAndCondition={handleTermsAndCondition}
          id={caseId}
          confirmationFunc={BuyConfirmationFunc}
        />
      )}
    </div>
  );
};

export default CasesList;
