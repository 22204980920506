import React, { useState } from "react";
import axios from "../../api/axios";
import Swal from "sweetalert2";

const ForgotPassword = () => {
  const [username, setUsername] = useState("");
  const [checkMail, setCheckMail] = useState(false);
  const locationUrl = window.location.origin;
  const resetPassword = async (username) => {
    try {
      if (username) {
        const response = await axios.post(
          `https://backend.ir4u.info/api/v1/reset/password?username=${username}`,
          {},
          {
            headers: {
              apphost: locationUrl,
            },
          }
        );
        if (response.status === 200) {
          // Swal.fire(
          //   "Success",
          //   "Password reset link send to your email",
          //   "success"
          // );
          setCheckMail(true);
        }
      } else {
        throw new Error("Username Missing");
      }
    } catch (error) {
      if(error.response.data.message === "Email address or username not valid.") {
        Swal.fire(
          "Invalid Username",
          "Username doesn't exists, please provide valid username",
          "warning"
        );
      }
    }
  };
  return (
    <div className="ir-ws-sign-in-main-container">
      <div className="ir-ws-signin-inner-container ir-ws-sign-inner-container">
        {!checkMail && (
          <div className="ir-ws-sign-in-content-container">
            <div className="ir-ws-signin-logo-container">
              <div className="ir-ws-text-center ir-ws-signin-logo-inner-container">
                <a href="/">
                  <img
                    className="ir-ws-signin-logo-img"
                    src={require("../../assets/images/ir4u2.png")}
                    alt="logo"
                  />
                </a>
              </div>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                //   handleSubmit(username);
                resetPassword(username);
              }}
            >
              <div className="ir-ws-signup-content-inner-container">
                <h3
                  className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title"
                  style={{
                    fontSize: "18px",
                    lineHeight: "1.5",
                    marginTop: "30px",
                  }}
                >
                  Enter username to receive a password reset link in your email
                </h3>
              </div>
              <div className="ir-ws-signup-form-container">
                <div className="ir-ws-signup-content-group">
                  <input
                    className={"ir-ws-signup-input-field"}
                    type="text"
                    required="required"
                    autoComplete="off"
                    value={username}
                    name="username"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <label className="ir-ws-signup-label">Username</label>
                  <span className="ir-ws-signup-highlight"></span>
                  <span className="ir-ws-signup-bar"></span>
                </div>
                <div className="ir-ws-text-center ir-ws-signup-form-submit-container">
                  <button className="ir-ws-app-bg ir-ws-no-border ir-color-white ir-ws-sign-submit-btn">
                    Submit
                  </button>
                </div>
              </div>
            </form>
            <div className="ir-ws-signup-form-footer-container">
              <div className="ir-ws-sign-footer-lin-container">
                <p className="ir-default-color">
                  Not having an account? &nbsp;
                  <b>
                    <a href="/signup" className="ir-ws-app-color">
                      SignUp
                    </a>
                  </b>
                </p>
              </div>
            </div>
          </div>
        )}
        {checkMail && (
          <div className="ir-ws-sign-in-content-container">
            <div className="ir-ws-signin-logo-container">
              <div className="ir-ws-text-center ir-ws-signin-logo-inner-container">
                <a href="/">
                  <img
                    className="ir-ws-signin-logo-img"
                    src={require("../../assets/images/ir4u2.png")}
                    alt="logo"
                  />
                </a>
              </div>
            </div>
            <h3
              className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title"
              style={{
                fontSize: "18px",
                lineHeight: "1.5",
                marginTop: "30px",
                marginBottom: "15px"
              }}
            >
              Check you email
            </h3>
            <p style={{ fontSize: "14px" }}>
              Thanks! we have sent you a password reset link to the email registered with <b>{username}</b>, 
              containing further instructions for resetting your password.
              <br /><br /> If you haven't received an email in 5 minutes, check your spam,&nbsp;
              <span className="ir-ws-app-color" onClick={() => setCheckMail(false)}><b>resend</b></span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;