import React, { useEffect, useRef, useState } from "react";
import Mcq from "./QuestionData/Mcq";
import Fib from "./QuestionData/Fib";
import FindAbnormality from "./QuestionData/FindAbnormality";
import Rapid from "./QuestionData/Rapid";
import Jumbling from "./QuestionData/Jumbling";
import Swal from "sweetalert2";
const SingleCaseComponent = ({
  caseDetails,
  answerType,
  setStartedAnswering,
  activeSlide,
  slideNumber,
}) => {
  const [addedComponents, setAddedComponents] = useState([]);
  const [rapidUserAnswers, setRapidUserAnswers] = useState({});
  const [circlePosition, setCirclePosition] = useState({ x: 250, y: 250 });
  const [submissionStatus, setSubmissionStatus] = useState([]);
  const [shuffledPairedOptions, setShuffledPairedOptions] = useState([]);
  const [activeAccordions, setActiveAccordions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [jumblingSubmissionStatus, setjumblingSubmissionStatus] =
    useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [rapidSubmittedStatus, setrapidSubmittedStatus] = useState(false);
  const [selectedAnswersMcq, setSelectedAnswersMcq] = useState({});
  const [submittedMcq, setSubmittedMcq] = useState(false);
  const [userAnswersFib, setUserAnswersFib] = useState({});
  const [submittedFib, setSubmittedFib] = useState(false);
  //   const [startedAnswering, setStartedAnswering] = useState(false);
  const jumblingRefs = useRef([]);
  const [timeLeft, setTimeLeft] = useState(120);
  const [isRunning, setIsRunning] = useState(false);
  const [initialTimeStart, setInitialTimeStart] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [timerStopped, setTimerStopped] = useState(false);
  const [isAllQuestionsSubmitted, setIsAllQuestionsSubmitted] = useState(false);

  const [showLearningPopup, setShowLearningPopup] = useState(false);
  const [showLearning, setShowLearning] = useState(false);

  const handleShowLearningPopup = () => {
    setShowLearningPopup(true);
  };
  const handleShowLearning = () => {
    setShowLearning(true);
    setShowLearningPopup(false);
  };
  const closeShowLearningPopup = () => {
    setShowLearningPopup(false);
    setShowLearning(false);
  };

  //   const [answerType, setAnswerType] = useState("");
  // const [startedAnswering, setStartedAnswering] = useState(false);
  const [answeredQuestions, setAnsweredQuestions] = useState(
    Array(addedComponents.length).fill(false)
  );
  const activeSlideObject = caseDetails?.slides?.find(
    (slide) => slide?.slideNumber === slideNumber
  );
  // console.log("this is activeSlideObject:", activeSlideObject);
  // useEffect(() => {
  //   console.log("answeredQuestions state:", answeredQuestions);
  // }, [answeredQuestions]);
  const accordionRefs = useRef([]);
  const answeredQuestionsRef = useRef(answeredQuestions);
  useEffect(() => {
    answeredQuestionsRef.current = answeredQuestions;
  }, [answeredQuestions]);

  // useEffect(() => {
  //   console.log(
  //     "All answered Questions state single case page:",
  //     answeredQuestions
  //   );
  // }, [answeredQuestions]);
  const parseTimeString = (timeString) => {
    const [minutes, seconds] = timeString.split(":").map(Number);
    return minutes * 60 + seconds;
  };

  useEffect(() => {
    if (activeSlideObject?.rapidTime) {
      const parsedTime = parseTimeString(activeSlideObject.rapidTime);
      setTimeLeft(parsedTime);
    } else {
      setTimeLeft(120); // Default to 120 seconds if rapidTime is not available
    }
  }, [activeSlideObject?.rapidTime]);
  useEffect(() => {
    let timer;
    if (isRunning && timeLeft > 0) {
      setInitialTimeStart(true);
      timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsRunning(false);
      setTimerStopped(true);
    }

    return () => clearTimeout(timer);
  }, [isRunning, timeLeft]);

  const startTimer = () => {
    if (timeLeft > 0) {
      setIsRunning(true);
    }
  };
  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, "0");
    const seconds = String(time % 60).padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const handleAnswerSelect = (questionId, selectedAnswer) => {
    setSelectedAnswersMcq({
      ...selectedAnswersMcq,
      [questionId]: selectedAnswer,
    });
    setStartedAnswering(true);
  };
  const checkAnswer = () => {
    // Perform validation logic here
    activeSlideObject.mcq.forEach((question) => {
      const userAnswer = selectedAnswersMcq[question.id];
      const correctAnswer = question.data.correctAnswer;

      // Example: Display result messages
      // const resultMessage = document.getElementById(`result-${question.id}`);
      const yourAnswerElement = document.getElementById(
        `your-answer-${question.id}`
      );
      const correctAnswerElement = document.getElementById(
        `correct-answer-${question.id}`
      );

      if (userAnswer === correctAnswer) {
        yourAnswerElement.textContent = "Your answer is Correct";
        yourAnswerElement.className = "your-answer correct";
      } else {
        yourAnswerElement.textContent = "Your answer is Wrong";
        yourAnswerElement.className = "your-answer wrong";
      }

      correctAnswerElement.textContent = `The answer is ${correctAnswer}`;
      correctAnswerElement.className = "correct-answer";
    });
    // updateAccordionHeight(0);
    setSubmittedMcq(true);
  };

  const handleInputChange = (questionId, event) => {
    if (!submittedFib) {
      setUserAnswersFib({
        ...userAnswersFib,
        [questionId]: event.target.value,
      });
      setStartedAnswering(true);
    }
  };

  const handleSubmitFib = () => {
    // Perform validation logic here
    activeSlideObject.fillInTheBlankData.forEach((question) => {
      const userAnswer = userAnswersFib[question.id];
      const correctAnswer = question.data.correctAnswer;

      const yourAnswerElement = document.getElementById(
        `fib-your-answer-${question.id}`
      );
      const correctAnswerElement = document.getElementById(
        `fib-correct-answer-${question.id}`
      );

      if (!userAnswer) {
        yourAnswerElement.textContent = "Your Answer: (No answer provided)";
        yourAnswerElement.className = "your-answer wrong";
        correctAnswerElement.textContent = `Correct Answer: ${correctAnswer}`;
        correctAnswerElement.className = "correct-answer";
      } else {
        yourAnswerElement.textContent = `Your Answer: ${userAnswer}`;
        correctAnswerElement.textContent = `Correct Answer: ${correctAnswer}`;

        if (userAnswer.toLowerCase() === correctAnswer.toLowerCase()) {
          yourAnswerElement.textContent = "Your answer is Correct";
          yourAnswerElement.className = "your-answer correct";
          correctAnswerElement.textContent = `The answer is ${correctAnswer}`;
          correctAnswerElement.className = "correct-answer";
        } else {
          yourAnswerElement.textContent = "Your answer is Wrong";
          yourAnswerElement.className = "your-answer wrong";
          correctAnswerElement.textContent = `The answer is ${correctAnswer}`;
          correctAnswerElement.className = "correct-answer";
        }
      }
    });

    setSubmittedFib(true);
  };

  const handleRapidInputChange = (questionId, e) => {
    setRapidUserAnswers((prevState) => ({
      ...prevState,
      [questionId]: e.target.value,
    }));
    setStartedAnswering(true);
  };

  const checkRapidQuestion = () => {
    const results = [];
    activeSlideObject.rapidQuestionData.forEach((questionGroup) => {
      questionGroup.data.forEach((question) => {
        const userAnswer = rapidUserAnswers[question.id] || "";
        const correctAnswer = question.answer;
        const isCorrect =
          userAnswer.trim().toLowerCase() ===
          correctAnswer.trim().toLowerCase();
        results.push({
          questionId: question.id,
          userAnswer,
          correctAnswer,
          isCorrect,
        });
      });
    });

    // Display the results
    results.forEach((result) => {
      const yourAnswerElement = document.getElementById(
        `rapid-your-answer-${result.questionId}`
      );
      const correctAnswerElement = document.getElementById(
        `rapid-correct-answer-${result.questionId}`
      );

      if (yourAnswerElement && correctAnswerElement) {
        if (result.isCorrect) {
          yourAnswerElement.textContent = "Your answer is Correct";
          yourAnswerElement.className = "your-answer correct";
        } else {
          yourAnswerElement.textContent = "Your answer is Wrong";
          yourAnswerElement.className = "your-answer wrong";
        }

        correctAnswerElement.textContent = `The answer is ${result.correctAnswer}`;
        correctAnswerElement.className = "correct-answer";
      } else {
        console.warn(
          `Elements for question ID ${result.questionId} not found.`
        );
      }
    });

    setrapidSubmittedStatus(true);
    setIsRunning(false);
    // updateAccordionHeight(2);
  };

  const handleImageClick = (evt, imgIndex) => {
    if (submissionStatus[imgIndex]) {
      return; // Prevent further marking if already submitted
    } // Prevent further marking if submitted
    // console.log(submissionStatus[imgIndex]);
    // console.log(imgIndex);
    const rect = evt.target.getBoundingClientRect();
    const offsetX = evt.clientX - rect.left;
    const offsetY = evt.clientY - rect.top;
    setCirclePosition({ x: offsetX, y: offsetY, index: imgIndex });
    setStartedAnswering(true);
  };

  const checkSelectedPoint = (imgIndex) => {
    if (submissionStatus[imgIndex]) {
      return; // Prevent further submission if already submitted
    }

    const tolerance = 20;

    // Check if activeSlideObject and the specific findAbnormanlity object exist
    if (
      !activeSlideObject ||
      !activeSlideObject.findAbnormanlity ||
      !activeSlideObject.findAbnormanlity[imgIndex]
    ) {
      console.error("activeSlideObject or findAbnormanlity data is undefined");
      return;
    }

    const abnormanlityPoint =
      activeSlideObject.findAbnormanlity[imgIndex].data.point;

    if (!abnormanlityPoint) {
      console.error("abnormanlityPoint is undefined");
      return;
    }

    const diffX = Math.abs(circlePosition?.x - abnormanlityPoint?.x);
    const diffY = Math.abs(circlePosition?.y - abnormanlityPoint?.y);

    const resultMessage =
      diffX <= tolerance && diffY <= tolerance ? "Correct!" : "Incorrect!";

    updateResultMessage(resultMessage, imgIndex);

    // Disable further submissions for this question
    setSubmissionStatus((prevStatus) => ({
      ...prevStatus,
      [imgIndex]: true,
    }));
    // updateAccordionHeight(4);
  };

  // const updateResultMessage = (message) => {
  //   const resultContainer = document.getElementById("resultContainer");
  //   resultContainer.textContent = message;
  //   resultContainer.style.color = message === "Correct!" ? "green" : "red";
  // };
  const updateResultMessage = (message) => {
    const containerId = `resultContainer-${slideNumber}`;
    const resultContainer = document.getElementById(containerId);
    if (resultContainer) {
      resultContainer.textContent = message;
      resultContainer.style.color = message === "Correct!" ? "green" : "red";
    }
  };

  const showAlert = (message) => {
    alert(message);
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    if (
      activeSlideObject?.jumblingQuestionData &&
      activeSlideObject?.jumblingQuestionData?.length > 0
    ) {
      const options = activeSlideObject?.jumblingQuestionData[0]?.data?.map(
        (item) => ({
          id: item.id,
          pairedOption: item.pairedOption,
        })
      );
      const shuffledOptions = shuffleArray(options);
      setShuffledPairedOptions([shuffledOptions]);

      const correctOptions =
        activeSlideObject?.jumblingQuestionData[0]?.data.map((item) => ({
          id: item.id,
          pairedOption: item.pairedOption,
        }));
      setCorrectAnswers([correctOptions]);
    } else {
      setShuffledPairedOptions([]);
      setCorrectAnswers([]);
    }
  }, [activeSlideObject]);

  const handleDragStart = (e, slideIndex, questionIndex) => {
    if (jumblingSubmissionStatus) return;
    e.dataTransfer.setData("slideIndex", slideIndex);
    e.dataTransfer.setData("questionIndex", questionIndex);
    setDragging(true);
    setStartedAnswering(true);
  };

  const handleDrop = (e, slideIndex, newQuestionIndex) => {
    if (jumblingSubmissionStatus) return;
    e.preventDefault();
    const oldSlideIndex = parseInt(e.dataTransfer.getData("slideIndex"), 10);
    const oldQuestionIndex = parseInt(
      e.dataTransfer.getData("questionIndex"),
      10
    );

    if (oldSlideIndex !== slideIndex) return; // Prevent cross-slide dragging

    const updatedShuffledPairedOptions = [...shuffledPairedOptions];
    // console.log("updatedShuffledPairedOptions", updatedShuffledPairedOptions);
    const [movedItem] = updatedShuffledPairedOptions[slideIndex]?.splice(
      oldQuestionIndex,
      1
    );
    updatedShuffledPairedOptions[slideIndex].splice(
      newQuestionIndex,
      0,
      movedItem
    );

    setShuffledPairedOptions(updatedShuffledPairedOptions);
    setDragging(false);
  };

  function stripHtmlTags(html, maxLength) {
    let doc = new DOMParser().parseFromString(html, "text/html");
    let plainText = doc.body.innerText;
    return plainText.length > maxLength
      ? plainText?.slice(0, maxLength) + "..."
      : plainText;
  }

  const markQuestionAsAnswered = (index) => {
    setAnsweredQuestions((prev) => {
      const newAnsweredQuestions = [...prev];
      newAnsweredQuestions[index] = true;
      // console.log(
      //   "Answered new question state single case page: ",
      //   newAnsweredQuestions
      // );
      console.log("marked as true for index single case page: ", index);
      return newAnsweredQuestions;
    });
  };

  const goToPreviousSlide = () => {
    if (activeSlideIndex > 0) {
      setShowLearning(false);
      setActiveSlideIndex(activeSlideIndex - 1);
    }
  };
  const goToNextSlide = () => {
    if (answeredQuestions[activeSlideIndex]) {
      if (activeSlideIndex < addedComponents.length - 1) {
        setShowLearning(false);
        setActiveSlideIndex(activeSlideIndex + 1);
      }
    } else {
      Swal.fire("", "You must select an answer to continue !");
    }
  };

  const setActiveSlideHandler = (index) => {
    for (let i = 0; i < index; i++) {
      if (!answeredQuestions[i]) {
        Swal.fire("", "You must select an answer to continue !");
        return;
      }
    }
    setShowLearning(false);
    setActiveSlideIndex(index);
  };

  useEffect(() => {
    setActiveSlideIndex(0);
  }, [activeSlideObject]);

  const isLearningPointExist = Boolean(
    activeSlideObject?.discussionImage?.length ||
      activeSlideObject?.discussionTitle ||
      activeSlideObject?.discussionDescription
  );

  useEffect(() => {
    if (activeSlideObject?.componentName?.length > 0) {
      const newComponents = activeSlideObject?.componentName?.map(
        (questionType, index) => {
          // const id = uuidv4();
          // console.log(index, "Component Index in singe case");
          const getQuestionData = (id, questionArray) =>
            questionArray.find((item) => item.id === id);
          const totalQuestions = addedComponents?.length;

          switch (questionType.typesOfQuestion) {
            case "Multiple Choice":
              const mcqData = getQuestionData(
                questionType.id,
                activeSlideObject.mcq
              );
              // console.log("this is the mcq data", mcqData);
              return (
                <Mcq
                  key={questionType.id}
                  mcqData={mcqData}
                  activeSlideObject={activeSlideObject}
                  activeAccordions={activeAccordions}
                  accordionRefs={accordionRefs}
                  //   toggleAccordion={toggleAccordion}
                  handleAnswerSelect={handleAnswerSelect}
                  submittedMcq={submittedMcq}
                  answerType={answerType}
                  checkAnswer={checkAnswer}
                  questionIndex={index}
                  markQuestionAsAnswered={markQuestionAsAnswered}
                  goToNextSlide={goToNextSlide}
                  totalQuestions={totalQuestions}
                  handleShowLearningPopup={handleShowLearning}
                  isLearningPointExist={isLearningPointExist}
                  isAllQuestionsSubmitted={isAllQuestionsSubmitted}
                  handleSubmitAllAnser={handleSubmitAllAnser}
                />
              );
            case "Fill in the Blank":
              const fibData = getQuestionData(
                questionType.id,
                activeSlideObject.fillInTheBlankData
              );
              // console.log("this is the fidData", fibData);
              return (
                <Fib
                  key={questionType.id}
                  fibData={fibData}
                  activeSlideObject={activeSlideObject}
                  activeAccordions={activeAccordions}
                  accordionRefs={accordionRefs}
                  //   toggleAccordion={toggleAccordion}
                  submittedFib={submittedFib}
                  answerType={answerType}
                  handleSubmitFib={handleSubmitFib}
                  handleInputChange={handleInputChange}
                  accordianBoolean={activeAccordions[index]}
                  questionIndex={index}
                  markQuestionAsAnswered={markQuestionAsAnswered}
                  goToNextSlide={goToNextSlide}
                  totalQuestions={totalQuestions}
                  handleShowLearningPopup={handleShowLearning}
                  isLearningPointExist={isLearningPointExist}
                  isAllQuestionsSubmitted={isAllQuestionsSubmitted}
                  handleSubmitAllAnser={handleSubmitAllAnser}
                />
              );
            case "Find Abnormality":
              const Fad = getQuestionData(
                questionType.id,
                activeSlideObject.findAbnormanlity
              );
              return (
                <FindAbnormality
                  key={questionType.id}
                  activeSlideObject={activeSlideObject}
                  Fad={Fad}
                  activeAccordions={activeAccordions}
                  accordionRefs={accordionRefs}
                  //   toggleAccordion={toggleAccordion}
                  handleImageClick={handleImageClick}
                  circlePosition={circlePosition}
                  answerType={answerType}
                  submissionStatus={submissionStatus}
                  checkSelectedPoint={checkSelectedPoint}
                  questionIndex={index}
                  markQuestionAsAnswered={markQuestionAsAnswered}
                  goToNextSlide={goToNextSlide}
                  totalQuestions={totalQuestions}
                  slideNumber={slideNumber}
                  handleShowLearningPopup={handleShowLearning}
                  isLearningPointExist={isLearningPointExist}
                  isAllQuestionsSubmitted={isAllQuestionsSubmitted}
                  handleSubmitAllAnser={handleSubmitAllAnser}
                />
              );
            case "Rapid":
              const rapidData = getQuestionData(
                questionType.id,
                activeSlideObject.rapidQuestionData
              );
              // console.log("this is the rapid Data", rapidData);
              return (
                <Rapid
                  key={questionType.id}
                  newRapidData={rapidData}
                  activeSlideObject={activeSlideObject}
                  activeAccordions={activeAccordions}
                  accordionRefs={accordionRefs}
                  //   toggleAccordion={toggleAccordion}
                  initialTimeStart={initialTimeStart}
                  rapidUserAnswers={rapidUserAnswers}
                  handleRapidInputChange={handleRapidInputChange}
                  isRunning={isRunning}
                  rapidSubmittedStatus={rapidSubmittedStatus}
                  answerType={answerType}
                  checkRapidQuestion={checkRapidQuestion}
                  timerStopped={timerStopped}
                  timeLeft={timeLeft}
                  formatTime={formatTime}
                  startTimer={startTimer}
                  questionComponentIndex={index}
                  markQuestionAsAnswered={markQuestionAsAnswered}
                  goToNextSlide={goToNextSlide}
                  totalQuestions={totalQuestions}
                  handleShowLearningPopup={handleShowLearning}
                  isLearningPointExist={isLearningPointExist}
                  isAllQuestionsSubmitted={isAllQuestionsSubmitted}
                  handleSubmitAllAnser={handleSubmitAllAnser}
                />
              );
            case "Jumbling":
              const jumblingData = getQuestionData(
                questionType.id,
                activeSlideObject.jumblingQuestionData
              );
              return (
                <Jumbling
                  key={questionType.id}
                  jumblingData={jumblingData}
                  activeSlideObject={activeSlideObject}
                  activeAccordions={activeAccordions}
                  accordionRefs={accordionRefs}
                  //   toggleAccordion={toggleAccordion}
                  stripHtmlTags={stripHtmlTags}
                  handleDrop={handleDrop}
                  dragging={dragging}
                  jumblingSubmissionStatus={jumblingSubmissionStatus}
                  handleDragStart={handleDragStart}
                  setDragging={setDragging}
                  shuffledPairedOptions={shuffledPairedOptions}
                  resultMessage={resultMessage}
                  answerType={answerType}
                  // checkJumblingAnswers={checkJumblingAnswers}
                  questionComponentIndex={index}
                  markQuestionAsAnswered={markQuestionAsAnswered}
                  // checkJumblingAnswers={checkJumblingAnswers}
                  // index={index}
                  ref={(el) => (jumblingRefs.current[index] = el)}
                  goToNextSlide={goToNextSlide}
                  totalQuestions={totalQuestions}
                  handleShowLearningPopup={handleShowLearning}
                  isLearningPointExist={isLearningPointExist}
                  isAllQuestionsSubmitted={isAllQuestionsSubmitted}
                  handleSubmitAllAnser={handleSubmitAllAnser}
                />
              );
            default:
              return null;
          }
        }
      );

      setAddedComponents(newComponents);
    }
  }, [
    slideNumber,
    activeSlideObject,
    activeAccordions,
    userAnswersFib,
    submittedFib,
    answerType,
    dragging,
    shuffledPairedOptions,
    resultMessage,
    jumblingSubmissionStatus,
    circlePosition,
    submissionStatus,
    submittedMcq,
    selectedAnswersMcq,
    initialTimeStart,
    rapidUserAnswers,
    timerStopped,
    timeLeft,
    rapidSubmittedStatus,
    isRunning,
    answeredQuestions,
  ]);

  useEffect(() => {
    if (activeSlideObject?.componentName?.length > 0) {
      // console.log("Setting initial activeAccordions state");
      setActiveAccordions(
        Array(activeSlideObject.componentName.length).fill(false)
      );
    }
  }, [activeSlideObject]);

  const handleSubmitAllAnser = () => {
    // Check if all questions are answered
    if (!answeredQuestions?.every((answered) => answered)) {
      alert("Please answer all the questions before submitting.");
      Swal.fire("", "Please answer all the questions before submitting.");
      return;
    }

    // Swal.fire({
    //   title: "Review Your Answers",
    //   text: "Please go through each question to check the correct answer.",
    //   icon: "info",
    //   confirmButtonText: "OK",
    // });

    // If all questions are answered, proceed with the submission
    checkAnswer();
    handleSubmitFib();
    checkRapidQuestion();
    // checkJumblingAnswers();
    checkSelectedPoint(0);

    setIsAllQuestionsSubmitted(true);
    // setAnsweredQuestions(Array(addedComponents.length).fill(true));
    setAnsweredQuestions(answeredQuestions.map(() => true));

    // addedComponents.forEach((component) => {
    //   // if (component.typesOfQuestion === "Jumbling") {
    //   //   console.log("JUmbling answered");
    //   //   jumblingRef.current.checkJumblingAnswers();
    //   // }
    //   jumblingRef.current.checkJumblingAnswers();
    //   console.log("JUmbling answered 2");
    //   // Add conditions for other types if needed
    // });
    jumblingRefs.current.forEach((ref) => {
      if (ref) {
        ref.checkJumblingAnswers();
      }
    });
    // updateAccordionHeight(0);
    // updateAccordionHeight(1);
    // updateAccordionHeight(2);
    // updateAccordionHeight(3);
    // updateAccordionHeight(4);
  };
  return (
    <>
      <div className="ir-ws-single-case-component-cont">
        {addedComponents.map((component, index) => (
          <div
            key={index}
            style={{
              display:
                index === activeSlideIndex && !showLearning ? "block" : "none",
              // height: "100%",
            }}
            className="ir-ws-single-case-component-active-question"
          >
            {component}
          </div>
        ))}
        <>
          {showLearning &&
            activeSlideIndex === addedComponents.length - 1 &&
            answeredQuestions.length === addedComponents.length &&
            answeredQuestions.every((answered) => answered) &&
            (activeSlideObject?.discussionImage?.length !== 0 ||
              activeSlideObject?.discussionTitle ||
              activeSlideObject?.discussionDescription) && (
              <div className="ir-ws-case-accordion-cont">
                <div className="ir-ws-case-accordion-header ir-ws-case-accordian-active-border">
                  <span className="ir-ws-case-slide-title-text">
                    Learning Points
                  </span>
                </div>
                <div className="ir-ws-case-accordion-accordion-content">
                  <div className="ir-ws-case-content-box">
                    {activeSlideObject?.discussionImage?.map((image, index) => (
                      <div
                        key={index}
                        className="ir-ws-create-case-bulk-image-box"
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        <img
                          className="learning-point-img"
                          src={image.url}
                          alt={image.name}
                        />
                      </div>
                    ))}
                    {activeSlideObject?.discussionTitle !== "" && (
                      <p className="discussion-title">
                        {/* <strong>Learning Point Title: </strong> */}
                        {activeSlideObject?.discussionTitle}
                      </p>
                    )}

                    {activeSlideObject?.discussionDescription && (
                      <div>
                        <p className="discussion-description">
                          {/* <strong>Learning Point description:</strong> */}
                        </p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: activeSlideObject?.discussionDescription,
                          }}
                        ></p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
        </>
      </div>
      {/* <div className="ir-ws-single-case-pagination-cont">
        <div className="ir-ws-single-case-pagination">
          <div
            className="ir-ws-single-case-prev-btn"
            onClick={goToPreviousSlide}
          >
            Prev
          </div>
          {addedComponents.map((_, index) => (
            <div
              key={index}
              onClick={() => setActiveSlideHandler(index)}
              className={
                index === activeSlideIndex
                  ? "ir-ws-single-case-page-active-slide-number"
                  : "ir-ws-single-case-page-inactive-slide-number"
              }
            >
              <p>{index + 1}</p>
            </div>
          ))}

          <div className="ir-ws-single-case-next-btn" onClick={goToNextSlide}>
            Next
          </div>
        </div>
        {answerType === "group" &&
          answeredQuestions.length === addedComponents.length &&
          answeredQuestions.every((answered) => answered) && (
            <div
              className="ir-ws-text-center ir-ws-default-btn-container finish-btn-group"
              onClick={handleSubmitAllAnser}
            >
              <button
                className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                style={{
                  // width: `${addedComponents?.length * 10}%`,
                  width: "180px",
                  // marginTop: "10px",
                  // marginBottom: "20px",
                }}
                disabled={isAllQuestionsSubmitted}
              >
                <span>Finish</span>
              </button>
            </div>
          )}
      </div> */}
      <div className="ir-ws-single-case-pagination-cont">
        <div className="ir-ws-single-case-pagination">
          {/* Prev Button */}
          <div
            className="ir-ws-single-case-prev-btn"
            onClick={goToPreviousSlide}
            // style={{ visibility: activeSlideIndex > 0 ? "visible" : "hidden" }}
          >
            Prev
          </div>

          {/* Dynamic Pagination */}
          {activeSlideIndex > 0 && (
            <div
              onClick={() => setActiveSlideHandler(activeSlideIndex)}
              className="ir-ws-single-case-page-inactive-slide-number"
            >
              <p>{activeSlideIndex}</p>
            </div>
          )}

          <div
            onClick={() => setActiveSlideHandler(activeSlideIndex)}
            className="ir-ws-single-case-page-active-slide-number"
          >
            <p>{activeSlideIndex + 1}</p>
          </div>
          {/*
          {activeSlideIndex < addedComponents.length - 1 && (
            <div
              onClick={() => setActiveSlideHandler(activeSlideIndex + 1)}
              className="ir-ws-single-case-page-inactive-slide-number"
            >
              <p>{activeSlideIndex + 2}</p>
            </div>
          )} */}

          {activeSlideIndex < addedComponents.length - 2 && (
            <>
              <span className="pagination-dots">...</span>
              <div
                onClick={() =>
                  setActiveSlideHandler(addedComponents.length - 1)
                }
                className="ir-ws-single-case-page-inactive-slide-number"
              >
                <p>{addedComponents.length}</p>
              </div>
            </>
          )}

          {/* Next Button */}
          <div
            className="ir-ws-single-case-next-btn"
            onClick={goToNextSlide}
            // style={{
            //   visibility:
            //     activeSlideIndex < addedComponents.length - 1
            //       ? "visible"
            //       : "hidden",
            // }}
          >
            Next
          </div>
        </div>

        {answerType === "group" &&
          answeredQuestions.length === addedComponents.length &&
          answeredQuestions.every((answered) => answered) && (
            <div
              className="ir-ws-text-center ir-ws-default-btn-container finish-btn-group"
              onClick={handleSubmitAllAnser}
            >
              <button
                className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                style={{
                  // width: `${addedComponents?.length * 10}%`,
                  width: "180px",
                  // marginTop: "10px",
                  // marginBottom: "20px",
                }}
                disabled={isAllQuestionsSubmitted}
              >
                <span>Finish</span>
              </button>
            </div>
          )}
      </div>

      {/* {showLearningPopup && (
        // activeSlideObject?.discussionImage?.length !== 0 &&
        <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
          <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
            <div className="ir-ws-signup-content-container">
              <div className="ir-ws-signup-content-inner-container">
                <h3
                  className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title"
                  // style={{ fontSize: "24px" }}
                >
                  Are you sure you want to see the Learning points ?
                </h3>
              </div>
              <div className="ir-ws-deleteModal-button-container">
                <button
                  className="ir-ws-deleteModal-button-yes"
                  onClick={handleShowLearning}
                >
                  Yes
                </button>
                <button
                  className="ir-ws-deleteModal-button-no"
                  onClick={closeShowLearningPopup}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default SingleCaseComponent;
