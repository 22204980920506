import React from "react";
import Header from "../../../components/Header/Header";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";

const UploadCasePage = ({ uploadingCaseProgress }) => {
  const slides = useSelector((state) => state.case.slides);
  const cases = useSelector((state) => state.case);
  const navigate = useNavigate();

  const { caseUpdateId, handleDeleteDraftCase, updateDraftCaseId } =
    useModalContext();

  const uploadBorderColors = {
    borderTopColor: uploadingCaseProgress >= 10 ? "#6842ef" : "#ffff",
    borderRightColor: uploadingCaseProgress >= 40 ? "#6842ef" : "#ffff",
    borderBottomColor: uploadingCaseProgress >= 70 ? "#6842ef" : "#ffff",
    borderLeftColor: uploadingCaseProgress >= 100 ? "#6842ef" : "#ffff",
    transform: "rotate(45deg)",
  };
  const handlePublishCase = async () => {
    if (updateDraftCaseId) {
      handleDeleteDraftCase(updateDraftCaseId);
    }
    if (caseUpdateId) {
      console.log("the case is updating:", caseUpdateId);
      try {
        const updateUrl = `https://case.ir4u.info/api/v1/admin/case/update/${caseUpdateId}`;
        const response = await fetch(updateUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
          },
          body: JSON.stringify(cases),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("Update Case Response:", data);
        Swal.fire({
          icon: "success",
          title: "Case Updated",
          text: "Your case has been successfully updated!",
        });
        navigate(`/case/${caseUpdateId}`);
      } catch (error) {
        console.error("Error updating case:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to update the case. Please try again later.",
        });
      }
    } else {
      console.log("we are creating new case");
      try {
        const response = await fetch(
          "https://case.ir4u.info/api/v1/admin/case/publish",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
            },
            body: JSON.stringify({
              slides: cases.slides,
              casePrice: cases.casePrice,
              categorie: cases.categorie,
              name: cases.name,
              description: cases.description,
              thumbnail: cases.thumbnail,
              bundleTitle: cases.bundleTitle,
            }),
          }
        );
        const data = await response.json();
        console.log("Publish Case Response:", data);
        Swal.fire({
          icon: "success",
          title: "Case Published",
          text: "Your case has been successfully published!",
        });
        navigate(`/case/${data?.id}`);
      } catch (error) {
        console.error("Error publishing case:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to publish the case. Please try again later.",
        });
      }
    }
  };

  return (
    <div>
      <Header />
      <section className="ir-course-preview-page-section ir-course-preview-loader-section">
        <div className="ir-course-preview-loader-container">
          <p className="ir-course-preview-loader-status-text">Status</p>
          <p className="ir-course-preview-loader-converting-text">Uploading</p>
          <div
            className="ir-course-preview-loader-box"
            style={uploadBorderColors}
          >
            <p
              className="ir-course-preview-upload-status-text"
              style={{ transform: "rotate(315deg)" }}
            >
              {uploadingCaseProgress}%
            </p>
          </div>

          <p className="ir-course-preview-loader-time-remaining-text">
            {uploadingCaseProgress !== 100 ? "2 min more" : "Good to go"}
          </p>
          <div
            className="ir-ws-text-center ir-ws-default-btn-container"
            onClick={handlePublishCase}
          >
            <button
              className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
              style={
                uploadingCaseProgress !== 100 ? { pointerEvents: "none" } : null
              }
              disabled={uploadingCaseProgress !== 100}
            >
              <span>Go to cases</span>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UploadCasePage;
