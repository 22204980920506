import React from "react";
import "./HomeTeamContainer.scss";

const HomeTeamContainer = () => {
  return (
    <div className="ir-ws-hme-team-main-container">
      {/* <p className="ir-ws-app-color ir-ws-hme-highlight-text">Team</p> */}
      <h2 className="ir-ws-app-color ir-ws-hme-prvw-title">Team IR4U</h2>
      {/* <p className="ir-default-color ir-ws-hme-title-content-para">
                An expert panel comprising of top academicians, industry professionals, and
                subject matter experts , always striving to bring the best of theory & practicals to IR4U Learners.
            </p> */}
      <div className="ir-ws-hme-team-inner-container">
        <div className="ir-ws-hme-prvw-card-container ir-ws-hme-team-division-container">
          <div className="ir-ws-card-main-container">
            <div className="ir-ws-text-center ir-ws-position-relative ir-ws-position-relative ir-ws-card-header">
              <img
                className="ir-ws-width-100 ir-ws-max-width-100 ir-ws-team-card-thumbnail-container"
                src={require("../../assets/images/ram-gurajala.webp")}
                alt="Courses thumbnail"
              />
            </div>
            <div className="ir-ws-card-body">
              <h4 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-hme-team-card-title">
                Dr. Ram Kishore Gurajala, MD
              </h4>
              <p className="ir-ws-text-center ir-default-color ir-ws-hme-team-designation">
                Interventional Radiologist
              </p>
              <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-team-social-container">
                <div className="ir-ws-hme-team-social-icon-container">
                  <a href="/" className="ir-ws-hme-team-link">
                    <i className="ir-ws-hme-team-icon bi bi-facebook"></i>
                  </a>
                </div>
                <div className="ir-ws-hme-team-social-icon-container">
                  <a href="/" className="ir-ws-hme-team-link">
                    <i className="ir-ws-hme-team-icon bi bi-instagram"></i>
                  </a>
                </div>
                <div className="ir-ws-hme-team-social-icon-container">
                  <a href="/" className="ir-ws-hme-team-link">
                    <i className="ir-ws-hme-team-icon bi bi-twitter"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ir-ws-hme-team-content-card-container">
          <h4>About Dr. Ram Kishore Gujarala MD</h4>
          <p className="ir-default-color">
            Dr. Ram Kishore Gurajala, MD, is a distinguished Interventional
            Radiologist.
            <br />
            <br />
            Dr. Gurajala embarked on his medical journey at Gandhi Medical
            College in Hyderabad, India, where he laid the foundation for his
            exceptional medical education. Subsequently, he pursued his
            residency training in Diagnostic and Interventional Radiology at the
            esteemed Mersey School of Radiology in Liverpool, UK. This phase of
            his training not only honed his diagnostic skills but also provided
            him with a solid background in interventional techniques.
            <br />
            <br />
            Recognizing the importance of advanced specialization, Dr. Gurajala
            completed a Fellowship in Interventional Radiology at the renowned
            Cleveland Clinic. This experience further elevated his expertise and
            allowed him to contribute significantly to the field.
            <br />
            <br />
            In addition to his clinical pursuits, Dr. Gurajala is also the
            visionary behind IR4U.info, a comprehensive educational website.
            Through this platform, he strives to disseminate knowledge about
            Interventional Radiology to a broader audience, including medical
            students, fellow healthcare professionals, and the general public.
            <br />
            <br />
            Dr. Ram Kishore Gurajala's dedication to his patients, coupled with
            his commitment to advancing the field of Interventional Radiology,
            has positioned him as a respected figure in the medical community.
            His journey from Gandhi Medical College to his current role at
            Cleveland Clinic exemplifies his relentless pursuit of excellence
            and innovation in the realm of radiology.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeTeamContainer;
