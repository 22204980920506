
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addFormData } from "../Store/CourseSlice";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";

const AdminCourseDraft = () => {
  const {
    setActiveTabCourse,
    handleCreatecourspage,
    draftCourseUpdateId,
    setDraftCourseUpdateId
  } = useModalContext();
  const [drafts, setDrafts] = useState([]);
   const [draftFormdataOfCurrentCourse, setDraftFormdataOfCurrentCourse] = useState(null);
  const dispatch = useDispatch()

  const fetchDrafts = async () => {
    const url = "https://courses.ir4u.info/api/course/drafts/all";
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log("draft data:", data);
      setDrafts(data);

    } catch (error) {
      console.error('Failed to fetch drafts:', error);
    }
  };

  useEffect(() => {
    fetchDrafts();
  }, []);

  const deleteDraftHandler = async (id) => {
    const url = `https://courses.ir4u.info/api/course/draft/${id}`;
    alert('Deleting draft');
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Draft deleted successfully:', data);

      setDrafts(drafts.filter((draft) => draft._id !== id));
    } catch (error) {
      console.error('Failed to delete draft:', error);
    }
  };

  const handleEditDraftCourse = async (draftId) => {
    setDraftCourseUpdateId(draftId)
    try {
      setActiveTabCourse(1);
      handleCreatecourspage();
  
      const url = `https://courses.ir4u.info/api/course/draft/${draftId}`;
      const response = await fetch(url);
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      const formData = data.formData;
      const updatedFormData = { ...formData };

      setDraftFormdataOfCurrentCourse(updatedFormData);
      dispatch(addFormData(updatedFormData));
    } catch (error) {
      console.error("Error fetching draft data:", error);
    }
  };

    // Update the useEffect to trigger form update when drafts change
    // useEffect(() => {
    //   if (draftFormdataOfCurrentCourse) {
    //     dispatch(addFormData(draftFormdataOfCurrentCourse));
    //   }
    // }, [draftFormdataOfCurrentCourse, dispatch]);
  

  return (
    <div className="draft-list">
    <h4 className="draft-title">Draft Courses</h4>
    <ul className="draft-list-items">
      {drafts?.map((draft) => (
        <li key={draft._id} className="draft-item">
          {/* <img src={draft.formData.imagePreview} alt="Course" /> */}
          <h2 className="draft-item-title">{draft.formData.title}</h2>
          <button className="edit-btn" onClick={() => handleEditDraftCourse(draft._id)}>Edit</button>
          <button className="delete-btn" onClick={() => deleteDraftHandler(draft._id)}>Delete</button>
        </li>
      ))}
    </ul>
  </div>
  

  
  )
}
export default AdminCourseDraft