import React, { useContext } from "react";
import {RootStore} from '../../../../store/store';
import ChatNavImg from '../../../../assets/images/chat-images/admin-chat-white-icon.png'; 
import UserNavImg from '../../../../assets/images/chat-images/admin-user-white-icon.png';
import ArchNavImg from '../../../../assets/images/chat-images/admin-arch-white-icon.png';
import FinNavImg from '../../../../assets/images/chat-images/admin-fin-white-icon.png';
import SetNavImg from '../../../../assets/images/chat-images/admin-set-white-icon.png';
import SetNavImgActive from '../../../../assets/images/chat-images/admin-set-black-icon.png';
import ChatNavImgActive from '../../../../assets/images/chat-images/admin-chat-black-icon.png';
import UserNavImgActive from '../../../../assets/images/chat-images/admin-user-black-icon.png';
import ArchNavImgActive from '../../../../assets/images/chat-images/admin-arch-black-icon.png';
import FinNavImgActive from '../../../../assets/images/chat-images/admin-fin-black-icon.png';
import NoImage from '../../../../assets/images/chat-images/no-image.png';
import './ChatAdminSidebar.scss';

const ChatAdminSidebar = ({ setNavMenuItemActive, navMenuItemActive} ) => {
    const [store, setStore] = useContext(RootStore)
    const { userStore } = store;
    const ChatNavValues = [
        {
            id: 1,
            alttitle: "Chat Rooms",
            img: ChatNavImg,
            imgActive: ChatNavImgActive,
        },
        {
            id: 2,
            alttitle: "Users",
            img: UserNavImg,
            imgActive: UserNavImgActive
        },
        {
            id: 3,
            alttitle: "Archived Rooms",
            img: ArchNavImg,
            imgActive: ArchNavImgActive
        },
        {
            id: 4,
            alttitle: "Finance",
            img: FinNavImg,
            imgActive: FinNavImgActive
        },
        {
            id: 5,
            alttitle: "Profile Settings",
            img: SetNavImg,
            imgActive: SetNavImgActive
        }

    ];
    console.log("userStore.profile", userStore?.profile)

    return (
        <div className="ir-ct-an-sb-main-container ir-chat-navigation-container side-menu flex-lg-column">
            <div className="navbar-brand-box">
                <a className="logo logo-light" href="https://ir4u.info/">
                    <img src={require('../../../../assets/images/chat-images/logo.png')} alt="logo" />
                    <p>IR4U.info</p>
                </a>
            </div>
            <div className="ir-ct-an-side-over-flow-container">
                <div className="ir-ct-an-profile-container">
                    {(userStore?.profile?.image === "") ? 
                        <p className="profile-bg-replace">{userStore?.profile?.firstname[0] + userStore?.profile?.lastname[0]}</p> :
                        <img
                            src={userStore?.profile?.image}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = NoImage;
                            }}
                            className="profile-icon"
                            alt="profile_pic" />
                    }
                </div>
                <ul className="ir-ct-an-sb-list-container side-menu-nav">
                    {ChatNavValues.map((val, id) => {
                        if(val.id !==6) {
                            return (
                                <li key={id} 
                                    className={`side-nav-item ${navMenuItemActive === id ? 'activeMenuItem' : ""}`}
                                    onClick={() => setNavMenuItemActive(id)}
                                    id={val.alttitle}
                                >
                                    <a href={void(0)} 
                                        id="pills-chat-tab" 
                                        role="tab" 
                                        data-tip 
                                        data-for={val.alttitle} 
                                        className={`nav-link  ${navMenuItemActive === id ? "active" : ""}`}
                                    >
                                            {navMenuItemActive === id ? 
                                                <img src={val.imgActive} alt={val.alttitle} /> : 
                                                <img src={val.img} alt={val.alttitle}  />
                                            }
                                            <span className="ir-ct-an-span-item">{val.alttitle}</span>
                                    </a>
                                </li>

                            )
                        }
                    })}
                    <li className="side-nav-item" id="ir-chat-admin-sidebar-logOut">
                        <a href="/" id="pills-setting-tab" role="tab" className="nav-link" onClick={()=> (localStorage.removeItem("token"))}>
                            <img src={require('../../../../assets/images/chat-images/log-nav.png')} alt="Logout" /> 
                        </a>
                        <span className="ir-nav-tooltip">Logout</span>
                    </li>
                </ul>
            </div>
        </div>
    )
};

export default ChatAdminSidebar;