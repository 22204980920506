import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import ConfirmationModal from "../../Courses/AdminCreateCourseTab/ConfirmationModal";
import { useSelector } from "react-redux";

const FillInTheBlankQuestion = ({
  onDelete,
  id,
  onQuestionDataChange,
  slideNumber,
  question, // Received from props
  correctAnswer,
}) => {
  const [questionData, setQuestionData] = useState({
    question: question || "", // Initialize with prop value or empty string
    correctAnswer: correctAnswer || "", // Initialize with prop value or empty string
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleDeleteClick = () => {
    onDelete(id);
  };

  const handleQuestionChange = (content) => {
    setQuestionData({ ...questionData, question: content });
    onQuestionDataChange({ ...questionData, question: content });
  };
  // console.log(questionData, "Fill in the blank data");

  const handleAnswerChange = (e) => {
    const { value } = e.target;
    setQuestionData({ ...questionData, correctAnswer: value });
    onQuestionDataChange({ ...questionData, correctAnswer: value });
  };

  const modules = {
    toolbar: [["bold", "italic"]],
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  return (
    <div className="ir-ws-multiple-choice-question-container">
      <div className="ir-ws-multiple-choice-question-box">
        <ReactQuill
          placeholder="Type Fill in the _____ Blanks"
          theme="snow"
          modules={modules}
          value={questionData?.question}
          // value={questionData[0]?.question}
          onChange={handleQuestionChange}
        />

        <svg
          height="30"
          viewBox="0 0 48 48"
          width="30"
          xmlns="http://www.w3.org/2000/svg"
          className="ir-ws-creat-case-icon ir-ws-creat-case-icon-margin"
          onClick={toggleDeleteModal}
        >
          <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
          <path d="M0 0h48v48H0z" fill="none" />
        </svg>
      </div>
      <div className="ir-ws-create-course-mcq-option-container fill-in-the-blanks-border">
        <input
          placeholder="Type correct answer"
          className="ir-ws-create-course-input-tag"
          onChange={handleAnswerChange}
          value={questionData?.correctAnswer}
          // value={questionData[0]?.correctAnswer}
        />
      </div>
      {showDeleteModal && (
        <ConfirmationModal
          toggleModal={toggleDeleteModal}
          confirmationFunc={handleDeleteClick}
          title="delete"
        />
      )}
    </div>
  );
};

export default FillInTheBlankQuestion;
