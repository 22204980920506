import React from 'react';
import"./CaseBanner.scss"
import { useNavigate } from 'react-router-dom';
const CaseBanner = ({scrollToCasesList}) => {
  const navigate = useNavigate();
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";

  const joinHandler = () => {
    if (loggedIn) {
      navigate('/cases');
    } else {
      navigate('/signup');
    }
  };

  return (
    <div className="ir-ws-banner-padding-tb ir-cases-banner-main-container">
      <div className="ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center ir-ws-padding-lr-132 ir-cases-banner-inner-container">
        <div className="ir-ws-width-60 ir-cases-banner-content-container">
          <p className="top-sub-heading"><span className="live-btn">Live</span> Instant results</p>
          <h4 className="improve-sub-heading">Improve your knowledge through our</h4>
          <h3 className="ir-ws-heading-default-color ir-ws-font-700 ir-ws-font-size-xl ir-ws-cases-banner-title">
          Interactive Case Portal 
          </h3>
          <h4 className="ire-welcom-title">Welcome to the Next Generation of Interventional Radiology Education</h4>
          <p className="ir-cases-banner-title">
            Welcome to our state-of-the-art Interventional Radiology interactive learning platform. Designed for medical professionals at all levels, our platform offers a comprehensive suite of engaging and educational tools to enhance your skills and knowledge.   
          </p>
          <div className="ir-ws-default-btn-container join-btn-container">
          <button
            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn join-btn"
            onClick={joinHandler}
          >
            {loggedIn ? <span onClick={scrollToCasesList}>Explore Cases</span> :  <span>Join IR4U its FREE</span>}
          </button>
          </div>
        </div>
        <div className="ir-ws-width-30 ir-cases-banner-img-container">
          <div className="ir-cases-banner-img-inner-container ir-ws-position-relative">
            <img
              className="ir-ws-max-width-100"
              src={require("../../assets/images/case-banner-hero.webp")}
              alt="banner"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default CaseBanner