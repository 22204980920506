import {
  GlobalStyles,
  MeetingProvider,
  lightTheme,
} from "amazon-chime-sdk-component-library-react";
import { ThemeProvider } from "styled-components";
import UserJoinMeetingForm from "./userContainers/UserJoinMeetingForm";
import { useParams } from "react-router-dom";

const UserMeetingTest = () => {
  // const { meetingId } = useParams();
  const { joinUrl } = useParams();
  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles />
      <UserJoinMeetingForm joinUrl={joinUrl} />
    </ThemeProvider>
  );
};

export default UserMeetingTest;
