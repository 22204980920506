import { useState, createContext } from "react";

import userStore from "./userStore";
import chatStore from "./chatStore";
import socketStore from "./socketStore";
import getAllUsersStore from "./getAllUsersStore";

const storeObject = {
    userStore: userStore,
    chatStore: chatStore,
    socketConnection: socketStore,
    getAllUsersStore: getAllUsersStore
}

const RootStore = createContext();

const StoreProvider = ({ children }) => {
    const [data, setData] = useState(storeObject);
    console.log("data os" ,data);
    return (
        <RootStore.Provider value={[data, setData]}>
            {children}
        </RootStore.Provider>
    )
}

export { RootStore, StoreProvider }