import React, { useEffect, useState } from "react";

const Fib = ({
  activeSlideObject,
  activeAccordions,
  accordionRefs,
  toggleAccordion,
  // submittedFib,
  // handleSubmitFib,
  handleInputChange,
  answerType,
  index,
  accordianBoolean,
  fibData,
  questionIndex,
  markQuestionAsAnswered,
  activeQuestionindex,
  goToNextSlide,
  totalQuestions,
  handleShowLearningPopup,
  isLearningPointExist,
  isAllQuestionsSubmitted,
  handleSubmitAllAnser,
}) => {
  useEffect(() => {
    console.log("activeSlideObject", activeSlideObject);
  }, [
    activeAccordions,
    index,
    accordionRefs,
    activeSlideObject,
    accordianBoolean,
  ]);
  const [, setDummyState] = useState(false);
  const triggerReRender = () => setDummyState((prev) => !prev);

  useEffect(() => {
    // Any effect that should trigger a re-render can call triggerReRender
    triggerReRender();
  }, [accordianBoolean, activeAccordions, activeSlideObject]);
  const [userAnswer, setUserAnswer] = useState("");
  const [isSubmitted, setIssubmitted] = useState(false);

  const fidHandleChange = (e) => {
    setUserAnswer(e.target.value);
  };

  const isLastQuestion = questionIndex === totalQuestions - 1;

  const checkAnswerFid = () => {
    setIssubmitted(true);
    const correctAnswer = fibData.data.correctAnswer.toLowerCase();
    const yourAnswerElement = document.getElementById(
      `fib-your-answer-${fibData.id}`
    );
    const correctAnswerElement = document.getElementById(
      `fib-correct-answer-${fibData.id}`
    );

    if (!userAnswer) {
      yourAnswerElement.textContent = "Your Answer: (No answer provided)";
      yourAnswerElement.className = "your-answer wrong";
      correctAnswerElement.textContent = `Correct Answer: ${correctAnswer}`;
      correctAnswerElement.className = "correct-answer";
    } else {
      yourAnswerElement.textContent = `Your Answer: ${userAnswer}`;
      correctAnswerElement.textContent = `Correct Answer: ${correctAnswer}`;

      if (userAnswer.toLowerCase() === correctAnswer) {
        yourAnswerElement.textContent = "Your answer is Correct";
        yourAnswerElement.className = "your-answer correct";
        correctAnswerElement.textContent = `The answer is ${correctAnswer}`;
        correctAnswerElement.className = "correct-answer";
      } else {
        yourAnswerElement.textContent = "Your answer is Wrong";
        yourAnswerElement.className = "your-answer wrong";
        correctAnswerElement.textContent = `The answer is ${correctAnswer}`;
        correctAnswerElement.className = "correct-answer";
      }
    }
  };

  const submitHandler = (questionIndex) => {
    checkAnswerFid();
    markQuestionAsAnswered(questionIndex);
  };
  // useEffect(() => {
  //   if (isAllQuestionsSubmitted) {
  //     markQuestionAsAnswered(questionIndex);
  //   }
  // }, [isAllQuestionsSubmitted]);
  return (
    <>
      {fibData && (
        <>
          <div className="ir-ws-case-accordion-cont">
            <div
              // className={`ir-ws-case-accordion-header ${accordianBoolean
              //     ? "ir-ws-case-accordian-active-border"
              //     : "ir-ws-case-accordian-inactive-border "
              //   }`}
              className="ir-ws-case-accordion-header ir-ws-case-accordian-active-border"
              // onClick={() => toggleAccordion(questionIndex)}
            >
              <span className="ir-ws-case-slide-title-text">
                {""}
                <p
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: fibData.data.question,
                  }}
                />
              </span>
              {/* <span className="ir-cs-section-icon">
                {accordianBoolean ? (
                  <i className="bi bi-chevron-up"></i>
                ) : (
                  <i className="bi bi-chevron-down"></i>
                )}
              </span> */}
            </div>
            <div
              className="ir-ws-case-accordion-accordion-content"
              // style={{ height: "100%" }}
              // ref={(el) => {
              //   accordionRefs.current[questionIndex] = el;
              //   console.log((accordionRefs.current[questionIndex] = el));
              // }}
              // style={{
              //   maxHeight: accordianBoolean
              //     ? `${accordionRefs.current[questionIndex]?.scrollHeight}px`
              //     : "0px",
              // }}
            >
              <div className="ir-ws-case-content-box">
                {/* {activeSlideObject?.fillInTheBlankData?.map(
                  (question, index) => ( */}
                <div key={questionIndex} className="fib-question-data-main">
                  {/* <p className="fib-question">
                        <p
                          className=""
                          dangerouslySetInnerHTML={{
                            __html: fibData.data.question,
                          }}
                        />
                      </p> */}

                  <input
                    id={`fib-input-${fibData.id}`}
                    type="text"
                    className="fib-ans-input"
                    onChange={(e) => {
                      handleInputChange(fibData.id, e);
                      fidHandleChange(e);

                      if (answerType === "group")
                        markQuestionAsAnswered(questionIndex);

                      // markQuestionAsAnswered(questionIndex)
                    }}
                    readOnly={
                      (answerType === "group" && isAllQuestionsSubmitted) ||
                      isSubmitted
                    }
                    // disabled={
                    //   (answerType === "group" && isAllQuestionsSubmitted) ||
                    //   isSubmitted
                    // }
                  />

                  <div
                    className="result-message"
                    id={`fib-result-${fibData.id}`}
                  >
                    <span
                      className="your-answer"
                      id={`fib-your-answer-${fibData.id}`}
                    ></span>
                    <span
                      className="correct-answer"
                      id={`fib-correct-answer-${fibData.id}`}
                    ></span>
                  </div>

                  {answerType === "single" && (
                    <div
                      className=" ir-ws-default-btn-container"
                      style={{
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        id={`fib-input-${fibData.id}`}
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                        // onClick={checkAnswerFid}
                        onClick={() => submitHandler(questionIndex)}
                        disabled={isSubmitted}
                        style={{ width: "240px" }}
                      >
                        <span>Submit</span>
                      </button>
                    </div>
                  )}
                  {/* {!isLastQuestion && answerType === "group" && (
                    <div
                      className=" ir-ws-default-btn-container"
                      style={{
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        id={`fib-input-${fibData.id}`}
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                        // onClick={checkAnswerFid}
                        onClick={goToNextSlide}
                        // onClick={() => submitHandler(questionIndex)}
                        // disabled={isSubmitted}
                        style={{ width: "240px" }}
                        disabled={isLastQuestion}
                      >
                        <span>Next</span>
                      </button>
                    </div>
                  )} */}
                  {answerType === "group" && (
                    <div
                      className="ir-ws-default-btn-container"
                      style={{
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      {isLastQuestion ? (
                        <button
                          id={`fib-input-${fibData.id}`}
                          className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                          onClick={handleSubmitAllAnser}
                          style={{ width: "240px" }}
                          disabled={isAllQuestionsSubmitted}
                        >
                          <span>Finish</span>
                        </button>
                      ) : (
                        <button
                          id={`fib-input-${fibData.id}`}
                          className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                          onClick={goToNextSlide}
                          style={{ width: "240px" }}
                          disabled={isLastQuestion}
                        >
                          <span>Next</span>
                        </button>
                      )}
                    </div>
                  )}

                  {(isLearningPointExist && isLastQuestion && isSubmitted) ||
                  (isLearningPointExist &&
                    answerType === "group" &&
                    isLastQuestion &&
                    isAllQuestionsSubmitted) ? (
                    <div
                      className="ir-ws-default-btn-container"
                      style={{
                        width: "100%",
                        marginTop: "20px",
                        marginLeft: "5px",
                      }}
                    >
                      <button
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                        // onClick={checkJumblingAnswers}
                        onClick={() => handleShowLearningPopup()}
                        style={{ width: "240px" }}
                      >
                        <span>Learning Points</span>
                      </button>
                    </div>
                  ) : null}
                </div>
                {/* )
                )} */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Fib;
