import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import WebinarListTab from "../../components/WebinarListTab/WebinarListTab";
import WebinarPageBanner from "../../components/WebinarPageBanner/WebinarPageBanner";

const Webinar = () => {
  return (
    <div className="ir-ws-home-main-container">
      <Header />
      <WebinarPageBanner />
      <WebinarListTab />
      <Footer />
    </div>
  );
};

export default Webinar;
