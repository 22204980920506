import React, {useState} from "react";
import ActiveUserAdminTab from "./ActiveUserAdminTab";
import InActiveUserAdminTab from "./InactiveUserAdminTab";
import CustomTab from '../../CustomTab/CustomTab';
import CustomTabs from '../../CustomTab/CustomTabs';

const UserAdminTab = ({ data, onAction, activeUser, inactiveUser, deleteUser }) => {
    const [active, setActive] = useState(0);
    const handleChange = newActive => setActive(newActive);

    return (
        <CustomTabs active={active} onChange={handleChange}>
            <CustomTab title="Active Users">
                <ActiveUserAdminTab 
                    data={data} 
                    onAction={onAction}
                    inactiveUser={inactiveUser}
                    deleteUser={deleteUser}
                    activeUser={activeUser}
                />
            </CustomTab>
            <CustomTab title="Inactive Users">
                <InActiveUserAdminTab
                    data={data} 
                    onAction={onAction}
                    inactiveUser={inactiveUser}
                    deleteUser={deleteUser}
                    activeUser={activeUser}
                />
            </CustomTab>
        </CustomTabs>
    );
};

export default UserAdminTab;