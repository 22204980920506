import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import _ from "videojs-contrib-quality-levels";
import qualitySelector from "videojs-hls-quality-selector";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-seek-buttons";
import "videojs-seek-buttons/dist/videojs-seek-buttons.css";
import "./VideoPlayer.scss";

const VideoPlayer = ({ hlsLink, type, onEnded }) => {
  const videoRef = useRef();
  const [player, setPlayer] = useState(undefined);
  const [callFinishVideoAPI, setCallFinishVideoAPI] = useState(false);
  const [vidDuration, setVidDuration] = useState(50000);
  const videoId = "e2280eeb-4cdb-43e7-a34f-36868326b8cb";
  const thumbnailURL =
    "https://cbsnews1.cbsistatic.com/hub/i/r/2022/01/19/e6521679-1e8a-4522-af30-b416193d8414/thumbnail/640x360/7ee43f3be0b62ed37355827de806bd13/cbsnews-1920x1080.jpg";
  const liveURL = hlsLink;
  useEffect(() => {
    if (player) {
      player.src({
        src: liveURL,
        type: "application/x-mpegURL",
        withCredentials: false,
      });
      player.poster("");
      setCallFinishVideoAPI(false);
      setVidDuration(50000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoId, liveURL, thumbnailURL]);

  useEffect(() => {
    if (callFinishVideoAPI) {
      //finishesVideo()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callFinishVideoAPI]);

  useEffect(() => {
    const videoJsOptions = {
      autoplay: false,
      preload: "auto",
      controls: true,
      playbackRates: [0.5, 1, 1.5, 2],
      controlBar: {
        audioTrackButton: false,
        pictureInPictureToggle: false, // Set to false to remove the audio track button
      },
      plugins: {
        seekButtons: {
          forward: 10,
          back: 10,
        },
      },
      poster: "",
      sources: [
        {
          src: liveURL,
          type: "application/x-mpegURL",
          withCredentials: false,
        },
      ],
      html5: {
        nativeAudioTracks: true,
        nativeVideoTracks: true,
        nativeTextTracks: true,
      },
    };

    const p = videojs(
      videoRef.current,
      videoJsOptions,
      function onPlayerReady() {
        // console.log('onPlayerReady');
      }
    );

    setPlayer(p);
    console.log(p.qualityLevels());

    return () => {
      if (player) player.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (player) {
      player.hlsQualitySelector({ displayCurrentQuality: true });

      // Add event listener for touch events
      const videoElement = videoRef.current;
      videoElement.addEventListener("touchend", handleTouchEnd);
      videoElement.addEventListener("keydown", handleKeyDown);

      return () => {
        videoElement.removeEventListener("touchend", handleTouchEnd);
        videoElement.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [player]);

  const handleKeyDown = useCallback(
    (event) => {
      switch (event.keyCode) {
        case 32: // Space key
          player.paused() ? player.play() : player.pause();
          break;
        case 37: // Left arrow key
          player.currentTime(player.currentTime() - 10);
          break;
        case 39: // Right arrow key
          player.currentTime(player.currentTime() + 10);
          break;
        default:
          break;
      }
    },
    [player]
  );

  let lastTapTime = 0;

  // Function to handle touch end event
  const handleTouchEnd = (event) => {
    const currentTime = new Date().getTime();
    const timeSinceLastTap = currentTime - lastTapTime;

    if (timeSinceLastTap < 300 && timeSinceLastTap > 0) {
      // Double tap detected
      const screenWidth = window.innerWidth;
      const tapX = event.changedTouches[0].clientX;

      // Double tap on the center to play/pause
      if (
        tapX > screenWidth / 3 &&
        tapX < (2 * screenWidth) / 3 &&
        event.touches.length === 0
      ) {
        player.paused() ? player.play() : player.pause();
      } else if (event.touches.length === 0) {
        // Double tap on the left third of the screen, rewind 10 seconds
        if (tapX < screenWidth / 3) {
          player.currentTime(player.currentTime() - 10);
        }
        // Double tap on the right third of the screen, forward 10 seconds
        else if (tapX > (2 * screenWidth) / 3) {
          player.currentTime(player.currentTime() + 10);
        }
      }
    }

    lastTapTime = currentTime;
  };

  const handleEndVido = () =>{
    onEnded()
  }

  return type === "modal" ? (
    <div data-vjs-player>
      <div className="ir-ws-course-video-player-container">
        <video
          ref={videoRef}
          onLoadedMetadata={(e, px) => {
            // console.log(e.target.duration);
            setVidDuration(e.target.duration);
          }}
          className="vidPlayer video-js vjs-default-skin vjs-big-play-centered "
          style={{ width: "480px", height: "360px" }}
        ></video>
      </div>
    </div>
  ) : (
    <div data-vjs-player>
      <div className="ir-ws-course-video-player-container">
        <video
          ref={videoRef}
          onEnded={handleEndVido}
          onLoadedMetadata={(e, px) => {
            // console.log(e.target.duration);
            setVidDuration(e.target.duration);
          }}
          className="vidPlayer video-js vjs-default-skin vjs-big-play-centered ir-ws-course-video-player-size "
        ></video>
      </div>
    </div>
  );
};

export default VideoPlayer;
