import React, { createContext, useContext, useState } from 'react';

const PaymentContext = createContext();

export const PaymentProvider = ({ children }) => {
  const [paymentResponses, setPaymentResponses] = useState({});

  return (
    <PaymentContext.Provider value={{ paymentResponses, setPaymentResponses }}>
      {children}
    </PaymentContext.Provider>
  );
};

export const usePaymentContext = () => {
  return useContext(PaymentContext);
};
