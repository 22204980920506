import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  slides: [{ slideNumber: 1, rapidTime: null }],
  casePrice: null,
  categorie: null,
  thumbnail: null,
  // description: null,
  bundleTitle: null,
};

const slideSlice = createSlice({
  name: "slides",
  initialState,
  reducers: {
    addSlides(state, action) {
      const newSlide = {
        slideNumber: state.slides.length + 1,
        rapidTime: null,
      };
      state.slides.push(newSlide);
    },
    updateSlide(state, action) {
      const { slideNumber, newData } = action.payload;
      const slideIndex = state.slides.findIndex(
        (slide) => slide.slideNumber === slideNumber
      );
      if (slideIndex !== -1) {
        state.slides[slideIndex] = { ...state.slides[slideIndex], ...newData };
      }
    },
    deleteSlides(state, action) {
      const slideNumber = action.payload;
      state.slides = state.slides.filter(
        (slide) => slide.slideNumber !== slideNumber
      );
      // Reassign slide numbers to be sequential
      state.slides = state.slides.map((slide, index) => ({
        ...slide,
        slideNumber: index + 1,
      }));
    },
    addCasePrice(state, action) {
      state.casePrice = action.payload;
    },
    addCategory(state, action) {
      state.categorie = action.payload;
    },
    addThumbnail(state, action) {
      state.thumbnail = action.payload;
    },
    // addDescription(state, action) {
    //   state.description = action.payload;
    // },
    addBundleTitle(state, action) {
      state.bundleTitle = action.payload;
    },
    addRapidTime(state, action) {
      const { slideNumber, rapidTime } = action.payload;
      const slide = state.slides.find(
        (slide) => slide.slideNumber === slideNumber
      );
      if (slide) {
        slide.rapidTime = rapidTime;
      }
    },
    setCaseData(state, action) {
      const { slides, casePrice, categorie, thumbnail , bundleTitle} = action.payload;

      state.slides = slides;
      state.casePrice = casePrice;
      state.categorie = categorie;
      state.thumbnail = thumbnail;
      state.bundleTitle=bundleTitle;
      // state.description=description
    },
  },
});

export const {
  addSlides,
  updateSlide,
  deleteSlides,
  addCasePrice,
  addRapidTime,
  setCaseData,
  addCategory,
  addThumbnail,
  // addDescription,
  addBundleTitle
} = slideSlice.actions;
export default slideSlice.reducer;
