import React, { useState } from "react";
import ConfirmationModal from "../Courses/AdminCreateCourseTab/ConfirmationModal";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";
import { useNavigate } from "react-router-dom";

const AdminCaseTrashList = () => {
  const navigate = useNavigate();
  const { deletedCases, restoreCaseFunction } = useModalContext();
  const [caseId, setCaseId] = useState("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  console.log("deletedCases:", deletedCases);

  const toggleConfirmationModal = (id) => {
    setCaseId(id);
    setOpenConfirmationModal(!openConfirmationModal);
  };

  const handleRestoreConfirmation = async () => {
    if (caseId) {
      await restoreCaseFunction(caseId);
      setCaseId("");
      setOpenConfirmationModal(false);
    }
  };
  return (
    <div>
      {deletedCases?.map((caseItem, index) => (
        <div className="ir-ws-webinar-list-tab" key={index}>
          <div className="ir-ws-admin-webinar-content">
            <div className="ir-ws-admin-webinar-title-container">
              {/* <img
                  src={course.formData.imagePreview}
                  className="ir-ws-course-list-image"
                  alt="course"
                /> */}
              <p className="ir-ws-webinar-title-text">
                {caseItem?.slides?.[0]?.name ?? "No name found"}
              </p>
            </div>
            <div className="ir-ws-admin-list-button-container">
              <button
                className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                onClick={() => navigate(`/case/${caseItem._id}`)}
              >
                View Case
              </button>
              <button
                className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                onClick={() => toggleConfirmationModal(caseItem._id)}
              >
                Restore
              </button>
            </div>
          </div>
        </div>
      ))}
      {openConfirmationModal && (
        <ConfirmationModal
          toggleModal={toggleConfirmationModal}
          confirmationFunc={handleRestoreConfirmation}
          title="restore"
        />
      )}
    </div>
  );
};

export default AdminCaseTrashList;
