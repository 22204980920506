import React from 'react';
import './Loader.scss';

const Loader = () => {
  return (
    <div className="loader-container">
      <div className='loader'>
      <img src={require("../../assets/images/loader.png")} alt="Loading..." className="spinning-icon" />
      <span>Please wait page loading..</span>
      </div>
    </div>
  );
};

export default Loader;
