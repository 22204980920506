import React, { useEffect, useState } from "react";
import "./FavoriteCases.scss";
import axios from "axios";
import { Link } from "react-router-dom";

const FavoriteCases = () => {
  const [accordianOpen, setAccordianOpen] = useState({});
  const [favoriteCases, setFavoriteCases] = useState([]);
  const [userEmail, setUseremail] = useState("");

  const token = localStorage.getItem("jwtTokenApi1");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const userProfileDetails = async () => {
      try {
        const response = await axios.get(
          `https://backend.ir4u.info/api/v1/user/profile`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );
        setUseremail(response.data?.email);
      } catch (error) {
        console.log(error);
      }
    };
    userProfileDetails();
  }, []);

  const getFavCases = () => {
    axios
      .get(`https://case.ir4u.info/api/v1/user/case/favorite-cases/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFavoriteCases(res?.data);
        console.log("fav cases: ", res?.data);
      })
      .catch((error) => {
        console.error("Error fetching favorite cases:", error);
      });
  };

  useEffect(() => {
    getFavCases();
  }, [userId]);

  const handleAccordianToggle = (caseId) => {
    setAccordianOpen((prevOpen) => ({
      ...prevOpen,
      [caseId]: !prevOpen[caseId] || false,
    }));
  };

  const checkoutHandler = async (caseID) => {
    const data = {
      caseId: caseID,
      userEmail: userEmail,
      successUrl: `${origin}/success`,
      cancelUrl: `${origin}/failed`,
      userId: userId,
    };
    const token = localStorage.getItem("jwtTokenApi1");
    // console.log(courseData);
    try {
      const response = await axios.post(
        "https://case.ir4u.info/api/v1/user/case/create-checkout-session",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response);
      if (response.status === 200) {
        const sessionUrl = response.data.url;
        window.location.href = sessionUrl;
      }
    } catch (error) {
      console.log(error, "error while buying the case");
    }
  };

  return (
    <div className="favorite-cases-container ir-ws-max-width-100">
      <p className="favorite-cases-title-text">Favorite Cases</p>
      <div className="favorite-cases-box">
        <ul className="favorite-cases-title-container ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center">
          <li>Title</li>
          <li>Category</li>
          <li>More Details</li>
        </ul>
        <div className="favorite-cases-details-container">
          {favoriteCases?.map((caseItem) => (
            <div className="favorite-cases-details-box" key={caseItem?.caseID}>
              <ul className="favorite-cases-title-details-container ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center">
                <li>{caseItem?.caseDetails.caseName}</li>
                <li>{caseItem?.caseDetails.caseCategory}</li>
                <li onClick={() => handleAccordianToggle(caseItem?.caseID)}>
                  <span
                    className={`${
                      !accordianOpen[caseItem?.caseID]
                        ? "accordian-dropdown-inactive"
                        : "accordian-dropdown-active"
                    }`}
                  >
                    {!accordianOpen[caseItem?.caseID] ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="dropdown-icon"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m19.5 8.25-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="dropdown-icon"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m4.5 15.75 7.5-7.5 7.5 7.5"
                        />
                      </svg>
                    )}
                  </span>
                </li>
              </ul>
              {accordianOpen[caseItem.caseID] && (
                // <ul className="favorite-cases-accordian-details ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center">
                //   <li>Case Price: {caseItem?.caseDetails.casePrice}</li>
                //   <li>
                //     <a
                //       // href={caseItem.purchaseLink}
                //       target="_blank"
                //       rel="noopener noreferrer"
                //     >
                //       Buy Now
                //     </a>
                //   </li>
                //   <li>
                //     <Link to={`/case/${caseItem.caseID}`}>View Case</Link>
                //   </li>
                // </ul>
                <>
                  <ul className="favorite-cases-accordian-details ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center">
                    <li>Case Price</li>
                    <li>Purchase Link</li>
                    <li>Case Link</li>
                  </ul>
                  <ul className="favorite-cases-accordian-details ir-ws-flex ir-ws-justify-space-btw ir-ws-align-center">
                    <li>{"$" + " " + caseItem?.caseDetails.casePrice}</li>
                    <li
                      onClick={() => checkoutHandler(caseItem.caseID)}
                      className="ir-fav-case-buy-link"
                    >
                      Buy Now
                    </li>
                    <li className="">
                      <Link to={`/case/${caseItem.caseID}`}>View Course</Link>
                    </li>
                  </ul>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FavoriteCases;
