import React, { useState } from "react";
import CreateCourseSecondPageSection from "../CreateCourseSecondPageSection/CreateCourseSecondPageSection";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import {
  addSection,
  deleteSection,
  resetFormData,
} from "../../Store/CourseSlice";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../ConfirmationModal";
import { useModalContext } from "../../../../components/WebinarAdminCreateWebinarTab/ModalContext";

const CreateCourseSecondPage = ({ currentPage, setCurrentPage }) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { handleCreateCourse } = useModalContext();
  const navigate = useNavigate();
  const toggleConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal);
  };

  const handleGoBackCoursePage = () => {
    setCurrentPage(1);
  };
  const allSections = useSelector((state) => state.course.sections);
  const allformdata = useSelector((state) => state.course.formData);
  const dispatch = useDispatch();
  // const handleAddSections = () => {
  //   const newSection = { id: uuidv4(), content: "Introduction", sectionNumber: allSections.length, lectures: [] };
  //   dispatch(addSection(newSection));
  // };
  const handleAddSections = () => {
    if (!allSections) {
      console.error("Sections not defined");

      return; // Exit early if sections are not defined
    }

    const hasNoUrls = allSections.some((section) =>
      section.lectures.every(
        (lecture) => !lecture.video.videoUrl && !lecture.document.docUrl
      )
    );
    if (hasNoUrls) {
      setErrorMessage(
        "Add video or document in lecture to add another section"
      );
    }

    if (!hasNoUrls) {
      const newSection = {
        id: uuidv4(),
        content: "Introduction",
        sectionNumber: allSections.length,
        lectures: [],
      };
      dispatch(addSection(newSection));
    }
  };

  const HandleDeleteSection = (sectionId) => {
    setErrorMessage("");
    dispatch(deleteSection(sectionId));
  };
  console.log("all sections", allSections);
  console.log("all data", allformdata);
  return (
    <div className="ir-ws-create-course-second-page-container">
      {allSections.map((section) => (
        <CreateCourseSecondPageSection
          key={section.id}
          sectionId={section.id}
          sectionContent={section.content}
          sectionNumber={section.sectionNumber}
          allLectures={section.lectures}
          onDeleteSection={() => HandleDeleteSection(section.id)}
          setErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
        />
      ))}
      {errorMessage !== "" && (
        <p className="ir-ws-course-section-error-message">{errorMessage}</p>
      )}
      <div className="ir-ws-add-section-button" onClick={handleAddSections}>
        + Add Section
      </div>
      <div
        className="ir-ws-flex"
        style={{ width: "75%", justifyContent: "space-between" }}
      >
        <div
          className="ir-ws-create-btn-container ir-ws-create-course-section-btn ir-ws-save-course-btn-container"
          style={{ marginBottom: "20px" }}
          onClick={handleGoBackCoursePage}
        >
          <button className="ir-ws-app-bg ir-color-white ir-ws-no-border  ir-ws-banner-btn ">
            <span>Back</span>
          </button>
        </div>
        <div
          className="ir-ws-create-btn-container ir-ws-create-course-section-btn ir-ws-save-course-btn-container"
          style={{ marginBottom: "20px" }}
          onClick={() => navigate("/preview")}
          // onClick={()=> console.log(allSections, allformdata)}
        >
          <button className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-banner-btn ">
            <span>Review</span>
          </button>
        </div>
        <div
          className="ir-ws-create-btn-container ir-ws-create-course-section-btn ir-ws-save-course-btn-container  "
          style={{ marginBottom: "20px" }}
          onClick={toggleConfirmationModal}
        >
          <button className="ir-ws-app-bg ir-color-white ir-ws-no-border ir-ws-banner-btn ">
            <span>Cancel</span>
          </button>
        </div>
      </div>
      {openConfirmationModal && (
        <ConfirmationModal
          toggleModal={toggleConfirmationModal}
          confirmationFunc={() => {
            dispatch(resetFormData());
            handleGoBackCoursePage();
            handleCreateCourse();
          }}
          title="cancel"
        />
      )}
    </div>
  );
};

export default CreateCourseSecondPage;
