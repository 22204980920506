import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

const Jumbling = forwardRef(
  (
    {
      activeSlideObject,
      activeAccordions,
      accordionRefs,
      toggleAccordion,
      stripHtmlTags,
      answerType,
      // index,
      jumblingData,
      questionComponentIndex,
      markQuestionAsAnswered,
      activeQuestionindex,
      goToNextSlide,
      totalQuestions,
      handleShowLearningPopup,
      isLearningPointExist,
      isAllQuestionsSubmitted,
      handleSubmitAllAnser,
    },
    ref
  ) => {
    const [shuffledPairedOptions, setShuffledPairedOptions] = useState([]);
    const [resultMessage, setResultMessage] = useState("");
    const [startedAnswering, setStartedAnswering] = useState(false);
    const [correctAnswers, setCorrectAnswers] = useState([]);
    const [dragging, setDragging] = useState(false);
    const [jumblingSubmissionStatus, setJumblingSubmissionStatus] =
      useState(false);

    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };
    const isLastQuestion = questionComponentIndex === totalQuestions - 1;

    // console.log("isLearningPointExist:", isLearningPointExist);

    // useEffect(() => {
    //   if (isAllQuestionsSubmitted) {
    //     markQuestionAsAnswered(questionComponentIndex);
    //   }
    // }, [isAllQuestionsSubmitted]);

    useEffect(() => {
      if (jumblingData?.data && jumblingData?.data.length > 0) {
        const shuffledOptions = shuffleArray(
          jumblingData.data.map((item) => ({
            id: item.id,
            pairedOption: item.pairedOption,
          }))
        );
        setShuffledPairedOptions(shuffledOptions);

        const correctOptions = jumblingData.data.map((item) => ({
          id: item.id,
          pairedOption: item.pairedOption,
        }));
        setCorrectAnswers(correctOptions);
      } else {
        setShuffledPairedOptions([]);
        setCorrectAnswers([]);
      }
    }, [jumblingData]);

    useImperativeHandle(ref, () => ({
      checkJumblingAnswers,
    }));

    const handleDragStart = (e, questionIndex) => {
      if (jumblingSubmissionStatus) return;
      e.dataTransfer.setData("questionIndex", questionIndex);
      setDragging(true);
      setStartedAnswering(true);
    };

    const handleDrop = (e, newQuestionIndex) => {
      if (jumblingSubmissionStatus) return;
      e.preventDefault();
      const oldQuestionIndex = parseInt(
        e.dataTransfer.getData("questionIndex"),
        10
      );

      const updatedShuffledPairedOptions = [...shuffledPairedOptions];
      const [movedItem] = updatedShuffledPairedOptions.splice(
        oldQuestionIndex,
        1
      );
      updatedShuffledPairedOptions.splice(newQuestionIndex, 0, movedItem);

      setShuffledPairedOptions(updatedShuffledPairedOptions);
      setDragging(false);
    };

    const checkJumblingAnswers = () => {
      let correctCount = 0;

      shuffledPairedOptions.forEach((option, optionIndex) => {
        if (
          option.pairedOption === correctAnswers[optionIndex].pairedOption &&
          option.id === correctAnswers[optionIndex].id
        ) {
          correctCount++;
        }
      });

      if (correctCount === 0) {
        setResultMessage("No answers are correct.");
      } else if (correctCount === shuffledPairedOptions.length) {
        setResultMessage("All answers are correct!");
      } else {
        setResultMessage(`${correctCount} answers are correct.`);
      }
      setJumblingSubmissionStatus(true);
    };
    const submitHandler = (questionComponentIndex) => {
      checkJumblingAnswers();
      markQuestionAsAnswered(questionComponentIndex);
      // console.log("This is the index of jumbling: ", questionComponentIndex);
    };

    return (
      <>
        {jumblingData?.data?.length > 0 && (
          <div className="ir-ws-case-accordion-cont">
            <div
              // className={`ir-ws-case-accordion-header ${
              //   activeAccordions[questionComponentIndex]
              //     ? "ir-ws-case-accordian-active-border"
              //     : "ir-ws-case-accordian-inactive-border"
              // }`}
              className="ir-ws-case-accordion-header ir-ws-case-accordian-active-border"
              // onClick={() => toggleAccordion(questionComponentIndex)}
            >
              <span className="ir-ws-case-slide-title-text">
                <p className="mul-question">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: jumblingData?.data[0]?.question,
                    }}
                  />
                </p>
              </span>
              {/* <span className="ir-cs-section-icon">
                {activeAccordions[questionComponentIndex] ? (
                  <i className="bi bi-chevron-up"></i>
                ) : (
                  <i className="bi bi-chevron-down"></i>
                )}
              </span> */}
            </div>
            <div
              className="ir-ws-case-accordion-accordion-content"
              // ref={(el) => (accordionRefs.current[questionComponentIndex] = el)}
              // style={{
              //   maxHeight: activeAccordions[questionComponentIndex]
              //     ? `${accordionRefs.current[questionComponentIndex]?.scrollHeight}px`
              //     : "0px",
              // }}
              // style={{ height: "100%" }}
            >
              <div className="ir-ws-case-content-box">
                <div key={questionComponentIndex}>
                  <h3 className="ir-ws-case-jumbling-question-text">
                    {stripHtmlTags(jumblingData?.data[0]?.question)}
                  </h3>
                  <div className="ir-ws-single-case-jumbling-main-cont">
                    <div>
                      {jumblingData?.data?.map((question, questionIndex) => (
                        <div
                          key={question.id}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "10px",
                          }}
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={(e) => handleDrop(e, questionIndex)}
                        >
                          <div>
                            <p className="jumbling-option-cont">
                              {question.option}
                            </p>
                          </div>
                          <div id={question.id}>
                            <p
                              style={{
                                cursor: "move",
                                opacity: dragging ? "0.5" : "1",
                              }}
                              className="jumbling-option-cont"
                              draggable={
                                !jumblingSubmissionStatus ||
                                (answerType === "group" &&
                                  isAllQuestionsSubmitted)
                              }
                              onDragStart={(e) => {
                                handleDragStart(e, questionIndex);
                                if (answerType === "group")
                                  markQuestionAsAnswered(
                                    questionComponentIndex
                                  );
                                // markQuestionAsAnswered(questionComponentIndex)
                              }}
                              onDragEnd={() => setDragging(false)}
                            >
                              {
                                shuffledPairedOptions[questionIndex]
                                  ?.pairedOption
                              }
                            </p>
                          </div>
                        </div>
                      ))}

                      {resultMessage !== "" && (
                        <p className="ir-ws-jumbling-correct-answr-text">
                          {resultMessage}
                        </p>
                      )}
                    </div>
                    {jumblingSubmissionStatus && (
                      <div className="correct-pair-container">
                        {jumblingData?.data?.map((question, questionIndex) => (
                          <div
                            key={question.id}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: "10px",
                            }}
                          >
                            <div>
                              <p className="jumbling-option-cont">
                                {question.option}
                              </p>
                            </div>
                            <div id={question.id}>
                              <p className="jumbling-option-cont">
                                {question.pairedOption}
                              </p>
                            </div>
                          </div>
                        ))}
                        <p className="ir-ws-jumbling-correct-pair-text">
                          Correct Pair
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                {answerType === "single" && (
                  <div
                    className=" ir-ws-default-btn-container"
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    <button
                      className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                      disabled={jumblingSubmissionStatus}
                      // onClick={checkJumblingAnswers}
                      onClick={() => submitHandler(questionComponentIndex)}
                      style={{ width: "240px" }}
                    >
                      <span>Submit</span>
                    </button>
                  </div>
                )}
                {/* {answerType === "group" && (
                  <div
                    className="ir-ws-default-btn-container"
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    {isLastQuestion ? (
                      <button
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                        onClick={handleSubmitAllAnser}
                        style={{ width: "240px" }}
                        disabled={isAllQuestionsSubmitted}
                      >
                        <span>Finish</span>
                      </button>
                    ) : (
                      <button
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                        disabled={jumblingSubmissionStatus}
                        onClick={() => submitHandler(questionComponentIndex)}
                        style={{ width: "240px" }}
                      >
                        <span>Submit</span>
                      </button>
                    )}
                  </div>
                )} */}

                {answerType === "group" && (
                  <div
                    className="ir-ws-default-btn-container"
                    style={{
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    {isLastQuestion ? (
                      <button
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                        onClick={handleSubmitAllAnser}
                        disabled={isAllQuestionsSubmitted}
                        style={{ width: "240px" }}
                      >
                        <span>Finish</span>
                      </button>
                    ) : (
                      <button
                        className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                        onClick={goToNextSlide}
                        style={{ width: "240px" }}
                      >
                        <span>Next</span>
                      </button>
                    )}
                  </div>
                )}

                {(isLearningPointExist &&
                  isLastQuestion &&
                  jumblingSubmissionStatus) ||
                (isLearningPointExist &&
                  answerType === "group" &&
                  isLastQuestion &&
                  isAllQuestionsSubmitted) ? (
                  <div
                    className="ir-ws-default-btn-container"
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      marginLeft: "5px",
                    }}
                  >
                    <button
                      className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                      // onClick={checkJumblingAnswers}
                      onClick={() => handleShowLearningPopup()}
                      style={{ width: "240px" }}
                    >
                      <span>Learning Points</span>
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default Jumbling;
