import { useModalContext } from "./ModalContext";

const CancelWebinarModal = ({ id, cancelModal, setCancelModal }) => {
  const { handleCancelWebinar } = useModalContext();

  const handleCloseCancelModal = () => {
    setCancelModal(false);
  };
  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={handleCloseCancelModal}
          >
            <span>X</span>
          </div>
          <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
              Are you sure you want to cancel this Webinar?
            </h3>
          </div>
          <div className="ir-ws-deleteModal-button-container">
            <button
              className="ir-ws-deleteModal-button-yes"
              onClick={async () => {
                try {
                  await handleCancelWebinar(id);
                  handleCloseCancelModal();
                } catch (error) {
                  console.error("Delete Webinar failed:", error);
                }
              }}
            >
              Yes
            </button>
            <button
              className="ir-ws-deleteModal-button-no"
              onClick={handleCloseCancelModal}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelWebinarModal;
