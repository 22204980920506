import React, { useEffect, useRef, useState } from "react";
import "./CreateCasePage.scss";
import CreateSlide from "./CreateSlide";
import { v4 as uuidv4 } from "uuid";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";
import ConfirmationModal from "../../Courses/AdminCreateCourseTab/ConfirmationModal";
import {
  addSlides,
  deleteSlides,
  addCasePrice,
  updateSlide,
  addThumbnail,
  addCategory,
  addDescription,
  addBundleTitle
} from "../CaseSlice/Caseslice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../../api/axios";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import CropImageModal from "./CropImageModal";
// import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";

const CreateCasePage = () => {
  const [slides, setSlides] = useState([{ id: uuidv4(), slideNumber: 1 }]);
  const {
    handleCreateCase,
    updateDraftCaseId,
    isUpdateDraftCaseId,
    caseUpdateId,
  } = useModalContext();
  const dispatch = useDispatch();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const navigate = useNavigate();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [draftConfirmationModelOpen, setDraftConfirmationOpen] =
    useState(false);
  const reduxSlice = useSelector((state) => state.case.slides);
  const reduxCase = useSelector((state) => state.case);
  const [activeCategoryDropdown, setActiveCategoryDropdown] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [thumbnail, setThumbnail] = useState(null);
  const [imageToCrop, setImageToCrop] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false);
  const [bundleTitle, setBundleTitle]=useState(null)

  const [emptyData, setEmptyData] = useState(false);
  // const [requiredData, setRequiredData] = useState(false);
  const [requiredDataStatus, setRequiredDataStatus] = useState(
    Array(slides.length).fill(false)
  );

  const slideData = useSelector((state) => state.case.slides);
  // console.log(slideData);
  const casePrice = useSelector((state) => state.case.casePrice);
  // console.log(casePrice);
  const [price, setPrice] = useState("");
  const [isPriceValid, setIsPriceValid] = useState(false);
  // Accessing casePrice from Redux store
  // const [inputPrice, setInputPrice] = useState(casePrice || '');
  useEffect(() => {
    if (slideData && slideData.length > 0) {
      setSlides(slideData);
      setActiveSlideIndex(0);
    }
    setPrice("$ " + (casePrice || ""));
    setSelectedCategory(reduxCase.categorie || null);
    setThumbnail(reduxCase.thumbnail || "");
    // setDescription(reduxCase.description || "");
    setBundleTitle(reduxCase.bundleTitle || "")
  }, []);
  console.log("reduxCase", reduxCase);
  const modules = {
    toolbar: [["bold", "italic"]],
  };
  const toggelDraftConfirmationModal = () => {
    setDraftConfirmationOpen(!draftConfirmationModelOpen);
  };
  const addSlide = () => {
    // Check if all existing slides have required data
    const isPricePresent = price.replace(/[^\d]/g, "").length > 0;
    const allSlidesHaveData = slideData.every(
      (slide, index) =>
        // slide.categorie &&
        slide.componentName
      // slide.description &&
      // slide.name
    );

    if (allSlidesHaveData && isPricePresent && selectedCategory !== null) {
      const newSlideNumber = slides.length + 1;
      const newSlide = { id: uuidv4(), slideNumber: newSlideNumber };
      setSlides([...slides, newSlide]);
      setActiveSlideIndex(slides.length);
      dispatch(addSlides(newSlideNumber));
      // Reset the requiredData state
      const updatedRequiredDataStatus = [...requiredDataStatus];
      updatedRequiredDataStatus[activeSlideIndex] = false;
      setRequiredDataStatus(updatedRequiredDataStatus);
    } else {
      // Set requiredData status for the current slide to true
      const updatedRequiredDataStatus = [...requiredDataStatus];
      updatedRequiredDataStatus[activeSlideIndex] = true;
      setRequiredDataStatus(updatedRequiredDataStatus);
      setEmptyData(true);
    }
  };
  // console.log(price);
  const deleteSlide = (slideNumber) => {
    const updatedSlides = slides.filter(
      (slide) => slide.slideNumber !== slideNumber
    );
    setSlides(updatedSlides);
    dispatch(deleteSlides(slideNumber)); // Dispatch action to delete slide in Redux store

    // Update requiredDataStatus after deleting a slide
    const updatedRequiredDataStatus = requiredDataStatus.filter(
      (_, index) => slides[index].slideNumber !== slideNumber
    );
    setRequiredDataStatus(updatedRequiredDataStatus);

    // Adjust activeSlideIndex if the deleted slide is the active slide
    const deletedSlideIndex = slides.findIndex(
      (slide) => slide.slideNumber === slideNumber
    );
    if (deletedSlideIndex === activeSlideIndex && slides.length > 1) {
      setActiveSlideIndex(deletedSlideIndex === 0 ? 0 : deletedSlideIndex - 1);
    }
  };

  const toggleConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal);
  };

  const goToPreviousSlide = () => {
    if (activeSlideIndex > 0) {
      setActiveSlideIndex(activeSlideIndex - 1);
    }
  };

  const handleDataSave = (data, slideIndex) => {
    dispatch(updateSlide({ slideNumber: slideIndex + 1, newData: data }));
    // dispatch(addCasePrice(price.replace(/[^\d]/g, "")));
  };
  useEffect(() => {
    dispatch(addCategory(selectedCategory));
    // dispatch(addName(name));
    dispatch(addThumbnail(thumbnail));
    // dispatch(addDescription(description));
    dispatch(addBundleTitle(bundleTitle));
    dispatch(addCasePrice(price.replace(/[^\d]/g, "")));
  }, [selectedCategory, price]);

  const handlePreview = () => {
    const allSlidesHaveData = slideData.every(
      (slide, index) =>
        // slide.categorie &&
        slide.componentName
      // slide.description &&
      // slide.name
    );
    const isPricePresent = price.replace(/[^\d]/g, "").length > 0; // Check if price has any number
    // setIsPriceValid(isPriceValid);
    // console.log(allSlidesHaveData);
    if (allSlidesHaveData && isPricePresent && selectedCategory !== null) {
      const updatedRequiredDataStatus = [...requiredDataStatus];
      updatedRequiredDataStatus[activeSlideIndex] = false;
      setRequiredDataStatus(updatedRequiredDataStatus);
      dispatch(addCasePrice(price.replace(/[^\d]/g, "")));
      navigate("/case/preview");
    } else {
      // Set requiredData status for the current slide to true
      const updatedRequiredDataStatus = [...requiredDataStatus];
      updatedRequiredDataStatus[activeSlideIndex] = true;
      setRequiredDataStatus(updatedRequiredDataStatus);
      setIsPriceValid(true);
      setEmptyData(true);
    }
  };

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    // Remove any non-digit characters
    const cleanedInput = userInput.replace(/\D/g, "");
    setPrice("$ " + cleanedInput);
  };

  // const handleThumbnailChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const imageUrl = URL.createObjectURL(file);
  //     setThumbnail(imageUrl); // Set the local state
  //     dispatch(addThumbnail(imageUrl)); // Update Redux state if necessary
  //   }
  // };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageToCrop(imageUrl);
      setShowCropModal(true);
    }
  };

  const handleCloseImageCrop = () => {
    setShowCropModal(false);
  };

  const handleImageThumbnail = (croppedImage) => {
    setThumbnail(croppedImage);
    dispatch(addThumbnail(croppedImage));
  };

  const handleBundleTitleChange = (value) => {
    setBundleTitle(value);
    dispatch(addBundleTitle(value));
  };

  // console.log(price.replace(/[^\d]/g, ""));
  // console.log(isPriceValid);
  const handleSaveToDraftConfirmation = async () => {
    setDraftConfirmationOpen(false);
    if (updateDraftCaseId) {
      await handleUpdateDraftCase(updateDraftCaseId);
    } else {
      await handleSaveDataToDraft();
    }
  };
  const hasCalledFunction = useRef(false);
  useEffect(() => {
    if (caseUpdateId) {
      console.log("this is from update case so it would not add to case");
      return;
    }
    const autoSaveData = async () => {
      console.log("This function is called");
      if (reduxCase?.categorie || reduxCase?.casePrice) {
        if (updateDraftCaseId) {
          await handleUpdateDraftCase(updateDraftCaseId);
        } else {
          await handleSaveDataToDraft();
          console.log("Saved to draft");
        }
      } else {
        console.log("There is no function name");
      }
    };

    // Call the function immediately
    if (!hasCalledFunction.current) {
      autoSaveData();
      hasCalledFunction.current = true;
    }

    // Set up the interval to call the function every 20 seconds
    const intervalId = setInterval(autoSaveData, 20000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [reduxSlice, updateDraftCaseId]);

  const handleSaveDataToDraft = async () => {
    try {
      const response = await axios.post(
        "https://case.ir4u.info/api/v1/admin/case/add/draft",
        {
          slides: reduxCase.slides,
          casePrice: reduxCase.casePrice,
          categorie: reduxCase.categorie,
          // name: reduxCase.name,
          // description: reduxCase.description,
        },

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );
      if (response.status === 200) {
        console.log("case added ");
        isUpdateDraftCaseId(response.data.id);
        // Swal.fire({
        //   icon: 'success',
        //   title: 'Saved To Draft',
        //   text: 'Your case has been successfully Drafted!',
        // });
      }
    } catch (error) {
      console.log("error while uploading case", error);
    }
  };

  const handleUpdateDraftCase = async (id) => {
    try {
      const response = await axios.put(
        `https://case.ir4u.info/api/v1/admin/case/update/draft/${id}`,
        {
          slides: reduxCase.slides,
          casePrice: reduxCase.casePrice,
          categorie: reduxCase.categorie,
          // name: reduxCase.name,
          // description: reduxCase.description,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );
      if (response.status === 200) {
        // Swal.fire({
        //   icon: 'success',
        //   title: 'Saved To Draft',
        //   text: 'Your Draft has been successfully Updated!',
        // });
        console.log("case updated");
      }
    } catch (error) {
      console.log("error while uploading case", error);
    }
  };

  const toggleCategoryDropDown = () => {
    setActiveCategoryDropdown(!activeCategoryDropdown);
    // sendDataToParent();
  };
  const categories = [
    {
      name: "Chest",
      imageSrc: require("../../../assets/images/chest-icon.png"),
    },
    {
      name: "⁠Gastrointestinal",
      imageSrc: require("../../../assets/images/gastrointestinal-icon.png"),
    },
    { name: "Liver", imageSrc: require("../../../assets/images/liver.png") },
    {
      name: "⁠Genitourinary",
      imageSrc: require("../../../assets/images/g1.png"),
    },
    {
      name: "Vascular",
      imageSrc: require("../../../assets/images/vascular-icon.png"),
    },
    {
      name: "Lymphatics",
      imageSrc: require("../../../assets/images/lymphatic-icon.png"),
    },
    {
      name: "T⁠rauma",
      imageSrc: require("../../../assets/images/trauma-icon.png"),
    },
    {
      name: "Venous",
      imageSrc: require("../../../assets/images/venous-icon.png"),
    },
    {
      name: "⁠Bleed",
      imageSrc: require("../../../assets/images/bleeding-icon.png"),
    },
    {
      name: "⁠Women’s health",
      imageSrc: require("../../../assets/images/womens-health-icon.png"),
    },
    {
      name: "⁠Men’s health",
      imageSrc: require("../../../assets/images/mens-health-icon.png"),
    },
    {
      name: "Bundle",
      imageSrc: require("../../../assets/images/bundle.png"),
    },
  ];
  const handleCategorySelection = (category) => {
    setSelectedCategory(category);
    setActiveCategoryDropdown(false);
    // sendDataToParent();
  };

  // const handleDescriptionChange = (value) => {
  //   setDescription(value);
  //   // sendDataToParent();
  // };

  // useEffect(() => {
  //   const quillContainer = document.querySelector(
  //     `#caseDescriptionBox .ql-container.ql-snow`
  //   );
  //   if (quillContainer) {
  //     if (emptyData) {
  //       quillContainer.classList.add("ir-ws-mandatory-filed-border");
  //     } else {
  //       quillContainer.classList.remove("ir-ws-mandatory-filed-border");
  //     }
  //   }

  //   // const discussionQuillContainer = document.querySelector(
  //   //   `#discussionCaseDescription${slideNumber} .ql-container.ql-snow`
  //   // );
  //   // if (discussionQuillContainer) {
  //   //   if (requiredData) {
  //   //     discussionQuillContainer.classList.add("ir-ws-mandatory-filed-border");
  //   //   } else {
  //   //     discussionQuillContainer.classList.remove(
  //   //       "ir-ws-mandatory-filed-border"
  //   //     );
  //   //   }
  //   // }
  // }, [emptyData]);
  return (
    <div className="ir-ws-create-case-main-container">
      {/* <div className="ir-ws-create-case-question-text-box">
        <p className="ir-ws-create-case-question-text">Slide - 1</p>
        <div>
          <svg
            height="30"
            viewBox="0 0 48 48"
            width="30"
            xmlns="http://www.w3.org/2000/svg"
            className="ir-ws-creat-case-icon "
            // onClick={toggleDeleteModal}
          >
            <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
            <path d="M0 0h48v48H0z" fill="none" />
          </svg>
        </div>
      </div> */}
      <div className="ir-ws-create-case-all-questions-container">
        <div className="ir-ws-create-case-all-questions-box">
          <p className="ir-ws-create-case-title-text">
            Category <span className="ir-case-mandatory-field-icon">*</span>
          </p>
          <div className="ir-ws-create-case-types-of-question-container ir-ws-create-case-category-container">
            <div className="ir-ws-create-case-types-of-question-add-btn-box ir-ws-create-case-category-margin">
              <div
                // className="ir-ws-create-case-all-types-question-box"
                // className="ir-ws-normal-filled-border ir-ws-create-case-all-types-question-box"
                className={`${
                  emptyData
                    ? "ir-ws-mandatory-filed-border"
                    : "ir-ws-normal-filled-border"
                } ir-ws-create-case-all-types-question-box`}
                onClick={toggleCategoryDropDown}
              >
                <p>
                  {selectedCategory ? (
                    <>
                      <img
                        className="ir-ws-create-case-category-selected-icon"
                        src={
                          categories.find(
                            (category) => category.name === selectedCategory
                          )?.imageSrc
                        }
                        alt="icon"
                      />
                      {selectedCategory}
                    </>
                  ) : (
                    "Category"
                  )}
                </p>
                {!activeCategoryDropdown ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="ir-ws-create-case-all-types-question-dropdown"
                    onClick={toggleCategoryDropDown}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="ir-ws-create-case-all-types-question-dropdown"
                    onClick={toggleCategoryDropDown}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 15.75 7.5-7.5 7.5 7.5"
                    />
                  </svg>
                )}
              </div>
            </div>

            {/* {activeCategoryDropdown && ( */}
            <div
              className={`ir-ws-create-case-category-list ${
                activeCategoryDropdown ? "active-dropdown" : ""
              }`}
              style={{
                marginBottom: activeCategoryDropdown ? "25px" : "0",
              }}
            >
              {categories.map((category, index) => (
                <div className="ir-ws-creat-case-category-icon-list">
                  <div>
                    <div
                      className="ir-ws-create-case-category-icon-container"
                      onClick={() => handleCategorySelection(category.name)}
                    >
                      <img
                        className="ir-ws-create-case-category-icon"
                        src={category.imageSrc}
                        alt="icon"
                      />
                    </div>
                    <p>{category.name}</p>
                  </div>
                </div>
              ))}
            </div>
            {/* )} */}
          </div>
          {/* <p className="ir-ws-create-case-title-text">
            Case Name <span className="ir-case-mandatory-field-icon">*</span>
          </p>
          <div className="ir-ws-multiple-choice-question-container">
            <div className="ir-ws-multiple-choice-question-box">
              <input
                // className="ir-ws-create-case-input "
                className={`${
                  emptyData ? "ir-ws-mandatory-filed-border" : ""
                } ir-ws-create-case-input`}
                placeholder="Case Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </div> */}

          {/* <p className="ir-ws-create-case-title-text">
            Case Description{" "}
          </p>
          <div className="ir-ws-multiple-choice-question-container">
            <div className="ir-ws-multiple-choice-question-box ir-ws-create-case-description-container">
              <ReactQuill
                placeholder="Case Description"
                theme="snow"
                id="caseDescriptionBox"
                modules={modules}
                value={description}
                onChange={(value) => handleDescriptionChange(value)}
              />
            </div>
          </div> */}

          {/* {images?.length !== 0 && (
            <div>
              <p className="ir-ws-create-case-title-text">Selected Image</p>
              <div className="ir-ws-create-case-bulk-image-container">
                {images?.map((image, index) => (
                  <div key={index} className="ir-ws-create-case-bulk-image-box">
                    <img src={image?.url} alt={image?.name} />


                    <div
                      className="ir-ws-create-case-delete-bulk-image"
                      // onClick={() => handleDeleteImage(index)}
                    >
                      <p>x</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )} */}

          {/* <div className="ir-ws-flex" style={{ marginBottom: "20px" }}>
            <div className="ir-ws-create-course-select-image-box">
              <label
                htmlFor={`inputSequence`}
                className="ir-ws-create-case-select-image-label"
              >
                <div class="ir-ws-create-case-select-image-container">
                  <img
                    src={require("../../../assets/images/imgaeicon.png")}
                    alt="icon"
                    className="ir-ws-create-case-image-icon"
                  />
                  <input
                    type="file"
                    id={`inputSequence`}
                    accept="image/*"
                    // disabled={
                    //   selectedField !== "" && selectedField !== "singleImage"
                    // }
                    // multiple
                    // onChange={handleImageChange}
                    // onChange={handleSelectedCropImage}
                    className="ir-ws-create-case-image"
                  />
                </div>
              </label>
              <div>
                <p className="ir-ws-create-case-upload-image-text">
                  Upload Thumbnail
                </p>
              </div>
            </div>
          </div> */}

          <div className="" style={{ marginBottom: "20px" }}>
            {thumbnail && (
              <div>
                <p className="ir-ws-create-case-title-text">
                  Selected thumbnail
                </p>
                <div className="ir-ws-create-case-bulk-image-container">
                  <div className="ir-ws-create-case-bulk-image-box">
                    <img
                      src={thumbnail}
                      alt="Thumbnail"
                      className="ir-ws-create-case-image-preview"
                    />
                    <div
                      className="ir-ws-create-case-delete-bulk-image"
                      onClick={() => setThumbnail(null)}
                    >
                      <p>x</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="ir-ws-create-course-select-image-box">
              <label
                htmlFor={`inputSequence`}
                className="ir-ws-create-case-select-image-label"
              >
                <div className="ir-ws-create-case-select-image-container">
                  <img
                    src={require("../../../assets/images/imgaeicon.png")}
                    alt="icon"
                    className="ir-ws-create-case-image-icon"
                  />
                  <input
                    type="file"
                    id={`inputSequence`}
                    accept="image/*"
                    onChange={handleThumbnailChange}
                    className="ir-ws-create-case-image"
                  />
                </div>
              </label>
              <div>
                <p className="ir-ws-create-case-upload-image-text">
                  Upload thumbnail
                </p>
              </div>
            </div>
          </div>
          {selectedCategory === 'Bundle' && (
        <>
            <p className="ir-ws-create-case-title-text">
            Bundle title
            </p>
            <div className="ir-ws-multiple-choice-question-container">
            <div className="ir-ws-multiple-choice-question-box">
                <input
                className="ir-ws-create-case-input"
                placeholder="Bundle title"
                value={bundleTitle}
                onChange={(e) => handleBundleTitleChange(e.target.value)}
                />
            </div>
            </div>
        </>
        )}


          {/* ******** */}
        </div>
      </div>
      {slides.map((slide, index) => (
        <div
          key={slide.id}
          style={{
            display: index === activeSlideIndex ? "block" : "none",
          }}
        >
          <CreateSlide
            key={slide.id}
            slideNumber={index + 1}
            // onDelete={() => deleteSlide(slide.id)}
            onDelete={() => deleteSlide(slide.slideNumber)}
            onDataSave={(data) => handleDataSave(data, index)}
            activeSlideIndex={activeSlideIndex}
            requiredData={requiredDataStatus[activeSlideIndex]}
          />
        </div>
      ))}
      <div>
        <p className="ir-ws-create-case-title-text">
          Case Price <span className="ir-case-mandatory-field-icon">*</span>
        </p>
        <div className="ir-ws-multiple-choice-question-container">
          <div className="ir-ws-multiple-choice-question-box">
            <input
              // className="ir-ws-course-title-inpute"
              className={`${
                isPriceValid ? "ir-ws-mandatory-filed-border" : ""
              } ir-ws-create-case-input ir-ws-create-case-price-input`}
              type="text"
              id="casePrice"
              placeholder="$"
              value={price}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        {requiredDataStatus[activeSlideIndex] && (
          <p className="ir-ws-case-mandatory-text">
            Please ensure all fields marked with
            <span className="ir-case-mandatory-field-icon">*</span>are filled in
          </p>
        )}
      </div>
      <div className="ir-ws-create-case-pagination-slide-container">
        <div className="ir-ws-flex ir-ws-align-center ir-ws-create-case-pagination-number-container">
          {slides.map((_, index) => (
            <div
              key={index}
              onClick={() => setActiveSlideIndex(index)}
              className={
                index === activeSlideIndex
                  ? "ir-ws-creat-case-page-active-slide-number"
                  : "ir-ws-creat-case-page-inactive-slide-number"
              }
            >
              <p> {index + 1}</p>
            </div>
          ))}
        </div>
        <div className="ir-ws-create-case-add-slide-container">
          <div
            className="ir-ws-text-center ir-ws-default-btn-container ir-ws-create-case-add-slide-btn-box"
            onClick={addSlide}
          >
            <button className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button ir-ws-create-case-add-slide-btn">
              <span>+ Add Slide</span>
            </button>
          </div>
        </div>
        <div className="ir-ws-flex ir-ws-align-center ir-ws-creat-case-publish-button-container ">
          <div
            className="ir-ws-text-center ir-ws-default-btn-container"
            style={{ width: "100%" }}
          >
            <button
              className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
              style={{ width: "100%" }}
              onClick={goToPreviousSlide}
            >
              <span>Back</span>
            </button>
          </div>
          <div
            className="ir-ws-text-center ir-ws-default-btn-container"
            style={{ width: "100%" }}
          >
            <button
              onClick={handlePreview}
              className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
              style={{ width: "100%" }}
            >
              <span>Preview</span>
            </button>
          </div>
          <div
            className="ir-ws-text-center ir-ws-default-btn-container"
            onClick={toggleConfirmationModal}
            style={{ width: "100%" }}
          >
            <button
              className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
              style={{ width: "100%" }}
            >
              <span>Cancel</span>
            </button>
          </div>
          {/* <div
            className="ir-ws-text-center ir-ws-default-btn-container"
            onClick={toggelDraftConfirmationModal}
            style={{ width: "100%" }}
          >
            <button
              className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
              style={{ width: "100%" }}
            >
              <span>Seve to draft</span>
            </button>
          </div> */}
        </div>
      </div>

      {showCropModal && (
        <CropImageModal
          cropImage={imageToCrop}
          setImageThumbnail={handleImageThumbnail}
          setCropImageModal={setShowCropModal}
          handleCloseImageCrop={handleCloseImageCrop}
        />
      )}

      {openConfirmationModal && (
        <ConfirmationModal
          toggleModal={toggleConfirmationModal}
          confirmationFunc={() => {
            handleCreateCase();
          }}
          title="cancel"
        />
      )}
      {draftConfirmationModelOpen && (
        <ConfirmationModal
          toggleModal={toggelDraftConfirmationModal}
          confirmationFunc={handleSaveToDraftConfirmation}
          title="Draft"
        />
      )}
    </div>
  );
};

export default CreateCasePage;
