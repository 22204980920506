import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./HomeWebinarFeatureContainer.scss";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";
import BookNowModal from "./BookNowModal";
import axios from "axios";
import TermsAndConditionModal from "./TermsAndConditionModal";
import Loader from "../Loader/Loader";
import BookAgainModal from "../WebinarListTab/BookAgainModal";

const HomeWebinarFeatureContainer = () => {
  const {
    webinarData,
    handleRegisterUserMeeting,
    setBookNowModal,
    bookNowModal,
    transacationDetails,
    handleBuyNowClick,
    isbookLoading,
    isLoading,
    handlePaymentCheckout,
  } = useModalContext();
  const reversedWebinarData = Array.isArray(webinarData)
    ? [...webinarData].reverse()
    : [];
  const firstThreeWebinars = reversedWebinarData.slice(0, 3);
  const [emailVerified, setEmailVerified] = useState(false);
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [sendWebinarId, setSendWebinarId] = useState("");
  const [username, setUsername] = useState("");
  const [bookAgainModal, setBookAgainModal] = useState(false);
  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
  const handleTermsAndCondition = () => {
    setTermsAndCondition(!termsAndCondition);
  };
  useEffect(() => {
    const userProfileDetails = async () => {
      try {
        const response = await axios.get(
          `https://backend.ir4u.info/api/v1/user/profile`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );
        console.log("User profile from 1st api response", response);
        setUsername(response.data?.username);
        if (response.data?.isEmailVerified) {
          setEmailVerified(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    userProfileDetails();
  }, []);
  function convertUTCToLocalAndFormat(utcTimeString) {
    // Create a JavaScript Date object from the UTC time string
    const utcDateTime = new Date(utcTimeString);

    // Get the local time zone offset in minutes
    const localTimeZoneOffset = new Date().getTimezoneOffset();

    // Convert UTC time to local time
    const localDateTime = new Date(
      utcDateTime.getTime() - localTimeZoneOffset * 60000
    );

    // Format local time as "2024-01-09T13:00"
    const localTimeFormat = localDateTime.toISOString().slice(0, 16);
    const inputDate = new Date(localTimeFormat);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const outputString = inputDate.toLocaleString("en-US", options);

    return outputString;
  }

  const navigate = useNavigate();
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const confirmationFunc = async () => {
    await handlePaymentCheckout(sendWebinarId);
    handleTermsAndCondition();
  };
  const toggleBookAgain = () => {
    setBookAgainModal(!bookAgainModal);
  };
  return (
    <div className="ir-ws-hme-webnr-inside-gradient-contianer">
      <div className="ir-ws-padding-tb-100 ir-ws-padding-lr-132 ir-ws-hme-webinar-main-container">
        <h2 className="ir-ws-text-center ir-ws-hme-webinar-title ir-ws-hme-upcoming-text">
          Upcoming Webinars on Interventional Radiology
        </h2>
        <div className="ir-ws-align-center ir-ws-hme-webinar-inner-container">
          <div className="ir-ws-hme-webinar-sessions-container">
            {webinarData && (
              <>
                {firstThreeWebinars?.map((webinar, index) => {
                  const isWebinarIdMatch = transacationDetails?.some(
                    (obj) => obj.webinarId === webinar.id
                  );
                  return (
                    <div
                      className="ir-ws-hme-prvw-card-container ir-ws-width-33 ir-ws-hme-webinar-division-main-container"
                      key={index}
                    >
                      <div className="ir-bg-white ir-ws-card-main-container">
                        <div className="ir-ws-text-center ir-ws-position-relative ir-ws-position-relative ir-ws-card-header">
                          {/* <img
                        className="ir-ws-width-100 ir-ws-max-width-100 ir-ws-webinar-card-thumbnail-container"
                        src={require("../../assets/images/webinar-3.jpg")}
                        alt="Courses thumbnail"
                      /> */}
                          <img
                            className="ir-ws-width-100 ir-ws-max-width-100 ir-ws-webinar-card-thumbnail-container"
                            src={webinar.thumbnail}
                            alt="Courses thumbnail"
                          />
                          <div className="ir-ws-position-absolute ir-ws-heading-default-color ir-ws-hme-webinar-car-title-tag">
                            {convertUTCToLocalAndFormat(webinar.dateAndTime) ===
                            "Apr 24, 2025, 11:06 PM"
                              ? "Upcoming"
                              : webinar.status}
                          </div>
                        </div>
                        <div className="ir-ws-heading-default-color ir-ws-app-bg ir-ws-text-center ir-ws-webinar-status-tag-box">
                          {convertUTCToLocalAndFormat(webinar.dateAndTime) ===
                          "Apr 24, 2025, 11:06 PM"
                            ? "Upcoming"
                            : convertUTCToLocalAndFormat(webinar.dateAndTime)}
                        </div>
                        <div className="ir-ws-card-body">
                          <h4
                            className="ir-ws-heading-default-color ir-ws-webinar-crd-bdy-title"
                            title={
                              webinar.title.length > 16 ? webinar.title : null
                            }
                          >
                            {webinar.title.length > 16
                              ? `${webinar.title.slice(0, 16)}...`
                              : webinar.title}
                          </h4>
                          <p
                            className="ir-default-color ir-ws-hme-webinar-crd-para"
                            title={
                              webinar.description.length > 25
                                ? webinar.description
                                : null
                            }
                          >
                            {webinar.description.length > 25
                              ? `${webinar.description.slice(0, 25)}...`
                              : webinar.description}
                          </p>
                          <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-seats-container">
                            <img
                              src={require("../../assets/images/car-seat.png")}
                              alt="users"
                            />
                            <span className="ir-ws-heading-default-color">
                              {webinar.noOfSeats} Seats available
                            </span>
                          </div>
                          <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-price-container">
                            {/* {item.currency}
														{item.price}{" "} */}
                            <img
                              src={require("../../assets/images/shopping.png")}
                              alt="users"
                            />
                            <span className="ir-ws-app-color">
                              {webinar.currency === "USD"
                                ? "$"
                                : webinar.currency}
                              {webinar.price}
                            </span>
                          </div>
                          <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-lang-container">
                            <img
                              src={require("../../assets/images/globe.png")}
                              alt="users"
                            />
                            <span className="ir-ws-heading-default-color">
                              English
                            </span>
                          </div>
                          {/* <div className="ir-ws-flex ir-ws-align-center ir-ws-justify-space-btw ir-ws-hme-webinar-pro-container">
                          <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-pro-content">
                              <img src={require('../../assets/images/hme-webinar-pro.png')} className="ir-ws-hme-webinar-pro-icon" alt="pro" />
                              <span className="ir-default-color ir-ws-hme-webinar-pro-txt">Pro/Beginner</span>
                          </div>
                          <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-webinar-cloc-container">
                              <img src={require('../../assets/images/hme-webinar-clock.png')} className="ir-ws-hme-webinar-cloc-icon" alt="clock"/>
                              <span className="ir-default-color ir-ws-hme-webinar-clock-txt">22hr..</span>
                          </div>
                      </div> */}
                          <div className="ir-ws-text-center ir-ws-default-btn-container ir-ws-book-now-container">
                            {!isWebinarIdMatch ? (
                              <button
                                className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                                style={{
                                  visibility:
                                    webinar.status === "cancelled" ||
                                    webinar.status === "completed" ||
                                    convertUTCToLocalAndFormat(
                                      webinar.dateAndTime
                                    ) === "Apr 24, 2025, 11:06 PM"
                                      ? "hidden"
                                      : "visible",
                                }}
                                onClick={() => {
                                  if (loggedIn) {
                                    if (emailVerified) {
                                      // handleRegisterUserMeeting(webinar.id);
                                      // handleBuyNowClick(webinar);
                                      handleTermsAndCondition();
                                      setSendWebinarId(webinar.id);
                                    } else {
                                      setBookNowModal(true);
                                    }
                                  } else if (!loggedIn) {
                                    setBookNowModal(true);
                                  }
                                }}
                                disabled={isAdmin || isbookLoading}
                              >
                                <span>Book now</span>
                              </button>
                            ) : (
                              <button
                                className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                                disabled={
                                  // Add disabled attribute to disable the button
                                  webinar.status === "cancelled" ||
                                  webinar.status === "completed" ||
                                  convertUTCToLocalAndFormat(
                                    webinar.dateAndTime
                                  ) === "Apr 24, 2025, 11:06 PM" ||
                                  isAdmin
                                }
                                onClick={() => {
                                  toggleBookAgain();
                                  setSendWebinarId(webinar.id);
                                }}
                              >
                                <span>Book now</span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="ir-ws-hme-webinar-content-container">
            <h3 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-hme-webinar-content-title">
              Learn from experts through IR4U Webinars
            </h3>
            <p className="ir-default-color ir-ws-hme-webinar-content-para">
              Webinars are conducted regularly to bring the ;latest advancements
              in the IR field, as well the industry practices and expert
              knowledge.
            </p>
            <div className="ir-ws-hme-prvw-signup-container">
              <div className="ir-ws-prvw-signup-btn-container">
                <button
                  className="ir-ws-app-bg ir-ws-text-uppercase ir-color-white ir-ws-no-border ir-ws-webinar-signup-btn"
                  onClick={() => navigate("/webinar")}
                >
                  <span>Explore Webinars now</span>
                </button>
              </div>
              <div className="ir-ws-flex ir-ws-align-center ir-ws-hme-prvw-signup-inner-container">
                <div className="ir-ws-prvw-signup-content-container">
                  <h4 className="ir-ws-heading-default-color ir-ws-prvw-signup-title">
                    <span className="ir-ws-text-uppercase">Sign up</span> to
                    access all these features and more
                  </h4>
                  <div className="ir-ws-flex ir-ws-align-center ir-ws-prvw-signup-dot-main-container">
                    <div className="ir-ws-prvw-signup-dot-small"></div>
                    <div className="ir-ws-prvw-signup-dot-small"></div>
                    <div className="ir-ws-prvw-signup-dot-large"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {bookNowModal && loggedIn && !emailVerified && (
        <BookNowModal
          type="Verify Email"
          emailVerified={!emailVerified}
          loggedIn={loggedIn}
          username={username}
          setBookNowModal={setBookNowModal}
        />
      )}
      {bookNowModal && !loggedIn && (
        <BookNowModal
          type="Sign up"
          loggedIn={!loggedIn}
          setBookNowModal={setBookNowModal}
        />
      )}
      {termsAndCondition && (
        <TermsAndConditionModal
          handleTermsAndCondition={handleTermsAndCondition}
          id={sendWebinarId}
          confirmationFunc={confirmationFunc}
        />
      )}
      {bookAgainModal && (
        <BookAgainModal
          id={sendWebinarId}
          toggleBookAgain={toggleBookAgain}
          confirmationFunc={confirmationFunc}
        />
      )}
      {isbookLoading && <Loader />}
    </div>
  );
};

export default HomeWebinarFeatureContainer;
