import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";
import Header from "../Header/Header";
import HomeJoinContainer from "../HomeJoinContainer/HomeJoinContainer";
import Footer from "../Footer/Footer";

import kidneyImage from "../../assets/images/kidney-white.png";
import heartImage from "../../assets/images/heart-white.png";
import liverImage from "../../assets/images/liver-white.png";
import lungsImage from "../../assets/images/lungs-white.png";
import pancreasImage from "../../assets/images/pancreas-white.png";
import stomachImage from "../../assets/images/stomach-white.png";
import brainImage from "../../assets/images/brain-white.png";
import bladderImage from "../../assets/images/bladder-white.png";
import arteriesImage from "../../assets/images/arteries-white.png";
import chestImage from "../../assets/images/chest-white-icon.png";
import gastrointestinalImage from "../../assets/images/gastrointestinal-white-icon.png";
import genitourinaryImage from "../../assets/images/g3.png";
import vascularImage from "../../assets/images/vascular-white-icon.png";
import lymphaticsImage from "../../assets/images/lymphatic-white-icon.png";
import traumaImage from "../../assets/images/trauma-white-icon.png";
import venousImage from "../../assets/images/venous-white-icon.png";
import bleedImage from "../../assets/images/bleeding-white-icon.png";
import womensHealthImage from "../../assets/images/womens-health-white-icon.png";
import mensHealthImage from "../../assets/images/mens-health-white-icon.png";

import BookNowModal from "../HomeWebinarFeatureContainer/BookNowModal";
import TermsAndConditionModal from "../HomeWebinarFeatureContainer/TermsAndConditionModal";

const CategoryCases = () => {
  const [casesToShow, setCasesToShow] = useState(6);
  const [userBoughtCases, setUserBoughtCases] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";
  const userId = localStorage.getItem("userId");
  const [caseId, setCaseId] = useState(null);
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [bookNowModal, setBookNowModal] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [username, setUsername] = useState("");
  const [userEmail, setUseremail] = useState("");

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { category } = useParams();
  const { cases } = useModalContext();

  console.log("cases from case category:", cases);
  console.log("category from case category:", category);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const categoryCase = cases?.filter((item) =>
    item?.categorie?.toLowerCase().includes(category?.toLowerCase())
  );

  console.log("categoryCase dfdgd:", categoryCase);

  function stripHtmlTags(html, maxLength) {
    let doc = new DOMParser().parseFromString(html, "text/html");
    let plainText = doc.body.innerText;
    return plainText.length > maxLength
      ? plainText.slice(0, maxLength) + "..."
      : plainText;
  }

  const handleShowMoreCases = () => {
    setCasesToShow(casesToShow + 6);
  };

  const handleShowLessCases = () => {
    setCasesToShow(casesToShow - 6);
  };

  const categoryImageMap = {
    "Chest": chestImage,
    "Gastrointestinal": gastrointestinalImage,
    "Liver": liverImage,
    "Genitourinary": genitourinaryImage,
    "Vascular": vascularImage,
    "Lymphatics": lymphaticsImage,
    "Trauma": traumaImage,
    "Venous": venousImage,
    "Bleed": bleedImage,
    "Women's health": womensHealthImage,
    "Men's health": mensHealthImage,
  };

  useEffect(() => {
    const fetchCaseTransactions = async () => {
      try {
        const token = localStorage.getItem("jwtTokenApi1");
        const response = await axios.get(
          `https://case.ir4u.info/api/v1/user/case/transaction/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const caseTransactionData = response.data;
        console.log("caseTransactionData", caseTransactionData);
        setTransactions(caseTransactionData);
      } catch (error) {
        console.error("Error fetching case transactions:", error);
      }
    };
    fetchCaseTransactions();
  }, []);

  useEffect(() => {
    const boughtCases = transactions
      ?.filter((transaction) => transaction?.status === "SUCCESS")
      ?.map((transaction) => transaction?.productId);
    setUserBoughtCases(boughtCases);
  }, [transactions]);

  const handleTermsAndCondition = (id) => {
    setTermsAndCondition(!termsAndCondition);
    setCaseId(id);
  };

  useEffect(() => {
    const userProfileDetails = async () => {
      try {
        const response = await axios.get(
          `https://backend.ir4u.info/api/v1/user/profile`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );
        // console.log("User profile from 1st api response", response);
        setUseremail(response.data?.email);
        setUsername(response.data?.username);
        // setUserId(response.data?.id);
        if (response.data?.isEmailVerified) {
          setEmailVerified(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    userProfileDetails();
  }, []);
  const buyNewCase = (id) => {
    // To navigate to the previous URL
    localStorage.setItem("prevURL", pathname);

    if (loggedIn) {
      if (emailVerified) {
        // handleRegisterUserMeeting(webinar.id);
        // handleBuyNowClick(webinar);
        handleTermsAndCondition(id);
        // console.log("CLicked");
        // setSendWebinarId(webinar.id);
      } else {
        setBookNowModal(true);
      }
    } else if (!loggedIn) {
      setBookNowModal(true);
    }
  };

  const BuyConfirmationFunc = async () => {
    const data = {
      caseId: caseId,
      userEmail: userEmail,
      successUrl: `${origin}/success`,
      cancelUrl: `${origin}/failed`,
      userId: userId,
    };
    const token = localStorage.getItem("jwtTokenApi1");
    // console.log(courseData);
    try {
      const response = await axios.post(
        "https://case.ir4u.info/api/v1/user/case/create-checkout-session",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response);
      if (response.status === 200) {
        const sessionUrl = response.data.url;
        window.location.href = sessionUrl;
      }
    } catch (error) {
      console.log(error, "error while buying the case");
    }
  };

  return (
    <>
      <Header />
      {/* <HomeJoinContainer /> */}
      <div className="ir-ws-banner-padding-tb ir-ws-padding-lr-132">
        {categoryCase && categoryCase.length > 0 ? (
          <div
            className="ir-ws-course-search-query-text-box"
            style={{ marginTop: "50px" }}
          >
            <p className="ir-ws-course-search-query-text">
              Showing cases for category of "{category}"
            </p>
          </div>
        ) : (
          <div className="ir-ws-course-search-query-text-box">
            <p className="ir-ws-course-search-query-text">
              Category "{category}"
            </p>
            <p className="ir-ws-course-search-query-text">
              No courses for category of "{category}"
            </p>
            <p className="ir-ws-go-back-btn" onClick={() => navigate(-1)}>
              Go Back
            </p>
          </div>
        )}
        <div className="ir-cs-list-grid-container">
          {categoryCase
            .reverse()
            ?.slice(0, casesToShow)
            ?.map((caseItem, index) => {
              const slides = caseItem.slides || [];
              const firstSlide = slides[0] || {};
              const caseImages = caseItem?.slides[0]?.caseImages || [];
              const imageUrl = caseImages[0]?.url;
              const isLocalhost = imageUrl && imageUrl.includes("localhost");
              const imageSource = isLocalhost
                ? require("../../assets/images/placeholder.jpg")
                : imageUrl;

              const title =  firstSlide?.name;
              const description = firstSlide?.description;
              const price = caseItem.casePrice || 0;
              //   const previewVideo = firstSlide?.caseVideo;
              const caseBought = userBoughtCases?.includes(caseItem._id);
              const categoryImage = categoryImageMap[caseItem?.categorie];
              return (
                <div className="ir-cs-list-grid-ind-container" key={index}>
                  <div className="ir-cs-list-grid-ind-inner">
                    <div className="ir-ws-flex ir-ws-course-list-main-box ir-ws-case-list-main-box">
                      <div className="ir-cs-list-grid-img-container">
                        <img
                          className=" ir-cs-case-list-grid-img "
                          src={
                            imageUrl ||
                            require("../../assets/images/placeholder.jpg")
                          }
                          alt="case-image"
                        />

                        <div className="catogey-img-container">
                          <div className="cat-main">
                            <img src={categoryImage} alt="category" />
                          </div>
                          {/* <h4>Title</h4> */}
                        </div>
                      </div>
                      <div className="ir-cs-list-grid-content-container">
                        <div className="ir-cs-list-grid-content">
                          <h3 className="cl-case-title ">{title}</h3>
                          <p
                            className="ir-default-color cl-case-description"
                            title={stripHtmlTags(description)}
                          >
                            {stripHtmlTags(description, 100)}
                          </p>
                        </div>
                        <div className="ir-ws-flex ir-cs-list-grid-price-read-container price-buy-container">
                          {caseBought || isAdmin ? (
                            <div
                              className="ir-cs-list-grid-price-container"
                              // style={{
                              //   ...(isAdmin
                              //     ? { pointerEvents: "none", cursor: "default" }
                              //     : {}),
                              // }}
                              // disabled={isAdmin}
                              // onClick={() => buyNewCase(caseItem._id)}
                              onClick={() => navigate(`/case/${caseItem._id}`)}
                            >
                              View
                            </div>
                          ) : (
                            <div
                              className="ir-cs-list-grid-price-container"
                              style={{
                                ...(isAdmin
                                  ? { pointerEvents: "none", cursor: "default" }
                                  : {}),
                              }}
                              disabled={isAdmin}
                              onClick={() => buyNewCase(caseItem._id)}
                            >
                              Buy
                            </div>
                          )}
                          {caseBought ? null : (
                            <div className="price-continer">
                              <img
                                src={require("../../assets/images/shopping.png")}
                                alt="users"
                                className="price-tag-icon"
                              />
                              <h4 className="ir-case-pice-tag">${price}</h4>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="ir-ws-course-show-button-container">
          {casesToShow > 6 && (
            <>
              <div
                onClick={handleShowLessCases}
                className="ir-ws-course-homepage-show-more-text"
              >
                <p>Show Less</p>
              </div>
              <div className="ir-ws-course-homepage-show-more-text">
                <p>|</p>
              </div>
            </>
          )}
          {categoryCase.reverse().length > casesToShow && (
            <div
              onClick={handleShowMoreCases}
              className="ir-ws-course-homepage-show-more-text"
            >
              <p>Show More</p>
            </div>
          )}
        </div>
      </div>
      <HomeJoinContainer />
      <Footer />

      {bookNowModal && loggedIn && !emailVerified && (
        <BookNowModal
          type="Verify Email"
          emailVerified={!emailVerified}
          loggedIn={loggedIn}
          username={username}
          setBookNowModal={setBookNowModal}
        />
      )}
      {bookNowModal && !loggedIn && (
        <BookNowModal
          type="Sign up"
          loggedIn={!loggedIn}
          setBookNowModal={setBookNowModal}
        />
      )}
      {termsAndCondition && (
        <TermsAndConditionModal
          handleTermsAndCondition={handleTermsAndCondition}
          id={caseId}
          confirmationFunc={BuyConfirmationFunc}
        />
      )}
    </>
  );
};

export default CategoryCases;
