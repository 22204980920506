import React, { useEffect, useState } from "react";
import "./WebinarAdminWebinarListTab.scss";
import DeleteWebinarModal from "../WebinarAdminCreateWebinarTab/DeleteWebinarModal";
import CreateWebinarModal from "../WebinarAdminCreateWebinarTab/CreateWebinarModal";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";
import LinkModal from "./LinkModal";
import CancelWebinarModal from "../WebinarAdminCreateWebinarTab/CancelWebinarModal";

const WebinarAdminWebinarListTab = ({
  id,
  title,
  meetingId,
  presenter1,
  passCode1,
  presenter2,
  passCode2,
  status,
  dateAndTime,
}) => {
  const webinarlistTab = [
    "Presenter 1",
    "Observer",
    "Cancel",
    "Delete",
    "Update",
    "Transaction",
  ];

  const [linkModal, setLinkModal] = useState(false);
  const [presenterData, setPresenterData] = useState(null);

  const handleOpenLinkModal = (presenterData) => {
    setLinkModal(true);
    setPresenterData(presenterData);
  };
  const accordianListButton = [
    "Username",
    "Card",
    "Status of Transaction",
    "Tax Invoice Number",
    "Invoice",
  ];
  // const webinarData = [
  //   {
  //     userName: "Sunny",
  //     timeDate: "28/10/2023  11:30 am",
  //     transaction: "Successful",
  //     invoiceNumber: 234567456,
  //   },
  //   {
  //     userName: "Mohit",
  //     timeDate: "28/10/2023  11:30 am",
  //     transaction: "Successful",
  //     invoiceNumber: 2334556,
  //   },
  //   {
  //     userName: "Saheb",
  //     timeDate: "28/10/2023  11:30 am",
  //     transaction: "Successful",
  //     invoiceNumber: 2363455,
  //   },
  // ];
  const { adminTransactionData } = useModalContext();
  const [isContentVisible, setContentVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
console.log(adminTransactionData);
  const filterTransaction = adminTransactionData.filter(
    (item) => item.productId === id
  );
  console.log("this is the filter transaction",filterTransaction);
  const toggleContentVisibility = () => {
    setContentVisible(!isContentVisible);
  };

  const handleDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleUpdateModalOpen = () => {
    setUpdateModal(true);
  };
  const handleOpenCancelModal = () => {
    setCancelModal(true);
  };
  function convertUTCToLocalAndFormat(utcTimeString) {
    // Create a JavaScript Date object from the UTC time string
    const utcDateTime = new Date(utcTimeString);

    // Get the local time zone offset in minutes
    const localTimeZoneOffset = new Date().getTimezoneOffset();

    // Convert UTC time to local time
    const localDateTime = new Date(
      utcDateTime.getTime() - localTimeZoneOffset * 60000
    );

    // Format local time as "2024-01-09T13:00"
    const localTimeFormat = localDateTime.toISOString().slice(0, 16);
    const inputDate = new Date(localTimeFormat);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const outputString = inputDate.toLocaleString("en-US", options);

    return outputString;
  }

  return (
    <div
      className={`ir-ws-webinar-list-tab ${!isContentVisible ? "active" : ""}`}
    >
      <div className="ir-ws-admin-webinar-content">
        <div className="ir-ws-admin-webinar-title-container">
          <p className="ir-ws-webinar-title-text">{title}</p>
          <span>{convertUTCToLocalAndFormat(dateAndTime)}</span>
          <div>
            {status === "active" ? (
              <div className="ir-ws-active-status-container">
                <img
                  src={require("../../assets/images/active.png")}
                  alt="active"
                  className="ir-ws-active-status-icon"
                />
                <p className="ir-ws-webinar-status-text"> {status}</p>
              </div>
            ) : status === "cancelled" ? (
              <div className="ir-ws-active-status-container">
                <img
                  src={require("../../assets/images/cancelled.png")}
                  alt="active"
                  className="ir-ws-active-status-icon"
                />
                <p className="ir-ws-webinar-status-text"> {status}</p>
              </div>
            ) : status === "completed" ? (
              <div className="ir-ws-active-status-container">
                <img
                  src={require("../../assets/images/complete.png")}
                  alt="active"
                  className="ir-ws-active-status-icon"
                />
                <p className="ir-ws-webinar-status-text"> {status}</p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="ir-ws-admin-list-button-container">
          {webinarlistTab.map((item, index) => (
            <div key={index}>
              {item === "Presenter 1" ? (
                <>
                  <button
                    className="ir-ws-webinar-presenter-2-button ir-ws-webinar-actions-button"
                    // onClick={() => handleCopyPresenterUrl(presenter2)}
                    onClick={() =>
                      handleOpenLinkModal({
                        title,
                        presenter: presenter1,
                        passCode: passCode1,
                      })
                    }
                  >
                    <div className="presernter1-button-icon">
                      {item}
                      <div className="ir4u-copyicons">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="0.5"
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                          />
                        </svg>
                      </div>
                    </div>
                  </button>
                </>
              ) : item === "Observer" ? (
                <button
                  className="ir-ws-webinar-presenter-2-button ir-ws-webinar-actions-button"
                  // onClick={() => handleCopyPresenterUrl(presenter2)}
                  onClick={() =>
                    handleOpenLinkModal({
                      title,
                      presenter: presenter2,
                      passCode: passCode2,
                    })
                  }
                >
                  <div className="presernter1-button-icon">
                    {item}
                    <div className="ir4u-copyicons">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="0.5"
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                        />
                      </svg>
                    </div>
                  </div>
                </button>
              ) : item === "Cancel" ? (
                <button
                  className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                  onClick={handleOpenCancelModal}
                >
                  {item}
                </button>
              ) : item === "Delete" ? (
                <button
                  className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                  onClick={handleDeleteModal}
                >
                  {item}
                </button>
              ) : item === "Update" ? (
                <button
                  className="ir-ws-webinar-update-button ir-ws-webinar-actions-button"
                  onClick={handleUpdateModalOpen}
                >
                  {item}
                </button>
              ) : item === "Transaction" ? (
                <button
                  className={`ir-ws-webinar-transaction-button ir-ws-webinar-actions-button ${
                    isContentVisible
                      ? "ir-ws-webinar-transaction-button-active"
                      : ""
                  }`}
                  onClick={toggleContentVisibility}
                >
                  {item}
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="ir-ws-admin-drop-down-icon"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                </button>
              ) : (
                <button key={index}>{item}</button>
              )}
            </div>
          ))}
        </div>
      </div>
      {isContentVisible && (
        <div>
          {filterTransaction.length !== 0 && (
            <ul className="ir-ws-webinar-list-accordian-tab">
              {accordianListButton.map((item, index) => (
                <li className="ir-ws-list-item-box" key={index}>
                  {item}
                </li>
              ))}
            </ul>
          )}
          <div>
            {filterTransaction.length === 0 ? (
              <ul className="ir-ws-webinar-list-data">
                <li>No purchase history</li>
              </ul>
            ) : (
              filterTransaction?.map((item, index) => (
                <ul className="ir-ws-webinar-list-data" key={index}>
                  <li>{item.username}</li>
                  <li>{item.productPaymentDetails.paymentMode}</li>
                  <li>{item.status}</li>
                  <li>{item.transactionId}</li>
                  <li>
                    <a
                      href={item.invoiceURL}
                      className="ir-ws-webinar-inovice-icon"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="ir-ws-webinar-download-icon"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              ))
            )}
          </div>
        </div>
      )}
      {linkModal && (
        <LinkModal
          linkModal={linkModal}
          setLinkModal={setLinkModal}
          presenterData={presenterData}
        />
      )}
      {deleteModal && (
        <DeleteWebinarModal
          id={id}
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
        />
      )}
      {updateModal && (
        <CreateWebinarModal
          id={id}
          type="Update Webinar"
          updateModal={updateModal}
          setUpdateModal={setUpdateModal}
        />
      )}
      {cancelModal && (
        <CancelWebinarModal
          id={id}
          cancelModal={cancelModal}
          setCancelModal={setCancelModal}
        />
      )}
    </div>
  );
};

export default WebinarAdminWebinarListTab;
