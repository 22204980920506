import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const WebinarPaymentProgress = () => {
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = today.getMonth() + 1;
      const day = today.getDate();
      const formattedDate = `${day < 10 ? "0" : ""}${day} - ${
        month < 10 ? "0" : ""
      }${month} - ${year}`;
      setCurrentDate(formattedDate);
    };

    getCurrentDate();
  }, []);

  return (
    <div className="ir-ws-webinar-payment-success-container ir-ws-flex ir-ws-justify-center ir-ws-align-center ir-ws-webinar-payment-progress-container">
      <div className="ir-ws-webinar-payment-success-box">
        <div className="ir-ws-flex ir-ws-justify-center ir-ws-align-center">
          <a href="/">
            <img
              src={require("../../assets/images/ir4u2.png")}
              alt="logo"
              className="ir-ws-webinar-payment-ir4u-logo"
            />
          </a>
        </div>
        <div className="ir-ws-flex ir-ws-justify-center ir-ws-align-center ir-ws-webinar-payment-progress-logo-container ir-ws-webinar-payment-success-logo-container ">
          <img
            src={require("../../assets/images/inprogress.png")}
            alt="Success"
            className="ir-ws-webinar-payment-progress-logo"
          />
          <p className="ir-ws-webinar-payment-progress-text">
            Transaction in progress!
          </p>
          <p className="ir-ws-webinar-payment-receipt-date-text">
            {currentDate}
          </p>
          <p className="ir-ws-webinar-payment-receipt-text">
            Your payment is in progress. <br />
            Please wait!
          </p>
          <div
            className="ir-ws-text-center ir-ws-default-btn-container ir-ws-webinar-payment-status-button-container"
            onClick={() => navigate("/")}
          >
            <button className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ">
              <span>OK</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebinarPaymentProgress;
