import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Comingsoon from "../../components/Comingsoon/Comingsoon";
import HomeJoinContainer from "../../components/HomeJoinContainer/HomeJoinContainer";
import CoursesBanner from "../../components/CoursesBanner/CoursesBanner";
import PopularCourses from "../../components/PopularCourses/PopularCourses";
import CoursesCategory from "../../components/CoursesCategory/CoursesCategory";
import CoursesList from "../../components/CoursesList/CoursesList";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";

const Courses = () => {
  const { courses } = useModalContext();
  const coursesListRef = useRef(null);

  const scrollToCoursesList = () => {
    coursesListRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="ir-ws-home-main-container">
      <Header />
      {/* <Comingsoon /> */}
      <CoursesBanner courses={courses} />
      <PopularCourses courses={courses} />
      <CoursesCategory scrollToCoursesList={scrollToCoursesList} />
      <CoursesList courses={courses} coursesListRef={coursesListRef} />
      <HomeJoinContainer />
      <Footer />
    </div>
  );
};

export default Courses;
