import React, { useState } from "react";
import "./WebinarAdminCustomTab.scss";

const WebinarAdminCustomTab = ({ toggle, setToggle }) => {
  // const handleShowUserToggle = () => {
  //   setToggle(false);
  // };

  const handleCreateToggle = () => {
    setToggle(false);
  };

  const handleShowWebinarListToggle = () => {
    setToggle(true);
  };
  return (
    <div className="ir-ws-webinar-admin-custom-tab">
      <p
        onClick={handleCreateToggle}
        className={`ir-ws-custom-tab-button  ${
          toggle === true ? "ir-ws-custom-tab-active" : ""
        }`}
      >
        Create Webinar
      </p>
      <p
        className={`ir-ws-custom-tab-button ${
          toggle === false ? "ir-ws-custom-tab-active" : ""
        }`}
        onClick={handleShowWebinarListToggle}
      >
        Webinar List
      </p>
    
    </div>
  );
};

export default WebinarAdminCustomTab;
