import "./CaseWhyChooseUs.scss"
const CaseWhyChooseUs = () => {
  return (
    <div className="why-choose-us-container">
      <div className="why-choose-us-main ir-ws-padding-lr-132">
      <div className="why-choose-us-header">
        <h1 className="why-choose-us-title ir-ws-heading-default-color">Why Choose Us?</h1>
        <h2 className="why-choose-us-subtitle">THE CASES</h2>
        <p className="why-choose-us-description">
          Gain access to a wide range of interactive cases available for purchase. Each case is meticulously designed to provide a realistic and educational experience.
        </p>
      </div>
      </div>
      <div className="who-can-benefit ir-ws-banner-padding-tb ir-ws-padding-lr-132">
        <h2 className="who-can-benefit-title ir-ws-heading-default-color">Who Can Benefit</h2>
        <p className="who-can-benefit-description">Our platform is tailored for</p>
        <div className="benefit-cards">
          <div className="benefit-card">
            <div className="benefit-icon doctors-icon"></div>
            <h3 className="benefit-title">Doctors</h3>
            <p className="benefit-description">
              Seeking to expand their expertise and stay updated with the latest in interventional radiology.
            </p>
          </div>
          <div className="benefit-card">
            <div className="benefit-icon medicos-icon"></div>
            <h3 className="benefit-title">Medicos</h3>
            <p className="benefit-description">
              Looking to supplement their studies with practical, hands-on learning.
            </p>
          </div>
          <div className="benefit-card">
            <div className="benefit-icon senior-doctors-icon"></div>
            <h3 className="benefit-title">Senior Doctors</h3>
            <p className="benefit-description">
              Aiming to refine their skills and mentor the next generation of medical professionals.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CaseWhyChooseUs