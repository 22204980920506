export const WS_Routes = {

    send_connect: "connect",
    receive_connect: "connect",

    send_disconnect: "disconnect",
    receive_disconnect: "disconnect",
    
    send_notification: "notifications",
    receive_notification: "remote_notifications",
    
    send_message: "message",
    receive_message: "remote_message",

    send_joinRoom: "join_room",
    receive_joinRoom: "chatRoom_messages",

    send_chat_message: "chat_message",
    receive_chat_message: "chatRoom_messages",

    send_typing: "typing",
    receive_typing: "remote_typing",
    receive_stop_typing: "remote_stop_typing"
}

export const STATUS = {
    active: "active",
    inactive: "inactive",
    archive: true,
    unarchive: false,
}

// Payment Routes
export const PAYMENT_API = {
    get_product: {url: "/payment/product/:id", method: "GET"},
    get_products: {url: "/payment/product", method: "GET"},
    get_paymentLinks: {url: "/payment/paymentLinks", method: "GET"},
    get_paymentLink: {url: "/payment/paymentLinks/:id", method: "GET"},
    create_payment: {url: "/payment/product", method: "GET"},
    get_product: {url: "/payment/product/:id", method: "GET"},
}

// Stripe URL : https://dashboard.stripe.com/test/payment-links/plink_1NZe3TAXIEoIEB6rkljpuRy5
// username: ir4uramki@gmail.com
// password: Ram@2022/1


// Get products list or product by id

// Method : GET 
// {{baseurl}}/payment/product/
// {{baseurl}}/payment/product/<product_id>

// Get payment link

// Method: GET
// {{baseurl}}/payment/paymentLinks
// {{baseurl}}/payment/paymentLinks/<payment_id>

// Create payment list

// Method: POST
// {{baseurl}}/payment/paymentLinks


// Create Product
// Method: POST
// payload: formdata
// {
// 	image: imagefile, 
// 	name: "product anme", 
// 	price: 100,
// 	currency: INR,
// 	quantity: 1
// }
// {{baseurl}}/payment/product


// Get all customers list

// Method: GET
// {{baseurl}}/payment/customer



// Get All price list

// Method: GET
// {{baseurl}}/payment/prices
