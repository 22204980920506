import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./redux/authSlice";
import CourseSlice from "./pages/Courses/Store/CourseSlice";
import Caseslice from "./pages/AdminCases/CaseSlice/Caseslice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    course: CourseSlice,
    case: Caseslice,
  },
});

export default store;
