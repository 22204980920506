import { useModalContext } from "./ModalContext";

const SuccessfulWebinarModal = () => {
  const { setSuccessfulWebinarModal } = useModalContext();
  const handleCloseSuccessfulWebinarModal = () => {
    setSuccessfulWebinarModal(false);
  };
  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={handleCloseSuccessfulWebinarModal}
          >
            <span>X</span>
          </div>
          <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
              You have Successfully Created the webinar
            </h3>
            <div className="ir-ws-webinar-link-modal-icon-container"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessfulWebinarModal;
