import React, { useContext, useState, useRef, useEffect } from "react";
import "./ChatProfile.scss";
import { RootStore } from "../../../store/store";

const ChatProfile = ({ activeChatRoom, updateChatRoom }) => {
  const [store, setStore] = useContext(RootStore);
  const [groupData, setGroupData] = useState({});
  const [openImageGalleryModal, setOpenImageGalleryModal] = useState(false);
  const [activeCaseName, setActiveCaseName] = useState("");
  const [nextButtonDisable, setNextButtonDisable] = useState(false);
  const [prevButtonDisable, setPreButtonDisable] = useState(false);
  const { chatStore } = store;
  const { groups } = chatStore;

  const ref = useRef();

  const [carouselActiveIndex, setCarouselActiveIndex] = useState(0);
  const onCarouselMenuItemclick = (carouselActiveIndex) => {
    setCarouselActiveIndex(carouselActiveIndex);
  };

  const showLargeImage = () => {
    setOpenImageGalleryModal(true);
    console.log("chatprofile");
  };

  const closeImageGalleryModal = () => {
    setOpenImageGalleryModal(false);
  };

  const leftCarouselIndex = () => {
    if (carouselActiveIndex > 0) {
      setCarouselActiveIndex(carouselActiveIndex - 1);
    }
  };
  const rightCarouselIndex = () => {
    // const allImages = chatHistory[props.activeChatRoom].filter(i => i.images !== "").map(e => e.images)
    const allImages = groupData?.image?.map((e) => e.image);
    console.log("all images erwe", allImages);
    if (carouselActiveIndex < allImages.length - 1) {
      setCarouselActiveIndex(carouselActiveIndex + 1);
      // setNextButtonDisable(true);
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, []);

  useEffect(() => {
    if (groups.length > 0) {
      let group = groups.find((e) => e.id === parseInt(activeChatRoom));
      setGroupData(group);
      setActiveCaseName(group?.name);
    }
  }, [activeChatRoom, chatStore.groups]);

  useEffect(() => {
    let isPreviousDiabled = carouselActiveIndex <= 0 ? true : false;
    const allImages = groupData?.image?.map((e) => e.image) || [];
    let isNextDiabled =
      carouselActiveIndex >= allImages.length - 1 ? true : false;

    setPreButtonDisable(isPreviousDiabled);
    setNextButtonDisable(isNextDiabled);
  }, [carouselActiveIndex]);

  useEffect(() => {
    // Check if carouselActiveIndex is at the beginning or end to disable/enable buttons.
    setPreButtonDisable(carouselActiveIndex <= 0);
    setNextButtonDisable(
      carouselActiveIndex >= (groupData?.image?.length || 0) - 1
    );
  }, [carouselActiveIndex, groupData]);

  return (
    <div
      className="ir-chat-profile-main-container tab-content-scroll ps ps--active-y"
      ref={ref}
    >
      <div className="ir-car-update-btn-container" title="Update Case">
        <button
          className="btn btn-primary btn-lg btn-block btn-round-update"
          id="newChat"
          onClick={() => updateChatRoom(true)}
        >
          <i className="bi bi-pencil"></i>
        </button>
      </div>
      <div className="ir-chat-profile-inner-container">
        {groupData?.image?.map((image, id) => (
          <div
            key={id}
            className={`iks-cine-content iks-cine-content-${id} ${
              id === carouselActiveIndex
                ? "iks-carousel-active"
                : "iks-carousel-inactive"
            }`}
          >
            <img
              className="ir-car-img"
              src={image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = require("../../../assets/images/chat-images/no-image.png");
              }}
              alt={"chat group"}
              onClick={() => showLargeImage(image)}
            />
          </div>
        ))}
        <div className="ir-car-item-main-container">
          {groupData?.image?.map((image, id) => (
            <div key={id} className="ir-car-item-container">
              <img
                onClick={() => onCarouselMenuItemclick(id)}
                className={`ir-car-item-img ${
                  carouselActiveIndex === id ? "active-image-border" : ""
                }`}
                src={image}
                alt={"chat group"}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = require("../../../assets/images/chat-images/no-image.png");
                }}
              />
            </div>
          ))}
        </div>
      </div>
      {openImageGalleryModal && (
        <div className="iks-mint-cust-modal ir-chat-inside-modal-container ir-image-preview-main-modal">
          <div className="iks-mint-modal-container">
            <div className="ir-thumb-image-inner-container">
              <div className="ir-image-large-thumb-title-container">
                <h3>{activeCaseName}</h3>
                <div className="ir-image-head-thumb-right">
                  <button onClick={closeImageGalleryModal}>
                    <i className="bi bi-x-lg"></i>
                  </button>
                </div>
              </div>
              <div className="ir-modal-thumb-container-large-image">
                {groupData?.image?.map((image, id) => (
                  <div
                    key={id}
                    className={`iks-cine-content iks-cine-content-${id} ${
                      id === carouselActiveIndex
                        ? "iks-carousel-active"
                        : "iks-carousel-inactive"
                    }`}
                  >
                    <img
                      className="ir-car-img"
                      src={image}
                      alt={"chat group"}
                    />
                  </div>
                ))}

                <button
                  onClick={leftCarouselIndex}
                  disabled={prevButtonDisable}
                  className={`ir-left-car-btn ir-carousel-action-btns`}
                >
                  <i className="bi bi-chevron-left"></i>
                </button>
                <button
                  onClick={rightCarouselIndex}
                  disabled={nextButtonDisable}
                  className={`ir-right-car-btn ir-carousel-action-btns`}
                >
                  <i className="bi bi-chevron-right"></i>
                </button>
              </div>
              <div className="ir-thumbanil-modal-image">
                {groupData?.image?.map((image, id) => (
                  <div
                    key={id}
                    onClick={() => onCarouselMenuItemclick(id)}
                    className={`ir-car-item-container ${
                      id === carouselActiveIndex
                        ? "iks-carousel-item-active"
                        : "iks-carousel-item-inactive"
                    }`}
                  >
                    <img
                      className="ir-car-img"
                      src={image}
                      alt={"chat group"}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatProfile;
