import { useState } from "react";
import "./TermsAndCondition.scss";
import Swal from "sweetalert2";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";

const TermsAndConditionModal = ({
  handleTermsAndCondition,
  id,
  confirmationFunc,
}) => {
  // console.log(sendWebinarId);
  const { handlePaymentCheckout, isPaymentLoading } = useModalContext();
  console.log('this is the loading', isPaymentLoading);
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div className="ir-ws-position-fixed ir-ws-sign-popup-container ">
      <div className="ir-ws-position-absolute ir-bg-white ir-ws-sign-popup-inner-container">
        <div className="ir-ws-signup-content-container">
          <div
            className="ir-ws-position-absolute ir-ws-signup-close-container"
            onClick={handleTermsAndCondition}
          >
            <span>X</span>
          </div>
          <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
              Terms And Condition
            </h3>
          </div>
          <div className="ir-ws-flex ir-ws-align-center ir-ws-justify-center ir-ws-terms-and-condition-container">
            <input
              type="checkbox"
              className="ir-ws-terms-and-condition-input"
              onChange={handleCheckboxChange}
            />
            <p>
              I agree with the
              <span>
                <a
                  href="/terms-conditions"
                  target="_blank"
                  className="ir-ws-terms-and-condition-link"
                >
                  {" "}
                  terms and condition
                </a>
              </span>
            </p>
          </div>

          {/* <div className="ir-ws-deleteModal-button-container"> */}
          {/* <button
              className="ir-ws-deleteModal-button-yes"
              onClick={handleTermsAndCondition}
            >
              Cancel
            </button> */}
          {/* <button
              className="ir-ws-deleteModal-button-no"
              onClick={async () => {
                if (!isChecked) {
                  Swal.fire("Accept the terms and condition to book webinar");
                } else if (sendWebinarId) {
                  await handleRegisterUserMeeting(sendWebinarId);
                  handleTermsAndCondition();
                }
              }}
            >
              Agree
            </button> */}
          {/* <div className="ir-ws-default-btn-container ir-profile-info-btn-container">
              <button
                className="ir-ws-default-btn ir-ws-app-bg ir-ws-terms-condition-agree-btn"
                onClick={() => {
                  if (!isChecked) {
                    Swal.fire("Accept the terms and condition to book webinar");
                  } else if (id) {
                    // // await handleRegisterUserMeeting(sendWebinarId);
                    // await handlePaymentCheckout(sendWebinarId);
                    // handleTermsAndCondition();
                    confirmationFunc();
                  }
                }}
                style={{ width: "150px" }}
              >
                <span> Agree</span>
              </button>
            </div> */}
          <div className="ir-ws-deleteModal-button-container">
          {!isPaymentLoading && 
            <button
            className="ir-ws-deleteModal-button-yes"
            onClick={() => {
              if (!isChecked) {
                Swal.fire("Accept the terms and condition to book webinar");
              } else if (id) {
                // // await handleRegisterUserMeeting(sendWebinarId);
                // await handlePaymentCheckout(sendWebinarId);
                // handleTermsAndCondition();
                confirmationFunc();
              }
            }}
            style={{ width: "180px" }}
          >
            Agree
          </button>
          } {
            isPaymentLoading && 
            <button
            className="ir-ws-deleteModal-button-yes"
        
            style={{ width: "180px" }}
          >
            processing..
          </button>
          }
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionModal;
