import Cropper from "react-easy-crop";
import { useModalContext } from "./ModalContext";
import { useRef, useState } from "react";
import "./FileEditor.scss";

const FileEditor = () => {
  const {
    setFile,
    handleCancel,
    selectedImage,
    setIsEditorOpen,
    file,
    setThumbnail,
  } = useModalContext();
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  // const onCropComplete = (croppedArea, croppedAreaPixels) => {
  //   try {
  //     const canvas = document.createElement("canvas");
  //     const image = new Image();

  //     image.src = selectedImage;
  //     image.onload = () => {
  //       const scaleX = image.naturalWidth / image.width;
  //       const scaleY = image.naturalHeight / image.height;

  //       canvas.width = 238;
  //       canvas.height = 300;

  //       const ctx = canvas.getContext("2d");

  //       ctx.drawImage(
  //         image,
  //         croppedAreaPixels.x * scaleX,
  //         croppedAreaPixels.y * scaleY,
  //         croppedAreaPixels.width * scaleX,
  //         croppedAreaPixels.height * scaleY,
  //         0,
  //         0,
  //         canvas.width,
  //         canvas.height
  //       );

  //       const base64Image = canvas.toDataURL("image/jpeg");
  //       console.log(base64Image);
  //       setThumbnail(base64Image);
  //       setCroppedImageUrl(base64Image);
  //     };
  //   } catch (error) {
  //     console.error("Error cropping image:", error);
  //   }
  // };
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    try {
      const image = new Image();
      image.src = selectedImage;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        canvas.width = croppedAreaPixels.width;
        canvas.height = croppedAreaPixels.height;

        const ctx = canvas.getContext("2d");

        // Use the original image's data to draw the cropped portion
        ctx.drawImage(
          image,
          croppedAreaPixels.x * scaleX,
          croppedAreaPixels.y * scaleY,
          croppedAreaPixels.width * scaleX,
          croppedAreaPixels.height * scaleY,
          0,
          0,
          croppedAreaPixels.width,
          croppedAreaPixels.height
        );

        const base64Image = canvas.toDataURL("image/webp");
        console.log(base64Image);
        setThumbnail(base64Image);
        setCroppedImageUrl(base64Image);
      };
    } catch (error) {
      console.error("Error cropping image:", error);
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    const fileName = selectedImage.split("/").pop();
    if (croppedImageUrl) {
      const fileObject = {
        fullName: fileName,
        height: crop.height,
        imageBase64: croppedImageUrl,
        mimeType: "image/webp",
        width: crop.width,
      };

      setFile(fileObject);

      setIsEditorOpen(false);
    }
  };
  return (
    <div>
      <div className="ir-ws-webinar-image-crop-container">
        <Cropper
          image={selectedImage}
          crop={crop}
          zoom={zoom}
          aspect={238 / 300}
          cropSize={{ width: 238, height: 300 }}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          style={{ background: "red" }}
        />
      </div>

      <div className="ir-ws-webinar-image-editor-btn-container ">
        <div className="ir-ws-webinar-image-controls">
          <div
            className="ir-ws-zoom-button"
            onClick={(e) => {
              e.preventDefault();
              setZoom(zoom + 0.03);
            }}
          >
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              height="16"
              width="16"
              viewBox="0 0 512 512"
            >
              <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM184 296c0 13.3 10.7 24 24 24s24-10.7 24-24V232h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H232V120c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H120c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z" />
            </svg> */}
            <img
              src={require("../../assets/images/chat-images/magnifying-glass.png")}
              className="ir-ws-zoom-button-images"
            />
          </div>
          <div
            className="ir-ws-zoom-button"
            onClick={(e) => {
              e.preventDefault();
              setZoom(zoom - 0.03);
            }}
          >
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              height="16"
              width="16"
              viewBox="0 0 512 512"
            >
              <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM136 184c-13.3 0-24 10.7-24 24s10.7 24 24 24H280c13.3 0 24-10.7 24-24s-10.7-24-24-24H136z" />
            </svg> */}
            <img
              src={require("../../assets/images/chat-images/magnifying-glass (1).png")}
              className="ir-ws-zoom-button-images"
            />
          </div>
        </div>

        {/* <button
          className="ir-ws-webinar-image-editor-save-btn ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
          onClick={handleSave}
        >
          Save
        </button> */}
        <div className="ir-ws-text-center ir-ws-default-btn-container">
          <button
            className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
            onClick={handleSave}
            style={{ width: "150px", height: "50px" }}
          >
            <span>Save</span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default FileEditor;
