import {
  useContentShareState,
  useLocalVideo,
} from "amazon-chime-sdk-component-library-react";
import { useEffect, useRef, useState } from "react";
import "./JoinMeetingForm.scss";
import PresenterScreen from "./PresenterScreen";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";

const JoinMeetingForm = () => {
  const [presenterScreen, setPresenterScreen] = useState(false);
  const { isVideoEnabled } = useLocalVideo();
  const { isLocalUserSharing } = useContentShareState();
  const [isMicOn, setIsMicOn] = useState(false);
  const [videoState, setVideoState] = useState(false);

  const {
    meetingManager,
    videoTiles,
    urlMeetingId,
    username,
    setUsername,
    passCode,
    setPassCode,
    handleJoinPresenterUrl,
    webinarData,
    isPresenterjoin,
  } = useModalContext();

  const [countdownComplete, setCountdownComplete] = useState(false);
  const [tenMins, setTenMins] = useState(false);
  //Decoding Base 64 and extracting webinar data from webinar Id
  const { joinUrl } = useParams();

  function decodeBase64(encodedString) {
    const decodedString = atob(encodedString);
    return decodedString;
  }

  const decodedString = decodeBase64(joinUrl);
  const webinarId = JSON.parse(decodedString)["webianrId"];
  const selectedWebinar = webinarId
    ? webinarData.find((webinar) => webinar.id === webinarId)
    : null;
  // console.log(selectedWebinar);
  //Function for converting UTC time into local time and showing it

  function convertUTCToLocalAndFormat(utcTimeString) {
    const utcDateTime = new Date(utcTimeString);
    const localTimeZoneOffset = new Date().getTimezoneOffset();
    const localDateTime = new Date(
      utcDateTime.getTime() - localTimeZoneOffset * 60000
    );
    const localTimeFormat = localDateTime.toISOString().slice(0, 16);
    const inputDate = new Date(localTimeFormat);
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const outputString = inputDate.toLocaleString("en-US", options);
    return outputString;
  }
  //Function for Showing Countdown timer of meeting
  const targetDate = new Date(selectedWebinar?.dateAndTime);
  const calculateTimeRemaining = () => {
    const now = new Date();
    const difference = targetDate - now;
    if (difference <= 0) {
      setCountdownComplete(true);
      return { hours: 0, minutes: 0, seconds: 0 };
    }
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return { hours, minutes, seconds };
  };
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining);
  useEffect(() => {
    const interval = setInterval(() => {
      const remainingTime = calculateTimeRemaining();
      setTimeRemaining(remainingTime);

      if (remainingTime.minutes < 10) {
        setTenMins(true);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  //Join Meeting function
  const handleJoin = async () => {
    if (!username || !passCode) return;
    await handleJoinPresenterUrl(joinUrl);
    setPresenterScreen(true);
  };

  //Function for turning on camera and mic before joining the meeting
  const videoRef = useRef(null);
  const [isWebcamOn, setIsWebcamOn] = useState(false);
  const [micStream, setMicStream] = useState(null);
  const startWebcam = async () => {
    try {
      setVideoState(true);
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setIsWebcamOn(true);
      }
    } catch (error) {
      console.error("Error accessing webcam:", error);
    }
  };

  const stopWebcam = () => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      const tracks = stream.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });

      videoRef.current.srcObject = null;
      setIsWebcamOn(false);
      setVideoState(false);
    }
  };

  const startMic = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMicStream(stream);
      setIsMicOn(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };
  const stopMic = () => {
    if (micStream) {
      micStream.getTracks().forEach((track) => {
        track.stop();
      });

      setMicStream(null);
      setIsMicOn(false);
    }
  };
  const formattedHours = String(timeRemaining.hours).padStart(2, "0");
  const formattedMinutes = String(timeRemaining.minutes).padStart(2, "0");
  const formattedSeconds = String(timeRemaining.seconds).padStart(2, "0");
  return (
    <>
      {presenterScreen && videoTiles && selectedWebinar?.id ? (
        <PresenterScreen
          username={username}
          isVideoEnabled={isVideoEnabled}
          meetingManager={meetingManager}
          webinarTitle={selectedWebinar?.title}
          urlMeetingId={urlMeetingId}
          isLocalUserSharing={isLocalUserSharing}
          videoState={videoState}
          isMicOn={isMicOn}
          webinarId={selectedWebinar?.id}
        />
      ) : (
        <div className="ir-bg-white ir-ws-webinar-meeting-login-container">
          <div className="ir-ws-webinar-meeting-login-inner-container">
            <div className="ir-ws-webinar-sub-inner-container">
              <div className="ir-ws-webinar-meeting-logo-container">
                <a href="/">
                  <img
                    src={require("../../../assets/images/ir4u2.png")}
                    alt="logo"
                    className="ir-ws-webinar-meeting-logo"
                  />
                </a>
              </div>
              <div className="ir-ws-gray-bg ir-ws-webinar-meeting-preview-container">
                <div className="ir-ws-webinar-meeting-preview-inner-container">
                  <div className="ir-ws-webinar-meeting-preview-sub-container">
                    <p className="ir-ws-webnar-meeting-webinar-name">
                      {selectedWebinar?.title}
                    </p>
                    <p className="ir-ws-webnar-meeting-webinar-time-date">
                      {selectedWebinar?.dateAndTime &&
                        convertUTCToLocalAndFormat(selectedWebinar.dateAndTime)}
                    </p>
                    {/* {selectedWebinar?.dateAndTime && !countdownComplete && (
                      <p className="ir-ws-webnar-meeting-webinar-time-date">
                        -----Timer-----
                      </p>
                    )} */}
                    {selectedWebinar?.dateAndTime ? (
                      countdownComplete ? (
                        <p className="ir-ws-webnar-meeting-webinar-join-meeting-text">
                          You can now join the meeting!
                        </p>
                      ) : (
                        <p className="ir-ws-webnar-meeting-webinar-timer">
                          {formattedHours} : {formattedMinutes} :{" "}
                          {formattedSeconds}
                        </p>
                      )
                    ) : null}
                    <div className="ir-ws-webinar-meeting-video-tile-preview">
                      <div className="ir-ws-webinar-video-title-preview-inner">
                        <div className="ir-ws-webinar-video-title-preview-inner-sub">
                          {videoState ? (
                            <div
                              style={{
                                position: "relative",
                                paddingTop: "56.25%",
                                width: "100%",
                              }}
                            >
                              <video
                                ref={videoRef}
                                autoPlay
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "8px",
                                }}
                              />
                            </div>
                          ) : (
                            <div className="ir-ws-webinar-meeting-blank-video">
                              <div className="ir-ws-position-absolute ir-ws-admin-video-off-container">
                                <img
                                  className="ir-ws-admin-video-off-img"
                                  src={require("../../../assets/images/video-off.png")}
                                  alt=""
                                />
                                <p className="ir-color-white ir-ws-admin-presenter-screen-off-text">
                                  Camera is off
                                </p>
                              </div>
                            </div>
                          )}

                          <div className="ir-ws-position-absolute ir-ws-audioVideo-control">
                            {isMicOn ? (
                              <span
                                className="material-symbols-outlined control-icon"
                                onClick={stopMic}
                              >
                                mic
                              </span>
                            ) : (
                              <span
                                className="material-symbols-outlined control-icon control-icon-inactive"
                                onClick={startMic}
                              >
                                mic_off
                              </span>
                            )}
                            {isWebcamOn ? (
                              <span
                                className="material-symbols-outlined control-icon"
                                onClick={stopWebcam}
                              >
                                videocam
                              </span>
                            ) : (
                              <span
                                className="material-symbols-outlined control-icon control-icon-inactive"
                                onClick={startWebcam}
                              >
                                videocam_off
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ir-ws-webinar-meeting-input-button-container">
                      <p className="ir-ws-webinar-meeting-presenter-name">
                        Name
                      </p>
                      <input
                        className="ir-ws-webinar-meeting-presenter-name-input"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      <p className="ir-ws-webinar-meeting-presenter-name ir-ws-webinar-meeting-presenter-passcode">
                        PassCode
                      </p>
                      <input
                        className="ir-ws-webinar-meeting-presenter-name-input"
                        value={passCode}
                        onChange={(e) => setPassCode(e.target.value)}
                      />

                      <button
                        className="ir-ws-webinar-meeting-join-button"
                        disabled={!tenMins}
                        onClick={handleJoin}
                      >
                        <span>Join</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isPresenterjoin && <Loader />}
    </>
  );
};

export default JoinMeetingForm;
