import {
  GlobalStyles,
  MeetingProvider,
  lightTheme,
} from "amazon-chime-sdk-component-library-react";
import { ThemeProvider } from "styled-components";
import JoinMeetingForm from "./containers/JoinMeetingForm";
import { useParams } from "react-router-dom";

const MeetingTest = () => {
  const { joinUrl } = useParams();
  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyles />
      <JoinMeetingForm joinUrl={joinUrl} />
    </ThemeProvider>
  );
};

export default MeetingTest;
