import React from "react";

const Progressbar = ({ progress }) => {
  const uploadBorderColors = {
    borderTopColor: progress >= 10 ? "#6842ef" : "#e9ecef",
    borderRightColor: progress >= 40 ? "#6842ef" : "#e9ecef",
    borderBottomColor: progress >= 70 ? "#6842ef" : "#e9ecef",
    borderLeftColor: progress >= 100 ? "#6842ef" : "#e9ecef",
    transform: "rotate(45deg)",
  };

  return (
    <section
      className="ir-course-preview-page-section ir-course-preview-loader-section"
      style={{ height: "100%", background: "transparent" }}
    >
      <div className="ir-course-preview-loader-container">
        {/* <div
          className="ir-course-preview-loader-box"
          style={uploadBorderColors}
        >
          <p
            className="ir-course-preview-upload-status-text"
            style={{ transform: "rotate(315deg)" }}
          >
            {progress}%
          </p>
        </div> */}
        <img
          src={require("../../../assets/images/loader.png")}
          alt="loader"
          className="ir-ws-course-promotional-video-loader"
          style={{ width: "120px" }}
        />
        <p className="ir-course-preview-loader-time-remaining-text">
          Video is being processed
        </p>
      </div>
    </section>
  );
};

export default Progressbar;
