import React from "react";
import Header from "../../components/Header/Header";
import Banner from "../../components/Banner/Banner";
import HomePreviewTabContainer from "../../components/HomePreviewTabContainer/HomePreviewTabContainer";
import HomeChatFeatureContainer from "../../components/HomeChatFeatureContainer/HomeChatFeatureContainer";
import HomeCoursesFeatureContainer from "../../components/HomeCoursesFeatureContainer/HomeCoursesFeatureContainer";
import HomeWebinarFeatureContainer from "../../components/HomeWebinarFeatureContainer/HomeWebinarFeatureContainer";
import HomeCasesFeatureContainer from "../../components/HomeCasesFeatureContainer/HomeCasesFeatureContainer";
import HomeExpertsContainer from "../../components/HomeExpertsContainer/HomeExpertsContainer";
import HomeJoinContainer from "../../components/HomeJoinContainer/HomeJoinContainer";
import Footer from "../../components/Footer/Footer";

const Home = () => {
    return (
        <div className="ir-ws-home-main-container">
            <Header />
            <Banner />
            <HomePreviewTabContainer />
            <HomeChatFeatureContainer />
            <HomeCoursesFeatureContainer />
            <HomeWebinarFeatureContainer />
            <HomeCasesFeatureContainer />
            <HomeExpertsContainer />
            <HomeJoinContainer />
            <Footer />
        </div>
    )
};
export default Home